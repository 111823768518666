import _str from 'underscore.string';
import React, { useState, useCallback } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';

import Helpers from 'helpers/helpers';
import {
  FormField,
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
} from 'components/forms/forms';
import I18nContext from 'contexts/i18n_context';
import { EmailAddress } from 'types/types';

const i18nScope = 'components.utils.one_click_unsubscribe';
const formI18nScope = `${i18nScope}.form`;
const successI18nScope = `${i18nScope}.success`;

// TODO use an enum for the possible setting names
export interface UnsubscribeFormValues {
  [key: string]: boolean;
}

interface OneClickUnsubscribeProps {
  emailAddress: EmailAddress;
  camelCaseSettingName: string;
  sentByText?: string | null;
  submitMainCallback: ({
    values,
    actions,
    onSuccessCallback,
  }: {
    values: UnsubscribeFormValues;
    actions: FormikHelpers<UnsubscribeFormValues>;
    onSuccessCallback: () => void;
  }) => void;
}

function UnsubscribeForm({
  emailAddress,
  camelCaseSettingName,
  sentByText = null,
  submitMainCallback,
  onSuccessCallback,
  onCancelCallback,
}: OneClickUnsubscribeProps & {
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const _onSubmit = function (
    values: UnsubscribeFormValues,
    actions: FormikHelpers<UnsubscribeFormValues>,
  ) {
    submitMainCallback({ values, actions, onSuccessCallback });
  };

  const descriptionTranslationKey =
    sentByText === null ||
    typeof sentByText === 'undefined' ||
    _str.isBlank(sentByText)
      ? 'description_without_sent_by'
      : 'description_with_sent_by';

  return (
    <Formik
      initialValues={{ [camelCaseSettingName]: false }}
      onSubmit={_onSubmit}
    >
      {({ status, isSubmitting }) => (
        <Form className="basic-form" noValidate>
          <p>
            {i18n.t(descriptionTranslationKey, {
              scope: formI18nScope,
              values: {
                emailAddress: emailAddress,
                sentBy: sentByText,
              },
            })}
          </p>

          <FormField type="hidden" name={camelCaseSettingName} />

          <FormActions className="rtl">
            <FormActionButton
              className="primary"
              text={i18n.t('actions.submit', { scope: formI18nScope })}
              isSubmitting={isSubmitting}
            />
            <FormActionButton
              className="secondary"
              text={i18n.t('actions.cancel', { scope: formI18nScope })}
              isDisabled={isSubmitting}
              handleClick={onCancelCallback}
            />
            <FormServerErrorMessages
              serverErrors={status?.serverErrors?.base}
            />
          </FormActions>
        </Form>
      )}
    </Formik>
  );
}

function SuccessView({ redirectToUrl }: { redirectToUrl: string }) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div>
      <p>
        {i18n.t('message', { scope: successI18nScope })}{' '}
        <a href={redirectToUrl}>
          {i18n.t('back_home_link', { scope: successI18nScope })}
        </a>
      </p>
    </div>
  );
}

export default function OneClickUnsubscribe(props: OneClickUnsubscribeProps) {
  const { i18n } = React.useContext(I18nContext);

  const [
    shouldShowUnsubscribedSuccesssfully,
    setShouldShowUnsubscribedSuccesssfully,
  ] = useState(false);

  const redirectToUrl = '/';

  const onSuccessCallback = useCallback(
    () => setShouldShowUnsubscribedSuccesssfully(true),
    [],
  );
  const onCancelCallback = useCallback(
    () => Helpers.Utils.redirectTo(redirectToUrl),
    [],
  );

  return (
    <div className="unsubscribe center-skinny center-skinny-500">
      <h1>{i18n.t('heading', { scope: i18nScope })}</h1>
      {shouldShowUnsubscribedSuccesssfully ? (
        <SuccessView redirectToUrl={redirectToUrl} />
      ) : (
        <UnsubscribeForm
          {...props}
          onCancelCallback={onCancelCallback}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </div>
  );
}
