import React from 'react';
import classNames from 'classnames';

interface FormActionsProps {
  className?: string;
  children: React.ReactNode;
}

export default function FormActions({ children, className }: FormActionsProps) {
  return (
    <div className={classNames('form-actions', className)}>{children}</div>
  );
}
