import { assertNotNullOrUndefined } from 'h';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Club, ClubCustomEmail } from '__generated__/graphql';

import Icon, { iconType } from 'components/utils/icon';
import BasicModal from 'components/utils/basic_modal';
import { ContentEditablePresenter } from 'components/utils/content_editable_presenter';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';
import ErrorMessageView from 'components/utils/error_message_view';

const GET_ADMIN_CLUB_CUSTOM_EMAIL_SHOW_CONTENT = gql(`
  query GetAdminClubCustomEmailShowContent($clubId: ID!, $customEmailId: ID!) {
    club(id: $clubId) {
      id
      customEmail(id: $customEmailId) {
        id
        title
        body
      }
    }
  }
`);

function CustomEmailContentLoadingPlaceholder({
  style,
}: {
  style?: React.CSSProperties;
}) {
  return (
    <div style={style}>
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="py-2"
        widthPercent="60%"
      />
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="mt-2"
        widthPercent="90%"
      />
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="mt-2"
        widthPercent="95%"
      />
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="mt-2"
        widthPercent="90%"
      />
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="mt-2"
        widthPercent="93%"
      />
    </div>
  );
}

function CustomEmailLoadingError({ style }: { style?: React.CSSProperties }) {
  return (
    <div className="vertical-center center" style={style}>
      <ErrorMessageView />
    </div>
  );
}

function CustomEmailContentPresenter({
  clubId,
  targetCustomEmailId,
  style,
}: {
  clubId: Club['id'];
  targetCustomEmailId: ClubCustomEmail['id'];
  style?: React.CSSProperties;
}) {
  const { loading, error, data } = useQuery(
    GET_ADMIN_CLUB_CUSTOM_EMAIL_SHOW_CONTENT,
    {
      variables: {
        clubId,
        customEmailId: targetCustomEmailId,
      },
    },
  );

  if (loading) return <CustomEmailContentLoadingPlaceholder style={style} />;
  if (error) return <CustomEmailLoadingError style={style} />;

  const customEmail = data?.club?.customEmail;
  assertNotNullOrUndefined(customEmail);

  return (
    <div>
      <h2>{customEmail.title}</h2>
      <ContentEditablePresenter content={customEmail.body} />
    </div>
  );
}

export default function ShowCustomEmailContentButton({
  classes,
  clubId,
  targetCustomEmailId,
  tooltipText,
}: {
  classes?: string;
  clubId: Club['id'];
  targetCustomEmailId: ClubCustomEmail['id'];
  tooltipText: string;
}) {
  const [shouldShowModal, setShouldShowModal] = useState(false);

  return (
    <span className={classes}>
      <Icon
        type={iconType.PREVIEW}
        tooltipText={tooltipText}
        onClick={() => setShouldShowModal(true)}
      />
      {shouldShowModal && (
        <BasicModal
          className="basic-modal-content"
          onRequestCloseCallback={() => setShouldShowModal(false)}
          shouldCloseOnOverlay={true}
          isOpen={true}
          style={{ content: { maxWidth: 650 } }}
        >
          <CustomEmailContentPresenter
            clubId={clubId}
            targetCustomEmailId={targetCustomEmailId}
            style={{ minHeight: 300 }}
          />
        </BasicModal>
      )}
    </span>
  );
}
