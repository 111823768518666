import _str from 'underscore.string';
import React, { useCallback } from 'react';
import { Field, useField } from 'formik';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';

type FormUrlFieldProps = Omit<FormFieldwrapperProps, 'children'> & {
  placeholder?: string;
  autoFocus?: boolean;
};

export default function FormUrlField({
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
  ...otherProps
}: FormUrlFieldProps) {
  const [, , helpers] = useField(name);
  const { setValue } = helpers;

  const appendHttpIfNeededCallback = useCallback(
    (currentValue: string) => {
      if (_str.isBlank(currentValue)) {
        // do nothing
      } else if (
        currentValue.startsWith('http://') ||
        currentValue.startsWith('https://')
      ) {
        // also do nothing, the url looks good
      } else {
        // okay, let's append it
        setValue(`http://${currentValue}`);
      }
    },
    [name, setValue],
  );

  const onBlurCallback = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      appendHttpIfNeededCallback(event.target?.value || '');
    },
    [appendHttpIfNeededCallback],
  );

  const onKeyDownCallback = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        appendHttpIfNeededCallback(event.target?.value || '');
      }
    },
    [appendHttpIfNeededCallback],
  );

  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      label={label}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-field form-url-field">
        <Field
          type="text"
          name={name}
          onBlur={onBlurCallback}
          onKeyDown={onKeyDownCallback}
          {...otherProps}
        />
      </div>
    </FormFieldWrapper>
  );
}
