import React, { useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import Store from 'helpers/store';
import I18nContext from 'contexts/i18n_context';

import LoginSignup from 'components/login_signup';
import { ClubProfilePic, picSize } from 'components/utils/profile_pic';
import { AuthModes } from 'components/login_signup/auth_modes';

const i18nScope = 'components.clubs.auth.club_auth';

interface ClubAuthProps {
  initialMode: AuthModes;
  afterAuthenticationUrl: string;
}
export default function ClubAuthWrapper(props: ClubAuthProps) {
  return (
    <RootAppWrapper>
      <ClubAuth {...props} />
    </RootAppWrapper>
  );
}

function ClubAuth({
  initialMode,
  afterAuthenticationUrl,
  ...props
}: ClubAuthProps) {
  const { i18n } = React.useContext(I18nContext);
  const [store] = useState(() => Store.parseInitData(props));
  const club = store.getCurrentClub();

  return (
    <div id="club-auth" className="body-content vertical-center">
      <div className="club-auth-inner">
        <div className="club-info vertical-center">
          <div>
            <ClubProfilePic
              club={club}
              size={picSize.LARGE}
              classes="inline-block mb-2"
            />
            <h2>
              {i18n.t(`${initialMode}.title`, {
                scope: i18nScope,
                values: { clubName: club.name },
              })}
            </h2>
          </div>
        </div>
        <div className="login-signup-wrapper elevate-content">
          <LoginSignup
            initialMode={initialMode}
            afterAuthenticationUrl={afterAuthenticationUrl}
          />
        </div>
      </div>
    </div>
  );
}
