import h from 'h';
import _ from 'underscore';
import _str from 'underscore.string';
import { DeprecatedAny } from 'types/types';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosTransformer,
  CancelToken,
  CancelTokenSource,
} from 'axios';

import UtilsHelper from 'helpers/utils_helper';

type APIRequestHeaders = DeprecatedAny;
type APIRequestParams = DeprecatedAny;

interface APIRequestProps {
  url: string;
  cancelToken?: CancelToken;
  params?: APIRequestParams;
  headers?: APIRequestHeaders;
  options?: {
    shouldUseFormData?: boolean;
  };
}

enum AxiosMethodName {
  POST = 'post',
  PUT = 'put',
}

// keeping this up to so it's a littl easier
// to define the types when I switch to typescript
// Options
// - shouldUseFormData, optional
export default class APIHelper {
  // https://axios-http.com/docs/cancellation
  static createCancelTokenSource(): CancelTokenSource {
    return axios.CancelToken.source();
  }

  static wasRequestCanceled(error: AxiosError<DeprecatedAny>): boolean {
    return axios.isCancel(error);
  }

  static get({
    url,
    cancelToken,
    params,
    headers,
  }: Pick<
    APIRequestProps,
    'url' | 'params' | 'headers' | 'cancelToken'
  >): Promise<AxiosResponse<unknown>> {
    const config: AxiosRequestConfig = this._getConfig(headers);

    if (typeof params !== 'undefined') {
      // we hide the camelCase to snake_case conversion in the API helper
      config.params = UtilsHelper.deepTransformKeysToSnakeCase(params);
    }
    if (!h.isNullOrUndefined(cancelToken)) {
      config.cancelToken = cancelToken;
    }

    return axios.get<unknown>(url, config);
  }

  static post({
    url,
    params,
    headers,
    options,
  }: Pick<APIRequestProps, 'url' | 'params' | 'headers' | 'options'>): Promise<
    AxiosResponse<unknown>
  > {
    return this._sendRequest({
      methodName: AxiosMethodName.POST,
      url,
      params,
      headers,
      options,
    });
  }

  static put({
    url,
    params,
    headers,
    options,
  }: Pick<APIRequestProps, 'url' | 'params' | 'headers' | 'options'>): Promise<
    AxiosResponse<unknown>
  > {
    return this._sendRequest({
      methodName: AxiosMethodName.PUT,
      url,
      params,
      headers,
      options,
    });
  }

  static delete({
    url,
    headers,
  }: Pick<APIRequestProps, 'url' | 'headers'>): Promise<
    AxiosResponse<unknown>
  > {
    return axios.delete<unknown>(url, this._getConfig(headers));
  }

  // -- PRIVATE METHODS --

  static _sendRequest({
    methodName,
    url,
    params,
    headers,
    options = {},
  }: APIRequestProps & { methodName: AxiosMethodName }): Promise<
    AxiosResponse<unknown>
  > {
    params = UtilsHelper.deepTransformKeysToSnakeCase(params || {}); // directly instead of using transform fn

    let data: APIRequestParams | FormData = params ?? {};

    // the form data option is useful when we want to upload files
    // like images with the payload. Not that there's a bug with this
    // option where it doesn't handle arrays well. Particularly when
    // the array is empty. Refer to issue#113 and issue#114
    if (options?.shouldUseFormData) {
      const formData = new FormData();
      this._buildFormData(formData, data);
      data = formData;
    }

    switch (methodName) {
      case AxiosMethodName.POST:
        return axios.post<unknown>(url, data, this._getConfig(headers));
      case AxiosMethodName.PUT:
        return axios.put<unknown>(url, data, this._getConfig(headers));
      default:
        return h.throwExhaustiveError('unsupported method name', methodName);
    }
  }

  static _getConfig(
    headers: APIRequestHeaders | null = null,
  ): AxiosRequestConfig {
    headers = _.extend(headers ?? {}, this._getCSRFHeader());

    //const paramsToSnakeCase = (data, headers) => { return _str.isBlank(data) ? data : UtilsHelper.deepTransformKeysToSnakeCase(data) }
    const parseJSON: AxiosTransformer = (data) => {
      return _str.isBlank(data) ? data : JSON.parse(data);
    };
    const responseToCamelCase: AxiosTransformer = (data) => {
      return _str.isBlank(data)
        ? data
        : UtilsHelper.deepTransformKeysToCamelCase(data);
    };

    return {
      headers: headers,
      // we need to return a FormData object in the transform fn, easier to just to pass in an
      // already transformed object
      //transformRequest: [paramsToSnakeCase],
      transformResponse: [parseJSON, responseToCamelCase],
    };
  }

  static _getCSRFHeader(): { 'X-CSRF-Token': string } {
    return { 'X-CSRF-Token': UtilsHelper.getCSRFToken() };
  }

  static _buildFormData(
    formData: FormData,
    data: APIRequestParams,
    parentKey?: string,
  ) {
    // TODO fix issue#113 doesn't handle empty arrays properly, the attribute
    // is not included in the payload.
    if (
      !h.isNullOrUndefined(data) &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this._buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key,
        );
      });
    } else {
      const value = data === null ? '' : data;
      if (typeof parentKey === 'undefined') {
        throw 'parent key is undefined';
      }
      formData.append(parentKey, value);
    }
  }
}
