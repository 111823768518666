import React from 'react';
import { Field } from 'formik';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';

type FormFieldProps = Omit<FormFieldwrapperProps, 'children'> & {
  children?: React.ReactNode;
  // otherProps
  placeholder?: string;
  autoFocus?: boolean;
  rows?: string;
  type?: string;
  component?: string;
  as?: string;
  onBlur?: () => void;
  // for number fields
  min?: string;
  step?: string;
};

export default function FormField({
  children,
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
  ...otherProps // eg. autoFocus, placeholder, rows ....
}: FormFieldProps) {
  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      label={label}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-field">
        <Field name={name} {...otherProps}>
          {children}
        </Field>
      </div>
    </FormFieldWrapper>
  );
}
