import React from 'react';
import I18nContext from 'contexts/i18n_context';
import {
  ClubMembership,
  ClubMembershipStateEnum,
  ClubMembershipTag,
} from '__generated__/graphql';

import classNames from 'classnames';

const i18nScope = 'clubs.admin.memberships.membership_state_badge';

function MembershipStateBadges({
  membership,
  hideActiveTag,
  hideExpiredTag,
}: {
  membership: Pick<
    ClubMembership,
    'state' | 'isActive' | 'hasJoinedRecently' | 'hasExpiredRecently'
  >;
  hideActiveTag?: boolean;
  hideExpiredTag?: boolean;
}) {
  const { i18n } = React.useContext(I18nContext);

  return (
    <>
      {membership.isActive && !hideActiveTag && (
        <span className="badge state-active">
          {i18n.t('active', { scope: i18nScope })}
        </span>
      )}
      {!membership.isActive && !hideExpiredTag && (
        <span className="badge state-expired">
          {i18n.t('expired', { scope: i18nScope })}
        </span>
      )}
      {membership.hasJoinedRecently && (
        <span className="badge state-joined-recently">
          {i18n.t('has_joined_recently', { scope: i18nScope })}
        </span>
      )}
      {membership.hasExpiredRecently && (
        <span className="badge state-expired-recently">
          {i18n.t('has_expired_recently', { scope: i18nScope })}
        </span>
      )}
      {membership.state === ClubMembershipStateEnum.RenewingSoon && (
        <span className="badge state-renewing-soon">
          {i18n.t('renewing_soon', { scope: i18nScope })}
        </span>
      )}
      {membership.state === ClubMembershipStateEnum.Overdue && (
        <span className="badge state-overdue">
          {i18n.t('overdue', { scope: i18nScope })}
        </span>
      )}
    </>
  );
}

function MembershipTagBadges({
  membershipTags,
}: {
  membershipTags: Pick<ClubMembershipTag, 'name'>[];
}) {
  return (
    <>
      {membershipTags.map((membershipTag) => (
        <span className="badge membership-tag">{membershipTag.name}</span>
      ))}
    </>
  );
}

export default function MembershipBadges({
  classes,
  membership,
  membershipTags,
  hideActiveTag,
  hideExpiredTag,
}: {
  classes?: string;
  membership: Pick<
    ClubMembership,
    'state' | 'isActive' | 'hasJoinedRecently' | 'hasExpiredRecently'
  >;
  membershipTags?: Pick<ClubMembershipTag, 'name'>[];
  hideActiveTag?: boolean;
  hideExpiredTag?: boolean;
}) {
  return (
    <div className={classNames('membership-badges', classes)}>
      <MembershipStateBadges
        membership={membership}
        hideActiveTag={hideActiveTag}
        hideExpiredTag={hideExpiredTag}
      />

      {membershipTags && membershipTags.length > 0 && (
        <MembershipTagBadges membershipTags={membershipTags} />
      )}
    </div>
  );
}
