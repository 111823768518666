import React from 'react';
import ApolloWrapper from 'components/utils/apollo_wrapper';
import { I18nContextProvider } from 'contexts/i18n_context';

// since we have multiple root components - it's nice to have
// a single place that we can update when making changes.
// so for example, if we want to add another provider we can do
// here instead of having to update all the root components which
// is a pain
export default function RootAppWrapper({
  children,
}: {
  children: React.ReactElement;
}) {
  return (
    <I18nContextProvider>
      <ApolloWrapper>{children}</ApolloWrapper>
    </I18nContextProvider>
  );
}
