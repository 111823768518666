import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Club, ClubCustomEmail } from '__generated__/graphql';

import { sendMutation } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import Icon, { iconType } from 'components/utils/icon';

const ADMIN_CLUB_CUSTOM_EMAIL_DUPLICATE = gql(`
  mutation AdminClubCustomEmailDuplicate($input: ClubCustomEmailDuplicateInput!) {
    clubCustomEmailDuplicate(input: $input) {
      customEmail {
        id
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function DuplicateCustomEmailButton({
  clubId,
  targetCustomEmailId,
  tooltipText,
}: {
  clubId: Club['id'];
  targetCustomEmailId: ClubCustomEmail['id'];
  tooltipText: string;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [duplicateMutation] = useMutation(ADMIN_CLUB_CUSTOM_EMAIL_DUPLICATE);

  const navigate = useNavigate();

  const duplicateCustomEmail = () => {
    sendMutation({
      mutationName: 'clubCustomEmailDuplicate',
      main: () =>
        duplicateMutation({
          variables: {
            input: {
              clubId,
              targetCustomEmailId,
            },
          },
        }),
      successCallback: (mutationPayload) => {
        const duplicatedCustomEmail =
          mutationPayload?.clubCustomEmailDuplicate?.customEmail;
        assertNotNullOrUndefined(duplicatedCustomEmail);
        navigate(`../drafts/${duplicatedCustomEmail.id}/edit`);
      },
      allErrorsCallback: () => {
        alert(i18n.t('forms.errors.unknown_error'));
      },
    });
  };

  return (
    <span>
      <Icon
        type={iconType.COPY}
        tooltipText={tooltipText}
        onClick={duplicateCustomEmail}
      />
    </span>
  );
}
