import React from 'react';
import classNames from 'classnames';

// export type PillValue = string | number | boolean;

export interface Pill<T> {
  value: T;
  element: React.ReactElement | string;
}

interface PillGroupProps<T> {
  classes?: string;
  value: T;
  pills: Pill<T>[];
  onClick: (value: T) => void;
  isVertical?: boolean;
}

export default function PillGroup<T = boolean>({
  classes,
  value,
  pills,
  onClick,
  isVertical = false,
}: PillGroupProps<T>) {
  return (
    <div
      className={classNames('pill-group', classes, { vertical: isVertical })}
    >
      {pills.map((pill, index) => (
        <div
          key={index}
          className={classNames('pill', {
            active: value === pill.value,
            'pill-start': index === 0,
            'pill-end': index === pills.length - 1,
          })}
          onClick={() => onClick(pill.value)}
        >
          {pill.element}
        </div>
      ))}
    </div>
  );
}
