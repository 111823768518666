import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import UtilsHelper from 'helpers/utils_helper';
import { relayStylePagination } from '@apollo/client/utilities';

const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-CSRF-Token': UtilsHelper.getCSRFToken(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      // they key is the __typeName in __generated__/graphql.ts
      Query: {
        fields: {
          clubs: relayStylePagination(),
          companies: relayStylePagination(),
        },
      },
      Club: {
        fields: {
          memberships: relayStylePagination(['search', 'filters']),
          orders: relayStylePagination(),
        },
      },
      ClubMembership: {
        fields: {
          emails: relayStylePagination(),
          membershipAuditLogs: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default function ApolloWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
