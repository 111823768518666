import React from 'react';
import { Outlet } from 'react-router-dom';
import { TabNavbar, TabLinkConfig } from 'components/utils/tabbed_view';

export default function AdminPageLayout({
  id,
  navbarLinkConfigs,
}: {
  id?: string;
  navbarLinkConfigs?: TabLinkConfig[];
}) {
  return (
    <div id={id} className="admin-page-layout">
      {navbarLinkConfigs && <TabNavbar tabLinkConfigs={navbarLinkConfigs} />}
      <Outlet />
    </div>
  );
}
