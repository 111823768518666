import { useState, useEffect } from 'react';
import Store from 'helpers/store';
import Helpers from 'helpers/helpers';
import { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { DeprecatedAny } from 'types/types';

// Handles all the boilder plate to load data for a component
// and showing a placeholder in the interim

export type FetchStoreDataCallback = ({
  cancelToken,
}: {
  cancelToken: CancelToken;
}) => Promise<AxiosResponse<unknown>>;

export type TransformResponseDataForStoreCallback = ({
  responseData,
}: {
  responseData: DeprecatedAny;
}) => void;

export type AfterStoreCreatedCallback = ({ store }: { store: Store }) => void;

interface LoadingViewProps {
  children: ({ store }: { store: Store }) => React.ReactElement;
  fetchStoreDataCallback: FetchStoreDataCallback;
  transformResponseDataForStoreCallback?: TransformResponseDataForStoreCallback;
  afterStoreCreatedCallback?: AfterStoreCreatedCallback;
  fetchErrorCallback?: (error: Error) => void;
  loadingElement: React.ReactElement;
}

export default function LoadingView({
  children,
  fetchStoreDataCallback,
  transformResponseDataForStoreCallback,
  afterStoreCreatedCallback,
  fetchErrorCallback,
  loadingElement,
}: LoadingViewProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [store, setStore] = useState<Store | null>(null);
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null);

  // we store the current fetch callback to detect when a new fetch has been triggered
  // we can't rely on useEffect since it is only triggered after the render so
  // we without it we would render the children with the old store
  const [currentFetchCallback, setCurrentFetchCallback] = useState(
    () => fetchStoreDataCallback,
  );
  const hasTriggeredFetch = currentFetchCallback !== fetchStoreDataCallback;

  useEffect(() => {
    // before we start a new one, let's cancel the pending one if it exists
    cancelTokenSource?.cancel();
    setStore(null); // and reset the store
    setCurrentFetchCallback(() => fetchStoreDataCallback);

    const newCancelTokenSource = Helpers.API.createCancelTokenSource();
    setCancelTokenSource(newCancelTokenSource);

    setIsLoading(true);
    fetchStoreDataCallback({ cancelToken: newCancelTokenSource.token })
      .then((response) => {
        setCancelTokenSource(null);

        const transformedResponseData =
          transformResponseDataForStoreCallback?.({
            responseData: response.data,
          }) ?? response.data;
        const newStore = Store.parseInitData(transformedResponseData);
        setStore(newStore);
        afterStoreCreatedCallback?.({ store: newStore });
      })
      .catch((error) => {
        if (Helpers.API.wasRequestCanceled(error)) {
          return; // do nothing
        }

        console.error(error);
        fetchErrorCallback?.(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // cleanup
    return () => {
      cancelTokenSource?.cancel();
    };
  }, [fetchStoreDataCallback]);

  return !(hasTriggeredFetch || isLoading) && store
    ? children({ store })
    : loadingElement;
}
