import React from 'react';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';
import {
  MembershipProfilePic,
  LoadingPlaceholderProfilePic,
  picSize,
} from 'components/utils/profile_pic';
import { ClubMembership } from '__generated__/graphql';
import { NavLink } from 'react-router-dom';

export function AdminClickableMembershipPreviewLoadingPlaceholder() {
  return (
    <div className="admin-clickable-membership-preview">
      <AdminMembershipPreviewLoadingPlaceholder />
    </div>
  );
}

export function AdminMembershipPreviewLoadingPlaceholder() {
  return (
    <div className="admin-membership-preview">
      <LoadingPlaceholderProfilePic size={picSize.MEDIUM} />
      <div className="info">
        <LoadingPlaceholder type={placeholderType.BAR} />
      </div>
    </div>
  );
}

interface AdminMembershipPreviewProps {
  membership: Pick<ClubMembership, 'id' | 'fullName' | 'profilePicUrl'>;
  size?: picSize;
  extraInfo?: string | React.ReactElement;
  secondaryInfo?: string | React.ReactElement;
}

// TODO: issue#384 we shouldn't have to pass in a `to` property
// with the relative route of the member's info page. We have the
// membership id so we should be able to determine the link. we
// should switch away from relative links to absolute links. refer
// to the issue for more details.
export function AdminClickableMembershipPreview({
  to,
  ...props
}: AdminMembershipPreviewProps & {
  to: string;
}) {
  return (
    <NavLink className="admin-clickable-membership-preview" to={to}>
      <AdminMembershipPreview {...props} />
    </NavLink>
  );
}

export function AdminMembershipPreview({
  membership,
  size = picSize.MEDIUM,
  extraInfo,
  secondaryInfo,
}: AdminMembershipPreviewProps) {
  return (
    <div className="admin-membership-preview">
      <MembershipProfilePic membership={membership} size={size} />
      <div className="info">
        <div className="info-name">
          {membership.fullName}
          {extraInfo && <span className="ml-1">{extraInfo}</span>}
        </div>
        {secondaryInfo && (
          <div className="info-secondary small">{secondaryInfo}</div>
        )}
      </div>
    </div>
  );
}
