import React from 'react';

import BasicModal from 'components/utils/basic_modal';
import { AuthModes } from 'components/login_signup/auth_modes';
import { LoginSignup } from 'components/login_signup';

// TODO eventually we want to support afterAuthenticationCallback function
// but we will still need to specify afterAuthenticationUrl because of
// omniauth providers

export default function AuthenticationModal({
  afterAuthenticationUrl,
  canClose = true,
  onCancel,
}: {
  afterAuthenticationUrl: string;
  canClose?: boolean;
  onCancel: () => void;
}) {
  // TODO i18n, ideally there's a way to read from a i18n provider
  // instead of having to pass it down all the way to this modal
  return (
    <BasicModal
      className="basic-modal-content authentication-modal"
      onRequestCloseCallback={onCancel}
      shouldShowCloseButton={canClose}
      isOpen={true}
    >
      <LoginSignup
        initialMode={AuthModes.SIGN_UP}
        afterAuthenticationUrl={afterAuthenticationUrl}
      />
    </BasicModal>
  );
}
