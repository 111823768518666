import _str from 'underscore.string';
import React from 'react';
import classNames from 'classnames';
import { EmergencyContact } from 'types/types';

export default function EmergencyContactInfo({
  classes,
  emergencyContact,
}: {
  classes?: string | null;
  emergencyContact: Pick<
    EmergencyContact,
    'name' | 'phoneNumber' | 'relationship'
  >;
}) {
  return (
    <div className={classNames('emergency-contact', classes)}>
      <div className="name">{emergencyContact.name}</div>
      <div className="phone small mt-1">{emergencyContact.phoneNumber}</div>
      {!_str.isBlank(emergencyContact.relationship) && (
        <div className="info small mt-1">{emergencyContact.relationship}</div>
      )}
    </div>
  );
}
