import { arrayFilterNulls, assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';

import AdminTable from 'components/utils/admin/admin_table';
import AdminDashboard, {
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import LoadMore from 'components/utils/load_more';
import { picSize } from 'components/utils/profile_pic';
import Icon, { iconType } from 'components/utils/icon';
import { AdminMembershipPreview } from 'components/utils/admin/admin_membership_preview';
import ExportOrdersButton from 'components/clubs/admin/money/orders/export_orders_button';

const i18nScope = 'clubs.admin.money.orders.index';
const tableScope = `${i18nScope}.table`;

const GET_ADMIN_CLUB_ORDERS = gql(`
  query GetAdminClubOrders($clubId: ID!, $cursor: String) {
    club(id: $clubId) {
      id
      orders(after: $cursor) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            confirmationCode
            totalAmountInCents
            createdAt
            purchaser {
              id
              fullName
              profilePicUrl
            }
          }
        }
      }
    }
  }
`);

export default function OrdersIndexPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();

  const { loading, error, data, fetchMore } = useQuery(GET_ADMIN_CLUB_ORDERS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading || data === null)
    return <AdminDashboardLoadingPlaceholder columnWidths={[15, 40, 35, 10]} />;
  if (error) return <ErrorPage />;
  const ordersConnection = data?.club?.orders;
  assertNotNullOrUndefined(ordersConnection);
  const pageInfo = ordersConnection?.pageInfo;
  const orders = arrayFilterNulls(
    (ordersConnection?.edges ?? []).map((e) => e?.node),
  );

  const tdStyle = { verticalAlign: 'middle' };

  const orderedAtFormatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };

  return (
    <div id="clubs-admin-money-orders-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={<ExportOrdersButton currentClubId={String(currentClubId)} />}
        contentClasses="elevate-content min-height-page"
      >
        <AdminTable
          items={orders}
          emptyTableElement={
            <p>{i18n.t('empty_dashboard', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.amount', { scope: tableScope })}</th>
              <th>{i18n.t('heading.customer', { scope: tableScope })}</th>
              <th>{i18n.t('heading.date', { scope: tableScope })}</th>
              <th></th>
            </tr>
          }
          renderItemColumns={(order) => (
            <>
              <td style={tdStyle}>
                {i18n.c({
                  amountInCents: order.totalAmountInCents,
                  stripInsignificantZeros: false,
                })}
              </td>
              <td style={tdStyle}>
                <AdminMembershipPreview
                  membership={order.purchaser}
                  size={picSize.SMALL}
                />
              </td>
              <td style={tdStyle}>
                {new Date(order.createdAt).toLocaleString(
                  [],
                  orderedAtFormatOptions,
                )}
              </td>
            </>
          )}
          renderAdditionalItemActions={(order) => (
            <Icon
              type={iconType.SHOW}
              classes="action mr-2"
              tooltipText={i18n.t('actions.show.tooltip', {
                scope: tableScope,
              })}
              onClick={() => navigate(order.id)}
            />
          )}
        />

        <LoadMore
          canLoadMore={pageInfo?.hasNextPage}
          onClick={() =>
            fetchMore({
              variables: {
                cursor: pageInfo?.endCursor,
              },
            })
          }
        />
      </AdminDashboard>
    </div>
  );
}
