import React, { useMemo, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { AuthModes } from 'components/login_signup/auth_modes';
import {
  FormActions,
  FormActionButton,
  FormField,
  FormServerErrorMessages,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import { EmailAddress } from 'types/types';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'auth.unclaimed_user_send_email';

interface UnclaimedUserSendEmailFormValues {
  emailAddress: EmailAddress;
}

function RequestWasSent() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div>
      <p
        dangerouslySetInnerHTML={{
          __html: i18n.t('request_was_sent_html', {
            scope: i18nScope,
          }),
        }}
      />
    </div>
  );
}

export default function UnclaimedUserSendEmailForm({
  setAuthenticationMode,
}: {
  setAuthenticationMode: (newMode: AuthModes) => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [wasRequestSent, setWasRequestSent] = useState(false);
  const initialValues: UnclaimedUserSendEmailFormValues = { emailAddress: '' };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        emailAddress: Yup.string()
          .email(i18n.t('forms.errors.invalid_email'))
          .required(i18n.t('forms.errors.required')),
      }),
    [],
  );

  const _onSubmit = function (
    values: UnclaimedUserSendEmailFormValues,
    actions: FormikHelpers<UnclaimedUserSendEmailFormValues>,
  ) {
    Helpers.Form.save({
      actions: actions,
      main: () => {
        const url = Helpers.Routes.getUnclaimedUserSendEmailPath();
        const params = { unclaimedUser: values };
        return Helpers.API.post({ url, params });
      },
      successCallback: () => {
        setWasRequestSent(true);
      },
    });
  };

  if (wasRequestSent) {
    return <RequestWasSent />;
  }

  return (
    <div className="unclaimed-user-send-email-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              autoFocus={true}
              type="email"
              name="emailAddress"
              placeholder={i18n.t('form.email_address_placeholder', {
                scope: i18nScope,
              })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('form.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <div className="other-form-actions">
                <a onClick={() => setAuthenticationMode(AuthModes.LOGIN)}>
                  {i18n.t('login_instead', {
                    scope: i18nScope,
                  })}
                </a>
              </div>
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
