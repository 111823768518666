import React, { useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import Store from 'helpers/store';
import { Club, DeprecatedAny, WithRequired } from 'types/types';
import SponsorLogosGrid from 'components/utils/sponsorships/sponsor_logos_grid';

export default function EmbedSponsors(props: DeprecatedAny) {
  const [store] = useState(() => Store.parseInitData(props));

  // NOTE: if we get the Company models directly then they'll appear
  // out of order specified by the club which is why we get the club
  // with their sponsors
  const club = store.getCurrentClub(['sponsors']) as WithRequired<
    Club,
    'sponsors'
  >;

  return (
    <RootAppWrapper>
      <div
        className="embed-sponsors"
        style={{ maxWidth: '900px', margin: 'auto' }}
      >
        <SponsorLogosGrid sponsors={club.sponsors} />
      </div>
    </RootAppWrapper>
  );
}
