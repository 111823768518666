import _ from 'underscore';
import React from 'react';
import EventsListView from 'components/utils/events/events_list_view';
import { Club, RawClubEvent } from 'types/types';
// import EventsCalendarView from 'components/utils/events/events_calendar_view';

export default function ClubEventsSection({
  club,
  rawClubEvents,
  title,
}: {
  club: Pick<Club, 'timeZone'>;
  rawClubEvents: RawClubEvent[];
  title: string;
}) {
  if (rawClubEvents.length === 0) {
    return null;
  }

  const sortedRawClubEvents = _.sortBy(
    rawClubEvents,
    (rce) => rce.attributes.startDate,
  );

  return (
    <div className="section events-section">
      <div className="center-content">
        <h2 className="section-heading">{title}</h2>
        <EventsListView
          currentTimeZone={club.timeZone}
          rawClubEvents={sortedRawClubEvents}
          numberOfEventsVisible={6}
          openLinksInNewTab={false}
        />
      </div>
    </div>
  );
}
