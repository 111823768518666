import React, { useCallback } from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
  BarChart,
} from 'recharts';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { DateYYYYMMDDString, DateTimeISOString } from 'types/types';
import { curveCardinal } from 'd3-shape';

export const cardinal10TensionFn = curveCardinal.tension(0.1);

export type ChartXAxisTickFormatter = (value: NameType) => string;
export type ChartYAxisTickFormatter = (value: ValueType) => string;

export type ChartColorScheme = string[];

export const RED_GRADIENT_COLOR_SCHEME = ['#ff4775'];

export const BLUE_GRADIENT_COLOR_SCHEME = [
  '#0b3cb7',
  '#1351F1',
  '#5180f5',
  '#8baaf8',
];

export const MULTI_COLOR_SCHEME = [
  '#1351F1',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  'red',
  'pink',
];

type LinearGradientColorScheme = {
  id: string;
  stroke: string;
  gradientConfig: { offset: string; stopColor: string; stopOpacity: number }[];
};

export const LINEAR_GRADIENT_COLOR_SCHEMES: LinearGradientColorScheme[] = [
  {
    id: 'blue',
    stroke: '#5188f5',
    gradientConfig: [
      { offset: '5%', stopColor: '#c5d7fc', stopOpacity: 0.95 },
      { offset: '85%', stopColor: '#c5d7fc', stopOpacity: 0.3 },
    ],
  },
  {
    id: 'green',
    stroke: '#00C49F',
    gradientConfig: [
      { offset: '5%', stopColor: '#00C49F', stopOpacity: 0.95 },
      { offset: '85%', stopColor: '#00C49F', stopOpacity: 0.3 },
    ],
  },
];

export function getStrokeAndFillForLinearGradient(
  scheme: LinearGradientColorScheme,
) {
  return { stroke: scheme.stroke, fill: `url(#${scheme.id})` };
}

export function ChartLinearGradient({
  scheme,
}: {
  scheme: LinearGradientColorScheme;
}) {
  return (
    <linearGradient id={scheme.id} x1="0" y1="0" x2="0" y2="1">
      {scheme.gradientConfig.map((config, index) => (
        <stop
          key={index}
          offset={config.offset}
          stopColor={config.stopColor}
          stopOpacity={config.stopOpacity}
        />
      ))}
    </linearGradient>
  );
}

type BarChartDataPoint<T> = {
  label: string;
} & T;
export type BarChartData<T> = BarChartDataPoint<T>[];

export function TimeSeriesBarChart<T>({
  children,
  data,
  yAxisTickFormatter,
  xAxisTickFormatter,
  xDataKey,
  yLabel,
  stackOffset,
  ...baseChartProps
}: Omit<BaseChartProps, 'children'> & {
  children: React.ReactElement | React.ReactElement[];
  data: BarChartData<T>;
  yAxisTickFormatter?: ChartYAxisTickFormatter;
  xAxisTickFormatter?: ChartXAxisTickFormatter;
  yLabel?: string;
  xDataKey: string;
  stackOffset?: 'sign';
}) {
  return (
    <span className="rechart-bar-chart">
      <BaseChart {...baseChartProps}>
        {({ tooltipElement }) => (
          <BarChart
            data={data}
            stackOffset={stackOffset}
            margin={{
              top: 0,
              right: 20,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={xDataKey}
              tick={{ fontSize: 12 }}
              tickLine={false}
              axisLine={false}
              tickFormatter={xAxisTickFormatter}
            />
            <YAxis
              width={40}
              label={{ value: yLabel, angle: -90, position: 'insideLeft' }}
              tick={{ fontSize: 12, dy: 0 }}
              tickLine={false}
              axisLine={false}
              tickFormatter={yAxisTickFormatter}
            />
            {tooltipElement}
            {children}
          </BarChart>
        )}
      </BaseChart>
    </span>
  );
}

interface TimeSeriesAreaDataPoint {
  date: DateYYYYMMDDString | DateTimeISOString;
  label: string;
}
export type TimeSeriesAreaData = TimeSeriesAreaDataPoint[];

export function TimeSeriesAreaChart({
  children,
  data,
  yAxisTickFormatter,
  xAxisTickFormatter,
  yLabel,
  xDataKey,
  stackOffset,
  ...baseChartProps
}: Omit<BaseChartProps, 'children'> & {
  children: React.ReactElement | React.ReactElement[];
  data: TimeSeriesAreaData;
  yAxisTickFormatter?: ChartYAxisTickFormatter;
  xAxisTickFormatter?: ChartXAxisTickFormatter;
  yLabel?: string;
  xDataKey: string;
  stackOffset?: 'expand';
}) {
  return (
    <span className="time-series-chart">
      <BaseChart {...baseChartProps}>
        {({ tooltipElement }) => (
          <AreaChart
            data={data}
            margin={{
              top: 0,
              right: 20,
              left: 0,
              bottom: 0,
            }}
            stackOffset={stackOffset}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={xDataKey}
              tick={{ fontSize: 12 }}
              tickLine={false}
              axisLine={false}
              tickFormatter={xAxisTickFormatter}
            />
            <YAxis
              width={40}
              label={{ value: yLabel, angle: -90, position: 'insideLeft' }}
              tick={{ fontSize: 12, dy: 0 }}
              tickLine={false}
              axisLine={false}
              tickFormatter={yAxisTickFormatter}
            />
            {tooltipElement}
            {children}
          </AreaChart>
        )}
      </BaseChart>
    </span>
  );
}

interface BaseChartProps {
  children: ({
    tooltipElement,
  }: {
    tooltipElement: React.ReactElement;
  }) => React.ReactElement;
  tooltipContentCallback?: ({
    payload,
    label,
  }: {
    payload: Payload<ValueType, NameType>[];
    label: string;
  }) => React.ReactElement;
}

function BaseChart({ children, tooltipContentCallback }: BaseChartProps) {
  const renderCustomTooltip = useCallback(
    ({
      active,
      payload,
      label,
    }: TooltipProps<ValueType, NameType>): React.ReactElement | null => {
      if (
        tooltipContentCallback === null ||
        typeof tooltipContentCallback === 'undefined'
      ) {
        return null;
      }

      if (active && payload && payload.length && label) {
        return tooltipContentCallback({ payload, label });
      }

      return null;
    },
    [tooltipContentCallback],
  );

  const tooltipElement = tooltipContentCallback ? (
    <Tooltip content={renderCustomTooltip} />
  ) : (
    <Tooltip />
  );

  return (
    <span className="charts-wrapper">
      <ResponsiveContainer width="100%" height="100%">
        {children({ tooltipElement })}
      </ResponsiveContainer>
    </span>
  );
}
