import React from 'react';
import FacebookAuthenticateButton from 'components/utils/auth/facebook_authenticate_button';
import GoogleAuthenticateButton from 'components/utils/auth/google_authenticate_button';

export default function OmniAuthOptionsWrapper({
  children,
  afterAuthenticationUrl,
  facebookButtonText,
  googleButtonText,
  authenticateButtonHiddenFields,
}: {
  children: React.ReactNode;
  afterAuthenticationUrl: string;
  facebookButtonText: string;
  googleButtonText: string;
  authenticateButtonHiddenFields?: React.ReactNode;
}) {
  return (
    <div className="omni-auth-options-wrapper">
      <FacebookAuthenticateButton
        buttonText={facebookButtonText}
        redirectPath={afterAuthenticationUrl}
      >
        {authenticateButtonHiddenFields}
      </FacebookAuthenticateButton>
      <GoogleAuthenticateButton
        classes="mt-2"
        buttonText={googleButtonText}
        redirectPath={afterAuthenticationUrl}
      >
        {authenticateButtonHiddenFields}
      </GoogleAuthenticateButton>
      <div className="my-3 center italic">&mdash; or &mdash;</div>
      {children}
    </div>
  );
}
