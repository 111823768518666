import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import classNames from 'classnames';
import Helpers from 'helpers/helpers';
import { AdminClickableMembershipPreview } from 'components/utils/admin/admin_membership_preview';

const i18nScope = 'clubs.admin.money.orders.show';

const GET_ADMIN_CLUB_ORDER = gql(`
  query GetAdminClubOrder($clubId: ID!, $orderId: ID!) {
    club(id: $clubId) {
      id
      order(id: $orderId) {
        id
        confirmationCode
        subtotalAmountInCents
        taxesCollectedInCents
        platformFeeInCents
        totalAmountInCents
        orderItems {
          id
          totalAmountInCents
          description
          quantity
          priceInCents
        }
        purchaser {
          id
          fullName
          profilePicUrl
        }
        createdAt
        updatedAt
      }
    }
  }
`);

function OrderDetailRow({
  text,
  amountInCents,
  classes,
}: {
  text?: string | React.ReactElement;
  amountInCents: number;
  classes?: string;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="order-detail-row">
      <div className={classNames('order-detail-row-left', classes)}>{text}</div>
      <div className={classNames('order-detail-row-right', classes)}>
        {i18n.c({ amountInCents, stripInsignificantZeros: false })}
      </div>
    </div>
  );
}

export default function OrdersShowPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_ORDER, {
    variables: {
      clubId: String(currentClubId),
      orderId: String(id),
    },
  });
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const order = data?.club?.order;
  assertNotNullOrUndefined(order);

  return (
    <div id="clubs-admin-money-order-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page skinny-page"
      >
        <div className="mb-4">
          <a onClick={() => navigate(-1)}>
            {i18n.t('back', { scope: i18nScope })}
          </a>
        </div>

        <div>
          <div style={{ width: 250 }}>
            <AdminClickableMembershipPreview
              membership={order.purchaser}
              to={`../../../members/list/${order.purchaser.id}`}
            />
          </div>
          <p>
            {Helpers.Utils.formatDateTimeLong(order.createdAt)}
            <br />
            {i18n.t('confirmation_code', { scope: i18nScope })}:{' '}
            {order.confirmationCode}
          </p>
        </div>

        <div className="order-details mt-5">
          <p className="bold mb-2">
            {i18n.t('details.heading', { scope: i18nScope })}
          </p>
          {order.orderItems.map((orderItem, index) => (
            <OrderDetailRow
              key={index}
              text={
                <span>
                  <span className="small bold mr-2">{orderItem.quantity}x</span>
                  {orderItem.description}
                </span>
              }
              amountInCents={orderItem.totalAmountInCents}
            />
          ))}
          <hr className="hr" />
          <div>
            <OrderDetailRow
              text={i18n.t('details.subtotal', { scope: i18nScope })}
              amountInCents={order.subtotalAmountInCents}
            />
            <OrderDetailRow
              text={i18n.t('details.taxes', { scope: i18nScope })}
              amountInCents={order.taxesCollectedInCents}
            />
            <OrderDetailRow
              text={i18n.t('details.platform_fee', { scope: i18nScope })}
              amountInCents={order.platformFeeInCents}
            />
            <OrderDetailRow
              classes="bold"
              text={i18n.t('details.total', { scope: i18nScope })}
              amountInCents={order.totalAmountInCents}
            />
          </div>
        </div>
      </AdminDashboard>
    </div>
  );
}
