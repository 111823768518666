import { arrayFilterNulls, assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAdminClubMembershipTagsQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import { sendMutation } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';

import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import AdminTable, { DestroyConfig } from 'components/utils/admin/admin_table';

const i18nScope = 'clubs.admin.memberships.tags.index';
const tableScope = `${i18nScope}.table`;

type Club = NonNullable<GetAdminClubMembershipTagsQuery['club']>;
type ClubMembershipTag = NonNullable<
  NonNullable<NonNullable<Club['membershipTags']['edges']>[number]>['node']
>;

const GET_ADMIN_CLUB_MEMBERSHIP_TAGS = gql(`
  query GetAdminClubMembershipTags($clubId: ID!) {
    club(id: $clubId) {
      id
      membershipTags {
        edges {
          node {
            id
            name
            membershipsCount
          }
        }
      }
    }
  }
`);

const ADMIN_CLUB_MEMBERSHIP_TAG_DESTROY = gql(`
  mutation AdminCluMembershipTagDestroy($input: ClubMembershipTagDestroyInput!) {
    clubMembershipTagDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function MembershipTagsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [destroyMutation] = useMutation(ADMIN_CLUB_MEMBERSHIP_TAG_DESTROY);

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_MEMBERSHIP_TAGS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading || data === null)
    return <AdminDashboardLoadingPlaceholder columnWidths={[70, 30]} />;
  if (error) return <ErrorPage />;
  const membershipTagsConnection = data?.club?.membershipTags;
  const membershipTags = arrayFilterNulls(
    (membershipTagsConnection?.edges ?? []).map((e) => e?.node),
  );
  assertNotNullOrUndefined(membershipTags);
  const sortedMembershipTags = _.sortBy(membershipTags, (mg) => mg.name);

  const destroyConfig: DestroyConfig<ClubMembershipTag> = {
    getConfirmText: (membershipTag) =>
      i18n.t('actions.delete_confirmation', {
        scope: tableScope,
        values: {
          membershipTagName: membershipTag.name,
        },
      }),
    destroyCallback: (membershipTag) => {
      // optimistic remove, and then we add it back in if it fails
      sendMutation({
        mutationName: 'clubMembershipTagDestroy',
        main: () =>
          destroyMutation({
            variables: { input: { id: membershipTag.id } },
            // // TODO: the update function runs but the component
            // // does not re-render so there is no optimistic update
            // optimisticResponse: {
            //   clubMembershipTagDestroy: {
            //     deletedId: membershipTag.id,
            //     errors: [],
            //   },
            // },
            update: (cache, result, options) => {
              const deletedId = options?.variables?.input?.id;
              assertNotNullOrUndefined(deletedId);

              const idToRemove = cache.identify({
                __typename: 'ClubMembershipTag',
                id: deletedId,
              });

              cache.evict({ id: idToRemove });
              cache.gc();
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <div id="clubs-admin-membership-groups-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_tag_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
        contentClasses="elevate-content min-height-page"
      >
        <AdminTable
          items={sortedMembershipTags}
          emptyTableElement={
            <p>{i18n.t('empty_dashboard', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.name', { scope: tableScope })}</th>
              <th>
                {i18n.t('heading.memberships_count', { scope: tableScope })}
              </th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(membershipTag) => (
            <>
              <td>{membershipTag.name}</td>
              <td>{membershipTag.membershipsCount}</td>
            </>
          )}
          onClickEditCallback={(mg) => navigate(`${mg.id}/edit`)}
          destroyConfig={destroyConfig}
        />
      </AdminDashboard>
    </div>
  );
}
