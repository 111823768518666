import { arrayFilterNulls, assertNotNullOrUndefined } from 'h';
import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'components/utils/use_debounce';

import I18nContext from 'contexts/i18n_context';

import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { picSize } from 'components/utils/profile_pic';
import MembershipBadges from 'components/clubs/admin/memberships_page/membership_badges';

import { ClubId } from 'types/types';
import Icon, { iconType } from 'components/utils/icon';
import ExportMembershipsButton from 'components/clubs/admin/memberships_page/export_memberships_button';
import AdminDashboard, {
  AdminDashboardAddButton,
} from 'components/utils/admin/admin_dashboard';
import LoadMore from 'components/utils/load_more';
import { AdminMembershipPreview } from 'components/utils/admin/admin_membership_preview';
import AdminTable from 'components/utils/admin/admin_table';

const i18nScope = 'clubs.admin.memberships.index';
const tableScope = `${i18nScope}.table`;

const GET_ADMIN_CLUB_MEMBERSHIPS = gql(`
  query GetAdminClubMemberships($clubId: ID!, $search: String, $cursor: String) {
    club(id: $clubId) {
      id
      memberships(search: $search, after: $cursor) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            fullName
            profilePicUrl
            emailAddress
            state
            isActive
            isExpired
            hasJoinedRecently
            hasExpiredRecently
          }
        }
      }
    }
  }
`);

export default function MembershipsIndexPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = useContext(I18nContext);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({ q: '' });
  const searchText = searchParams.get('q') ?? '';
  const debouncedSearchText = useDebounce(searchText, 300);

  const [isInitialLoad, setIsInitalLoad] = useState(true);

  const { loading, error, data, previousData, fetchMore } = useQuery(
    GET_ADMIN_CLUB_MEMBERSHIPS,
    {
      variables: {
        clubId: String(currentClubId),
        search: debouncedSearchText,
      },
      onCompleted: () => setIsInitalLoad(false),
    },
  );

  if (loading && isInitialLoad) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const isLoadingOrPending = loading || searchText != debouncedSearchText;

  const membershipsConnection = (data ?? previousData)?.club?.memberships;
  assertNotNullOrUndefined(membershipsConnection);
  const pageInfo = membershipsConnection?.pageInfo;
  const memberships = arrayFilterNulls(
    (membershipsConnection?.edges ?? []).map((e) => e?.node),
  );
  const expectedNumberOfMemberships = membershipsConnection?.totalCount;

  return (
    <div id="clubs-admin-memberships-index">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <>
            <ExportMembershipsButton currentClubId={String(currentClubId)} />
            <AdminDashboardAddButton
              buttonText={i18n.t('add_membership_button', {
                scope: i18nScope,
              })}
              onClickCallback={() => navigate('new')}
            />
          </>
        }
        contentClasses="elevate-content min-height-page"
      >
        <div>
          <div className="search-bar">
            <Icon type={iconType.SEARCH} />
            <input
              type="text"
              value={searchText}
              onChange={(e) =>
                setSearchParams({ q: e.target.value }, { replace: true })
              }
              placeholder={i18n.t('search.placeholder', { scope: i18nScope })}
            />
          </div>
          <div
            className={classNames('results mt-4', {
              faded: isLoadingOrPending,
            })}
          >
            <div className="info mb-4 italic">
              {i18n.t('results', {
                scope: i18nScope,
                count: expectedNumberOfMemberships,
              })}
            </div>

            <AdminTable
              items={memberships}
              emptyTableElement={<p></p>}
              getRowClasses={(membership) =>
                classNames({
                  'grayed-out': membership.isExpired,
                })
              }
              renderItemColumns={(membership) => (
                <>
                  <td>
                    <AdminMembershipPreview
                      membership={membership}
                      size={picSize.SMALL}
                      extraInfo={
                        <MembershipBadges
                          classes="inline-block"
                          membership={membership}
                          hideActiveTag={true}
                          hideExpiredTag={true}
                        />
                      }
                    />
                  </td>
                  <td className="td-email-address">
                    {membership.emailAddress}
                  </td>
                  <td>
                    {membership.isActive
                      ? i18n.t('active', { scope: tableScope })
                      : membership.isExpired
                      ? i18n.t('expired', { scope: tableScope })
                      : null}
                  </td>
                </>
              )}
              renderAdditionalItemActions={(membership) => (
                <Icon
                  type={iconType.SHOW}
                  classes="action mr-2"
                  tooltipText={i18n.t('actions.show.tooltip', {
                    scope: tableScope,
                  })}
                  onClick={() => navigate(membership.id)}
                />
              )}
            />
            <LoadMore
              canLoadMore={pageInfo?.hasNextPage}
              onClick={() =>
                fetchMore({
                  variables: {
                    search: searchText,
                    cursor: pageInfo.endCursor,
                  },
                })
              }
            />
          </div>
        </div>
      </AdminDashboard>
    </div>
  );
}
