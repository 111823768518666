import React from 'react';
import Map from 'components/utils/map';

interface GoogleMapsProps {
  googleMapsApiPublicKey: string;
  latitude: string;
  longitude: string;
}

export default function GoogleMaps({
  googleMapsApiPublicKey,
  latitude,
  longitude,
}: GoogleMapsProps) {
  return (
    <Map
      apiKey={googleMapsApiPublicKey}
      latitude={parseFloat(latitude)}
      longitude={parseFloat(longitude)}
    />
  );
}
