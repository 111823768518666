import React from 'react';

import { ClubProfileHeaderPhoto } from 'components/utils/image';
import Location from 'components/utils/location';
import { Club, ClubProfile } from 'types/types';

interface ClubHeaderSectionProps {
  club: Club;
  clubProfile: ClubProfile;
}

export default function ClubHeaderSection({
  club,
  clubProfile,
}: ClubHeaderSectionProps) {
  const headerImageElement = (
    <div className="image-wrapper">
      <ClubProfileHeaderPhoto clubProfile={clubProfile} />
    </div>
  );

  return (
    <>
      <span className="responsive-image-wrapper">{headerImageElement}</span>
      <div className="center-content section header-section">
        {headerImageElement}
        <div className="club-summary">
          <h1 className="bold">{club.name}</h1>
          <div className="info">
            <Location text={`${club.city}, ${club.state}`} />
            <div className="website">
              <i className="fas fa-globe-americas mr-2"></i>
              <a href={club.website} target="_blank">
                {club.website}
              </a>
            </div>
          </div>
          <p className="club-description pre-wrap">{clubProfile.description}</p>
        </div>
      </div>
    </>
  );
}
