import React from 'react';
import classNames from 'classnames';
import { ClubEvent } from '__generated__/graphql';
import { getEventTimeText } from 'models/event';
import Icon, { iconType } from 'components/utils/icon';

export default function EventTime({
  event,
  currentTimeZone,
  classes,
}: {
  event: Pick<
    ClubEvent,
    | 'startDate'
    | 'startTimeInMinutes'
    | 'endDate'
    | 'endTimeInMinutes'
    | 'isAllDay'
    | 'timeZone'
  >;
  currentTimeZone?: string;
  classes?: string;
}) {
  const shouldIncludeTimeZone =
    typeof currentTimeZone === 'undefined' ||
    event.timeZone !== currentTimeZone;
  const eventTimeText = getEventTimeText({ event, shouldIncludeTimeZone });

  return (
    <div className={classNames('event-time', classes)}>
      <span className="icon-wrapper mr-1">
        <Icon type={iconType.TIME} />
      </span>
      <span className="text">{eventTimeText}</span>
    </div>
  );
}
