import React from 'react';
import classNames from 'classnames';
import {
  CartItem,
  getTotalItemsInCart,
} from 'components/clubs/storefronts/cart';
import Icon, { iconType } from 'components/utils/icon';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'components.clubs.storefronts.cart_button';

export default function CartButton({
  cartItems,
  onClickCallback,
  classes,
}: {
  cartItems: CartItem[];
  onClickCallback: () => void;
  classes?: string;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <button
      className={classNames(
        'storefront-cart-button btn btn-layered-secondary',
        classes,
      )}
      onClick={onClickCallback}
    >
      <Icon type={iconType.SHOPPING_BAG} />
      {i18n.t('cart', {
        scope: i18nScope,
        count: getTotalItemsInCart(cartItems),
      })}
    </button>
  );
}
