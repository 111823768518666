import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { getIn } from 'formik';

import FormServerErrorMessages from 'components/forms/form_server_error_messages';
interface FormErrorMessageProps {
  name: string;
}

export default function FormErrorMessage({ name }: FormErrorMessageProps) {
  const { touched, submitCount, status, errors } = useFormikContext();
  const wasTouched = getIn(touched, name);

  const clientErrors = getIn(errors, name) ?? [];
  const serverErrors = getIn(status, ['serverErrors', name]) ?? [];

  const hasServerErrors = !wasTouched && serverErrors.length > 0;
  const hasClientErrors = wasTouched && clientErrors.length > 0;
  const hasErrors = submitCount > 0 && (hasServerErrors || hasClientErrors);

  let show: React.ReactElement | null = null;

  if (hasErrors) {
    if (hasServerErrors) {
      show = <FormServerErrorMessages serverErrors={serverErrors} />;
    } else if (hasClientErrors) {
      show = <ErrorMessage name={name} />;
    }
  }
  return <div className="form-error-message">{show}</div>;
}
