import h from 'h';
import React, { useMemo } from 'react';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormCheckboxField,
  FormServerErrorMessages,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import { RawWaiver, WaiverId } from 'types/types';
import { AxiosResponse } from 'axios';

const i18nScope = 'components.utils.waivers.pending_waivers_form';

interface PendingWaiversFormValues {
  waivers: { id: WaiverId; hasAgreed: boolean }[];
}

export default function PendingWaiversForm({
  rawWaivers,
  getSignedWaiversApiUrl,
  onSuccessCallback,
  overrideSubmitActionText,
  formActionClasses,
}: {
  rawWaivers: RawWaiver[];
  getSignedWaiversApiUrl: ({ waiverId }: { waiverId: WaiverId }) => string;
  onSuccessCallback: () => void;
  overrideSubmitActionText?: string;
  formActionClasses?: string;
}) {
  const { i18n } = React.useContext(I18nContext);

  const initialValues = {
    waivers: rawWaivers.map((rawWaiver) => ({
      id: rawWaiver.attributes.id,
      hasAgreed: false,
    })),
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        waivers: Yup.array().of(
          Yup.object().shape({
            hasAgreed: Yup.boolean().oneOf(
              [true],
              i18n.t('forms.errors.required_agree'),
            ),
          }),
        ),
      }),
    [],
  );

  const _onSubmit = (
    values: PendingWaiversFormValues,
    actions: FormikHelpers<PendingWaiversFormValues>,
  ) => {
    return Helpers.Form.save<
      PendingWaiversFormValues,
      AxiosResponse<unknown>[]
    >({
      actions,
      main: () => {
        const signingWaiverPromises = rawWaivers.map((rawWaiver) => {
          const url = getSignedWaiversApiUrl({
            waiverId: rawWaiver.attributes.id,
          });
          return Helpers.API.post({ url, params: {} });
        });
        return Promise.all(signingWaiverPromises);
      },
      successCallback: () => onSuccessCallback(),
    });
  };

  return (
    <div className="pending-waivers-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, isSubmitting, values }) => (
          <Form className="basic-form" noValidate>
            {rawWaivers.length > 0 && (
              <FieldArray
                name="waivers"
                render={() => (
                  <div className="form-section mb-2">
                    <div className="form-section-body">
                      {values.waivers.map((waiverInForm, index) => {
                        const rawWaiver = rawWaivers.find(
                          (rw) => waiverInForm.id === rw.attributes.id,
                        );

                        if (typeof rawWaiver === 'undefined') {
                          return h.throwError('somehow waiver is missing');
                        }
                        const waiverLink = (
                          <a
                            className="link"
                            target="_blank"
                            href={rawWaiver.links.showPath}
                          >
                            {rawWaiver.attributes.name}
                          </a>
                        );

                        return (
                          <div className="waiver" key={index}>
                            <FormCheckboxField
                              name={`waivers.${index}.hasAgreed`}
                              label={i18n.rt('fields.has_agreed.label', {
                                scope: i18nScope,
                                values: {
                                  waiverName: waiverLink,
                                },
                              })}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              />
            )}
            <FormActions className={formActionClasses}>
              <FormActionButton
                text={
                  overrideSubmitActionText ??
                  i18n.t('actions.submit', { scope: i18nScope })
                }
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
