import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ClubEvent,
  ClubEventEventAnnouncementCreateMutation,
} from '__generated__/graphql';

import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
  FormAutoExpandField,
} from 'components/forms/forms';

import I18nContext from 'contexts/i18n_context';

const i18nScope =
  'components.clubs.club_events.club_event_detail_view.event_announcement_form';

const CLUB_EVENT_EVENT_ANNOUNCEMENT_CREATE = gql(`
  mutation ClubEventEventAnnouncementCreate($input: ClubEventEventAnnouncementCreateInput!) {
    clubEventEventAnnouncementCreate(input: $input) {
      eventAnnouncement {
        id
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

interface EventAnnouncementFormValues {
  message: string;
}

export function buildNewEventAnnouncement(): EventAnnouncementFormValues {
  return {
    message: '',
  };
}

export default function EventAnnouncementForm({
  clubEvent,
  onSuccessCallback,
  onCancelCallback,
}: {
  clubEvent: Pick<ClubEvent, 'id' | 'name'>;
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [createMutation] = useMutation(CLUB_EVENT_EVENT_ANNOUNCEMENT_CREATE);

  const initialValues = buildNewEventAnnouncement();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        message: Yup.string()
          .min(20, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
      }),
    [],
  );

  const _onSubmit = function (
    values: EventAnnouncementFormValues,
    actions: FormikHelpers<EventAnnouncementFormValues>,
  ) {
    sendMutationAndUpdateForm<
      EventAnnouncementFormValues,
      ClubEventEventAnnouncementCreateMutation
    >({
      actions,
      mutationName: 'clubEventEventAnnouncementCreate',
      main: () =>
        createMutation({
          variables: {
            input: {
              clubEventId: clubEvent.id,
              ...values,
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  return (
    <div id="event-announcement-form">
      <h1>
        {i18n.t('heading', {
          scope: i18nScope,
          values: {
            eventName: clubEvent.name,
          },
        })}
      </h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <div className="mt-5">
              <FormAutoExpandField
                autoFocus={true}
                rows="5"
                name="message"
                placeholder={i18n.t('fields.message.placeholder', {
                  scope: i18nScope,
                })}
              />
            </div>
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('submit_button', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                text={i18n.t('back_button', { scope: i18nScope })}
                className="secondary"
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
