import _str from 'underscore.string';
import React from 'react';
import classNames from 'classnames';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'components.utils.contact_support';

export enum SupportType {
  ORGANIZERS = 'organizers',
  GENERAL = 'general',
}

export function getSupportEmailAddress(
  type: SupportType = SupportType.GENERAL,
): string {
  let supportEmail;

  switch (type) {
    case SupportType.ORGANIZERS:
      supportEmail = 'organizers@clubsum.com';
      break;
    default:
      supportEmail = 'support@clubsum.com';
  }
  return supportEmail;
}

export default function ContactSupport({
  classes,
  type,
  subject,
}: {
  classes?: string;
  type: SupportType;
  subject?: string;
}) {
  const { i18n } = React.useContext(I18nContext);

  const supportEmail = getSupportEmailAddress(type);
  let link = `mailto:${supportEmail}`;
  if (
    subject !== null &&
    typeof subject !== 'undefined' &&
    !_str.isBlank(subject)
  ) {
    link += `?subject=${encodeURI(subject)}`;
  }

  return (
    <div className={classNames('contact-support', classes)}>
      {i18n.t('text', { scope: i18nScope })} &nbsp;
      <a href={link} target="_blank">
        {supportEmail}
      </a>
    </div>
  );
}
