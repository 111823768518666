import React from 'react';
import CompanyLogo, { logoSize } from 'components/utils/company_logo';
import { Company } from 'types/types';

export default function SponsorLogosGrid({
  sponsors,
}: {
  sponsors: Company[];
}) {
  return (
    <div className="sponsor-logos-grid">
      {sponsors.map((sponsor, index) => (
        <span key={index}>
          <CompanyLogo
            classes="inline-block sponsor"
            company={sponsor}
            size={logoSize.LARGE}
          />
          {index % 3 === 2 && <br />}
        </span>
      ))}
    </div>
  );
}
