import { mergeAttributes, Node } from '@tiptap/core';
import { DeprecatedAny } from 'types/types';

export interface SmallOptions {
  HTMLAttributes: Record<string, DeprecatedAny>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    small: {
      /**
       * Set small
       */
      setSmall: () => ReturnType;
      /**
       * Toggle a small
       */
      toggleSmall: () => ReturnType;
    };
  }
}

export const Small = Node.create<SmallOptions>({
  name: 'small',

  priority: 1000,

  group: 'block',

  content: 'inline*',

  parseHTML() {
    return [{ tag: 'small' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'small',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setSmall:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name);
        },
      toggleSmall:
        () =>
        ({ commands }) => {
          return commands.toggleNode(this.name, 'paragraph');
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-8': () => this.editor.commands.toggleSmall(),
    };
  },
});
