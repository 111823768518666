import React, { createContext, useState } from 'react';
import I18n from 'helpers/i18n';

interface I18nContextType {
  i18n: I18n;
}

const I18nContext = createContext<I18nContextType>({
  i18n: new I18n(window.translations),
});
export default I18nContext;

export function I18nContextProvider({
  // can be initialized with a mockI18n for testing purposes
  mockI18n,
  children,
}: {
  mockI18n?: I18n;
  children: React.ReactElement | React.ReactElement[];
}) {
  const [i18n] = useState<I18n>(
    () => mockI18n ?? new I18n(window.translations),
  );

  return (
    <I18nContext.Provider value={{ i18n }}>{children}</I18nContext.Provider>
  );
}
