import _str from 'underscore.string';
import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import Switch from 'rc-switch';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';

type FormCurrencyFieldProps = Omit<FormFieldwrapperProps, 'children'> & {
  disabled?: boolean;
  onChangeCallback?: (newValue: boolean) => void;
};

export default function FormSwitchField({
  classes,
  style,
  name,
  label,
  description,
  withoutErrorMessage,
  disabled,
  onChangeCallback,
}: FormCurrencyFieldProps) {
  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;
  const onChange = (value: boolean) => {
    setValue(value);
    setTouched(true);
    onChangeCallback?.(value);
  };
  return (
    <FormFieldWrapper
      name={name}
      classes={classNames('form-switch-field-wrapper', classes)}
      style={style}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-field form-switch-field">
        <div className="flex">
          <div className="flex-1">
            <label className="form-field-label">{label}</label>
          </div>
          <div className="flex-1 right">
            <Switch
              checked={field.value}
              onChange={onChange}
              onClick={onChange}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </FormFieldWrapper>
  );
}
