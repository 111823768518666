import h from 'h';
import _ from 'underscore';
import _str from 'underscore.string';
import {
  DateYYYYMMDDString,
  DateTimeISOString,
  DateTimeMinutes,
  DateTimeZone,
  DeprecatedAny,
} from 'types/types';

// this is the standard JS library
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#options
interface ToLocaleDateStringOptions {
  year?: 'numeric' | '2-digit';
  weekday?: 'long' | 'short' | 'narrow';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
}
// this for our wrapper around the standard library JS method
interface ToLocaleDateStringWrapperConfig {
  withYear?: boolean;
  withDay?: boolean;
}

type GenericObject = Record<string, DeprecatedAny>;

export function updateListStateAfterCreateOrUpdate<T extends { id: string }>({
  setListFn,
  newItem,
}: {
  setListFn: (value: React.SetStateAction<T[]>) => void;
  newItem: T;
}) {
  setListFn((prevItems) => {
    const existingItemIndex = prevItems.findIndex(
      (item) => item.id === newItem.id,
    );
    if (existingItemIndex > -1) {
      // existing item, update the item in the list
      return [
        ...prevItems.slice(0, existingItemIndex),
        newItem,
        ...prevItems.slice(existingItemIndex + 1),
      ];
    } else {
      // new item, append to the list
      return [...prevItems, newItem];
    }
  });
}

export function updateListStateAfterDestroy<T extends { id: string }>({
  setListFn,
  deletedItem,
}: {
  setListFn: (value: React.SetStateAction<T[]>) => void;
  deletedItem: T;
}): void {
  setListFn((prevItems) =>
    prevItems.filter((item) => item.id !== deletedItem.id),
  );
}

// similar to inGroupsOf in rails
// [1,2,3,4,5].in_groups_of(2, nil) => [[1,2], [3,4], [5, nil]]
export function inGroupsOf<T>({
  array,
  groupSize,
  fillWith,
}: {
  array: T[];
  groupSize: number;
  fillWith?: T;
}): T[][] {
  const result = [];

  for (let i = 0; i < array.length; i += groupSize) {
    const group = array.slice(i, i + groupSize);

    // if the group is smaller than the group size, fill the remaining slots
    if (typeof fillWith !== 'undefined') {
      while (group.length < groupSize) {
        group.push(fillWith);
      }
    }

    result.push(group);
  }

  return result;
}

export default class UtilsHelper {
  static redirectTo(url: string): void {
    window.location.href = url;
  }

  static openLinkInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  static copyStringToClipboard(text: string): void {
    const type = 'text/plain';
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
      () => {
        /* success */
      },
      () => {
        alert('unable to copy to clipboard');
        /* failure */
      },
    );
  }

  static getCSRFToken(): string {
    const csrfToken =
      document
        .querySelector("meta[name='csrf-token']")
        ?.getAttribute('content') ?? null;

    if (csrfToken === null) {
      return h.throwError('csrf token is missing');
    }

    return csrfToken;
  }

  // Given a string in YYYY-MM-DD it returns a date
  static convertYYYY_MM_DDToDateInLocalTimeZone(
    dateString: DateYYYYMMDDString,
  ): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  // Given a date it return a string like YYYY-MM-DD
  static convertDateInLocalTimeZoneToYYYY_MM_DD(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const monthString = _str.lpad(String(month), 2, '0');
    const dayString = _str.lpad(String(day), 2, '0');
    return `${year}-${monthString}-${dayString}`;
  }

  static convertMinutesToTimeInLocalTimeZone(minutes: DateTimeMinutes): Date {
    h.assert.isTrue(minutes <= 24 * 60, 'minutes greater than 24 * 60');
    h.assert.isTrue(minutes >= 0, 'minutes less than 0');

    const hour = minutes / 60;
    const remainingMinutes = minutes % 60;

    const date = new Date();
    date.setHours(hour);
    date.setMinutes(remainingMinutes);
    return date;
  }

  static convertTimeInLocalTimeZoneToMinutes(date: Date): number {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return hours * 60 + minutes;
  }

  static formatDateAndTimeRange({
    isAllDay,
    startDate,
    startTimeInMinutes,
    endDate,
    endTimeInMinutes,
    timeZone,
    shouldIncludeTimeZone,
  }: {
    isAllDay: boolean;
    startDate: DateYYYYMMDDString;
    startTimeInMinutes: DateTimeMinutes;
    endDate: DateYYYYMMDDString;
    endTimeInMinutes: DateTimeMinutes;
    timeZone: DateTimeZone;
    shouldIncludeTimeZone: boolean;
  }): string {
    const opts = { withDay: true };
    const startDateText = this.formatDate(startDate, opts);
    const endDateText = this.formatDate(endDate, opts);

    let result;
    if (isAllDay) {
      if (startDateText === endDateText) {
        result = startDateText;
      } else {
        result = `${startDateText} - ${endDateText}`;
      }
    } else {
      const startTimeText = this.formatTime(startTimeInMinutes).toLowerCase();
      const endTimeText = this.formatTime(endTimeInMinutes).toLowerCase();

      if (startDateText === endDateText) {
        const shouldStripMeridiem =
          (startTimeText.endsWith(' am') && endTimeText.endsWith(' am')) ||
          (startTimeText.endsWith(' pm') && endTimeText.endsWith(' pm'));
        const strippedStartTimeText = shouldStripMeridiem
          ? startTimeText.substring(0, startTimeText.length - 3)
          : startTimeText; // no need to strip

        result = `${startDateText}, ${strippedStartTimeText} - ${endTimeText}`;
      } else {
        result = `${startDateText}, ${startTimeText} - ${endDateText}, ${endTimeText}`;
      }

      if (shouldIncludeTimeZone) {
        const timeZoneText = this.formatTimeZone({
          date: this.convertYYYY_MM_DDToDateInLocalTimeZone(startDate),
          timeZone,
        });
        result += ` ${timeZoneText}`;
      }
    }

    return result;
  }

  // so hacky - we can't just get the time zone, so
  // we're forced to get the date with time zone then
  // remove the date
  static formatTimeZone({
    date,
    timeZone,
  }: {
    date: Date;
    timeZone: DateTimeZone;
  }) {
    // returns '1/10/2022, PST'
    const dateWithTimeZone = date.toLocaleDateString([], {
      timeZone,
      timeZoneName: 'short',
    });
    // returns '1/10/2022'
    const dateWithoutTimeZone = date.toLocaleDateString([], {
      timeZone,
    });

    const result = dateWithTimeZone.replace(dateWithoutTimeZone, '');
    return _str.trim(result, ' ,');
  }

  static formatTime(timeInMinutes: DateTimeMinutes): string {
    const time = this.convertMinutesToTimeInLocalTimeZone(timeInMinutes);
    const opts: ToLocaleDateStringOptions = { hour: 'numeric' };
    if (timeInMinutes % 60 != 0) {
      opts.minute = '2-digit';
    }
    return time.toLocaleTimeString([], opts);
  }

  static formatDateOnlyMonthAndYear(dateString: DateYYYYMMDDString): string {
    const date = this.convertYYYY_MM_DDToDateInLocalTimeZone(dateString);
    return date.toLocaleDateString([], { month: 'short', year: 'numeric' });
  }

  static formatDate(
    dateString: DateYYYYMMDDString,
    config: ToLocaleDateStringWrapperConfig = {},
  ) {
    const date = this.convertYYYY_MM_DDToDateInLocalTimeZone(dateString);
    return this._toLocalDateString(date, config);
  }

  static formatDateTime(
    dateTimeISOString: DateTimeISOString | null,
    config: ToLocaleDateStringWrapperConfig = {},
  ): string | null {
    if (dateTimeISOString === null) {
      return null;
    }
    const date = new Date(dateTimeISOString);
    return this._toLocalDateString(date, config);
  }

  // NOTE: we should consolidate all the OTHER date time methods and just use
  // two or three. I was doing some funky logic before. It should just use
  // the built in browser libraries toLocaleDateString or toLocaleTimeString
  static formatDateTimeLong(dateTimeISOString: DateTimeISOString | null) {
    if (dateTimeISOString === null) {
      return null;
    }
    const date = new Date(dateTimeISOString);
    return date.toLocaleTimeString([], {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
  }

  static _toLocalDateString(
    date: Date,
    config: ToLocaleDateStringWrapperConfig,
  ): string {
    const { withYear, withDay } = config;
    const opts: ToLocaleDateStringOptions = { month: 'short', day: 'numeric' };
    if (withDay) {
      opts.weekday = 'short';
    }
    if (withYear) {
      opts.year = 'numeric';
    }
    return date.toLocaleDateString([], opts);
  }

  static pluralize(
    word: string,
    count: number,
    opts: { plural?: string; hideCount?: boolean } = {},
  ): string {
    const pluralWord = opts.plural;
    const hideCount = opts.hideCount;

    const text = count === 1 ? word : pluralWord ?? `${word}s`;
    return hideCount ? text : `${count} ${text}`;
  }

  static deepTransformKeysToSnakeCase(object: GenericObject): GenericObject {
    return this.deepTransformKeys(object, (key: string) =>
      _str.underscored(key),
    );
  }

  static deepTransformKeysToCamelCase(object: GenericObject): GenericObject {
    return this.deepTransformKeys(object, (key: string) => _str.camelize(key));
  }

  static deepTransformKeys(
    object: GenericObject,
    transformKeyCallback: (key: string) => string,
  ): Record<string, DeprecatedAny> {
    if (Array.isArray(object)) {
      return _.map(object, (o) => {
        return this.deepTransformKeys(o, transformKeyCallback);
      });
    } else if (
      object &&
      typeof object === 'object' &&
      !(object instanceof File)
    ) {
      const newObject: Record<string, DeprecatedAny> = {};
      _.each(object, (value: DeprecatedAny, key: string) => {
        const newKey = transformKeyCallback(key);
        newObject[newKey] =
          _.keys(value).length > 0
            ? this.deepTransformKeys(value, transformKeyCallback)
            : value;
      });
      return newObject;
    } else {
      return object;
    }
  }
}
