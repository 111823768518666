import _str from 'underscore.string';
import React from 'react';
import { ClubEvent } from '__generated__/graphql';
import classNames from 'classnames';
import Icon, { iconType } from 'components/utils/icon';

import I18nContext from 'contexts/i18n_context';

const i18nScope = 'components.utils.event_location_or_virtual';

interface EventLocationOrVirtualProps {
  classes?: string;
  shouldShowLocationLink?: boolean;
  event: Pick<
    ClubEvent,
    | 'isVirtual'
    | 'googlePlaceId'
    | 'longitude'
    | 'latitude'
    | 'location'
    | 'locationName'
    | 'virtualMeetingUrl'
  >;
}

export function EventLocation({
  shouldShowLocationLink = true,
  event,
}: Pick<EventLocationOrVirtualProps, 'event' | 'shouldShowLocationLink'>) {
  const { locationName, location, googlePlaceId, longitude, latitude } = event;

  const locationNameOrBlank = locationName ?? '';
  const googlePlaceIdOrBlank = googlePlaceId ?? '';

  let googleMapsLink: string | null;
  if (
    !_str.isBlank(googlePlaceIdOrBlank) &&
    latitude !== null &&
    longitude !== null
  ) {
    // the url with the place_id and longitude / latitude for best results
    // https://developers.google.com/maps/documentation/urls/get-started
    // eg. https://www.google.com/maps/search/?api=1&query=47.5951518%2C-122.3316393&query_place_id=ChIJKxjxuaNqkFQR3CK6O1HNNqY

    const searchParams = new URLSearchParams({
      query_place_id: googlePlaceIdOrBlank,
      query: `${latitude},${longitude}`,
    });

    googleMapsLink =
      'https://www.google.com/maps/search/?api=1&' + searchParams.toString();
  } else {
    googleMapsLink = null;
  }

  const shouldShowName =
    !_str.isBlank(locationNameOrBlank) &&
    !location.startsWith(locationNameOrBlank);

  // HACK: for now I'm hiding the zipcode and country like this. Ideally it should
  // now the context of the club or event and decide whether to show or not
  const zipcodeAndCountryRegex = /\s\d{5},\sUSA$/;
  const locationWithoutZipCodeAndCountry = zipcodeAndCountryRegex.test(location)
    ? location.replace(zipcodeAndCountryRegex, '')
    : location;

  // we show the location name first if possible then the address
  const locationTextParts: string[] = [];
  if (shouldShowName) {
    locationTextParts.push(locationNameOrBlank);
  }
  locationTextParts.push(locationWithoutZipCodeAndCountry);

  return (
    <div className="event-location">
      {locationTextParts.map((locationTextPart, index) => {
        let locationTextPartelement: React.ReactElement;
        // the first line we linkify it if possible
        if (index === 0 && shouldShowLocationLink && googleMapsLink !== null) {
          locationTextPartelement = (
            <a href={googleMapsLink} target="_blank">
              {locationTextPart}
            </a>
          );
        } else {
          locationTextPartelement = <span>{locationTextPart}</span>;
        }

        return (
          <span key={index}>
            {locationTextPartelement}
            {index + 1 < locationTextParts.length && <br />}
          </span>
        );
      })}
    </div>
  );
}

export default function EventLocationOrVirtual({
  classes,
  shouldShowLocationLink = true,
  event,
}: EventLocationOrVirtualProps) {
  const { i18n } = React.useContext(I18nContext);

  const { isVirtual, virtualMeetingUrl, location } = event;

  let icon: React.ReactNode,
    locationTextOrVirtualLink: string | React.ReactNode;

  if (isVirtual) {
    icon = <Icon type={iconType.VIRTUAL} />;

    if (_str.isBlank(virtualMeetingUrl)) {
      locationTextOrVirtualLink = i18n.t('unknown_virtual_meeting_url', {
        scope: i18nScope,
      });
    } else {
      const url = virtualMeetingUrl.startsWith('http')
        ? virtualMeetingUrl
        : `https://${virtualMeetingUrl}`;

      locationTextOrVirtualLink = (
        <a href={url} target="_blank">
          {i18n.t('online_event', { scope: i18nScope })}
        </a>
      );
    }
  } else {
    icon = <Icon type={iconType.LOCATION} />;
    locationTextOrVirtualLink = _str.isBlank(location) ? (
      i18n.t('unknown_location', { scope: i18nScope })
    ) : (
      <EventLocation
        event={event}
        shouldShowLocationLink={shouldShowLocationLink}
      />
    );
  }

  return (
    <div className={classNames('event-location-or-virtual', classes)}>
      <span className="icon-wrapper mr-1">{icon}</span>
      <span className="text">{locationTextOrVirtualLink}</span>
    </div>
  );
}
