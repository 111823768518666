import { camelize } from 'underscore.string';
import { assertNotNullOrUndefined } from 'h';
import React, { useCallback } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';

import { FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';

import {
  UnsubscribeUserNotificationsSettingMutation,
  UserNotificationsSetting,
} from '__generated__/graphql';
import { UserNotificationsSettingId } from 'types/types';

import OneClickUnsubscribe, {
  UnsubscribeFormValues,
} from 'components/utils/one_click_unsubscribe';

// const i18nScope = 'components.user_notifications_settings.unsubscribe';

const UNSUBSCRIBE_USER_NOTIFICATIONS_SETTING = gql(`
  mutation UnsubscribeUserNotificationsSetting($input: UserNotificationsSettingUpdateInput!) {
    userNotificationsSettingUpdate(input: $input) {
      errors {
        attribute
        messages
      }
    }
  }
`);

interface UnsubscribeProps {
  emailAddress?: string;
  userNotificationsSettingId?: UserNotificationsSettingId;
  settingName?: keyof UserNotificationsSetting;
  unsubscribeToken?: string;
}

export default function UnsubscribeWrapper(props: UnsubscribeProps) {
  return (
    <RootAppWrapper>
      <Unsubscribe {...props} />
    </RootAppWrapper>
  );
}

function Unsubscribe({
  emailAddress,
  userNotificationsSettingId,
  settingName,
  unsubscribeToken,
}: UnsubscribeProps) {
  assertNotNullOrUndefined(emailAddress);
  assertNotNullOrUndefined(userNotificationsSettingId);
  assertNotNullOrUndefined(settingName);
  assertNotNullOrUndefined(unsubscribeToken);

  const [updateMutation] = useMutation(UNSUBSCRIBE_USER_NOTIFICATIONS_SETTING);

  const submitMainCallback = useCallback(
    ({
      values,
      actions,
      onSuccessCallback,
    }: {
      values: UnsubscribeFormValues;
      actions: FormikHelpers<UnsubscribeFormValues>;
      onSuccessCallback: () => void;
    }) => {
      sendMutationAndUpdateForm<
        UnsubscribeFormValues,
        UnsubscribeUserNotificationsSettingMutation
      >({
        actions,
        mutationName: 'userNotificationsSettingUpdate',
        main: () =>
          updateMutation({
            variables: {
              input: {
                id: String(userNotificationsSettingId),
                unsubscribeToken,
                ...values,
              },
            },
          }),
        successCallback: onSuccessCallback,
      });
    },
    [],
  );

  return (
    <OneClickUnsubscribe
      camelCaseSettingName={camelize(settingName)}
      emailAddress={emailAddress}
      submitMainCallback={submitMainCallback}
    />
  );
}
