import _str from 'underscore.string';
import React from 'react';
import classNames from 'classnames';
import FormErrorMessage from 'components/forms/form_error_message';

export interface FormFieldwrapperProps {
  children: React.ReactElement | React.ReactElement[];
  classes?: string;
  style?: React.CSSProperties;
  name: string;
  label?: string;
  description?: string;
  withoutErrorMessage?: boolean;
}

export default function FormFieldWrapper({
  children,
  classes,
  style,
  name,
  label = '',
  description = '',
  withoutErrorMessage = false,
}: FormFieldwrapperProps) {
  return (
    <div className={classNames('form-field-wrapper', classes)} style={style}>
      {!_str.isBlank(label) && <div className="form-field-label">{label}</div>}
      {children}
      {!_str.isBlank(description) && (
        <div className="form-field-description small">{description}</div>
      )}
      {!withoutErrorMessage && <FormErrorMessage name={name} />}
    </div>
  );
}
