import React, { useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

interface VerticalMenuProps {
  children: React.ReactNode[];
  triggerElement: React.ReactNode;
}

export default function VerticalMenu({
  children,
  triggerElement,
}: VerticalMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);

  const calcHeight = (el: HTMLElement) => setMenuHeight(el.offsetHeight);
  const resetHeight = () => setMenuHeight(0);

  return (
    <div className={classNames('vertical-menu', { open: isOpen })}>
      <div className="trigger" onClick={() => setIsOpen(!isOpen)}>
        {triggerElement}
      </div>
      <div className="menu-wrapper">
        <div className="menu-wrapper-inner" style={{ height: menuHeight }}>
          <CSSTransition
            unmountOnExit
            in={isOpen}
            timeout={500}
            classNames="menu"
            onEnter={calcHeight}
            onExit={resetHeight}
          >
            <div className="menu">
              <div className="menu-items">
                {children.map((child, index) => (
                  <span key={index} onClick={() => setIsOpen(false)}>
                    {child}
                  </span>
                ))}
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
}
