import _ from 'underscore';
import React, { useMemo } from 'react';
import { ClubEventComment } from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Icon, { iconType } from 'components/utils/icon';
import {
  FormActionButton,
  FormAutoExpandField,
  FormServerErrorMessages,
} from 'components/forms/forms';

import CommentWrapper, {
  AUTHOR_PROFILE_PIC_SIZE,
} from 'components/utils/discussions/comment_wrapper';
import { picSize } from 'components/utils/profile_pic';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'components.utils.discussions.add_comment';

export interface CommentFormValues {
  body: string;
}

export type AddCommentCallback<C> = ({
  values,
  actions,
  onSuccessCallback,
}: {
  values: CommentFormValues;
  actions: FormikHelpers<CommentFormValues>;
  onSuccessCallback: (comment: C) => void;
}) => void;

export function buildNewComment(): CommentFormValues {
  return {
    body: '',
  };
}

export default function AddComment<A, C>({
  currentAuthor,
  comment,
  getAuthorProfilePicCallback,
  addCommentCallback,
}: {
  currentAuthor: A;
  comment: Pick<ClubEventComment, 'body'>;
  getAuthorProfilePicCallback: (author: A, size: picSize) => React.ReactElement;
  addCommentCallback: AddCommentCallback<C>;
}) {
  const { i18n } = React.useContext(I18nContext);
  const initialValues = useMemo(() => _.pick(comment, 'body'), [comment]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        body: Yup.string().required(i18n.t('forms.errors.required')),
      }),
    [],
  );

  const authorProfilePic = useMemo(
    () => getAuthorProfilePicCallback(currentAuthor, AUTHOR_PROFILE_PIC_SIZE),
    [getAuthorProfilePicCallback, currentAuthor],
  );

  const _onSubmit = function (
    values: CommentFormValues,
    actions: FormikHelpers<CommentFormValues>,
  ) {
    addCommentCallback({
      values,
      actions,
      onSuccessCallback: () => {
        actions.setSubmitting(false);
        // hmm, reusing the same initial values as before is fine
        // for now since they're both empty but will probably be an issue
        // at some point
        actions.resetForm({ values: initialValues });
      },
    });
  };

  return (
    <div className="add-comment">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, dirty, isValid, isSubmitting }) => (
          <Form autoComplete="off" noValidate>
            <CommentWrapper
              authorElement={authorProfilePic}
              bodyElement={
                <FormAutoExpandField
                  rows="1"
                  name="body"
                  placeholder={i18n.t('form.body.placeholder', {
                    scope: i18nScope,
                  })}
                  withoutErrorMessage={true}
                />
              }
              rightActionsElement={
                <FormActionButton
                  className="btn btn-icon"
                  isDisabled={!isValid || !dirty}
                  isSubmitting={isSubmitting}
                >
                  <Icon type={iconType.SEND} classes="mx-0" />
                </FormActionButton>
              }
              footerElement={
                <FormServerErrorMessages
                  serverErrors={status?.serverErrors?.base}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
