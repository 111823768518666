import h from 'h';
import _ from 'underscore';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import {
  FormActions,
  FormActionButton,
  FormField,
  FormServerErrorMessages,
  FormAutoExpandField,
} from 'components/forms/forms';
import Autosave from 'components/forms/autosave';
import Icon, { iconType } from 'components/utils/icon';
import TippyMenu from 'components/utils/tippy_menu';
import FormContentEditableField from 'components/forms/form_content_editable_field';
import {
  EmailAddress,
  RemoteImageImageType,
  RemoteImageSubjectType,
} from 'types/types';
import I18nContext from 'contexts/i18n_context';
import {
  AdminClubCustomEmailBlastMutation,
  AdminClubCustomEmailPreviewMutation,
  AdminClubCustomEmailUpdateMutation,
  Club,
  ClubCustomEmail,
  CustomEmailAudienceTypeEnum,
} from '__generated__/graphql';

const i18nScope = 'clubs.admin.emails.drafts.form';

enum FormSteps {
  WRITE = 'write',
  BLAST = 'blast',
}

enum AutosaveState {
  IDLE = 'idle',
  SAVING = 'saving',
  FAILED = 'failed',
}

interface PreviewCustomEmailMenuFormValues {
  emailAddress: EmailAddress;
}

type CustomEmailWriteStepFormValues = Pick<ClubCustomEmail, 'title' | 'body'>;

type CustomEmailBlastStepFormValues = Pick<
  ClubCustomEmail,
  'audienceType' | 'replyTo'
>;

export type CustomEmailFormValues = CustomEmailWriteStepFormValues &
  CustomEmailBlastStepFormValues;

const ADMIN_CLUB_CUSTOM_EMAIL_UPDATE = gql(`
  mutation AdminClubCustomEmailUpdate($input: ClubCustomEmailCreateOrUpdateInput!) {
    clubCustomEmailCreateOrUpdate(input: $input) {
      customEmail {
        id
        body
        replyTo
        audienceType
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

const ADMIN_CLUB_CUSTOM_EMAIL_PREVIEW = gql(`
  mutation AdminClubCustomEmailPreview($input: ClubCustomEmailPreviewInput!) {
    clubCustomEmailPreview(input: $input) {
      success
      errors {
        attribute
        messages
      }
    }
  }
`);

const ADMIN_CLUB_CUSTOM_EMAIL_BLAST = gql(`
  mutation AdminClubCustomEmailBlast($input: ClubCustomEmailBlastInput!) {
    clubCustomEmailBlast(input: $input) {
      customEmail {
        id
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export function buildNewCustomEmail({
  club,
}: {
  club: Pick<Club, 'replyTo'>;
}): CustomEmailFormValues {
  return {
    title: '',
    body: '',
    replyTo: club.replyTo,
    audienceType: CustomEmailAudienceTypeEnum.ClubActiveMembers,
  };
}

function CustomEmailFormHeaderMenu({
  children,
  onBackCallback,
}: {
  children?: React.ReactElement[];
  onBackCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="top-menu">
      <div className="left-actions">
        <FormActionButton
          className="btn btn-small btn-white"
          handleClick={onBackCallback}
        >
          <Icon type={iconType.BACK} classes="mr-1" />
          <span>{i18n.t('header_menu.back', { scope: i18nScope })}</span>
        </FormActionButton>
      </div>
      <div className="right-actions">{children}</div>
    </div>
  );
}

function PreviewCustomEmailMenu({
  currentUserEmailAddress,
  customEmailId,
}: {
  currentUserEmailAddress: EmailAddress;
  customEmailId: ClubCustomEmail['id'];
}) {
  const { i18n } = React.useContext(I18nContext);
  const [previewMutation] = useMutation(ADMIN_CLUB_CUSTOM_EMAIL_PREVIEW);

  const resetSuccessMessageRef = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );
  const [shouldShowSuccessMessage, setShouldShowSuccessMessage] =
    useState(false);

  useEffect(() => {
    return () => {
      resetSuccessMessageRef.current &&
        clearTimeout(resetSuccessMessageRef.current);
    };
  }, []);

  const initialValues = useMemo(() => {
    return { emailAddress: currentUserEmailAddress };
  }, [currentUserEmailAddress]);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      emailAddress: Yup.string()
        .email(i18n.t('forms.errors.invalid_email'))
        .required(i18n.t('forms.errors.required')),
    });
  }, []);

  const _onSubmit = (
    values: PreviewCustomEmailMenuFormValues,
    actions: FormikHelpers<PreviewCustomEmailMenuFormValues>,
  ) => {
    sendMutationAndUpdateForm<
      PreviewCustomEmailMenuFormValues,
      AdminClubCustomEmailPreviewMutation
    >({
      actions,
      mutationName: 'clubCustomEmailPreview',
      resetSpinnerAfterSuccess: true,
      main: () =>
        previewMutation({
          variables: {
            input: {
              id: customEmailId,
              previewEmailAddress: values.emailAddress,
            },
          },
        }),
      successCallback: () => {
        actions.setSubmitting(false);
        setShouldShowSuccessMessage(true);
        resetSuccessMessageRef.current = setTimeout(
          () => setShouldShowSuccessMessage(false),
          2000,
        );
      },
    });
  };

  if (shouldShowSuccessMessage) {
    return (
      <div className="preview-custom-email-menu">
        {i18n.t('preview_custom_email_menu.success_message', {
          scope: i18nScope,
        })}
      </div>
    );
  }

  return (
    <div className="preview-custom-email-menu">
      <Formik
        onSubmit={_onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormField
              type="email"
              name="emailAddress"
              label={i18n.t(
                'preview_custom_email_menu.fields.email_address.label',
                { scope: i18nScope },
              )}
              placeholder={i18n.t(
                'preview_custom_email_menu.fields.email_address.placeholder',
                { scope: i18nScope },
              )}
            />

            <FormActions className="rtl">
              <FormActionButton
                className="btn-small primary"
                isSubmitting={isSubmitting}
              >
                <Icon type={iconType.SEND} classes="mr-1" />
                <span>
                  {i18n.t('preview_custom_email_menu.actions.submit', {
                    scope: i18nScope,
                  })}
                </span>
              </FormActionButton>
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function CustomEmailFormWriteStepStatusIndicator({
  autosaveState,
  hasPendingChanges,
}: {
  autosaveState: AutosaveState;
  hasPendingChanges: boolean;
}) {
  const { i18n } = React.useContext(I18nContext);
  let statusColorClass: string;
  let statusText: string;
  if (autosaveState === AutosaveState.FAILED) {
    statusColorClass = 'failed';
    statusText = i18n.t('write_step.status_indicator.failed', {
      scope: i18nScope,
    });
  } else if (hasPendingChanges) {
    statusColorClass = 'pending';
    statusText = i18n.t('write_step.status_indicator.saving', {
      scope: i18nScope,
    });
  } else {
    statusText = '';
    statusColorClass = 'up-to-date';
  }

  return (
    <span className="status-indicator mr-3">
      <span className="small light-text-color">{statusText}</span>

      <Icon
        type={iconType.CIRCLE}
        classes={classNames('status ml-2', statusColorClass)}
      />
    </span>
  );
}

function CustomEmailFormWriteStep({
  currentUserEmailAddress,
  clubId,
  customEmail,
  onNextCallback,
  onCancelCallback,
}: {
  clubId: Club['id'];
  currentUserEmailAddress: EmailAddress;
  customEmail: CustomEmailWriteStepFormValues & { id: ClubCustomEmail['id'] };
  onNextCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [updateMutation] = useMutation(ADMIN_CLUB_CUSTOM_EMAIL_UPDATE);

  const initialValues: CustomEmailWriteStepFormValues = useMemo(
    () => _.pick(customEmail, 'body', 'title'),
    [customEmail],
  );

  const [autosaveState, setAutosaveState] = useState(AutosaveState.IDLE);
  const [lastSavedValues, setLastSavedValues] = useState(initialValues);

  const _onSubmit = (
    values: CustomEmailWriteStepFormValues,
    actions: FormikHelpers<CustomEmailWriteStepFormValues>,
  ) => {
    setAutosaveState(AutosaveState.SAVING);

    sendMutationAndUpdateForm<
      CustomEmailFormValues,
      AdminClubCustomEmailUpdateMutation
    >({
      actions,
      mutationName: 'clubCustomEmailCreateOrUpdate',
      resetSpinnerAfterSuccess: true,
      main: () =>
        updateMutation({
          variables: {
            input: {
              clubId,
              id: customEmail.id,
              ...values,
            },
          },
        }),
      successCallback: () => {
        setAutosaveState(AutosaveState.IDLE);
        setLastSavedValues(values);
      },
      errorCallback: () => {
        setAutosaveState(AutosaveState.FAILED);
      },
    });
  };

  return (
    <div className="custom-email-form-write-step">
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {({ values, isSubmitting }) => {
          const hasPendingChanges =
            !_.isEqual(lastSavedValues, values) ||
            autosaveState === AutosaveState.SAVING;

          return (
            <Form className="basic-form" autoComplete="off" noValidate>
              <CustomEmailFormHeaderMenu onBackCallback={onCancelCallback}>
                <Autosave />
                <CustomEmailFormWriteStepStatusIndicator
                  autosaveState={autosaveState}
                  hasPendingChanges={hasPendingChanges}
                />

                <TippyMenu
                  offset={[0, 5]}
                  maxWidth={400}
                  content={
                    <PreviewCustomEmailMenu
                      currentUserEmailAddress={currentUserEmailAddress}
                      customEmailId={customEmail.id}
                    />
                  }
                >
                  <button
                    type="button"
                    className="btn btn-small btn-white"
                    disabled={isSubmitting || hasPendingChanges}
                  >
                    {i18n.t('write_step.actions.preview', { scope: i18nScope })}
                  </button>
                </TippyMenu>

                <button
                  type="button"
                  className="btn btn-small btn-primary"
                  onClick={onNextCallback}
                  disabled={isSubmitting || hasPendingChanges}
                >
                  {i18n.t('write_step.actions.submit', { scope: i18nScope })}
                </button>
              </CustomEmailFormHeaderMenu>

              <div className="center-content">
                <FormAutoExpandField
                  autoFocus={true}
                  rows="1"
                  classes="blend title-form-field"
                  name="title"
                  placeholder={i18n.t('write_step.fields.title.placeholder', {
                    scope: i18nScope,
                  })}
                />
                <FormContentEditableField
                  name="body"
                  value={values.body}
                  placeholder={i18n.t('write_step.fields.body.placeholder', {
                    scope: i18nScope,
                  })}
                  remoteImageUploadConfig={{
                    imageType: RemoteImageImageType.CUSTOM_EMAIL,
                    subjectType: RemoteImageSubjectType.CUSTOM_EMAIL,
                    subjectId: Number(customEmail.id),
                  }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

function CustomEmailFormBlastStep({
  clubId,
  customEmail,
  onDoneCallback,
  onCancelCallback,
}: {
  clubId: Club['id'];
  customEmail: CustomEmailBlastStepFormValues & { id: ClubCustomEmail['id'] };

  onDoneCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [updateMutation] = useMutation(ADMIN_CLUB_CUSTOM_EMAIL_UPDATE);
  const [blastMutation] = useMutation(ADMIN_CLUB_CUSTOM_EMAIL_BLAST);

  const initialValues = useMemo(
    () => _.pick(customEmail, 'replyTo', 'audienceType'),
    [customEmail],
  );
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      audienceType: Yup.string().required(i18n.t('forms.errors.required')),
      replyTo: Yup.string()
        .trim()
        .required(i18n.t('forms.errors.required'))
        .email(i18n.t('forms.errors.invalid_email')),
    });
  }, []);

  const _onSubmit = (
    values: CustomEmailBlastStepFormValues,
    actions: FormikHelpers<CustomEmailBlastStepFormValues>,
  ) => {
    sendMutationAndUpdateForm<
      CustomEmailBlastStepFormValues,
      AdminClubCustomEmailUpdateMutation
    >({
      actions,
      mutationName: 'clubCustomEmailCreateOrUpdate',
      main: () =>
        updateMutation({
          variables: {
            input: {
              clubId,
              id: customEmail.id,
              ...values,
            },
          },
        }),
      successCallback: () => {
        // okay, now that we've saved we'll go ahead and blast
        sendMutationAndUpdateForm<
          CustomEmailBlastStepFormValues,
          AdminClubCustomEmailBlastMutation
        >({
          actions,
          mutationName: 'clubCustomEmailBlast',
          main: () =>
            blastMutation({
              variables: {
                input: {
                  id: customEmail.id,
                },
              },
            }),
          successCallback: onDoneCallback,
        });
      },
    });
  };

  return (
    <div className="custom-email-form-blast-step">
      <CustomEmailFormHeaderMenu onBackCallback={onCancelCallback} />

      <div className="center-content">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_onSubmit}
        >
          {({ status, isSubmitting }) => (
            <Form className="basic-form" autoComplete="off" noValidate>
              <div className="center-content mt-5">
                <FormField
                  as="select"
                  name="audienceType"
                  label={i18n.t('blast_step.fields.audience_type.label', {
                    scope: i18nScope,
                  })}
                >
                  <option value={CustomEmailAudienceTypeEnum.ClubAllMembers}>
                    {i18n.t(
                      'blast_step.fields.audience_type.options.club_all_members',
                      { scope: i18nScope },
                    )}
                  </option>
                  <option value={CustomEmailAudienceTypeEnum.ClubActiveMembers}>
                    {i18n.t(
                      'blast_step.fields.audience_type.options.club_active_members',
                      { scope: i18nScope },
                    )}
                  </option>
                  <option
                    value={CustomEmailAudienceTypeEnum.ClubExpiredMembers}
                  >
                    {i18n.t(
                      'blast_step.fields.audience_type.options.club_expired_members',
                      { scope: i18nScope },
                    )}
                  </option>
                </FormField>
                <FormField
                  type="text"
                  name="replyTo"
                  label={i18n.t('blast_step.fields.reply_to.label', {
                    scope: i18nScope,
                  })}
                />
                <FormActions className="rtl">
                  <FormActionButton
                    className="primary"
                    isSubmitting={isSubmitting}
                  >
                    <Icon type={iconType.SEND} classes="mr-1" />
                    <span>
                      {i18n.t('blast_step.actions.submit', {
                        scope: i18nScope,
                      })}
                    </span>
                  </FormActionButton>
                  <FormServerErrorMessages
                    serverErrors={status?.serverErrors?.base}
                  />
                </FormActions>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default function CustomEmailForm({
  currentUserEmailAddress,
  clubId,
  customEmail,
  onDoneCallback,
  onCancelCallback,
}: {
  currentUserEmailAddress: EmailAddress;
  clubId: Club['id'];
  customEmail: Pick<
    ClubCustomEmail,
    'id' | 'title' | 'body' | 'audienceType' | 'replyTo'
  >;
  onDoneCallback: () => void;
  onCancelCallback: () => void;
}) {
  const [currentStep, setCurrentStep] = useState(FormSteps.WRITE);

  let show;
  switch (currentStep) {
    case FormSteps.WRITE:
      show = (
        <CustomEmailFormWriteStep
          currentUserEmailAddress={currentUserEmailAddress}
          clubId={clubId}
          customEmail={customEmail}
          onNextCallback={() => setCurrentStep(FormSteps.BLAST)}
          onCancelCallback={onCancelCallback}
        />
      );
      break;

    case FormSteps.BLAST:
      show = (
        <CustomEmailFormBlastStep
          clubId={clubId}
          customEmail={customEmail}
          onDoneCallback={onDoneCallback}
          onCancelCallback={() => setCurrentStep(FormSteps.WRITE)}
        />
      );
      break;

    default:
      h.throwExhaustiveError('unknown custom email form step', currentStep);
  }

  return <div className="custom-email-form">{show}</div>;
}
