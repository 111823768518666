import _ from 'underscore';
import React, { useState } from 'react';

import { FormActionButton } from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import BasicModal from 'components/utils/basic_modal';
import ProfilePageSection from 'components/clubs/profile/profile_page_section';
import ProfilePageSectionFormModal from 'components/clubs/admin/profile_page/profile_page_section_form_modal';
import ProfilePageMetaForm from 'components/clubs/admin/profile_page/profile_page_meta_form';

export default function ProfilePage({ i18n, pageStore }) {
  const club = pageStore.getCurrentClub();
  const profilePage = _.first(club.profilePages);

  const [sections, setSections] = useState(
    _.sortBy(profilePage.sections, (s) => s.sortOrder),
  );
  const [deletedSections, setDeletedSections] = useState([]);
  const [editingSection, setEditingSection] = useState(null);
  const [showAddSectionSelector, setShowAddSectionSelector] = useState(false);
  const [newSection, setNewSection] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMetaFormModalShown, setIsMetaFormModalShown] = useState(false);

  const _moveSectionCallback = function (section, steps) {
    const index = _.indexOf(sections, section);
    const tmpSections = [...sections];
    tmpSections.splice(index + steps, 0, tmpSections.splice(index, 1)[0]);
    // update the sort order for the sections, it's okay if destroyed are still in there
    _.each(tmpSections, (s, index) => (s.sortOrder = index + 1));
    setSections(tmpSections);
  };

  const _editSectionCallback = function (section) {
    setEditingSection(section);
  };
  const _finishedEditingSectionCallback = function (section, newConfig) {
    if (section === newSection) {
      setSections([...sections, { ...newSection, config: newConfig }]);
      setNewSection(null);
    } else {
      // if it's an existing section then we need to update it
      const index = _.findIndex(sections, section);
      const tmpSection = { ...sections[index], config: newConfig };
      const tmpSections = [...sections];
      tmpSections[index] = tmpSection;
      setSections(tmpSections);
    }
    setEditingSection(null);
  };
  const _canceledEditingSectionCallback = function () {
    setEditingSection(null);
    setNewSection(null);
  };

  const _removeSectionCallback = function (section) {
    section._destroy = true;
    setDeletedSections([...deletedSections, section]);
    setSections(_.without(sections, section));
  };
  const _addSectionCallback = function (sectionType) {
    const tmpSection = {
      sectionType: sectionType,
      sortOrder: sections.length + 1,
      config: {},
    };
    setShowAddSectionSelector(false);
    setNewSection(tmpSection);
    _editSectionCallback(tmpSection);
  };

  const _saveCallback = function () {
    const allSections = sections.concat(deletedSections);
    const values = { sections_attributes: allSections };

    setIsSubmitting(true);
    const url = Helpers.Routes.getClubAdminProfilePagePath(
      profilePage.clubId,
      profilePage.id,
    );
    const params = { clubProfilePage: values };
    Helpers.API.put({ url, params })
      .then(() => {
        setDeletedSections([]);
      })
      .catch(() => {
        alert(i18n.t('forms.errors.unknown_error'));
      })
      .finally(() => setIsSubmitting(false));
  };

  const sectionsHtml = _.map(sections, (section, index) => {
    return (
      <ProfilePageSection
        i18n={i18n}
        store={pageStore}
        key={index}
        index={index}
        section={section}
        isFirst={index === 0}
        isLast={index === sections.length - 1}
        canEdit={true}
        isEditing={section === editingSection}
        editSectionCallback={_editSectionCallback}
        moveSectionCallback={_moveSectionCallback}
        removeSectionCallback={_removeSectionCallback}
      />
    );
  });

  return (
    <div id="clubs-admin-profile-page">
      <div className="header">
        <div className="title">
          <h1 className="capitalize">{i18n.t('clubs.admin.profile.name')}</h1>
          <span
            className="edit-meta-icon"
            onClick={() => setIsMetaFormModalShown(true)}
          >
            ⚙
          </span>
        </div>

        <div className="actions">
          <FormActionButton
            className="btn btm-small btn-primary"
            isSubmitting={isSubmitting}
            handleClick={_saveCallback}
            text={i18n.t('clubs.admin.profile.save')}
          />
        </div>
      </div>

      {editingSection && (
        <ProfilePageSectionFormModal
          i18n={i18n}
          store={pageStore}
          section={editingSection}
          onFinishedEditingCallback={_finishedEditingSectionCallback}
          onCancelCallback={_canceledEditingSectionCallback}
        />
      )}
      {_renderProfilePageMetaFormModal({
        isMetaFormModalShown,
        setIsMetaFormModalShown,
        i18n,
        profilePage,
      })}

      <div id="club-profile-page">
        {sectionsHtml}
        <div
          className="add-section h1 bold"
          onClick={() => setShowAddSectionSelector(true)}
        >
          add a section
        </div>
        {showAddSectionSelector && (
          <div className="add-section-selector">
            <a onClick={() => setShowAddSectionSelector(false)}>cancel</a>
            <br />
            <a onClick={() => _addSectionCallback('banner')}>banner</a>
            <br />
            <a onClick={() => _addSectionCallback('header')}>header</a>
            <br />
            <a onClick={() => _addSectionCallback('members')}>members</a>
            <br />
            <a onClick={() => _addSectionCallback('sponsors')}>sponsors</a>
            <br />
            <a onClick={() => _addSectionCallback('races')}>races</a>
          </div>
        )}
      </div>
    </div>
  );
}

function _renderProfilePageMetaFormModal({
  isMetaFormModalShown,
  setIsMetaFormModalShown,
  i18n,
  profilePage,
}) {
  const _onSaveCallback = () => {
    alert('update profile page');
    setIsMetaFormModalShown(false);
  };

  return (
    <BasicModal
      className="basic-modal-content profile-page-meta-form-modal basic-form"
      isOpen={isMetaFormModalShown}
      onRequestCloseCallback={() => setIsMetaFormModalShown(false)}
    >
      <ProfilePageMetaForm
        i18n={i18n}
        profilePage={profilePage}
        onSaveCallback={_onSaveCallback}
      ></ProfilePageMetaForm>
    </BasicModal>
  );
}
