import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAdminClubWaiversQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminTable, {
  DestroyConfig,
  UpdatePositionConfig,
} from 'components/utils/admin/admin_table';
import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import Icon, { iconType } from 'components/utils/icon';
import { sendMutation } from 'helpers/gql_form_helpers';

const i18nScope = 'clubs.admin.waivers.index';
const tableScope = `${i18nScope}.table`;

type Club = NonNullable<GetAdminClubWaiversQuery['club']>;
type Waiver = Club['waivers'][number];

const GET_ADMIN_CLUB_WAIVERS = gql(`
  query GetAdminClubWaivers($clubId: ID!) {
    club(id: $clubId) {
      id
      waivers {
        id
        name
        isRequired
        position
        signedCount
        showUrl
      }
    }
  }
`);

const ADMIN_CLUB_WAIVER_DESTROY = gql(`
  mutation AdminClubWaiverDestroy($input: ClubWaiverDestroyInput!) {
    clubWaiverDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

const ADMIN_CLUB_WAIVER_SWAP_POSITION = gql(`
  mutation AdminClubWaiverSwapPosition($input: ClubWaiverSwapPositionInput!) {
    clubWaiverSwapPosition(input: $input) {
      waiver1 {
        id
        position
      }
      waiver2 {
        id
        position
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function WaiversPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [destroyMutation] = useMutation(ADMIN_CLUB_WAIVER_DESTROY);
  const [swapPositionMutation] = useMutation(ADMIN_CLUB_WAIVER_SWAP_POSITION);

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_WAIVERS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading || data === null)
    return <AdminDashboardLoadingPlaceholder columnWidths={[40, 40, 20]} />;
  if (error) return <ErrorPage />;

  const club = data?.club;
  assertNotNullOrUndefined(club);
  const waivers = club.waivers;
  assertNotNullOrUndefined(waivers);
  const sortedWaivers = _.sortBy(waivers, (waiver) => waiver.position);

  const updatePositionConfig: UpdatePositionConfig<Waiver> = {
    getPosition: (waiver) => waiver.position ?? Infinity,
    updatePositionCallback: (target, adjacent) => {
      sendMutation({
        mutationName: 'clubWaiverSwapPosition',
        main: () =>
          swapPositionMutation({
            variables: {
              input: {
                waiver1Id: target.id,
                waiver2Id: adjacent.id,
              },
            },
            // optimistic update for speedy UI
            optimisticResponse: {
              clubWaiverSwapPosition: {
                waiver1: {
                  id: target.id,
                  __typename: 'ClubWaiver',
                  position: adjacent.position,
                },
                waiver2: {
                  id: adjacent.id,
                  __typename: 'ClubWaiver',
                  position: target.position,
                },
                errors: [],
              },
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  const destroyConfig: DestroyConfig<Waiver> = {
    getCanDestroy: (waiver) => waiver.signedCount === 0,
    getTooltipText: (waiver, canDestroy) =>
      canDestroy
        ? ''
        : i18n.t('actions.delete_disabled_tooltip', { scope: tableScope }),
    getConfirmText: (waiver) =>
      i18n.t('actions.delete_confirmation', {
        scope: tableScope,
        values: {
          name: waiver.name,
        },
      }),
    destroyCallback: (waiver) => {
      // optimistic remove, and then we add it back in if it fails
      sendMutation({
        mutationName: 'clubWaiverDestroy',
        main: () =>
          destroyMutation({
            variables: { input: { id: waiver.id } },
            // // TODO: the update function runs but the component
            // // does not re-render so there is no optimistic update
            // optimisticResponse: {
            //   clubWaiverDestroy: {
            //     deletedId: waiver.id,
            //     errors: [],
            //   },
            // },
            update: (cache, result, options) => {
              const deletedId = options?.variables?.input?.id;
              assertNotNullOrUndefined(deletedId);

              const idToRemove = cache.identify({
                __typename: 'ClubWaiver',
                id: deletedId,
              });

              cache.evict({ id: idToRemove });
              cache.gc();
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <div id="clubs-admin-waivers-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_waiver_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
        contentClasses="elevate-content min-height-page"
      >
        <AdminTable
          items={sortedWaivers}
          emptyTableElement={
            <p>{i18n.t('empty_dashboard', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.name', { scope: tableScope })}</th>
              <th>{i18n.t('heading.is_required', { scope: tableScope })}</th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(waiver) => (
            <>
              <td>{waiver.name}</td>
              <td>
                {waiver.isRequired
                  ? i18n.t('fields.is_required.true', { scope: tableScope })
                  : i18n.t('fields.is_required.false', { scope: tableScope })}
              </td>
            </>
          )}
          renderAdditionalItemActions={(waiver) => (
            <Icon
              type={iconType.PREVIEW}
              classes="action mr-2"
              tooltipText={i18n.t('actions.preview', {
                scope: tableScope,
              })}
              onClick={() => window.open(waiver.showUrl, '_blank')}
            />
          )}
          onClickEditCallback={(waiver) => navigate(`${waiver.id}/edit`)}
          destroyConfig={destroyConfig}
          updatePositionConfig={updatePositionConfig}
        />
      </AdminDashboard>
    </div>
  );
}
