import { arrayFilterNulls } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useParams } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { AdminTableLoadingPlaceholder } from 'components/utils/admin/admin_table';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AuditLogsList from 'components/utils/audit_logs/audit_logs_list';
import LoadMore from 'components/utils/load_more';

const i18nScope = 'clubs.admin.memberships.show.logs';

const GET_ADMIN_CLUB_MEMBERSHIP_AUDIT_LOGS = gql(`
  query GetAdminClubMembershipAuditLogs($clubId: ID!, $membershipId: ID!, $cursor: String) {
    club(id: $clubId) {
      id
      membership(id: $membershipId) {
        id
        membershipAuditLogs(after: $cursor) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              type
              logType
              loggedAt
              isSystemGenerated
              createdByFullName
              data
            }
          }
        }
      }
    }
  }
`);

export default function MembershipShowLogsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();

  const { loading, error, data, fetchMore } = useQuery(
    GET_ADMIN_CLUB_MEMBERSHIP_AUDIT_LOGS,
    {
      variables: {
        clubId: String(currentClubId),
        membershipId: String(id),
      },
    },
  );

  if (loading) return <AdminTableLoadingPlaceholder columnWidths={[100]} />;
  if (error) return <ErrorPage />;

  const logsConnection = data?.club?.membership?.membershipAuditLogs;
  const pageInfo = logsConnection?.pageInfo;
  const membershipAuditLogs = arrayFilterNulls(
    (logsConnection?.edges ?? []).map((e) => e?.node),
  );

  return (
    <div className="membership-emails-tab">
      {membershipAuditLogs.length == 0 ? (
        <p>{i18n.t('none', { scope: i18nScope })}</p>
      ) : (
        <>
          <AuditLogsList auditLogs={membershipAuditLogs} />
          <LoadMore
            canLoadMore={pageInfo?.hasNextPage}
            onClick={() =>
              fetchMore({
                variables: {
                  cursor: pageInfo?.endCursor,
                },
              })
            }
          />
        </>
      )}
    </div>
  );
}
