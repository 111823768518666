import _ from 'underscore';
import { useCallback } from 'react';
import { useSkipInitialEffect } from 'helpers/react';
import { useFormikContext } from 'formik';

// TODO, may bring this back.

//export const AutosaveState = {
//  UP_TO_DATE: 'up_to_date',
//  PENDING: 'pending',
//  FAILED: 'failed'
//}

//export default function Autosave({children, onSaveCallback, onSuccessCallback, debounceInMs=300}) {
//  const { values, isSubmitting } = useFormikContext()
//  const [saveState, setSaveState] = useState(AutosaveState.UP_TO_DATE)
//
//  const debouncedSave = useCallback(_.debounce(({values}) => {
//    // we could use submitForm from Formik which would trigger
//    // validations too
//    return onSaveCallback({values})
//      .then((response) => {
//        setSaveState(AutosaveState.UP_TO_DATE)
//        //onSuccessCallback?.(response)
//      })
//      //.catch(() => setSaveState(AutosaveState.FAILED))
//  }, debounceInMs), [onSaveCallback, onSuccessCallback, debounceInMs])
//
//  useSkipInitialEffect(() => {
//    setSaveState(AutosaveState.PENDING)
//    debouncedSave({values})
//  }, [debouncedSave, values])
//
//  return children ? children({autosaveState: saveState}) : null
//}

interface AutosaveProps {
  debounceInMs?: number;
}

export default function Autosave<T>({ debounceInMs = 300 }: AutosaveProps) {
  const { values, submitForm } = useFormikContext<T>();

  const debouncedSave = useCallback(
    _.debounce(() => {
      submitForm();
    }, debounceInMs),
    [debounceInMs, submitForm],
  );

  useSkipInitialEffect(() => {
    debouncedSave();
  }, [debouncedSave, values]);
  return null;
}
