import React from 'react';
import { Formik, Form } from 'formik';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import { FormField, FormSelectField } from 'components/forms/forms';

const COLOR_OPTIONS = [
  { value: 'brown', label: 'Brown' },
  { value: 'red', label: 'Red' },
  { value: 'green', label: 'Green' },
];

export default function ReactSelectPage() {
  const initialValues = {
    name: '',
    color: '',
    otherColor: COLOR_OPTIONS[0],
    colors: [],
    otherColors: COLOR_OPTIONS.slice(0, 1),
  };

  const _onSubmit = () => {
    // do nothing
  };
  return (
    <AdminDashboard
      title={'React select tests'}
      contentClasses="elevate-content min-height-page skinny-page"
    >
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        <Form className="basic-form" autoComplete="off" noValidate>
          <FormField
            autoFocus={true}
            type="text"
            name="name"
            label="example text field"
            placeholder="your name"
            description="shown to be able to compare styling against a form field"
          />

          <FormSelectField
            label="single select"
            name="color"
            placeholder={'Select a color'}
            options={COLOR_OPTIONS}
          />

          <FormSelectField
            label="single select (preselected)"
            name="otherColor"
            placeholder={'Select another color'}
            options={COLOR_OPTIONS}
          />

          <FormSelectField
            label="multi select"
            name="colors"
            placeholder={'Select colors'}
            isMulti={true}
            options={COLOR_OPTIONS}
          />

          <FormSelectField
            label="multi select (preselected options)"
            name="otherColors"
            placeholder={'Select more'}
            isMulti={true}
            options={COLOR_OPTIONS}
          />
        </Form>
      </Formik>
    </AdminDashboard>
  );
}
