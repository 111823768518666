import _ from 'underscore';
import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';

import 'tippy.js/dist/tippy.css';

interface TippyTooltipProps extends TippyProps {
  classes?: string;
  children: React.ReactElement;
}

export default function TippyTooltip({
  classes,
  children,
  ...props
}: TippyTooltipProps) {
  const config = Object.assign({}, props);
  _.defaults(config, {
    arrow: true,
    placement: 'top',
    trigger: 'mouseenter click',
    interactive: false,
  });

  return (
    <Tippy className={classNames('tippy-tooltip', classes)} {...config}>
      {children}
    </Tippy>
  );
}
