import h from 'h';

import React from 'react';
import I18nContext from 'contexts/i18n_context';
import { ClubProduct } from '__generated__/graphql';

const i18nScope = 'components.clubs.storefronts.product_badges';

export default function ProductBages({
  product,
  maxNumberOfBadges,
}: {
  product: Pick<ClubProduct, 'isSoldOut'>;
  maxNumberOfBadges?: number;
}) {
  const { i18n } = React.useContext(I18nContext);

  const badges: React.ReactElement[] = [];

  if (product.isSoldOut) {
    badges.push(
      <span key="sold-out" className="badge sold-out">
        {i18n.t('sold_out', { scope: i18nScope })}
      </span>,
    );
  }

  if (badges.length === 0) {
    return null;
  }

  const selectedBadges = h.isNullOrUndefined(maxNumberOfBadges)
    ? badges
    : badges.slice(0, maxNumberOfBadges);

  return <div className="storefront-product-badges">{selectedBadges}</div>;
}
