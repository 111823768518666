import _ from 'underscore';
import { CardElement } from '@stripe/react-stripe-js';

import Helpers from 'helpers/helpers';
import { FormikHelpers } from 'formik';
import { PaymentMethodResult, Stripe, StripeElements } from '@stripe/stripe-js';
import h from 'h';
import { StripePaymentMethod, UnparsedStripePaymentMethod } from 'types/types';
import { AxiosResponse } from 'axios';

type ClubsumStripePaymentMethodParams = Pick<StripePaymentMethod, 'isDefault'>;

export async function tokenizeAndSavePaymentMethodAsync<T = unknown>({
  stripe,
  elements,
  actions,
  clubsumStripePaymentMethodParams,
}: {
  stripe: Stripe | null;
  elements: StripeElements | null;
  actions: FormikHelpers<T>;
  clubsumStripePaymentMethodParams: ClubsumStripePaymentMethodParams;
}): Promise<UnparsedStripePaymentMethod | null> {
  const tokenizeResponse = await _tokenizeAsync<T>({
    stripe,
    elements,
    actions,
  });
  if (tokenizeResponse === null) {
    // TODO test this path when tokenization fails
    return null;
  }
  return await _savePaymentMethodAsync<T>({
    tokenizeResponse,
    actions,
    clubsumStripePaymentMethodParams,
  });
}

async function _tokenizeAsync<T>({
  stripe,
  elements,
  actions,
}: {
  stripe: Stripe | null;
  elements: StripeElements | null;
  actions: FormikHelpers<T>;
}): Promise<PaymentMethodResult | null> {
  if (stripe === null || elements === null) {
    return h.throwError('stripe or elements are null');
  }

  const cardElement = elements.getElement(CardElement);
  if (cardElement === null) {
    return h.throwError('unable to find card element');
  }

  const result = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
  });

  if (result.error) {
    // Show error to your customer (e.g., insufficient funds)
    // The StripeCreditCardFormField handles displaying these errors
    // like incorrect date, or incorrect
    console?.error('result.error', result.error);
    actions.setSubmitting(false);
    return null;
  }

  return result;
}

async function _savePaymentMethodAsync<T>({
  tokenizeResponse,
  actions,
  clubsumStripePaymentMethodParams,
}: {
  tokenizeResponse: PaymentMethodResult;
  actions: FormikHelpers<T>;
  clubsumStripePaymentMethodParams: ClubsumStripePaymentMethodParams;
}): Promise<UnparsedStripePaymentMethod | null> {
  const paymentMethod = tokenizeResponse.paymentMethod;

  if (typeof paymentMethod === 'undefined') {
    return h.throwError('payment method after tokenization is undefined');
  }

  const stripePaymentMethodParams = {
    rawStripeId: paymentMethod.id,
    paymentMethodType: paymentMethod.type,
    ..._.pick(
      paymentMethod.card ?? {},
      'last4',
      'brand',
      'exp_month',
      'exp_year',
    ),
    ...clubsumStripePaymentMethodParams,
  };

  try {
    const response = await Helpers.Form.saveAsync<T>({
      actions,
      main: () => {
        const url = Helpers.Routes.getStripePaymentMethodsPath();
        const params = { stripePaymentMethod: stripePaymentMethodParams };
        return Helpers.API.post({ url, params });
      },
    });
    return (response as AxiosResponse<UnparsedStripePaymentMethod>).data;
  } catch (error) {
    // do nothing, the Helpers.Form handles setting the error
    // on the formik form
    return null;
  }
}
