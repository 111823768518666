import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetCurrentUserQuery } from '__generated__/graphql';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import I18nContext from 'contexts/i18n_context';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormFieldWrapper,
  FormServerErrorMessages,
} from 'components/forms/forms';
import ImageUploader from 'components/utils/image_uploader';
import Helpers from 'helpers/helpers';
import { UnparsedUserAsOwner, UserAsOwner } from 'types/types';
import { AxiosResponse } from 'axios';

import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';

const i18nScope = 'components.users.my_account.profile';

type CurrentUser = NonNullable<GetCurrentUserQuery['currentUser']>;

const GET_CURRENT_USER = gql(`
  query GetCurrentUser {
    currentUser {
      id
      firstName
      lastName
      fullName
      emailAddress
      profilePicUrl
    }
  }
`);

type UserProfileFormValues = Pick<
  UserAsOwner,
  'firstName' | 'lastName' | 'emailAddress'
>;

function UserProfileForm({
  user,
  onSuccessCallback,
}: {
  user: UserProfileFormValues & Pick<CurrentUser, 'id' | 'profilePicUrl'>;
  onSuccessCallback: (updatedUser: CurrentUser) => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const initialValues = _.pick(
    user,
    'firstName',
    'lastName',
    'emailAddress',
    'profilePic',
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string()
          .min(2, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
        lastName: Yup.string()
          .min(2, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
        emailAddress: Yup.string()
          .email(i18n.t('forms.errors.invalid_email'))
          .required(i18n.t('forms.errors.required')),
      }),
    [],
  );

  const onSubmit = function (
    values: UserProfileFormValues,
    actions: FormikHelpers<UserProfileFormValues>,
  ) {
    Helpers.Form.save({
      actions,
      main: () => {
        const url = Helpers.Routes.getUserPath(Number(user.id));
        const params = { user: values };
        return Helpers.API.put({
          url,
          params,
          options: { shouldUseFormData: true },
        });
      },
      successCallback: (response) => {
        const userAttributes = (response as AxiosResponse<UnparsedUserAsOwner>)
          .data.data.attributes;
        onSuccessCallback({ ...userAttributes, id: String(userAttributes.id) });
      },
    });
  };

  return (
    <div className="user-profile-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ status, setFieldTouched, setFieldValue, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormFieldWrapper name="profilePic">
              <ImageUploader
                name="profilePic"
                classes="profile-pic-uploader"
                existingImageUrl={user.profilePicUrl}
                aspectRatio={1}
                onChangeCallback={(name, croppedImage) => {
                  setFieldValue(name, croppedImage);
                  setFieldTouched(name, true);
                }}
                cropModalTitle={i18n.t('form.profile_pic.crop_modal_title', {
                  scope: i18nScope,
                })}
                cropModalSubmit={i18n.t('form.profile_pic.crop_modal_submit', {
                  scope: i18nScope,
                })}
              />
            </FormFieldWrapper>
            <FormField
              type="text"
              name="firstName"
              placeholder={i18n.t('form.first_name_placeholder', {
                scope: i18nScope,
              })}
            />
            <FormField
              type="text"
              name="lastName"
              placeholder={i18n.t('form.last_name_placeholder', {
                scope: i18nScope,
              })}
            />
            <FormField
              type="email"
              name="emailAddress"
              placeholder={i18n.t('form.email_address_placeholder', {
                scope: i18nScope,
              })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('form.submit', {
                  scope: i18nScope,
                })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function UserProfileInfo({ user }: { user: CurrentUser }) {
  return (
    <div className="user-profile">
      <div className="profile-pic mb-2">
        <img src={user.profilePicUrl} />
      </div>
      <h2>{user.fullName}</h2>
      <p>{user.emailAddress}</p>
    </div>
  );
}

export default function UserProfilePage() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [user, setUser] = useState<CurrentUser | null>(null);

  const { loading, error } = useQuery(GET_CURRENT_USER, {
    onCompleted: (newData) => {
      assertNotNullOrUndefined(newData?.currentUser);
      setUser(newData.currentUser);
    },
  });

  if (error) return <ErrorPage />;
  if (loading || user === null) return <LoadingPage />;

  let contents;
  if (isEditMode) {
    contents = (
      <UserProfileForm
        user={user}
        onSuccessCallback={(updatedUser) => {
          setIsEditMode(false);
          setUser(updatedUser);
        }}
      />
    );
  } else {
    contents = <UserProfileInfo user={user} />;
  }

  return (
    <div id="user-profile-page">
      <div className="elevate-content">
        <div id="user-profile-page-inner">
          <div className="user-profile-edit-toggle">
            <i
              onClick={() => setIsEditMode(!isEditMode)}
              className={isEditMode ? 'fas fa-times' : 'fas fa-pen'}
            ></i>
          </div>

          {contents}
        </div>
      </div>
    </div>
  );
}
