import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import SettingsForm from 'components/clubs/admin/settings_page/settings_form';

const i18nScope = 'clubs.admin.settings.edit';

const GET_CLUB_EDIT_SETTINGS = gql(`
  query GetClubEditSettings($clubId: ID!) {
    club(id: $clubId) {
      id
      logoUrl
      name
      shortName
      website
      city
      state
      currency
      timeZone
      replyTo
    }
  }
`);

export function SettingsEditPage({ currentClubId }: { currentClubId: ClubId }) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(GET_CLUB_EDIT_SETTINGS, {
    variables: {
      clubId: String(currentClubId),
      productId: String(id),
    },
  });
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);

  return (
    <div id="clubs-admin-settings-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page skinny-page"
      >
        <SettingsForm
          club={club}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
