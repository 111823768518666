import React from 'react';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'clubs.clubhouse.races';

export default function RacesPage() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div id="clubhouse-races-page">
      <div className="page-header">
        <div className="title">
          <h1 className="mb-0">{i18n.t('title', { scope: i18nScope })}</h1>
        </div>
      </div>

      <div
        className="elevate-content vertical-center center"
        style={{ height: 400 }}
      >
        <h2 className="mb-0">{i18n.t('coming_soon', { scope: i18nScope })}</h2>
      </div>
    </div>
  );
}
