import React from 'react';
import I18nContext from 'contexts/i18n_context';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import SettingsGridView from 'components/clubs/admin/shared/settings_grid_view';

const i18nScope = 'clubs.admin.events.settings';

export default function EventsSettingsPage() {
  const { i18n } = React.useContext(I18nContext);

  const settingFields = [
    {
      label: i18n.t('fields.who_can_create.label', {
        scope: i18nScope,
      }),
      value: i18n.t('fields.who_can_create.value', {
        scope: i18nScope,
      }),
    },
    {
      label: i18n.t('fields.calendar_first_day.label', {
        scope: i18nScope,
      }),
      value: i18n.t('fields.calendar_first_day.value', {
        scope: i18nScope,
      }),
    },
  ];

  return (
    <div id="clubs-admin-events-settings-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page"
      >
        <SettingsGridView settingFields={settingFields} />
      </AdminDashboard>
    </div>
  );
}
