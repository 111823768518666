import React, { useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';
import Store, { ModelType } from 'helpers/store';
import Helpers from 'helpers/helpers';
import PendingWaiversForm from 'components/utils/waivers/pending_waivers_form';
import { DeprecatedAny, Club, RawWaiver } from 'types/types';

const i18nScope = 'components.clubs.waivers.pending_waivers';

function NoPendingWaivers() {
  const { i18n } = React.useContext(I18nContext);
  return <h1>{i18n.t('none.title', { scope: i18nScope })}</h1>;
}

function SignPendingWaiversForm({
  club,
  rawWaivers,
}: {
  club: Pick<Club, 'id' | 'name'>;
  rawWaivers: RawWaiver[];
}) {
  const { i18n } = React.useContext(I18nContext);
  const [shouldShowSignedSuccessfully, setShouldShowSignedSuccessfully] =
    useState(false);

  return (
    <>
      <h1>
        {i18n.t('title', {
          scope: i18nScope,
          count: rawWaivers.length,
          values: {
            clubName: club.name,
          },
        })}
      </h1>
      {shouldShowSignedSuccessfully ? (
        <p className="mt-5">
          {i18n.t('signed_successfully.text', { scope: i18nScope })}
        </p>
      ) : (
        <PendingWaiversForm
          getSignedWaiversApiUrl={({ waiverId }) =>
            Helpers.Routes.getClubWaiverSignedWaiversPath(club.id, waiverId)
          }
          rawWaivers={rawWaivers}
          onSuccessCallback={() => setShouldShowSignedSuccessfully(true)}
        />
      )}
    </>
  );
}

export default function PendingWaiversWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <PendingWaivers {...props} />
    </RootAppWrapper>
  );
}

function PendingWaivers(props: DeprecatedAny) {
  const [store] = useState(() => Store.parseInitData(props));

  const club = store.getCurrentClub();
  const rawWaivers = store.getAllRawModels<RawWaiver>(ModelType.WAIVER);

  return (
    <div className="pending-waivers">
      {rawWaivers.length === 0 ? (
        <NoPendingWaivers />
      ) : (
        <SignPendingWaiversForm club={club} rawWaivers={rawWaivers} />
      )}
    </div>
  );
}
