import h, { arrayFilterNulls, assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ClubNotificationsSettingNotificationTypeEnum,
  GetAdminClubNotificationsSettingsQuery,
} from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import { sendMutation } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';

import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import { DestroyConfig } from 'components/utils/admin/admin_table';
import Icon, { iconType } from 'components/utils/icon';
import StaticImagesHelper from 'helpers/static_images_helper';

const i18nScope = 'clubs.admin.settings.notifications.index';
const tableScope = `${i18nScope}.table`;

type Club = NonNullable<GetAdminClubNotificationsSettingsQuery['club']>;
type ClubNotificationsSetting = NonNullable<
  NonNullable<
    NonNullable<Club['clubNotificationsSettings']['edges']>[number]
  >['node']
>;

const GET_ADMIN_CLUB_NOTIFICATONS_SETTINGS = gql(`
  query GetAdminClubNotificationsSettings($clubId: ID!) {
    club(id: $clubId) {
      id
      clubNotificationsSettings {
        edges {
          node {
            id
            notificationType
            discordWebhookUrl
            slackWebhookUrl
            slackChannel
          }
        }
      }
    }
  }
`);

const ADMIN_CLUB_CLUB_NOTIFICATONS_SETTING_DESTROY = gql(`
  mutation AdminCluNotificationsSettingDestroy($input: ClubNotificationsSettingDestroyInput!) {
    clubNotificationsSettingDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

function ClubNotificationsSettingCard({
  clubNotificationsSetting,
  destroyConfig,
  onClickEditCallback,
}: {
  clubNotificationsSetting: ClubNotificationsSetting;
  destroyConfig: DestroyConfig<ClubNotificationsSetting>;
  onClickEditCallback: (t: ClubNotificationsSetting) => void;
}) {
  let cardConfig: { title: string; logoUrl: string; channel?: string } | null =
    null;

  switch (clubNotificationsSetting.notificationType) {
    case ClubNotificationsSettingNotificationTypeEnum.Discord:
      cardConfig = {
        title: ClubNotificationsSettingNotificationTypeEnum.Discord,
        logoUrl: StaticImagesHelper.discordIconUrl(),
      };
      break;
    case ClubNotificationsSettingNotificationTypeEnum.Slack:
      cardConfig = {
        title: ClubNotificationsSettingNotificationTypeEnum.Slack,
        channel: clubNotificationsSetting.slackChannel,
        logoUrl: StaticImagesHelper.slackIconUrl(),
      };
      break;
    default:
      h.throwExhaustiveError(
        'unknown notification type',
        clubNotificationsSetting.notificationType,
      );
  }

  assertNotNullOrUndefined(cardConfig);

  return (
    <div className="cns-card">
      <div className="info">
        <div className="title">
          <img src={cardConfig.logoUrl} height="20" />
          <span>{cardConfig.title}</span>
        </div>
        {cardConfig.channel && (
          <div className="channel small light-text-color">
            #{cardConfig.channel}
          </div>
        )}
      </div>
      <div className="actions">
        <Icon
          type={iconType.EDIT}
          classes="action ml-2"
          onClick={() => onClickEditCallback(clubNotificationsSetting)}
        />
        <Icon
          type={iconType.DELETE}
          classes="action ml-2"
          confirmationText={destroyConfig.getConfirmText?.(
            clubNotificationsSetting,
          )}
          tooltipText={destroyConfig.getTooltipText?.(
            clubNotificationsSetting,
            true,
          )}
          onClick={() =>
            destroyConfig.destroyCallback(clubNotificationsSetting)
          }
        />
      </div>
    </div>
  );
}

export default function ClubNotificationsSettingsIndexPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [destroyMutation] = useMutation(
    ADMIN_CLUB_CLUB_NOTIFICATONS_SETTING_DESTROY,
  );

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(
    GET_ADMIN_CLUB_NOTIFICATONS_SETTINGS,
    {
      variables: {
        clubId: String(currentClubId),
      },
    },
  );

  if (loading || data === null)
    return <AdminDashboardLoadingPlaceholder columnWidths={[70, 30]} />;
  if (error) return <ErrorPage />;
  const clubNotificationsSettingsConnection =
    data?.club?.clubNotificationsSettings;
  const clubNotificationsSettings = arrayFilterNulls(
    (clubNotificationsSettingsConnection?.edges ?? []).map((e) => e?.node),
  );
  assertNotNullOrUndefined(clubNotificationsSettings);

  const destroyConfig: DestroyConfig<ClubNotificationsSetting> = {
    getConfirmText: (clubNotificationsSetting) =>
      i18n.t('actions.delete_confirmation', {
        scope: tableScope,
        values: {
          notificationType: clubNotificationsSetting.notificationType,
        },
      }),
    destroyCallback: (clubNotificationsSetting) => {
      // optimistic remove, and then we add it back in if it fails
      sendMutation({
        mutationName: 'clubNotificationsSettingDestroy',
        main: () =>
          destroyMutation({
            variables: { input: { id: clubNotificationsSetting.id } },
            // // TODO: the update function runs but the component
            // // does not re-render so there is no optimistic update
            // optimisticResponse: {
            //   clubNotificationsSettingDestroy: {
            //     deletedId: clubNotificationsSetting.id,
            //     errors: [],
            //   },
            // },
            update: (cache, result, options) => {
              const deletedId = options?.variables?.input?.id;
              assertNotNullOrUndefined(deletedId);

              const idToRemove = cache.identify({
                __typename: 'ClubNotificationsSetting',
                id: deletedId,
              });

              cache.evict({ id: idToRemove });
              cache.gc();
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <div id="clubs-admin-settings-notifications-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
        contentClasses="elevate-content min-height-page"
      >
        {clubNotificationsSettings.length === 0 ? (
          <div>{i18n.t('empty_dashboard', { scope: i18nScope })}</div>
        ) : (
          <div>
            {clubNotificationsSettings.map((cns, index) => (
              <ClubNotificationsSettingCard
                key={index}
                clubNotificationsSetting={cns}
                onClickEditCallback={(cns) => navigate(`${cns.id}/edit`)}
                destroyConfig={destroyConfig}
              />
            ))}
          </div>
        )}
      </AdminDashboard>
    </div>
  );
}
