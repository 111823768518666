import React, { useCallback, useEffect, useState } from 'react';
import { UserPreference } from '__generated__/graphql';
import Helpers from 'helpers/helpers';
import { Club, RawClubEvent } from 'types/types';
import EventsCalendarView from 'components/utils/events/events_calendar_view';
import EventsTabbedListView from 'components/utils/events/events_tabbed_list_view';
import LoadingView, {
  FetchStoreDataCallback,
} from 'components/utils/loading_view';
import { ModelType } from 'helpers/store';
import { EventsListViewLoadingPlaceholder } from 'components/utils/events/events_list_view';

interface ClubEventsCalendarOrListViewProps {
  club: Pick<Club, 'id' | 'timeZone'>;
  openLinksInNewTab: boolean;
  shouldShowCalendarIfPossible: boolean;
  calendarFirstDay?: UserPreference['calendarFirstDay'];
}

const MIN_WIDTH_FOR_CALENDAR_IN_PX = 700;

export function getCanShowCalendar(): boolean {
  return !Helpers.ScreenSize.getIsWidthSmallerThanOrEqual(
    MIN_WIDTH_FOR_CALENDAR_IN_PX,
  );
}

function ClubEventsCalendarView({
  club,
  openLinksInNewTab,
  calendarFirstDay,
}: Pick<
  ClubEventsCalendarOrListViewProps,
  'club' | 'openLinksInNewTab' | 'calendarFirstDay'
>) {
  return (
    <EventsCalendarView
      currentTimeZone={club.timeZone}
      apiGetUrl={Helpers.Routes.getClubClubEventsPath(club.id)}
      openLinksInNewTab={openLinksInNewTab}
      calendarFirstDay={calendarFirstDay}
    />
  );
}

function ClubEventsListView({
  club,
  openLinksInNewTab,
}: Pick<ClubEventsCalendarOrListViewProps, 'club' | 'openLinksInNewTab'>) {
  const fetchStoreDataCallback: FetchStoreDataCallback = useCallback(
    ({ cancelToken }) => {
      const params = { type: 'recent' };
      const url = Helpers.Routes.getClubClubEventsPath(club.id);
      return Helpers.API.get({ url, params, cancelToken });
    },
    [club],
  );

  return (
    <div>
      <LoadingView
        fetchStoreDataCallback={fetchStoreDataCallback}
        loadingElement={<EventsListViewLoadingPlaceholder />}
      >
        {({ store }) => {
          const rawClubEvents = store.getAllRawModels<RawClubEvent>(
            ModelType.CLUB_EVENT,
          );

          return (
            <EventsTabbedListView
              currentTimeZone={club.timeZone}
              openLinksInNewTab={openLinksInNewTab}
              rawClubEvents={rawClubEvents}
            />
          );
        }}
      </LoadingView>
    </div>
  );
}

export default function ClubEventsCalendarOrListView({
  club,
  openLinksInNewTab,
  shouldShowCalendarIfPossible = true,
  calendarFirstDay,
}: ClubEventsCalendarOrListViewProps) {
  // if the user is viewing from a small device then we
  // default to a list view
  const [canShowCalendar, setCanShowCalendar] = useState(getCanShowCalendar);

  useEffect(
    Helpers.ScreenSize.useEffect(() => {
      const newCanShowCalendar = getCanShowCalendar();
      setCanShowCalendar(newCanShowCalendar);
    }),
    [setCanShowCalendar],
  );

  const shouldShowCalendar = canShowCalendar && shouldShowCalendarIfPossible;

  return (
    <div>
      {shouldShowCalendar ? (
        <ClubEventsCalendarView
          club={club}
          openLinksInNewTab={openLinksInNewTab}
          calendarFirstDay={calendarFirstDay}
        />
      ) : (
        <ClubEventsListView club={club} openLinksInNewTab={openLinksInNewTab} />
      )}
    </div>
  );
}
