import React, { useMemo, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
  FormAutoExpandField,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import { ClubId, MembershipRenewalCancelation } from 'types/types';

const i18nScope = 'views.clubs.membership_renewal_cancelations.form';

type CancelRenewalFormValues = Pick<MembershipRenewalCancelation, 'feedback'>;

export default function CancelRenewalForm({
  clubId,
  onBackRedirectToPath,
  onSuccessCallback,
}: {
  clubId: ClubId;
  onBackRedirectToPath: string;
  onSuccessCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [isRedirecting, setIsRedirecting] = useState(false);

  const initialValues: CancelRenewalFormValues = { feedback: '' };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        feedback: Yup.string().required(i18n.t('forms.errors.required')),
      }),
    [],
  );

  const _onSubmit = function (
    values: CancelRenewalFormValues,
    actions: FormikHelpers<CancelRenewalFormValues>,
  ) {
    Helpers.Form.save({
      actions,
      main: () => {
        const url =
          Helpers.Routes.getClubMembershipRenewalCancelationsPath(clubId);
        return Helpers.API.post({
          url,
          params: { membershipRenewalCancelation: values },
        });
      },
      successCallback: () => onSuccessCallback(),
    });
  };

  return (
    <div id="membership-renewal-cancelation-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <div className="mt-5">
              <FormAutoExpandField
                autoFocus={true}
                rows="5"
                name="feedback"
                label={i18n.t('fields.feedback.label', { scope: i18nScope })}
                placeholder={i18n.t('fields.feedback.placeholder', {
                  scope: i18nScope,
                })}
              />
            </div>
            <FormActions>
              <FormActionButton
                text={i18n.t('submit', { scope: i18nScope })}
                className="btn-black"
                isSubmitting={isSubmitting}
                isDisabled={isRedirecting}
              />
              <FormActionButton
                text={i18n.t('back_button', { scope: i18nScope })}
                className="btn-white"
                isSubmitting={isRedirecting}
                isDisabled={isSubmitting}
                handleClick={() => {
                  setIsRedirecting(true);
                  window.location.href = onBackRedirectToPath;
                }}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
