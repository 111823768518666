import _ from 'underscore';
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormFieldWrapper,
} from 'components/forms/forms';
import RemoteImageUploader from 'components/utils/remote_image_uploader';
import BasicModal from 'components/utils/basic_modal';

const i18nScope = 'clubs.admin.profile.section_forms';

export default function EditProfilePageSectionModal({
  i18n,
  section,
  title,
  onFinishedEditingCallback,
  onCancelCallback,
}) {
  const submitCallback = (values) =>
    onFinishedEditingCallback?.(section, values);
  const onRequestClose = () => onCancelCallback?.(section);

  let form;
  switch (section.sectionType) {
    case 'banner':
      form = _renderBannerSectionForm({ i18n, submitCallback, section });
      break;
    case 'header':
      form = _renderHeaderSectionForm({ i18n, submitCallback, section });
      break;
    case 'members':
      form = _renderMembersSectionForm({
        i18n,
        submitCallback,
        section,
      });
      break;
    case 'programs':
      form = _renderProgramsSectionForm({
        i18n,
        submitCallback,
        section,
      });
      break;
    case 'races':
      form = _renderRacesSectionForm({ i18n, submitCallback, section });
      break;
    case 'sponsors':
      form = _renderSponsorsSectionForm({
        i18n,
        submitCallback,
        section,
      });
      break;
    default:
      throw new Error(`unknown section ${section.sectionType}`);
  }

  return (
    <BasicModal
      className="profile-page-section-form-modal"
      isOpen={true}
      onRequestCloseCallback={onRequestClose}
      contentLabel={title}
    >
      {form}
    </BasicModal>
  );
}

// --------------
// --- BANNER ---
// --------------
const BannerSectionSchema = Yup.object().shape({
  banner: Yup.string()
    .min(2, window.translations.forms.errors.min_length)
    .required(window.translations.forms.errors.required),
});

function _renderBannerSectionForm({ i18n, submitCallback, section }) {
  return (
    <div className="banner-section-form">
      <Formik
        initialValues={_.pick(section.config, 'banner')}
        validationSchema={BannerSectionSchema}
        onSubmit={submitCallback}
      >
        {({ isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              component="textarea"
              name="banner"
              placeholder={i18n.t('banner.banner', { scope: i18nScope })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('banner.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

// --------------
// --- HEADER ---
// --------------
const HeaderSectionSchema = Yup.object().shape({
  description: Yup.string()
    .min(20, window.translations.forms.errors.min_length)
    .required(window.translations.forms.errors.required),
});

function _renderHeaderSectionForm({ i18n, submitCallback, section }) {
  const defaultHeroImageUrl = 'https://via.placeholder.com/300x175';

  return (
    <div className="header-section-form">
      <Formik
        initialValues={_.pick(section.config, 'description', 'heroImageId')}
        validationSchema={HeaderSectionSchema}
        onSubmit={submitCallback}
      >
        {({ setFieldTouched, setFieldValue, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormFieldWrapper name="heroImageId">
              <RemoteImageUploader
                i18n={i18n}
                name="heroImageId"
                classes="hero-image-uploader"
                remoteImageUploadConfig={{
                  imageType: 'club_profile_page_section',
                  subjectId: null,
                  subjectType: null,
                }}
                existingImageUrl={
                  section.remoteImageUrlsById?.[section.config.heroImageId] ??
                  defaultHeroImageUrl
                }
                aspectRatio={600 / 350}
                onChangeCallback={(unparsedRemoteImage, fieldName) => {
                  setFieldValue(
                    fieldName,
                    unparsedRemoteImage.data.attributes.id,
                  );
                  setFieldTouched(fieldName, true);
                }}
                cropModalSubmit={i18n.t('header.hero_image_submit', {
                  scope: i18nScope,
                })}
              />
            </FormFieldWrapper>
            <FormField
              component="textarea"
              name="description"
              placeholder={i18n.t('header.description', { scope: i18nScope })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('header.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

// ----------------
// --- PROGRAMS ---
// ----------------
const ProgramsSectionSchema = Yup.object().shape({
  heading: Yup.string().min(2, window.translations.forms.errors.min_length),
});

function _renderProgramsSectionForm({ i18n, submitCallback, section }) {
  return (
    <div className="programs-section-form">
      <Formik
        initialValues={_.pick(section.config, 'heading')}
        validationSchema={ProgramsSectionSchema}
        onSubmit={submitCallback}
      >
        {({ isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              component="textarea"
              name="heading"
              placeholder={i18n.t('programs.heading', { scope: i18nScope })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('programs.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

// -------------
// --- RACES ---
// -------------
const RacesSectionSchema = Yup.object().shape({
  heading: Yup.string()
    .min(2, window.translations.forms.errors.min_length)
    .required(window.translations.forms.errors.required),
});

function _renderRacesSectionForm({ i18n, submitCallback, section }) {
  return (
    <div className="races-section-form">
      <Formik
        initialValues={_.pick(section.config, 'heading')}
        validationSchema={RacesSectionSchema}
        onSubmit={submitCallback}
      >
        {({ isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              component="textarea"
              name="heading"
              placeholder={i18n.t('races.heading', { scope: i18nScope })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('races.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

// ---------------
// --- MEMBERS ---
// ---------------
const MembersSectionSchema = Yup.object().shape({
  heading: Yup.string()
    .min(2, window.translations.forms.errors.min_length)
    .required(window.translations.forms.errors.required),
});

function _renderMembersSectionForm({ i18n, submitCallback, section }) {
  return (
    <div className="members-section-form">
      <Formik
        initialValues={_.pick(section.config, 'heading')}
        validationSchema={MembersSectionSchema}
        onSubmit={submitCallback}
      >
        {({ isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              component="textarea"
              name="heading"
              placeholder={i18n.t('members.heading', { scope: i18nScope })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('members.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

// ----------------
// --- SPONSORS ---
// ----------------
const SponsorsSectionSchema = Yup.object().shape({
  heading: Yup.string()
    .min(2, window.translations.forms.errors.min_length)
    .required(window.translations.forms.errors.required),
});

function _renderSponsorsSectionForm({ i18n, submitCallback, section }) {
  return (
    <div className="sponsors-section-form">
      <Formik
        initialValues={_.pick(section.config, 'heading')}
        validationSchema={SponsorsSectionSchema}
        onSubmit={submitCallback}
      >
        {({ isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              component="textarea"
              name="heading"
              placeholder={i18n.t('sponsors.heading', { scope: i18nScope })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('sponsors.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
