import classNames from 'classnames';
import React from 'react';

export type SettingField = {
  label?: string;
  value: string | React.ReactElement | null | undefined;
  shouldHide?: boolean;
};

export type SettingFields = SettingField[];

export default function SettingsGridView({
  classes,
  settingFields,
}: {
  classes?: string;
  settingFields: SettingFields;
}) {
  return (
    <div className={classNames('settings-grid-view', classes)}>
      {settingFields
        .filter((settingField) => !(settingField.shouldHide ?? false))
        .map((settingField, index) => (
          <div className="settings-row" key={index}>
            <div className="label">{settingField.label}</div>
            <div className="value">{settingField.value}</div>
          </div>
        ))}
    </div>
  );
}
