import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAdminClubPermissionGroupsQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import { sendMutation } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';

import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import AdminTable, { DestroyConfig } from 'components/utils/admin/admin_table';

const i18nScope = 'clubs.admin.permission_groups.index';
const tableScope = `${i18nScope}.table`;

type Club = NonNullable<GetAdminClubPermissionGroupsQuery['club']>;
type ClubPermissionGroup = Club['permissionGroups'][number];

const GET_ADMIN_CLUB_PERMISSION_GROUPS = gql(`
  query GetAdminClubPermissionGroups($clubId: ID!) {
    club(id: $clubId) {
      id
      permissionGroups {
        id
        name
      }
    }
  }
`);

const ADMIN_CLUB_PERMISSION_GROUP_DESTROY = gql(`
  mutation AdminCluPermissionGroupDestroy($input: ClubPermissionGroupDestroyInput!) {
    clubPermissionGroupDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function PermissionGroupsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [destroyMutation] = useMutation(ADMIN_CLUB_PERMISSION_GROUP_DESTROY);

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_PERMISSION_GROUPS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading || data === null)
    return <AdminDashboardLoadingPlaceholder columnWidths={[70, 30]} />;
  if (error) return <ErrorPage />;
  const permissionGroups = data?.club?.permissionGroups;
  assertNotNullOrUndefined(permissionGroups);
  const sortedPermissionGroups = _.sortBy(permissionGroups, (pg) => pg.name);

  const destroyConfig: DestroyConfig<ClubPermissionGroup> = {
    getConfirmText: (permissionGroup) =>
      i18n.t('actions.delete_confirmation', {
        scope: tableScope,
        values: {
          permissionGroupName: permissionGroup.name,
        },
      }),
    destroyCallback: (permissionGroup) => {
      // optimistic remove, and then we add it back in if it fails
      sendMutation({
        mutationName: 'clubPermissionGroupDestroy',
        main: () =>
          destroyMutation({
            variables: { input: { id: permissionGroup.id } },
            // // TODO: the update function runs but the component
            // // does not re-render so there is no optimistic update
            // optimisticResponse: {
            //   clubPermissionGroupDestroy: {
            //     deletedId: permissionGroup.id,
            //     errors: [],
            //   },
            // },
            update: (cache, result, options) => {
              const deletedId = options?.variables?.input?.id;
              assertNotNullOrUndefined(deletedId);

              const idToRemove = cache.identify({
                __typename: 'ClubPermissionGroup',
                id: deletedId,
              });

              cache.evict({ id: idToRemove });
              cache.gc();
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <div id="clubs-admin-permission-groups-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_group_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
        contentClasses="elevate-content min-height-page"
      >
        <AdminTable
          items={sortedPermissionGroups}
          emptyTableElement={
            <p>{i18n.t('empty_dashboard', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.name', { scope: tableScope })}</th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(permissionGroup) => (
            <>
              <td>{permissionGroup.name}</td>
            </>
          )}
          renderAdditionalItemActions={(pg) => (
            <a
              className="action link"
              onClick={() => navigate(`${pg.id}/manage`)}
            >
              {i18n.t('actions.manage_memberships', { scope: tableScope })}
            </a>
          )}
          onClickEditCallback={(pg) => navigate(`${pg.id}/edit`)}
          destroyConfig={destroyConfig}
        />
      </AdminDashboard>
    </div>
  );
}
