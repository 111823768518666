import React from 'react';
import I18nContext from 'contexts/i18n_context';
import { ClubProduct } from '__generated__/graphql';
import ProductBages from 'components/clubs/storefronts/product_badges';

// const i18nScope = 'components.clubs.storefronts.product_card';

export default function ProductCard<
  P extends Pick<
    ClubProduct,
    'name' | 'description' | 'priceInCents' | 'imageUrls' | 'isSoldOut'
  >,
>({
  product,
  onClickCallback,
}: {
  product: P;
  onClickCallback: (product: P) => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div
      className="storefront-product-card"
      onClick={() => onClickCallback(product)}
    >
      <div className="product-img">
        <img src={product.imageUrls?.[0]} />
        <ProductBages product={product} maxNumberOfBadges={1} />
      </div>
      <div className="product-info">
        <span className="bold">{product.name}</span>
        <br />
        {i18n.c({ amountInCents: product.priceInCents })}
      </div>
    </div>
  );
}
