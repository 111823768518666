import _ from 'underscore';
import Helpers from 'helpers/helpers';
import { ClubEvent } from '__generated__/graphql';

// TODO: this should probably be computed on the backend not here
export enum EventStates {
  UPCOMING = 'upcoming',
  ENDED = 'ended',
  CANCELED = 'canceled',
}

export function getEventState(
  event: Pick<ClubEvent, 'wasCanceled' | 'hasEnded'>,
): EventStates {
  if (event.wasCanceled) {
    return EventStates.CANCELED;
  } else if (event.hasEnded) {
    return EventStates.ENDED;
  } else {
    return EventStates.UPCOMING;
  }
}

export function getEventTimeText({
  event,
  shouldIncludeTimeZone = true,
}: {
  event: Pick<
    ClubEvent,
    | 'startDate'
    | 'startTimeInMinutes'
    | 'endDate'
    | 'endTimeInMinutes'
    | 'isAllDay'
    | 'timeZone'
  >;
  shouldIncludeTimeZone: boolean;
}): string {
  const dateAndTimeText = Helpers.Utils.formatDateAndTimeRange({
    startDate: event.startDate,
    startTimeInMinutes: event.startTimeInMinutes,
    endDate: event.endDate,
    endTimeInMinutes: event.endTimeInMinutes,
    isAllDay: event.isAllDay,
    timeZone: event.timeZone,
    shouldIncludeTimeZone,
  });
  return dateAndTimeText;
}

export function getEventLatitudeAndLongitudeIfExists(
  event: Pick<ClubEvent, 'longitude' | 'latitude'>,
):
  | { latitude: number; longitude: number }
  | { latitude: null; longitude: null } {
  const { latitude, longitude } = event;
  if (latitude === null || longitude === null) {
    return { latitude: null, longitude: null };
  }
  const lat = _.isString(latitude) ? parseFloat(latitude) : latitude;
  const lng = _.isString(longitude) ? parseFloat(longitude) : longitude;
  return { latitude: lat, longitude: lng };
}
