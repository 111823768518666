import _ from 'underscore';
import _str from 'underscore.string';
import React, { useMemo, useState } from 'react';
import { StripePaymentMethod } from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import {
  FormActions,
  FormActionButton,
  FormFieldWrapper,
  FormServerErrorMessages,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import { tokenizeAndSavePaymentMethodAsync } from 'helpers/stripe_helpers';

import PaymentMethodFormField from 'components/payments/payment_method_form_field';
import I18nContext from 'contexts/i18n_context';
import { DeprecatedAny, OrderItem } from 'types/types';

const i18nScope = `componentts.utils.orders.checkout_wrapper`;

interface OrderFormValues {
  // TODO: issue#200, using a string instead of StripePaymentMethodId
  // until we have better support for placeholder values
  platformStripePaymentMethodId: string;
}

// HACK: issue#200 using this to convert Id to String
// When initializing a form sometimes there is no id, and since
// we can't use null we use ''. So that means I had to case
// the value as a string, so it means anywhere were we do have
// an id it must be converted to a string
function convertIdToString(modelId: number | string) {
  return String(modelId);
}

export default function CheckoutWrapper<T>({
  children,
  additionalInitialValues,
  additionalValidationSchema,
  onSuccessCallback,
  orderIdempotencyKey,
  platformStripePaymentMethods,
  createOrderItemsCallback,
  overrideSubmitButtonText,
  secondaryActionButtonText,
  secondaryActionButtonClickCallback,
}: {
  children: ({
    paymentMethodFieldFormFieldElement,
    status,
    setFieldTouched,
    setFieldValue,
    values,
    isSubmitting,
  }: {
    paymentMethodFieldFormFieldElement: React.ReactElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    status: any;
    isSubmitting: boolean;
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined,
    ) => void;
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void;
    values: OrderFormValues & T;
  }) => React.ReactElement;
  additionalInitialValues: T;
  // TODO: replace DeprecatedAny with
  //  with option  of T,
  additionalValidationSchema: DeprecatedAny;
  orderIdempotencyKey: string;
  platformStripePaymentMethods: Pick<
    StripePaymentMethod,
    | 'id'
    | 'isDefault'
    | 'brand'
    | 'brandLogoUrl'
    | 'last4'
    | 'expMonth'
    | 'expYear'
  >[];
  createOrderItemsCallback: ({
    values,
  }: {
    values: DeprecatedAny;
  }) => Pick<OrderItem, 'subjectType' | 'subjectId' | 'priceInCents'>[];
  overrideSubmitButtonText?: string;
  secondaryActionButtonText?: string;
  secondaryActionButtonClickCallback?: () => void;
  onSuccessCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [stripePaymentMethods, setStripePaymentMethods] = useState(
    platformStripePaymentMethods,
  );

  const [isRedirecting, setIsRedirecting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const selectedStripePaymentMethod =
    stripePaymentMethods.find((spm) => spm.isDefault) ??
    _.first(stripePaymentMethods) ??
    null;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...additionalValidationSchema,
      }),
    [],
  );

  // const stripePaymentIntentClientSecret = store.get('stripePaymentIntentClientSecret')

  const _onSubmit = async (
    values: OrderFormValues & T,
    actions: FormikHelpers<OrderFormValues & T>,
  ) => {
    Helpers.Form.resetErrorsAndMarkTouched({ actions });

    // update the PaymentIntent object with the correct amount
    //const orderParams = {};
    //Helpers.Form.save({
    //  values,
    //  actions,
    //  main: (() => { return Helpers.API.put(`/-/orders/updatePaymentIntentAmount`, {order: orderParams}) })
    //  successCallback
    //})
    // charge the card
    //const result = await stripe.confirmCardPayment(stripePaymentIntentClientSecret, {
    //  payment_method: {
    //    card: elements.getElement(CardElement),
    //  }
    //});
    //if (result.error) {
    //  // Show error to your customer (e.g., insufficient funds)
    //  console.log('result.error', result.error);
    //  actions.setStatus({serverErrors: {base: result.error.message})
    //  actions.setSubmitting(false)
    //} else {
    //  // The payment has been processed!
    //  if (result.paymentIntent.status === 'succeeded') {
    //    // Show a success message to your customer
    //    // There's a risk of the customer closing the window before callback
    //    // execution. Set up a webhook or plugin to listen for the
    //    // payment_intent.succeeded event that handles any business critical
    //    // post-payment actions.
    //  }
    //}

    // if we haven't already tokenized their payment method or
    // they haven't selected an existing payment method then
    // we're expecting them to enter their credit card info
    let newlyCreatedPlatformplatformStripePaymentMethodId;
    if (_str.isBlank(values.platformStripePaymentMethodId)) {
      const unparsedStripePaymentMethod =
        await tokenizeAndSavePaymentMethodAsync({
          stripe,
          elements,
          actions,
          clubsumStripePaymentMethodParams: {
            // if it's the first one, it'll be made the default
            isDefault: false,
          },
        });
      if (unparsedStripePaymentMethod === null) {
        // there was error tokenizing, abort
        actions.setSubmitting(false);
        return;
      }

      newlyCreatedPlatformplatformStripePaymentMethodId =
        unparsedStripePaymentMethod.data.id;

      // TODO: we set the stripe payment method here but if there are
      // issues with the card and the order fails to get created, then
      // we won't create a new stripe payment method if the user changes
      // their card info in the stripe elemnent. We need to figure out a
      // better solution
      actions.setFieldValue(
        'platformStripePaymentMethodId',
        newlyCreatedPlatformplatformStripePaymentMethodId,
      );
    }

    // create the order object
    const orderParams = {
      idempotencyKey: orderIdempotencyKey,
      orderItemsAttributes: createOrderItemsCallback({ values }),
    };

    const params = {
      order: orderParams,
      platformStripePaymentMethodId:
        newlyCreatedPlatformplatformStripePaymentMethodId ??
        values.platformStripePaymentMethodId,
    };

    await Helpers.Form.saveAsync({
      actions,
      main: () => {
        const url = Helpers.Routes.getOrdersPath();
        return Helpers.API.post({ url, params });
      },
    });

    onSuccessCallback();
    setIsRedirecting(true);
  };

  const initialValues = {
    ...additionalInitialValues,
    platformStripePaymentMethodId:
      selectedStripePaymentMethod === null
        ? ''
        : convertIdToString(selectedStripePaymentMethod.id),
  };

  return (
    <div id="checkout-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, setFieldTouched, setFieldValue, isSubmitting, values }) => {
          const paymentMethodFieldFormFieldElement = (
            <FormFieldWrapper name="platformStripePaymentMethodId">
              <PaymentMethodFormField
                stripePaymentMethods={stripePaymentMethods}
                value={values.platformStripePaymentMethodId}
                onSelectCallback={(selectedStripePaymentMethodId) => {
                  setFieldValue(
                    'platformStripePaymentMethodId',
                    convertIdToString(selectedStripePaymentMethodId),
                  );
                  setFieldTouched('platformStripePaymentMethodId', true);
                }}
                onAddCallback={(stripePaymentMethod) => {
                  setStripePaymentMethods((prev) => [
                    ...prev,
                    stripePaymentMethod,
                  ]);
                }}
              />
            </FormFieldWrapper>
          );

          return (
            <Form className="basic-form" noValidate>
              {children({
                paymentMethodFieldFormFieldElement,
                status,
                setFieldTouched,
                setFieldValue,
                isSubmitting,
                values,
              })}

              <FormActions>
                <FormActionButton
                  text={
                    overrideSubmitButtonText ??
                    i18n.t('form.actions.submit', {
                      scope: i18nScope,
                    })
                  }
                  className="primary"
                  isSubmitting={isSubmitting || isRedirecting}
                  isDisabled={!stripe || isRedirecting}
                />
                {secondaryActionButtonText &&
                  secondaryActionButtonClickCallback && (
                    <FormActionButton
                      text={secondaryActionButtonText}
                      className="secondary"
                      isSubmitting={isRedirecting}
                      isDisabled={isSubmitting}
                      handleClick={secondaryActionButtonClickCallback}
                    />
                  )}

                <FormServerErrorMessages
                  serverErrors={status?.serverErrors?.base}
                />
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
