import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export interface TabLinkConfig {
  text: string;
  to: string;
  replace?: boolean;
}

// there probably is a way to keep it DRY with TabbedView but not
// worth figuring out since tabbed view will be phased out now that
// we're using react router
export function TabNavbar({
  tabLinkConfigs,
}: {
  tabLinkConfigs: TabLinkConfig[];
}) {
  return (
    <div className="tabbed-view">
      <div className="tab-names">
        {tabLinkConfigs.map((linkConfig, index) => (
          <NavLink
            key={index}
            className={({ isActive }) =>
              classNames('tab-name mr-2 ', {
                active: isActive,
              })
            }
            style={{ textDecoration: 'none' }}
            to={linkConfig.to}
            replace={linkConfig.replace ?? false}
          >
            {linkConfig.text}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default function TabbedView({
  children,
  initial,
  tabNames,
}: {
  initial?: string;
  tabNames: string[];
  children: ({
    selectedTabName,
  }: {
    selectedTabName: string;
  }) => React.ReactNode;
}) {
  const [selectedTabName, setSelectedTabName] = useState<string>(
    initial ?? tabNames[0] ?? '',
  );

  return (
    <div className="tabbed-view">
      <div className="tab-names vertical-scroll">
        {tabNames.map((tabName, index) => {
          return (
            <span
              key={index}
              className={classNames('tab-name mr-2', {
                active: selectedTabName === tabName,
              })}
              onClick={() => setSelectedTabName(tabName)}
            >
              {tabName}
            </span>
          );
        })}
      </div>
      <div className="tab-content">{children({ selectedTabName })}</div>
    </div>
  );
}

export function Tab({
  children,
  name,
  selectedTabName,
}: {
  children: React.ReactNode;
  name: string;
  selectedTabName: string;
}) {
  if (selectedTabName !== name) {
    // we're rendering a different tab
    // don't do anything here
    return null;
  }

  return <div className="tab">{children}</div>;
}
