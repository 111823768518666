import _ from 'underscore';
import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormAutoExpandField,
  FormFieldWrapper,
  FormServerErrorMessages,
} from 'components/forms/forms';
import ImageUploader from 'components/utils/image_uploader';
import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import { Club, ClubProfile } from '__generated__/graphql';

const i18nScope = 'clubs.admin.club_profile.form';

type ClubProfileFormValues = Pick<ClubProfile, 'description'>;

export default function ClubProfileForm({
  clubId,
  clubProfile,
  onSuccessCallback,
  onCancelCallback,
}: {
  clubId: Club['id'];
  clubProfile: ClubProfileFormValues &
    Pick<ClubProfile, 'id' | 'headerPhotoUrl'>;
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const initialValues = _.pick(clubProfile, 'headerImage', 'description');
  const clubProfileSchema = useMemo(
    () =>
      Yup.object().shape({
        description: Yup.string()
          .min(20, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
      }),
    [],
  );

  const _onSubmit = function (
    values: ClubProfileFormValues,
    actions: FormikHelpers<ClubProfileFormValues>,
  ) {
    Helpers.Form.save({
      actions,
      main: () => {
        const url = Helpers.Routes.getClubAdminClubProfilePath(
          Number(clubId),
          Number(clubProfile.id),
        );
        const params = { clubProfile: values };
        return Helpers.API.put({
          url,
          params,
          options: { shouldUseFormData: true },
        });
      },
      successCallback: () => {
        onSuccessCallback?.();
      },
    });
  };

  return (
    <div className="club-profile-form">
      <Formik
        initialValues={initialValues}
        validationSchema={clubProfileSchema}
        onSubmit={_onSubmit}
      >
        {({ status, setFieldValue, setFieldTouched, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormFieldWrapper
              name="headerPhoto"
              label={i18n.t('header_photo.label', { scope: i18nScope })}
              classes="header-photo-section"
            >
              <ImageUploader
                name="headerPhoto"
                classes="header-photo-uploader"
                existingImageUrl={clubProfile.headerPhotoUrl}
                aspectRatio={16 / 9}
                onChangeCallback={(name, croppedImage) => {
                  setFieldValue(name, croppedImage);
                  setFieldTouched(name, true);
                }}
                cropModalTitle={i18n.t('header_photo.crop_modal_title', {
                  scope: i18nScope,
                })}
                cropModalSubmit={i18n.t('header_photo.crop_modal_submit', {
                  scope: i18nScope,
                })}
              />
            </FormFieldWrapper>
            <FormAutoExpandField
              autoFocus={true}
              rows="4"
              name="description"
              label={i18n.t('description.label', { scope: i18nScope })}
            />
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('actions.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                className="secondary"
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
