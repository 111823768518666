import Cookies from 'js-cookie';

// should keep this in sync with app/constants/dark_mode.rb
export const CookieNames = {
  IS_DARK_MODE: 'is_dark_mode',
};

// use a wrapper so we can easily swap out js-cookie
// in the future
export default {
  get: Cookies.get,
  set: Cookies.set,
  remove: Cookies.remove,
};
