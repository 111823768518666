import { arrayFilterNulls } from 'h';
import _ from 'underscore';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import ContactSupport, { SupportType } from 'components/utils/contact_support';
import Icon, { iconType } from 'components/utils/icon';
import CompanyLogo, { logoSize } from 'components/utils/company_logo';

import {
  FormActions,
  FormActionButton,
  FormAutoExpandField,
  FormServerErrorMessages,
  FormAsyncGqlSelectField,
} from 'components/forms/forms';
import { FormSelectFieldStringOptionType } from 'components/forms/form_select_field';
import I18nContext from 'contexts/i18n_context';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  AdminClubSponsorshipCreateOrUpdateMutation,
  AdminClubSponsorshipSearchCompaniesQuery,
  Club,
  ClubSponsorship,
  ClubSponsorshipDeal,
} from '__generated__/graphql';

import { OptionProps, SingleValueProps, components } from 'react-select';

const i18nScope = 'clubs.admin.deals.form';

type Company = NonNullable<
  NonNullable<
    NonNullable<
      AdminClubSponsorshipSearchCompaniesQuery['companies']['edges']
    >[number]
  >['node']
>;

type CompanyOptionType = FormSelectFieldStringOptionType & {
  object: Company;
};

export interface SponsorshipFormValues {
  company: CompanyOptionType | null;
  deals: ClubSponsorshipDeal[];
}

const ADMIN_CLUB_SPONSORSHIP_SEARCH_COMPANIES = gql(`
  query AdminClubSponsorshipSearchCompanies($search: String) {
    companies(search: $search) {
      edges {
        node {
          id
          name
          logoUrl
        }
      }
    }
  }
`);

const ADMIN_CLUB_SPONSORSHIP_CREATE_OR_UPDATE = gql(`
  mutation AdminClubSponsorshipCreateOrUpdate($input: ClubSponsorshipCreateOrUpdateInput!) {
    clubSponsorshipCreateOrUpdate(input: $input) {
      sponsorship {
        id
        position
        deals {
          description
          redeemInstructions
        }
        company {
          id
          name
          logoUrl
        }
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

// Company Select field helpers
function convertCompanyToCompanyOption(company: Company): CompanyOptionType {
  return {
    label: company.name,
    value: company.id,
    object: company,
  };
}

function CompanyOption({ company }: { company: Company }) {
  return (
    <div className="company-option">
      <CompanyLogo
        classes="inline-block"
        company={company}
        size={logoSize.SMALL}
      />
      <span className="company-name">{company.name}</span>
    </div>
  );
}

const SingleValue = ({ ...props }: SingleValueProps<CompanyOptionType>) => (
  <components.SingleValue {...props}>
    <CompanyOption company={props.data.object} />
  </components.SingleValue>
);

const Option = (props: OptionProps<CompanyOptionType>) => {
  return (
    <components.Option {...props}>
      <CompanyOption company={props.data.object} />
    </components.Option>
  );
};

export function buildNewSponsorship(): {
  company: ClubSponsorship['company'] | null;
  deals: ClubSponsorship['deals'];
} {
  return {
    company: null, // hack
    deals: [],
  };
}

function buildNewDeal(): ClubSponsorshipDeal {
  return {
    description: '',
    redeemInstructions: '',
  };
}

export default function SponsorshipForm({
  clubId,
  isEditing,
  sponsorship,
  onSuccessCallback,
  onCancelCallback,
}: {
  clubId: Club['id'];
  isEditing: boolean;
  sponsorship: Omit<SponsorshipFormValues, 'company'> & {
    id?: ClubSponsorship['id'];
    company: Company | null;
  };
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [createOrUpdateMutation] = useMutation(
    ADMIN_CLUB_SPONSORSHIP_CREATE_OR_UPDATE,
  );

  const initialValues: SponsorshipFormValues = useMemo(
    // NOTE: we do this instead of _.pick(sponsorship, 'companyId', 'deals')
    // because when editing deals include a __typename which gets included
    // and causes an error. Ideally we want a deep pick method so we can
    // specify exactly which attributes to include. I like the structure
    // of how strong parameters does it on ruby. ideally we can do something like
    // _.pick(sponsorship, 'companyId', {deals: ['description', 'redeemInstructions']})
    () => ({
      company:
        sponsorship.company === null
          ? null
          : convertCompanyToCompanyOption(sponsorship.company),
      deals: sponsorship.deals.map((deal) =>
        _.pick(deal, 'description', 'redeemInstructions'),
      ),
    }),
    [],
  );

  const validationSchema = Yup.object().shape({
    company: Yup.object().required(i18n.t('forms.errors.required')).nullable(),
    deals: Yup.array().of(
      Yup.object().shape({
        description: Yup.string()
          .required(i18n.t('forms.errors.required'))
          .min(10, i18n.t('forms.errors.min_length')),
        redeemInstructions: Yup.string().min(
          8,
          i18n.t('forms.errors.min_length'),
        ),
      }),
    ),
  });

  const _onSubmit = function (
    values: SponsorshipFormValues,
    actions: FormikHelpers<SponsorshipFormValues>,
  ) {
    sendMutationAndUpdateForm<
      SponsorshipFormValues,
      AdminClubSponsorshipCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubSponsorshipCreateOrUpdate',
      main: () =>
        createOrUpdateMutation({
          variables: {
            input: {
              clubId,
              id: sponsorship?.id,
              ..._.omit(values, 'company'),
              companyId: values.company?.value,
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  return (
    <div className="sponsorship-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, values, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormAsyncGqlSelectField
              name="company"
              components={{
                Option,
                SingleValue,
              }}
              label={i18n.t('search.label', { scope: i18nScope })}
              isClearable={true}
              placeholder={i18n.t('search.placeholder', { scope: i18nScope })}
              gql={ADMIN_CLUB_SPONSORSHIP_SEARCH_COMPANIES}
              getVariables={(inputValue) => ({
                search: inputValue,
              })}
              transformResponseToOptions={(data) => {
                const companiesConnection = data?.companies;
                const companies = arrayFilterNulls(
                  (companiesConnection?.edges ?? []).map((e) => e?.node),
                );
                return companies.map(convertCompanyToCompanyOption);
              }}
              noOptionsMessage={() => (
                <>
                  {i18n.t('search.no_suggestions.text', { scope: i18nScope })}
                  <ContactSupport
                    classes="mt-1"
                    type={SupportType.ORGANIZERS}
                    subject={i18n.t('search.no_suggestions.email_subject', {
                      scope: i18nScope,
                    })}
                  />
                </>
              )}
            />

            <FieldArray
              name="deals"
              render={(arrayHelpers) => (
                <div className="deals-field-array">
                  {values.deals.length > 0 && <h5 className="mt-4">Deals</h5>}
                  {values.deals.map((deal, index) => (
                    <div
                      key={index}
                      className={classNames(index > 0 && 'mt-4')}
                    >
                      <div className="field-array-actions">
                        <Icon
                          type={iconType.DELETE}
                          classes="field-array-action"
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </div>
                      <FormAutoExpandField
                        rows="1"
                        name={`deals.${index}.description`}
                        label={i18n.t('deals.description.label', {
                          scope: i18nScope,
                        })}
                      />
                      <FormAutoExpandField
                        rows="1"
                        name={`deals.${index}.redeemInstructions`}
                        label={i18n.t('deals.redeem_instructions.label', {
                          scope: i18nScope,
                        })}
                      />
                      {index < values.deals.length - 1 && <hr className="hr" />}
                    </div>
                  ))}
                  <div className="mt-4">
                    <a onClick={() => arrayHelpers.push(buildNewDeal())}>
                      {i18n.t('add_a_deal', {
                        scope: i18nScope,
                      })}
                    </a>
                  </div>
                </div>
              )}
            />
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
