import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import AdminTable, {
  UpdatePositionConfig,
} from 'components/utils/admin/admin_table';
import { sendMutation } from 'helpers/gql_form_helpers';
import { GetAdminClubSubscriptionPlansQuery } from '__generated__/graphql';

const i18nScope = 'clubs.admin.subscription_plans.index';
const tableScope = `${i18nScope}.table`;

type Club = NonNullable<GetAdminClubSubscriptionPlansQuery['club']>;
type SubscriptionPlan = Club['subscriptionPlans'][number];

const GET_ADMIN_CLUB_SUBSCRIPTION_PLANS = gql(`
  query GetAdminClubSubscriptionPlans($clubId: ID!) {
    club(id: $clubId) {
      id
      currency
      subscriptionPlans {
        id
        name
        position
        priceInCents
        available
      }
    }
  }
`);

const ADMIN_CLUB_SUBSCRIPTION_PLAN_SWAP_POSITION = gql(`
  mutation AdminClubSubscriptionPlanSwapPosition($input: ClubSubscriptionPlanSwapPositionInput!) {
    clubSubscriptionPlanSwapPosition(input: $input) {
      subscriptionPlan1 {
        id
        position
      }
      subscriptionPlan2 {
        id
        position
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function SubscriptionPlansPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [swapPositionMutation] = useMutation(
    ADMIN_CLUB_SUBSCRIPTION_PLAN_SWAP_POSITION,
  );

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_SUBSCRIPTION_PLANS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading)
    return <AdminDashboardLoadingPlaceholder columnWidths={[60, 15, 15, 10]} />;
  if (error) return <ErrorPage />;

  const club = data?.club;
  assertNotNullOrUndefined(club);
  const subscriptionPlans = club.subscriptionPlans;
  assertNotNullOrUndefined(subscriptionPlans);
  const sortedSubscriptionPlans = _.sortBy(
    subscriptionPlans,
    (subscriptionPlan) => subscriptionPlan.position,
  );

  const updatePositionConfig: UpdatePositionConfig<SubscriptionPlan> = {
    getPosition: (subscriptionPlan) => subscriptionPlan.position ?? Infinity,
    updatePositionCallback: (target, adjacent) => {
      sendMutation({
        mutationName: 'clubSubscriptionPlanSwapPosition',
        main: () =>
          swapPositionMutation({
            variables: {
              input: {
                subscriptionPlan1Id: target.id,
                subscriptionPlan2Id: adjacent.id,
              },
            },
            // optimistic update for speedy UI
            optimisticResponse: {
              clubSubscriptionPlanSwapPosition: {
                subscriptionPlan1: {
                  id: target.id,
                  __typename: 'ClubSubscriptionPlan',
                  position: adjacent.position,
                },
                subscriptionPlan2: {
                  id: adjacent.id,
                  __typename: 'ClubSubscriptionPlan',
                  position: target.position,
                },
                errors: [],
              },
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <div id="clubs-admin-subscription-plans-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_plan_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
        contentClasses="elevate-content min-height-page"
      >
        <AdminTable
          items={sortedSubscriptionPlans}
          emptyTableElement={
            <p>{i18n.t('empty_dashboard', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.name', { scope: tableScope })}</th>
              <th>{i18n.t('heading.price_in_cents', { scope: tableScope })}</th>
              <th>{i18n.t('heading.available', { scope: tableScope })}</th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(subscriptionPlan) => (
            <>
              <td>{subscriptionPlan.name}</td>
              <td>
                {i18n.c({ amountInCents: subscriptionPlan.priceInCents })}
              </td>
              <td>
                {subscriptionPlan.available
                  ? i18n.t('fields.available.true', { scope: tableScope })
                  : i18n.t('fields.available.false', { scope: tableScope })}
              </td>
            </>
          )}
          onClickEditCallback={(subscriptionPlan) =>
            navigate(`${subscriptionPlan.id}/edit`)
          }
          updatePositionConfig={updatePositionConfig}
        />
      </AdminDashboard>
    </div>
  );
}
