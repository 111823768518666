import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import _str from 'underscore.string';
import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAdminClubCustomPagesQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminTable, {
  DestroyConfig,
  UpdatePositionConfig,
} from 'components/utils/admin/admin_table';
import AdminDashboard, {
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import { sendMutation } from 'helpers/gql_form_helpers';
import CreateCustomPageButton from 'components/clubs/admin/pages/create_custom_page_button';

const i18nScope = 'clubs.admin.pages.index';
const tableScope = `${i18nScope}.table`;

type Club = NonNullable<GetAdminClubCustomPagesQuery['club']>;
type ClubCustomPage = Club['customPages'][number];

const GET_ADMIN_CLUB_CUSTOM_PAGES = gql(`
  query GetAdminClubCustomPages($clubId: ID!) {
    club(id: $clubId) {
      id
      customPages {
        id
        title
        isPublished
        position
      }
    }
  }
`);

const ADMIN_CLUB_CUSTOM_PAGE_DESTROY = gql(`
  mutation AdminClubCustomPageDestroy($input: ClubCustomPageDestroyInput!) {
    clubCustomPageDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

const ADMIN_CLUB_CUSTOM_PAGE_SWAP_POSITION = gql(`
  mutation AdminClubCustomPageSwapPosition($input: ClubCustomPageSwapPositionInput!) {
    clubCustomPageSwapPosition(input: $input) {
      customPage1 {
        id
        position
      }
      customPage2 {
        id
        position
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

function _getCustomPageTitle({ customPage }: { customPage: ClubCustomPage }) {
  const { i18n } = React.useContext(I18nContext);
  return _str.isBlank(customPage.title ?? '')
    ? i18n.t('blank_page_title', { scope: i18nScope })
    : customPage.title;
}

export default function CustomPagesPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [destroyMutation] = useMutation(ADMIN_CLUB_CUSTOM_PAGE_DESTROY);
  const [swapPositionMutation] = useMutation(
    ADMIN_CLUB_CUSTOM_PAGE_SWAP_POSITION,
  );

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_CUSTOM_PAGES, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading || data === null)
    return <AdminDashboardLoadingPlaceholder columnWidths={[40, 40, 20]} />;
  if (error) return <ErrorPage />;

  const club = data?.club;
  assertNotNullOrUndefined(club);
  const customPages = club.customPages;
  assertNotNullOrUndefined(customPages);
  const sortedCustomPages = _.sortBy(
    customPages,
    (customPage) => customPage.position,
  );

  const updatePositionConfig: UpdatePositionConfig<ClubCustomPage> = {
    getPosition: (customPage) => customPage.position ?? Infinity,
    updatePositionCallback: (target, adjacent) => {
      sendMutation({
        mutationName: 'clubCustomPageSwapPosition',
        main: () =>
          swapPositionMutation({
            variables: {
              input: {
                customPage1Id: target.id,
                customPage2Id: adjacent.id,
              },
            },
            // optimistic update for speedy UI
            optimisticResponse: {
              clubCustomPageSwapPosition: {
                customPage1: {
                  id: target.id,
                  __typename: 'ClubCustomPage',
                  position: adjacent.position,
                },
                customPage2: {
                  id: adjacent.id,
                  __typename: 'ClubCustomPage',
                  position: target.position,
                },
                errors: [],
              },
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  const destroyConfig: DestroyConfig<ClubCustomPage> = {
    getConfirmText: (customPage) =>
      i18n.t('actions.delete_confirmation', {
        scope: tableScope,
        values: {
          title: _getCustomPageTitle({ customPage }),
        },
      }),
    destroyCallback: (customPage) => {
      // optimistic remove, and then we add it back in if it fails
      sendMutation({
        mutationName: 'clubCustomPageDestroy',
        main: () =>
          destroyMutation({
            variables: { input: { id: customPage.id } },
            // // TODO: the update function runs but the component
            // // does not re-render so there is no optimistic update
            // optimisticResponse: {
            //   clubCustomPageDestroy: {
            //     deletedId: customPage.id,
            //     errors: [],
            //   },
            // },
            update: (cache, result, options) => {
              const deletedId = options?.variables?.input?.id;
              assertNotNullOrUndefined(deletedId);

              const idToRemove = cache.identify({
                __typename: 'ClubCustomPage',
                id: deletedId,
              });

              cache.evict({ id: idToRemove });
              cache.gc();
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <div id="clubs-admin-custom-pages-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <CreateCustomPageButton
            club={club}
            buttonText={i18n.t('add_button', {
              scope: i18nScope,
            })}
          />
        }
        contentClasses="elevate-content min-height-page"
      >
        <AdminTable
          items={sortedCustomPages}
          emptyTableElement={
            <p>{i18n.t('empty_dashboard', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.title', { scope: tableScope })}</th>
              <th>{i18n.t('heading.is_published', { scope: tableScope })}</th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(customPage) => (
            <>
              <td>{_getCustomPageTitle({ customPage })}</td>
              <td>
                {customPage.isPublished
                  ? i18n.t('fields.is_published.true', { scope: tableScope })
                  : i18n.t('fields.is_published.false', { scope: tableScope })}
              </td>
            </>
          )}
          onClickEditCallback={(customPage) =>
            navigate(`${customPage.id}/edit`)
          }
          destroyConfig={destroyConfig}
          updatePositionConfig={updatePositionConfig}
        />
      </AdminDashboard>
    </div>
  );
}
