import React from 'react';
import classNames from 'classnames';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';
import { AdminTableLoadingPlaceholder } from 'components/utils/admin/admin_table';

export function AdminDashboardLoadingPlaceholder({
  children,
  columnWidths,
  contentClasses = 'elevate-content min-height-page',
}: {
  children?: React.ReactElement;
  columnWidths?: number[];
  contentClasses?: string;
}) {
  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-header">
        <div className="title">
          <LoadingPlaceholder
            type={placeholderType.BAR}
            widthPercent="50%"
            classes="p-2"
          />
        </div>
      </div>

      <div className={classNames('admin-dash-content', contentClasses)}>
        {children ?? (
          <AdminTableLoadingPlaceholder columnWidths={columnWidths} />
        )}
      </div>
    </div>
  );
}

export function AdminDashboardAddButton({
  buttonText,
  onClickCallback,
  classes,
}: {
  buttonText: string;
  onClickCallback: () => void;
  classes?: string;
}) {
  return (
    <button
      type="button"
      className={classNames('btn btn-small btn-primary', classes)}
      onClick={onClickCallback}
    >
      <i className="fa fa-plus" />
      &nbsp; &nbsp;
      {buttonText}
    </button>
  );
}

export default function AdminDashboard({
  children,
  title,
  titleActions,
  actions,
  actionsClasses,
  contentClasses,
}: {
  children: React.ReactElement | React.ReactElement[];
  title: string | React.ReactElement;
  titleActions?: React.ReactElement;
  actions?: React.ReactElement;
  actionsClasses?: string;
  contentClasses?: string;
}) {
  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-header">
        <div className="title ">
          <h1 className="capitalize mb-0 inline-block">{title}</h1>
          {titleActions}
        </div>
        {actions && (
          <div
            className={classNames(
              'actions hide-on-tablet-or-smaller',
              actionsClasses,
            )}
          >
            <div className="actions-inner">{actions}</div>
          </div>
        )}
      </div>
      <div className={classNames('admin-dash-content', contentClasses)}>
        {children}
      </div>
    </div>
  );
}
