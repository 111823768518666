import _ from 'underscore';
import React, { useMemo } from 'react';
import { Club } from '__generated__/graphql';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormSwitchField,
  FormFieldWrapper,
  FormServerErrorMessages,
  FormAutocompleteLocationField,
  FormUrlField,
} from 'components/forms/forms';
import I18nContext from 'contexts/i18n_context';
import ClubLogoUploader from 'components/utils/images/club_logo_uploader';
import Helpers from 'helpers/helpers';
import { OptionsForSelectField, UnparsedClub } from 'types/types';
import { AxiosResponse } from 'axios';
import { Wrapper } from '@googlemaps/react-wrapper';
import { getAddressCity, getAddressState } from 'helpers/google_maps_helper';

const i18nScope = 'components.super_admin.dashboard.clubs.form';

const USA_LATITUDE = 37.09024;
const USA_LONGITUDE = -95.712891;

export type ClubFormValues = Pick<
  Club,
  | 'name'
  | 'website'
  | 'slugName'
  | 'city'
  | 'state'
  | 'countryAlpha3'
  | 'longitude'
  | 'latitude'
  | 'timeZone'
  | 'isListed'
  | 'clubTypeId'
>;
export type ClubFormValuesWithLogo = ClubFormValues & Pick<Club, 'logoUrl'>;

export function buildNewClub(): ClubFormValuesWithLogo {
  return {
    logoUrl: '',
    name: '',
    website: '',
    slugName: '',
    city: '',
    state: '',
    countryAlpha3: 'USA',
    longitude: null,
    latitude: null,
    timeZone: 'America/Los_Angeles',
    isListed: false,
    clubTypeId: '',
  };
}

export default function SuperAdminClubForm({
  googleMapsApiPublicKey,
  clubTypesForSelectField,
  countriesForSelectField,
  timeZonesForSelectField,
  isEditing,
  club,
  onSuccessCallback,
  onCancelCallback,
}: {
  googleMapsApiPublicKey: string;
  clubTypesForSelectField: OptionsForSelectField;
  countriesForSelectField: OptionsForSelectField;
  timeZonesForSelectField: OptionsForSelectField;
  isEditing: boolean;
  club: ClubFormValuesWithLogo & { id?: Club['id'] };
  onSuccessCallback: (unparsedClub: UnparsedClub) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const initialValues = useMemo(
    () =>
      _.pick(
        club,
        'name',
        'website',
        'slugName',
        'city',
        'state',
        'countryAlpha3',
        'longitude',
        'latitude',
        'timeZone',
        'isListed',
        'clubTypeId',
      ),
    [club],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required().min(4),
        website: Yup.string().url().required(),
        slugName: Yup.string().required().min(4),
        city: Yup.string().required().min(2),
        state: Yup.string().required().min(2),
        countryAlpha3: Yup.string().required().min(3).max(3),
        timeZone: Yup.string().required(),
        clubTypeId: Yup.string().required(),
      }),
    [],
  );

  const _onSubmit = function (
    values: ClubFormValues,
    actions: FormikHelpers<ClubFormValues>,
  ) {
    Helpers.Form.save({
      actions,
      main: () => {
        const options = { shouldUseFormData: true };
        const params = { club: values };

        const url = isEditing
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Helpers.Routes.getSuperAdminClubPath(Number(club.id)!)
          : Helpers.Routes.getSuperAdminClubsPath();

        return isEditing
          ? Helpers.API.put({ url, params, options })
          : Helpers.API.post({ url, params, options });
      },
      successCallback: (response) => {
        onSuccessCallback((response as AxiosResponse<UnparsedClub>).data);
      },
    });
  };

  return (
    <div className="club-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, values, setFieldValue, setFieldTouched, isSubmitting }) => (
          <Form className="basic-form" noValidate autoComplete="off">
            <div className="flex" style={{ gap: 30 }}>
              <div className="flex-1">
                <FormFieldWrapper name="logo">
                  <ClubLogoUploader
                    name="logo"
                    club={club}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </FormFieldWrapper>
                <FormField
                  autoFocus={true}
                  type="text"
                  name="name"
                  label={i18n.t('fields.name.label', { scope: i18nScope })}
                />
                <FormUrlField
                  name="website"
                  label={i18n.t('fields.website.label', { scope: i18nScope })}
                />
                <FormField
                  type="text"
                  name="slugName"
                  label={i18n.t('fields.slug.label', { scope: i18nScope })}
                />
                <FormField
                  as="select"
                  name="clubTypeId"
                  label={i18n.t('fields.club_type.label', { scope: i18nScope })}
                >
                  {clubTypesForSelectField.map((clubType, index) => (
                    <option key={index} value={clubType.value}>
                      {clubType.name}
                    </option>
                  ))}
                </FormField>
                <FormSwitchField
                  name="isListed"
                  label={i18n.t('fields.is_listed.label', { scope: i18nScope })}
                />
              </div>
              <div className="flex-1">
                <FormField
                  as="select"
                  name="countryAlpha3"
                  label={i18n.t('fields.country.label', { scope: i18nScope })}
                >
                  {countriesForSelectField.map((country, index) => (
                    <option key={index} value={country.value}>
                      {country.name}
                    </option>
                  ))}
                </FormField>

                <Wrapper
                  apiKey={googleMapsApiPublicKey}
                  libraries={['places']}
                  render={() => <></>}
                >
                  <FormAutocompleteLocationField
                    googleMapsApiPublicKey={googleMapsApiPublicKey}
                    name="city"
                    label={i18n.t('fields.city.label', { scope: i18nScope })}
                    defaultLatitude={USA_LATITUDE}
                    defaultLongitude={USA_LONGITUDE}
                    latitude={values.latitude ?? null}
                    longitude={values.longitude ?? null}
                    broadZoomLevel={3}
                    detailedZoomLevel={10}
                    onSelectLocationCallback={(
                      place: google.maps.places.PlaceResult | null,
                    ) => {
                      const location = place?.geometry?.location;
                      if (
                        place == null ||
                        location == null ||
                        typeof location === 'undefined'
                      ) {
                        setFieldValue('longitude', null);
                        setFieldValue('latitude', null);
                        setFieldValue('state', '');
                        setFieldValue('city', '');
                        return;
                      }

                      setFieldValue('longitude', location.lng());
                      setFieldValue('latitude', location.lat());
                      const addressState = getAddressState(
                        place.address_components ?? [],
                      );
                      setFieldValue('state', addressState?.short_name);
                      const addressCity = getAddressCity(
                        place.address_components ?? [],
                      );
                      setFieldValue('city', addressCity?.long_name);
                    }}
                  >
                    <div className="italic">
                      {values.city}, {values.state}
                    </div>
                  </FormAutocompleteLocationField>
                </Wrapper>

                <FormField
                  as="select"
                  name="timeZone"
                  label={i18n.t('fields.time_zone.label', { scope: i18nScope })}
                >
                  {timeZonesForSelectField.map((timeZone, index) => (
                    <option key={index} value={timeZone.value}>
                      {timeZone.name}
                    </option>
                  ))}
                </FormField>
              </div>
            </div>
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
