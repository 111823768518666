import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

Modal.setAppElement('body');

export default function BasicModal({
  style,
  children,
  onRequestCloseCallback,
  isOpen,
  shouldCloseOnOverlay = false,
  shouldCloseOnEsc = true,
  shouldShowCloseButton = true,
  title,
  className,
}: {
  style?: Modal.Styles;
  children: React.ReactElement | React.ReactElement[];
  isOpen: boolean;
  onRequestCloseCallback: () => void;
  title?: string;
  className?: string;
  shouldCloseOnOverlay?: boolean;
  shouldCloseOnEsc?: boolean;
  shouldShowCloseButton?: boolean;
}) {
  return (
    <Modal
      overlayClassName="basic-modal-overlay"
      className={classNames('basic-modal-content basic-form', className)}
      isOpen={isOpen}
      onRequestClose={onRequestCloseCallback}
      closeTimeoutMS={300}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlay}
      contentLabel={title}
      style={style}
    >
      {shouldShowCloseButton && (
        <i
          className="close-modal-btn fas fa-times"
          onClick={onRequestCloseCallback}
        ></i>
      )}
      {children}
    </Modal>
  );
}
