import React from 'react';
import { Field, useField } from 'formik';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';

type FormAutoExpandFieldProps = Omit<FormFieldwrapperProps, 'children'> & {
  children?: React.ReactNode;
  // otherProps
  autoFocus?: boolean;
  rows?: string;
  placeholder?: string;
};

export default function FormAutoExpandField({
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
  // AutoExpand specific fields
  ...otherProps // eg. rows, autoFocus, placeholder
}: FormAutoExpandFieldProps) {
  const [field, ,] = useField(name);
  const { value } = field;

  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      label={label}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-field form-auto-expand-field">
        <div className="expand-wrapper" data-replicated-value={value}>
          <Field name={name} component="textarea" {...otherProps} />
        </div>
      </div>
    </FormFieldWrapper>
  );
}
