import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import classNames from 'classnames';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormSwitchField,
  FormServerErrorMessages,
} from 'components/forms/forms';

import I18nContext from 'contexts/i18n_context';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';

import {
  AdminClubPermissionGroupCreateOrUpdateMutation,
  Club,
  ClubPermissionGroup,
} from '__generated__/graphql';
import { inGroupsOf } from 'helpers/utils_helper';

const i18nScope = 'clubs.admin.permission_groups.form';

type IsPermissionCategoryEnabledByName = Record<string, boolean>;

type PermissionGroupFormValues = Pick<ClubPermissionGroup, 'name'> & {
  isPermissionCategoryEnabledByName: IsPermissionCategoryEnabledByName;
};

type PermissionGroupForForm = Pick<ClubPermissionGroup, 'name'> & {
  permissionCategoryNames: string[];
};

// NOTE: this is a weird structure. for permission groups with full admin permissions
// we "enable" all other permission types on the front end so that the UX shows the
// switches are enabled. And then when we go to save we only want to send back the
// full admin permission category and not all children ones. It's cleaner this way
// and less issues if we add a new permission category.

// TODO this is not ideal, remove duplication with permission_category.rb
enum PermissionCategoryNames {
  ADMIN = 'admin',
  ADMIN_EMAILS = 'admin_emails',
  ADMIN_EVENTS = 'admin_events',
  ADMIN_MEMBERSHIPS = 'admin_memberships',
  ADMIN_PAGES = 'admin_pages',
  ADMIN_PERMISSIONS = 'admin_permissions',
  ADMIN_PROFILE = 'admin_profile',
  ADMIN_SPONSORS = 'admin_sponsors',
  ADMIN_STOREFRONT = 'admin_storefront',
  ADMIN_SUBSCRIPTION_PLANS = 'admin_subscription_plans',
  ADMIN_WAIVERS = 'admin_waivers',
}

const ADMIN_CLUB_PERMISSION_GROUP_CREATE_OR_UPDATE = gql(`
  mutation AdminClubPermissionGroupCreateOrUpdate($input: ClubPermissionGroupCreateOrUpdateInput!) {
    clubPermissionGroupCreateOrUpdate(input: $input) {
      permissionGroup {
        id
        name
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export function buildNewPermissionGroup(): PermissionGroupForForm {
  return {
    name: '',
    permissionCategoryNames: [],
  };
}

export default function PermissionGroupForm({
  isEditing,
  clubId,
  permissionGroup,
  onSuccessCallback,
  onCancelCallback,
}: {
  clubId: Club['id'];
  isEditing: boolean;
  // id is optional because it is only provided when editing, not adding
  permissionGroup: PermissionGroupForForm & {
    id?: ClubPermissionGroup['id'];
  };
  onSuccessCallback: (permissionGroupId: ClubPermissionGroup['id']) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [createOrUpdateMutation] = useMutation(
    ADMIN_CLUB_PERMISSION_GROUP_CREATE_OR_UPDATE,
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(i18n.t('forms.errors.required'))
      .min(2, i18n.t('forms.errors.min_length')),
  });

  const _onSubmit = function (
    values: PermissionGroupFormValues,
    actions: FormikHelpers<PermissionGroupFormValues>,
  ) {
    let permissionCategoryNames: PermissionCategoryNames[];
    if (
      values.isPermissionCategoryEnabledByName[PermissionCategoryNames.ADMIN]
    ) {
      // strip out all other admin permissions if the user is a full admin
      permissionCategoryNames = [PermissionCategoryNames.ADMIN];
    } else {
      permissionCategoryNames = _.compact(
        _.map(
          values.isPermissionCategoryEnabledByName,
          (isEnabled, permissionCategoryName) =>
            isEnabled ? permissionCategoryName : null,
        ),
      ) as PermissionCategoryNames[];
    }

    sendMutationAndUpdateForm<
      PermissionGroupFormValues,
      AdminClubPermissionGroupCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubPermissionGroupCreateOrUpdate',
      main: () =>
        createOrUpdateMutation({
          variables: {
            input: {
              clubId,
              id: permissionGroup?.id,
              ..._.omit(values, 'isPermissionCategoryEnabledByName'),
              permissionCategoryNames,
            },
          },
        }),
      successCallback: (mutationPayload) => {
        const savedPermissionGroup =
          mutationPayload?.clubPermissionGroupCreateOrUpdate?.permissionGroup;
        assertNotNullOrUndefined(savedPermissionGroup);
        onSuccessCallback(savedPermissionGroup.id);
      },
    });
  };

  const enabledPermissionCategoryNames =
    permissionGroup.permissionCategoryNames ?? [];
  const isPermissionCategoryEnabledByName =
    {} as IsPermissionCategoryEnabledByName;
  Object.values(PermissionCategoryNames).forEach((permissionCategoryName) => {
    // if they're a super admin then set all admins to true
    isPermissionCategoryEnabledByName[permissionCategoryName] =
      enabledPermissionCategoryNames.includes(permissionCategoryName) ||
      enabledPermissionCategoryNames.includes(PermissionCategoryNames.ADMIN);
  });

  const initialValues: PermissionGroupFormValues = {
    name: permissionGroup.name,
    isPermissionCategoryEnabledByName,
  };

  const permissionCategoryNames = useMemo(
    () => [
      // first column
      PermissionCategoryNames.ADMIN_MEMBERSHIPS,
      PermissionCategoryNames.ADMIN_EMAILS,
      PermissionCategoryNames.ADMIN_PROFILE,
      PermissionCategoryNames.ADMIN_PERMISSIONS,
      PermissionCategoryNames.ADMIN_STOREFRONT,
      // second column
      PermissionCategoryNames.ADMIN_EVENTS,
      PermissionCategoryNames.ADMIN_SPONSORS,
      PermissionCategoryNames.ADMIN_SUBSCRIPTION_PLANS,
      PermissionCategoryNames.ADMIN_WAIVERS,
      PermissionCategoryNames.ADMIN_PAGES,
    ],
    [],
  );

  return (
    <div className="permission-group-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, values, setFieldValue, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              autoFocus={true}
              type="text"
              name="name"
              label={i18n.t('name.label', { scope: i18nScope })}
              description={i18n.t('name.description', { scope: i18nScope })}
            />

            <FormSwitchField
              name="isPermissionCategoryEnabledByName.admin"
              style={{ maxWidth: 200 }}
              label={i18n.t('admin.label', { scope: i18nScope })}
              description={i18n.t('admin.description', { scope: i18nScope })}
              onChangeCallback={(value) => {
                if (value) {
                  Object.values(PermissionCategoryNames).forEach(
                    (permissionCategoryName) => {
                      setFieldValue(
                        `isPermissionCategoryEnabledByName.${permissionCategoryName}`,
                        true,
                      );
                    },
                  );
                }
              }}
            />
            <div
              className={classNames('pl-3 mt-2 granular-permissions flex', {
                disabled: values.isPermissionCategoryEnabledByName.admin,
              })}
            >
              {inGroupsOf({
                array: permissionCategoryNames,
                groupSize: permissionCategoryNames.length / 2,
              }).map((permissionCategoryNameGroup, groupIndex) => (
                <div key={groupIndex} className="flex-1">
                  {permissionCategoryNameGroup.map(
                    (permissionCategoryName, index) => (
                      <FormSwitchField
                        key={index}
                        classes="permission-field"
                        disabled={
                          values.isPermissionCategoryEnabledByName.admin
                        }
                        name={`isPermissionCategoryEnabledByName.${permissionCategoryName}`}
                        label={i18n.t(`${permissionCategoryName}.label`, {
                          scope: i18nScope,
                        })}
                        description={i18n.t(
                          `${permissionCategoryName}.description`,
                          {
                            scope: i18nScope,
                          },
                        )}
                      />
                    ),
                  )}
                </div>
              ))}
            </div>

            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
