import React from 'react';
import { slide as SlidingMobileMenu } from 'react-burger-menu';
import TippyMenu from 'components/utils/tippy_menu';
import Store from 'helpers/store';
import Icon, { iconType } from 'components/utils/icon';

import DarkModeSwitch from 'components/navbar/dark_mode_switch';
import { Club, DeprecatedAny, User } from 'types/types';
import h from 'h';

interface SingleMenuItem {
  path: string;
  text: string;
  pageName: string;
}

// NOTE: if you change you must also update the backend
// app/services/navbar_service.rb
enum GroupMenuIds {
  MY_ACCOUNT = 'my_account',
}

interface GroupMenuItem {
  type: 'group';
  id: GroupMenuIds;
  items: SingleMenuItem[];
}

type MenuItem = SingleMenuItem | GroupMenuItem;
type MenuItems = MenuItem[];

function getIsGroupMenuItem(menuItem: MenuItem): boolean {
  return 'type' in menuItem && menuItem.type === 'group';
}

function DesktopGroupMenuItem({
  menuItem,
  currentUser,
}: {
  menuItem: GroupMenuItem;
  currentUser: null | Pick<User, 'profilePicUrl'>;
}) {
  switch (menuItem.id) {
    case GroupMenuIds.MY_ACCOUNT: {
      const tippyMenuItems = menuItem.items.map((singleMenuItem) => (
        <div className="tippy-menu-item" key={singleMenuItem.pageName}>
          <a href={singleMenuItem.path}>{singleMenuItem.text}</a>
        </div>
      ));
      const dropdownContent = <>{tippyMenuItems}</>;

      if (currentUser === null) {
        return h.throwError('currentUser is unexpectedly null');
      }

      return (
        <TippyMenu
          trigger="mouseenter focus"
          hideOnClick={false}
          content={dropdownContent}
        >
          <span className="profile-pic">
            <img className="round-image" src={currentUser.profilePicUrl} />
          </span>
        </TippyMenu>
      );
    }
    default:
      return h.throwExhaustiveError('unknown group menu type', menuItem.id);
  }
}

function DesktopSingleMenuItem({ menuItem }: { menuItem: SingleMenuItem }) {
  return (
    <span className="menu-item menu-item-link" key={menuItem.pageName}>
      <a href={menuItem.path}>{menuItem.text}</a>
    </span>
  );
}

interface DesktopMenu {
  menuItems: MenuItems;
  currentUser: User | null;
  darkModeSwitch: React.ReactElement;
}

function DesktopMenu({ menuItems, currentUser, darkModeSwitch }: DesktopMenu) {
  return (
    <div className="desktop-menu">
      {darkModeSwitch}
      {menuItems.map((menuItem, index) => (
        <React.Fragment key={index}>
          {getIsGroupMenuItem(menuItem) ? (
            <DesktopGroupMenuItem
              menuItem={menuItem as GroupMenuItem}
              currentUser={currentUser}
            />
          ) : (
            <DesktopSingleMenuItem menuItem={menuItem as SingleMenuItem} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

interface MobileMenuProps {
  menuItems: MenuItems;
  rootPath: string;
  websiteName: string;
  darkModeSwitch: React.ReactElement;
}

function MobileMenu({
  menuItems,
  rootPath,
  websiteName,
  darkModeSwitch,
}: MobileMenuProps) {
  const pageMenuItems: SingleMenuItem[] = menuItems
    .map((menuItem) =>
      getIsGroupMenuItem(menuItem)
        ? (menuItem as GroupMenuItem).items
        : (menuItem as SingleMenuItem),
    )
    .flat();

  return (
    <div className="mobile-menu">
      <SlidingMobileMenu
        noOverlay
        width={'100%'}
        customBurgerIcon={<Icon type={iconType.BURGER_MENU} />}
        customCrossIcon={<Icon type={iconType.CLOSE} />}
        right
      >
        <div className="logo center">
          <a href={rootPath}>{websiteName}</a>
        </div>
        <div className="menu-items">
          {pageMenuItems.map((pageMenuItem, index) => (
            <div className="menu-item" key={index}>
              <a id={pageMenuItem.pageName} href={pageMenuItem.path}>
                {pageMenuItem.text}
              </a>
            </div>
          ))}
          {darkModeSwitch}
        </div>
      </SlidingMobileMenu>
    </div>
  );
}

interface NavbarClubLogoProps {
  club: null | Pick<Club, 'logoUrl'>;
}

function NavbarClubLogoIfExists({ club }: NavbarClubLogoProps) {
  if (club === null) {
    return null;
  }

  return (
    <span className="club-logo">
      <img className="round-image" src={club.logoUrl} />
    </span>
  );
}

export default function Navbar(props: DeprecatedAny) {
  const store = Store.parseInitData(props);

  const currentUserIfExists = store.getCurrentUserIfExists();
  const clubIfExists = store.getCurrentClubIfExists();

  const rootPath = store.getOrThrow<string>('rootPath');
  const websiteName = store.getOrThrow<string>('websiteName');
  const menuItems = store.getOrThrow<MenuItems>('menuItems');
  const isDarkMode = store.getOrThrow<boolean>('isDarkMode');

  const darkModeSwitch = currentUserIfExists ? (
    <div className="menu-item menu-item-link">
      <DarkModeSwitch initialValue={isDarkMode} />
    </div>
  ) : (
    <span />
  );

  return (
    <div id="navbar-wrapper">
      <div id="navbar">
        <div className="center-content">
          <div id="navbar-inner">
            <div className="left-menu-items">
              <NavbarClubLogoIfExists club={clubIfExists} />
              <span className="logo">
                <a href={rootPath}>{websiteName}</a>
              </span>
            </div>
            <div className="right-menu-items">
              <DesktopMenu
                menuItems={menuItems}
                currentUser={currentUserIfExists}
                darkModeSwitch={darkModeSwitch}
              />

              <MobileMenu
                menuItems={menuItems}
                rootPath={rootPath}
                websiteName={websiteName}
                darkModeSwitch={darkModeSwitch}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
