import { sortBy } from 'underscore';
import { DeprecatedAny, OptionsForSelectField } from 'types/types';

export function isNullOrUndefined<T>(val: T | null | undefined): boolean {
  return val === null || typeof val === 'undefined';
}

const throwExhaustiveError = (message: string, val: never): never => {
  throw `${message}: '${val}'`;
};

const throwError = (message: string): never => {
  throw message;
};

export function arrayFilterNulls<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((item): item is T => !isNullOrUndefined(item));
}

export function optionsForSelectField<T>({
  array,
  value,
  name,
  shouldSort = true,
}: {
  array: T[];
  value: keyof T;
  name: keyof T;
  shouldSort?: boolean;
}): OptionsForSelectField {
  const sortedArrayIfNeeded = shouldSort
    ? sortBy(array, (item) => item[name])
    : array;
  return sortedArrayIfNeeded.map((item) => ({
    value: item[value],
    name: item[name],
  })) as OptionsForSelectField;
}

// doesn't work if include as part of an object below for some
// reason so exporting individually which is actually best practice
export function assertNotNullOrUndefined<T>(
  value: T | null | undefined,
  message?: string,
): asserts value is NonNullable<T> {
  if (value === null || typeof value === 'undefined') {
    throw new Error(message ?? 'value cannot be null or undefined');
  }
}

export function assertIsTrue(
  value: boolean,
  message?: string,
): asserts value is true {
  if (!value) {
    throw new Error(message ?? 'value is false');
  }
}

export function assertIsNumber(
  value: unknown,
  message?: string,
): asserts value is number {
  if (typeof value !== 'number') {
    throw new Error(message ?? 'value is not a number');
  }
}

export default {
  isNullOrUndefined,
  throwExhaustiveError,
  throwError,
  assert: {
    isNotNullOrUndefined: (
      val: DeprecatedAny,
      message = 'should not be null or undefined',
    ): never | void => {
      if (isNullOrUndefined(val)) {
        throw message;
      }
    },
    isTrue: (
      val: DeprecatedAny,
      message = 'expected to be true',
    ): never | void => {
      if (val !== true) {
        throw message;
      }
    },
  },
};
