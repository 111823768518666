import _ from 'underscore';
import React from 'react';
import ProfilePageSection from 'components/clubs/profile/profile_page_section';

import Store from 'helpers/store';

export default function ProfilePage(props) {
  const store = Store.parseInitData(props);
  const profilePage = store.getModel(
    'clubProfilePage',
    store.get('currentClubProfilePageId'),
  );

  const sectionsHtml = _.map(profilePage.sections, (section, index) => {
    return <ProfilePageSection key={index} section={section} store={store} />;
  });

  return (
    <div id="club-profile-page-old">
      <div className="center-content">{sectionsHtml}</div>
    </div>
  );
}
