import { assertNotNullOrUndefined } from 'h';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClubId } from 'types/types';

import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import PillGroup from 'components/utils/pill_group';
import ClubEventsCalendarOrListView, {
  getCanShowCalendar,
} from 'components/utils/events/club_events_calendar_or_list_view';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';

const i18nScope = 'clubs.clubhouse.events';

const GET_CLUB_EVENTS_PAGE = gql(`
  query GetClubEventsPage($clubId: ID!) {
    club(id: $clubId) {
      id
      timeZone
      newClubEventUrl
    }
    currentUser {
      id
      userPreference {
        id
        calendarFirstDay
      }
    }
  }
`);

export default function EventsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const canShowCalendar = getCanShowCalendar();
  const [shouldShowCalendar, setShouldShowCalendar] = useState(canShowCalendar);
  useEffect(
    Helpers.ScreenSize.useEffect(() => {
      const newCanShowCalendar = getCanShowCalendar();
      // if not shown then continue not showing it, but if it
      // is show we must check whether we can still show the
      // calendar
      setShouldShowCalendar(newCanShowCalendar && shouldShowCalendar);
    }),
    [],
  );

  const { loading, error, data } = useQuery(GET_CLUB_EVENTS_PAGE, {
    variables: { clubId: String(currentClubId) },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const club = data?.club;
  assertNotNullOrUndefined(club);
  const userPreference = data?.currentUser?.userPreference;
  assertNotNullOrUndefined(userPreference);

  const timeZone = club.timeZone;
  assertNotNullOrUndefined(timeZone);
  const calendarFirstDay = userPreference.calendarFirstDay;
  assertNotNullOrUndefined(calendarFirstDay);

  return (
    <div id="clubhouse-events-page">
      <div className="page-header">
        <div className="title">
          <h1 className="capitalize inline-block">
            {i18n.t('title', { scope: i18nScope })}
          </h1>
          {canShowCalendar && (
            <PillGroup
              classes="ml-5 inline-block"
              pills={[
                { value: true, element: <i className="far fa-calendar"></i> },
                { value: false, element: <i className="fas fa-list"></i> },
              ]}
              value={shouldShowCalendar}
              onClick={(value) => setShouldShowCalendar(value)}
            />
          )}
        </div>
        <div className="actions">
          <button
            className="btn btn-primary btn-small"
            onClick={() => (window.location.href = club.newClubEventUrl)}
          >
            <i className="fa fa-plus" />
            &nbsp; &nbsp;
            {i18n.t('add_event_button', { scope: i18nScope })}
          </button>
        </div>
      </div>
      <div className="elevate-content">
        <ClubEventsCalendarOrListView
          club={{ timeZone, id: currentClubId }}
          openLinksInNewTab={true}
          shouldShowCalendarIfPossible={shouldShowCalendar}
          calendarFirstDay={calendarFirstDay}
        />
      </div>
    </div>
  );
}
