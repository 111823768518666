import React, { useEffect, useMemo } from 'react';
import { useFormikContext, Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormUrlField,
} from 'components/forms/forms';
import EditorHelper from 'components/forms/form_content_editable_field/editor_helper';
import I18nContext from 'contexts/i18n_context';
import { Editor } from '@tiptap/react';

const i18nScope = 'components.forms.form_content_editable_field.link.form';

interface LinkFormValues {
  url: string;
}

const ListenToEditorSelectionChanges = ({ editor }: { editor: Editor }) => {
  const { setFieldValue, resetForm } = useFormikContext();

  useEffect(() => {
    const onSelectionUpdate = () => {
      const val = EditorHelper.getCurrentUrl({ editor });
      setFieldValue('url', val);
      resetForm();
    };
    editor.on('selectionUpdate', onSelectionUpdate);
    return () => {
      editor.off('selectionUpdate', onSelectionUpdate);
    };
  }, [editor]);

  return null;
};

export default function LinkForm({
  editor,
  onDoneCallback,
}: {
  editor: Editor;
  onDoneCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const initialValues = { url: EditorHelper.getCurrentUrl({ editor }) };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        url: Yup.string()
          .required(i18n.t('forms.errors.required'))
          .url(i18n.t('forms.errors.invalid_url')),
      }),
    [],
  );

  const _onSubmit = (values: LinkFormValues) => {
    EditorHelper.convertToLink({ editor, url: values.url });
    onDoneCallback();
  };

  return (
    <div className="link-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {() => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormUrlField
              autoFocus={true}
              name="url"
              placeholder={i18n.t('fields.url.placeholder', {
                scope: i18nScope,
              })}
            />
            <FormActions className="rtl">
              <FormActionButton
                className="primary"
                text={i18n.t('actions.submit', { scope: i18nScope })}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                handleClick={onDoneCallback}
              />
            </FormActions>
            <ListenToEditorSelectionChanges editor={editor} />
          </Form>
        )}
      </Formik>
    </div>
  );
}
