import { assertIsTrue, assertNotNullOrUndefined } from 'h';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ClubMembershipStateEnum,
  GetMembershipSettingsQuery,
} from '__generated__/graphql';

import Helpers from 'helpers/helpers';

import { AddOnNames } from 'components/add_ons/names';
import { getDoesClubHaveAddOnInstalled } from 'models/club';

import BasicModal from 'components/utils/basic_modal';
import MembershipRenewalResumptionForm from 'components/clubs/clubhouse/settings_page/membership_renewal_resumption_form';
import PaymentMethodSummary from 'components/payments/payment_method_summary';
import EmergencyContactsList from 'components/clubs/clubhouse/settings_page/emergency_contacts_list';
import I18nContext from 'contexts/i18n_context';
import { ClubId } from 'types/types';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';

const i18nScope = 'clubs.clubhouse.settings';

type Club = NonNullable<GetMembershipSettingsQuery['club']>;
type CurrentUser = NonNullable<GetMembershipSettingsQuery['currentUser']>;
type CurrentMembership = NonNullable<CurrentUser['membership']>;
type StripePaymentMethod = CurrentUser['platformStripePaymentMethods'][number];
type EmergencyContact = CurrentUser['emergencyContacts'][number];

const GET_MEMBERSHIP_SETTINGS = gql(`
  query GetMembershipSettings($clubId: ID!) {
    club(id: $clubId) {
      id
      name
      addOnNames
      clubRenewUrl
      clubCancelUrl 
    }
    currentUser {
      id
      emergencyContacts {
        id
        name
        phoneNumber
        relationship
      }
      platformStripePaymentMethods {
        id
        paymentMethodType
        brand
        brandLogoUrl
        last4
        expYear
        expMonth
        isDefault
      }
      membership(clubId: $clubId) {
        id
        state
        startsAt
        expiresAt
        renewsAt
        isRenewalCanceled
        subscriptions {
          expiresAt
          subscriptionPlan {
            priceInCents
            frequency
          }
        }
      }
    }
  }
`);

function MembershipSection({
  club,
  membership,
}: {
  club: Club;
  membership: CurrentMembership;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [
    shouldShowMembershipRenewalResumptionModal,
    setShouldShowMembershipRenewalResumptionModal,
  ] = useState(false);

  const startsAt = Helpers.Utils.formatDate(membership.startsAt, {
    withYear: true,
  });
  const expiresAt = Helpers.Utils.formatDate(membership.expiresAt, {
    withYear: true,
  });

  const _onClickRejoin = () => {
    if (membership.state === ClubMembershipStateEnum.Overdue) {
      // if the membership is overdue then we just send
      // them directly to the renew page because there's probably
      // some issue that needs their attention
      window.location.href = club.clubRenewUrl;
    } else {
      setShouldShowMembershipRenewalResumptionModal(true);
    }
  };

  let content: React.ReactElement;

  if (membership.isRenewalCanceled) {
    content = (
      <>
        {i18n.t('membership.canceled.summary', {
          scope: i18nScope,
          values: {
            startsAt,
            expiresAt,
          },
        })}
        <div className="mt-2">
          <button
            className="btn btn-primary btn-small"
            onClick={_onClickRejoin}
          >
            {i18n.t('membership.canceled.rejoin_button', {
              scope: i18nScope,
            })}
          </button>
        </div>
        {shouldShowMembershipRenewalResumptionModal && (
          <BasicModal
            className="basic-modal-content membership-renewal-resumption-modal"
            isOpen={true}
            onRequestCloseCallback={() =>
              setShouldShowMembershipRenewalResumptionModal(false)
            }
          >
            <MembershipRenewalResumptionForm
              club={club}
              membership={membership}
              subscriptions={membership.subscriptions}
              onCancelCallback={() =>
                setShouldShowMembershipRenewalResumptionModal(false)
              }
              onSuccessCallback={() => {
                // we ideally would just reload the panel but for
                // not we'll reload the page so we can update the copy
                window.location.reload();
              }}
            />
          </BasicModal>
        )}
      </>
    );
  } else {
    content = (
      <>
        {i18n.t('membership.active.summary', {
          scope: i18nScope,
          values: {
            startsAt,
            expiresAt,
          },
        })}{' '}
        <a href={club.clubRenewUrl}>
          {i18n.t('membership.active.renew_button', { scope: i18nScope })}
        </a>{' '}
        or{' '}
        <a href={club.clubCancelUrl}>
          {i18n.t('membership.active.cancel_button', { scope: i18nScope })}
        </a>
        .
      </>
    );
  }

  return (
    <div className="section membership-section">
      <h6 className="section-title">
        {i18n.t('membership.title', { scope: i18nScope })}
      </h6>
      <div className="section-content">{content}</div>
    </div>
  );
}

function StripePaymentMethodsSection({
  stripePaymentMethods,
}: {
  stripePaymentMethods: StripePaymentMethod[];
}) {
  const { i18n } = React.useContext(I18nContext);
  let content: React.ReactElement;

  if (stripePaymentMethods.length === 0) {
    content = <div>{i18n.t('payment_methods.none', { scope: i18nScope })}</div>;
  } else {
    content = (
      <div>
        {stripePaymentMethods.map((stripePaymentMethod, index) => (
          <div className="payment-method" key={index}>
            <PaymentMethodSummary stripePaymentMethod={stripePaymentMethod} />
            <span className="light-text-color small ml-2">
              ({stripePaymentMethod.expMonth}/{stripePaymentMethod.expYear})
            </span>
            {stripePaymentMethods.length > 1 &&
              stripePaymentMethod.isDefault && (
                <span className="light-text-color small ml-2">
                  {i18n.t('payment_methods.list.default', { scope: i18nScope })}
                </span>
              )}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="section payment-methods-section">
      <h6 className="section-title">
        {i18n.t('payment_methods.title', { scope: i18nScope })}
      </h6>
      <div className="section-content">{content}</div>
    </div>
  );
}

function EmergencyContactsSection({
  emergencyContacts,
  setEmergencyContacts,
}: {
  emergencyContacts: EmergencyContact[];
  setEmergencyContacts: (v: EmergencyContact[]) => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  return (
    <div className="section emergency-contacts-section">
      <h6 className="section-title">
        {i18n.t('emergency_contacts.title', { scope: i18nScope })}
      </h6>
      <div className="section-content">
        <EmergencyContactsList
          emergencyContacts={emergencyContacts}
          onSaveCallback={(newEmergencyContact) => {
            const index = emergencyContacts.findIndex(
              (ec) => String(ec.id) === String(newEmergencyContact.id),
            );
            if (index === -1) {
              // new contact, add it to the list
              setEmergencyContacts([...emergencyContacts, newEmergencyContact]);
            } else {
              // edited contact, update the list
              const newEmergencyContacts = [...emergencyContacts];
              newEmergencyContacts.splice(index, 1, newEmergencyContact);
              setEmergencyContacts(newEmergencyContacts);
            }
          }}
          onDestroyCallback={(emergencyContact) => {
            const index = emergencyContacts.findIndex(
              (ec) => String(ec.id) === String(emergencyContact.id),
            );
            assertIsTrue(
              index !== -1,
              'cannot find emergency contact to remove',
            );

            const newEmergencyContacts = [...emergencyContacts];
            const removedContacts = newEmergencyContacts.splice(index, 1);
            setEmergencyContacts(newEmergencyContacts);
            return removedContacts[0];
          }}
          onUndestroyCallback={(emergencyContact) => {
            setEmergencyContacts([...emergencyContacts, emergencyContact]);
          }}
        />
      </div>
    </div>
  );
}

export default function SettingsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [emergencyContacts, setEmergencyContacts] = useState<
    EmergencyContact[]
  >([]);

  const [stripePaymentMethods, setStripePaymentMethods] = useState<
    StripePaymentMethod[]
  >([]);

  const { loading, error, data } = useQuery(GET_MEMBERSHIP_SETTINGS, {
    variables: {
      clubId: String(currentClubId),
    },
    onCompleted: (newData) => {
      assertNotNullOrUndefined(newData?.currentUser);
      setEmergencyContacts(newData.currentUser.emergencyContacts);
      setStripePaymentMethods(newData.currentUser.platformStripePaymentMethods);
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const club = data?.club;
  assertNotNullOrUndefined(club);
  const currentMembership = data?.currentUser?.membership;
  assertNotNullOrUndefined(currentMembership);

  return (
    <div id="clubhouse-settings-page">
      <div className="page-header">
        <div className="title">
          <h1>{i18n.t('title', { scope: i18nScope })}</h1>
        </div>
      </div>
      <div className="elevate-content">
        {currentMembership.state !== ClubMembershipStateEnum.Draft && (
          <>
            <MembershipSection club={club} membership={currentMembership} />
            <hr className="hr" />
          </>
        )}

        <StripePaymentMethodsSection
          stripePaymentMethods={stripePaymentMethods}
        />

        {getDoesClubHaveAddOnInstalled({
          club,
          addOnName: AddOnNames.EMERGENCY_CONTACTS,
        }) && (
          <>
            <hr className="hr" />
            <EmergencyContactsSection
              emergencyContacts={emergencyContacts}
              setEmergencyContacts={(newEmergencyContacts) =>
                setEmergencyContacts(newEmergencyContacts)
              }
            />
          </>
        )}
      </div>
    </div>
  );
}
