import React from 'react';
import classNames from 'classnames';
import { ClubProfile } from 'types/types';

interface ClubProfileHeaderPhoto {
  clubProfile: Pick<ClubProfile, 'headerPhotoUrl'>;
  classes?: string;
  alt?: string;
}

export function ClubProfileHeaderPhoto({
  classes,
  clubProfile,
  alt = 'club header photo',
}: ClubProfileHeaderPhoto) {
  return (
    <Image
      classes={classNames('club-profile-header-photo', classes)}
      imageUrl={clubProfile.headerPhotoUrl}
      alt={alt}
    />
  );
}

// Internal component, we don't expose it directly. We
// should use one of the wrapper components
interface ImageProps {
  imageUrl?: string;
  classes?: string;
  alt?: string;
}

function Image({ classes, imageUrl, alt }: ImageProps) {
  return (
    <div className={classNames('image-wrapper', classes)}>
      <div className="image">
        <img src={imageUrl} alt={alt} />
      </div>
    </div>
  );
}
