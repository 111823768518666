import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ClubId } from 'types/types';

import {
  FourOFourPage,
  PagesGroup,
  PagesLayout,
} from 'components/utils/pages_sidebar';
import DealsPage from 'components/clubs/clubhouse/deals_page';
import EventsPage from 'components/clubs/clubhouse/events_page';
import MembershipsPage from 'components/clubs/clubhouse/memberships_page';
import RacesPage from 'components/clubs/clubhouse/races_page';
import SettingsPage from 'components/clubs/clubhouse/settings_page';
import CustomPagePage from 'components/clubs/clubhouse/page';
import WelcomePage from 'components/clubs/clubhouse/welcome';

enum ClubhousePageId {
  WELCOME = 'welcome',
  DEALS = 'deals',
  EVENTS = 'events',
  MEMBERS = 'members',
  RACES = 'races',
  SETTINGS = 'settings',
  PAGES = 'pages',
}

export default function Clubhouse({
  currentClubId,
  clubhousePath,
  sidebar,
}: {
  currentClubId: ClubId;
  clubhousePath: string;
  sidebar: PagesGroup[];
}) {
  assertNotNullOrUndefined(currentClubId);
  assertNotNullOrUndefined(clubhousePath);
  assertNotNullOrUndefined(sidebar);

  const pageProps = { currentClubId };
  return (
    <RootAppWrapper>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${clubhousePath}/*`}
            element={
              <PagesLayout
                basePath={clubhousePath}
                groups={sidebar}
                getHasPermission={() => true}
              />
            }
          >
            <Route
              index
              path={ClubhousePageId.WELCOME}
              element={<WelcomePage {...pageProps} />}
            />
            <Route
              index
              path={ClubhousePageId.EVENTS}
              element={<EventsPage {...pageProps} />}
            />
            <Route
              path={ClubhousePageId.DEALS}
              element={<DealsPage {...pageProps} />}
            />
            <Route
              path={ClubhousePageId.MEMBERS}
              element={<MembershipsPage {...pageProps} />}
            />
            <Route path={ClubhousePageId.RACES} element={<RacesPage />} />
            <Route
              path={ClubhousePageId.SETTINGS}
              element={<SettingsPage {...pageProps} />}
            />
            <Route
              path={`${ClubhousePageId.PAGES}/:id`}
              element={<CustomPagePage {...pageProps} />}
            />
            <Route path="*" element={<FourOFourPage />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </RootAppWrapper>
  );
}
