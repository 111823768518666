import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import PermissionGroupForm, {
  buildNewPermissionGroup,
} from 'components/clubs/admin/permission_groups_page/permission_group_form';
import PermissionGroupManageMembershipsForm from 'components/clubs/admin/permission_groups_page/permission_group_manage_memberships_form';

const i18nScope = 'clubs.admin.permission_groups';

const GET_ADMIN_CLUB_EDIT_PERMISSION_GROUP = gql(`
  query GetAdminClubEditPermissionGroup($clubId: ID!, $permissionGroup: ID!) {
    club(id: $clubId) {
      id
      permissionGroup(id: $permissionGroup) {
        id
        name
        permissionCategoryNames
      }
    }
  }
`);

const GET_ADMIN_CLUB_MANAGE_PERMISSION_GROUP = gql(`
  query GetAdminClubManagePermissionGroup($clubId: ID!, $permissionGroup: ID!) {
    club(id: $clubId) {
      id
      permissionGroup(id: $permissionGroup) {
        id
        name
        memberships {
          id
          fullName
          profilePicUrl
          emailAddress
        }
      }
    }
  }
`);

export function PermissionGroupNewPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const newPermissionGroup = useMemo(() => buildNewPermissionGroup(), []);

  return (
    <div id="clubs-admin-permission-groups-page">
      <AdminDashboard
        title={i18n.t('new.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page form-page"
      >
        <PermissionGroupForm
          clubId={String(currentClubId)}
          isEditing={false}
          permissionGroup={newPermissionGroup}
          onSuccessCallback={(permissionGroupId) =>
            navigate(`../${permissionGroupId}/manage`)
          }
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}

export function PermissionGroupEditPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(
    GET_ADMIN_CLUB_EDIT_PERMISSION_GROUP,
    {
      variables: {
        clubId: String(currentClubId),
        permissionGroup: String(id),
      },
    },
  );
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const permissionGroup = club.permissionGroup;
  assertNotNullOrUndefined(permissionGroup);

  return (
    <div id="clubs-admin-permission-groups-page">
      <AdminDashboard
        title={i18n.t('edit.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page form-page"
      >
        <PermissionGroupForm
          clubId={String(currentClubId)}
          isEditing={true}
          permissionGroup={permissionGroup}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}

export function PermissionGroupManagePage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(
    GET_ADMIN_CLUB_MANAGE_PERMISSION_GROUP,
    {
      variables: {
        clubId: String(currentClubId),
        permissionGroup: String(id),
      },
    },
  );
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const permissionGroup = club.permissionGroup;
  assertNotNullOrUndefined(permissionGroup);

  return (
    <div id="clubs-admin-permission-groups-page">
      <AdminDashboard
        title={i18n.t('manage.title', {
          scope: i18nScope,
          values: { name: permissionGroup.name },
        })}
        contentClasses="elevate-content min-height-page manage-form-page"
      >
        <PermissionGroupManageMembershipsForm
          clubId={String(currentClubId)}
          permissionGroup={permissionGroup}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
