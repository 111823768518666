import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { AdminClubCustomPageCreateMutation, Club } from '__generated__/graphql';

import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import Icon, { iconType } from 'components/utils/icon';
import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
} from 'components/forms/forms';
import {
  buildNewCustomPage,
  CustomPageFormValues,
} from 'components/clubs/admin/pages/custom_page_form';

const ADMIN_CLUB_CUSTOM_PAGE_CREATE = gql(`
  mutation AdminClubCustomPageCreate($input: ClubCustomPageCreateOrUpdateInput!) {
    clubCustomPageCreateOrUpdate(input: $input) {
      customPage {
        id
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function CreateCustomPageButton({
  club,
  buttonText,
}: {
  club: Pick<Club, 'id'>;
  buttonText: string;
}) {
  const [createOrUpdateMutation] = useMutation(ADMIN_CLUB_CUSTOM_PAGE_CREATE);

  const navigate = useNavigate();
  const initialValues = buildNewCustomPage();

  const _onSubmit = (
    values: CustomPageFormValues,
    actions: FormikHelpers<CustomPageFormValues>,
  ) => {
    sendMutationAndUpdateForm<
      CustomPageFormValues,
      AdminClubCustomPageCreateMutation
    >({
      actions,
      mutationName: 'clubCustomPageCreateOrUpdate',
      main: () =>
        createOrUpdateMutation({
          variables: {
            input: {
              clubId: club.id,
              ...values,
            },
          },
        }),

      successCallback: (mutationPayload) => {
        const createdCustomPage =
          mutationPayload?.clubCustomPageCreateOrUpdate?.customPage;
        assertNotNullOrUndefined(createdCustomPage);
        navigate(`${createdCustomPage.id}/edit`);
      },
    });
  };

  return (
    <div className="create-custom-page-button">
      <Formik onSubmit={_onSubmit} initialValues={initialValues}>
        {({ status, isSubmitting }) => (
          <Form noValidate className="basic-form">
            <FormActions className="rtl">
              <FormActionButton
                className="primary small"
                isSubmitting={isSubmitting}
                style={{ marginTop: 0, textTransform: 'none' }}
              >
                <Icon type={iconType.ADD} classes="mr-1" />
                <span>{buttonText}</span>
              </FormActionButton>
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
