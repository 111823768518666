import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import Icon, { iconType } from 'components/utils/icon';
import { ClubProfileHeaderPhoto } from 'components/utils/image';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import { ClubId } from 'types/types';

const i18nScope = 'clubs.admin.club_profile.show';

const GET_ADMIN_CLUB_PROFILE = gql(`
  query GetAdminClubProfile($clubId: ID!) {
    club(id: $clubId) {
      id
      clubProfile {
        id
        description
        headerPhotoUrl
      }
    }
  }
`);

export default function ClubProfilePage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_PROFILE, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const clubProfile = data?.club?.clubProfile;
  assertNotNullOrUndefined(clubProfile);

  return (
    <div id="clubs-admin-club-profile-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        titleActions={
          <Icon type={iconType.EDIT} onClick={() => navigate('edit')} />
        }
        contentClasses="elevate-content min-height-page"
      >
        <div className="info-wrapper">
          <div className="field">
            <div className="label">
              {i18n.t('info.field.header_photo', { scope: i18nScope })}
            </div>
            <div className="value">
              <ClubProfileHeaderPhoto clubProfile={clubProfile} />
            </div>
          </div>
          <div className="field">
            <div className="label">
              {i18n.t('info.field.description', { scope: i18nScope })}
            </div>
            <div className="value pre-wrap">{clubProfile.description}</div>
          </div>
        </div>
      </AdminDashboard>
    </div>
  );
}
