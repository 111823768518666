import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useNavigate, useParams } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import EmergencyContactInfo from 'components/utils/emergency_contact_info';
import { AddOnNames } from 'components/add_ons/names';
import { getDoesClubHaveAddOnInstalled } from 'models/club';
import { useClubContext } from 'components/clubs/admin/memberships_page/show/layout';

const i18nScope = 'clubs.admin.memberships.show.emergency';

const GET_ADMIN_CLUB_MEMBERSHIP_EMERGENCY_CONTACTS = gql(`
  query GetAdminClubMembershipEmergencyContacts($clubId: ID!, $membershipId: ID!) {
    club(id: $clubId) {
      id
      addOnNames
      membership(id: $membershipId) {
        id
        emergencyContacts {
          id
          name
          phoneNumber
          relationship
        }
      }
    }
  }
`);

export default function MembershipShowEmergencyPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const clubContext = useClubContext();

  // let's check if they have the emergency contacts add on
  // before we fetch the data
  const hasEmergencyContactsApp = getDoesClubHaveAddOnInstalled({
    club: clubContext,
    addOnName: AddOnNames.EMERGENCY_CONTACTS,
  });
  if (!hasEmergencyContactsApp) {
    navigate('..', { replace: true });
  }

  const { loading, error, data } = useQuery(
    GET_ADMIN_CLUB_MEMBERSHIP_EMERGENCY_CONTACTS,
    {
      variables: {
        clubId: String(currentClubId),
        membershipId: String(id),
      },
    },
  );

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const emergencyContacts = data?.club?.membership?.emergencyContacts;
  assertNotNullOrUndefined(emergencyContacts);

  return (
    <div>
      {emergencyContacts.length == 0 ? (
        <p>{i18n.t('none', { scope: i18nScope })}</p>
      ) : (
        emergencyContacts.map((emergencyContact, index) => (
          <EmergencyContactInfo
            key={index}
            classes={index === 0 ? 'mt-2' : 'mt-4'}
            emergencyContact={emergencyContact}
          />
        ))
      )}
    </div>
  );
}
