import React from 'react';
import { ClubCustomPage } from '__generated__/graphql';
import classNames from 'classnames';
import { ContentEditablePresenter } from 'components/utils/content_editable_presenter';

export function CustomPagePresenter({
  customPage,
  classes,
}: {
  customPage: Pick<ClubCustomPage, 'title' | 'body'>;
  classes?: string;
}) {
  return (
    <div
      className={classNames('custom-page-presenter', classes)}
      style={{ width: 650, maxWidth: '100%' }}
    >
      <ContentEditablePresenter content={customPage.body} />
    </div>
  );
}
