import h, { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import { ClubId, ClubMetricsSummaryPeriodType } from 'types/types';
import {
  BLUE_GRADIENT_COLOR_SCHEME,
  RED_GRADIENT_COLOR_SCHEME,
  ChartColorScheme,
  MULTI_COLOR_SCHEME,
  TimeSeriesBarChart,
  TimeSeriesAreaChart,
  ChartXAxisTickFormatter,
  ChartYAxisTickFormatter,
  cardinal10TensionFn,
  ChartLinearGradient,
  getStrokeAndFillForLinearGradient,
  LINEAR_GRADIENT_COLOR_SCHEMES,
} from 'components/utils/charts_wrapper';
import { Area, Bar, ReferenceLine } from 'recharts';
import Icon, { iconType } from 'components/utils/icon';
import PillGroup from 'components/utils/pill_group';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';
import { GetClubMetricsSummariesQuery } from '__generated__/graphql';

const i18nScope = 'clubs.admin.insights';

type Club = NonNullable<GetClubMetricsSummariesQuery['club']>;
type ClubMetricsSummary = Club['clubMetricsSummaries'][number];

const GET_CLUB_METRICS_SUMMARIES = gql(`
  query GetClubMetricsSummaries($clubId: ID!, $periodType: String!) {
    club(id: $clubId) {
      id
      clubMetricsSummaries(periodType: $periodType) {
        id
        periodType
        startDate
        endDate

        membershipsActiveCount
        membershipsJoinedCount
        membershipsExpiredCount

        ordersTotalAmountInCents
        ordersCount
        ordersMembershipsTotalAmountInCents
        ordersMembershipsCount
        ordersStorefrontTotalAmountInCents
        ordersStorefrontCount

        eventsCount
        eventsActiveMembersCount
        eventsNumMembersByEventsAttendedCountSnapshot {
          numEvents
          numMembers
        }
      }
    }
  }
`);

function roundNumberToTwoDecimalPlaces(number: number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

const toPercent = (decimal: number, fixed = 0): string => {
  return `${(decimal * 100).toFixed(fixed)}%`;
};

const getPercent = (value: number, total: number) => {
  const ratio = total > 0 ? value / total : 0;

  return toPercent(ratio, 0);
};

interface DataKey<T> {
  attributeName: keyof T;
  displayName: string;
}

function InsightsBarChart<T>({
  children,
  heading,
  headingTooltip,
  clubMetricsSummaries,
  dataKeys,
  getData,
  colorScheme = BLUE_GRADIENT_COLOR_SCHEME,
  stackOffset,
  yAxisFormatter,
  xAxisFormatter,
}: {
  children?: React.ReactElement;
  heading: string;
  headingTooltip: string;
  colorScheme: ChartColorScheme;
  clubMetricsSummaries: ClubMetricsSummary[];
  dataKeys: DataKey<T>[];
  getData: (clubMetricsSummary: ClubMetricsSummary) => T;
  stackOffset?: 'sign';
  yAxisFormatter?: ChartYAxisTickFormatter;
  xAxisFormatter?: ChartXAxisTickFormatter;
}) {
  const data = _.sortBy(clubMetricsSummaries, 'startDate').map(
    (clubMetricsSummary) => ({
      label: clubMetricsSummary.startDate,
      ...getData(clubMetricsSummary),
    }),
  );

  return (
    <div className="insights-chart">
      <div className="insights-chart-heading">
        {heading}
        <Icon
          type={iconType.INFO}
          classes="ml-1"
          tooltipText={headingTooltip}
        />
      </div>
      <TimeSeriesBarChart<T>
        data={data}
        yAxisTickFormatter={yAxisFormatter}
        xAxisTickFormatter={xAxisFormatter}
        xDataKey="label"
        stackOffset={stackOffset}
        tooltipContentCallback={({ payload, label }) => {
          return (
            <div className="data-point-tooltip">
              {xAxisFormatter?.(label) ?? label} <br />
              {payload.map((chart, index) => {
                const dataKey = dataKeys.find(
                  (dk) => dk.attributeName === chart.name,
                );

                return (
                  <div
                    key={index}
                    className="bold"
                    style={{ color: chart.color }}
                  >
                    {(typeof chart.value !== 'undefined' &&
                      yAxisFormatter?.(chart.value)) ??
                      chart.value}{' '}
                    {dataKey?.displayName}
                  </div>
                );
              })}
            </div>
          );
        }}
      >
        <>
          {children}
          {dataKeys.map((dataKey, index) => (
            <Bar
              key={index}
              dataKey={dataKey.attributeName as string}
              stackId="a"
              fill={colorScheme[index]}
            />
          ))}
        </>
      </TimeSeriesBarChart>
    </div>
  );
}

function InsightsStackedAreaChart<T>({
  heading,
  headingTooltip,
  clubMetricsSummaries,
  dataKeys,
  getData,
  colorScheme = BLUE_GRADIENT_COLOR_SCHEME,
  stackOffset,
  yAxisFormatter,
  xAxisFormatter,
}: {
  heading: string;
  headingTooltip: string;
  colorScheme: ChartColorScheme;
  clubMetricsSummaries: ClubMetricsSummary[];
  dataKeys: DataKey<T>[];
  getData: (clubMetricsSummary: ClubMetricsSummary) => T;
  stackOffset?: 'expand';
  yAxisFormatter?: ChartYAxisTickFormatter;
  xAxisFormatter?: ChartXAxisTickFormatter;
}) {
  const timeSeriesData = _.sortBy(clubMetricsSummaries, 'startDate').map(
    (clubMetricsSummary) => ({
      date: clubMetricsSummary.startDate,
      label: clubMetricsSummary.startDate,
      ...getData(clubMetricsSummary),
    }),
  );

  return (
    <div className="insights-chart">
      <div className="insights-chart-heading">
        {heading}
        <Icon
          type={iconType.INFO}
          classes="ml-1"
          tooltipText={headingTooltip}
        />
      </div>
      <TimeSeriesAreaChart
        data={timeSeriesData}
        yAxisTickFormatter={yAxisFormatter}
        xAxisTickFormatter={xAxisFormatter}
        xDataKey="label"
        stackOffset={stackOffset}
        tooltipContentCallback={({ payload, label }) => {
          const total = payload.reduce(
            (result, entry) => result + (entry.value as number),
            0,
          );
          return (
            <div className="data-point-tooltip">
              {xAxisFormatter?.(label) ?? label} <br />
              {payload.map((chart, index) => {
                const dataKey = dataKeys.find(
                  (dk) => dk.attributeName === chart.name,
                );

                return (
                  <div
                    key={index}
                    className="bold"
                    style={{ color: chart.color }}
                  >
                    {chart.value} ({getPercent(chart.value as number, total)}){' '}
                    {dataKey?.displayName}
                  </div>
                );
              })}
            </div>
          );
        }}
      >
        {dataKeys.map((dataKey, index) => (
          <Area
            key={index}
            type="monotone"
            strokeWidth={3}
            dataKey={dataKey.attributeName as string}
            stackId="1"
            stroke={colorScheme[index]}
            fill={colorScheme[index]}
          />
        ))}
      </TimeSeriesAreaChart>
    </div>
  );
}

function InsightsAreaChart<T>({
  heading,
  headingTooltip,
  clubMetricsSummaries,
  dataKeys,
  getData,
  yAxisFormatter,
  xAxisFormatter,
}: {
  heading: string;
  headingTooltip: string;
  clubMetricsSummaries: ClubMetricsSummary[];
  dataKeys: DataKey<T>[];
  getData: (clubMetricsSummary: ClubMetricsSummary) => T;
  yAxisFormatter?: ChartYAxisTickFormatter;
  xAxisFormatter?: ChartXAxisTickFormatter;
}) {
  const timeSeriesData = _.sortBy(clubMetricsSummaries, 'startDate').map(
    (clubMetricsSummary) => ({
      date: clubMetricsSummary.startDate,
      label: clubMetricsSummary.startDate,
      ...getData(clubMetricsSummary),
    }),
  );

  const charts = dataKeys.map((dataKey, index) => ({
    dataKey,
    ...getStrokeAndFillForLinearGradient(LINEAR_GRADIENT_COLOR_SCHEMES[index]),
  }));

  xAxisFormatter =
    xAxisFormatter ??
    ((value) => Helpers.Utils.formatDateOnlyMonthAndYear(value as string));

  return (
    <div className="insights-chart">
      <div className="insights-chart-heading">
        {heading}
        <Icon
          type={iconType.INFO}
          classes="ml-1"
          tooltipText={headingTooltip}
        />
      </div>
      <TimeSeriesAreaChart
        data={timeSeriesData}
        yAxisTickFormatter={yAxisFormatter}
        xAxisTickFormatter={xAxisFormatter}
        xDataKey="label"
        tooltipContentCallback={({ payload, label }) => {
          return (
            <div className="data-point-tooltip">
              {xAxisFormatter?.(label) ?? label} <br />
              {payload.map((chart, index) => {
                const dataKey = dataKeys.find(
                  (dk) => dk.attributeName === chart.name,
                );

                return (
                  <div
                    key={index}
                    className="bold"
                    style={{ color: chart.color }}
                  >
                    {(typeof chart.value !== 'undefined' &&
                      yAxisFormatter?.(chart.value)) ??
                      chart.value}{' '}
                    {dataKey?.displayName}
                  </div>
                );
              })}
            </div>
          );
        }}
      >
        <>
          <defs>
            {LINEAR_GRADIENT_COLOR_SCHEMES.map((gradientColorScheme, index) => (
              <ChartLinearGradient key={index} scheme={gradientColorScheme} />
            ))}
          </defs>

          {charts.map((chartProps, index) => (
            <Area
              key={index}
              type={cardinal10TensionFn}
              strokeWidth={3}
              dataKey={chartProps.dataKey.attributeName as string}
              stroke={chartProps.stroke}
              fill={chartProps.fill}
            />
          ))}
        </>
      </TimeSeriesAreaChart>
    </div>
  );
}

function EventsInsights({
  clubMetricsSummaries,
  xAxisFormatter,
}: {
  clubMetricsSummaries: ClubMetricsSummary[];
  xAxisFormatter: ChartXAxisTickFormatter;
}) {
  const { i18n } = React.useContext(I18nContext);
  const numEventsAttendedCategories = useMemo(() => [0, 1, 2, 3], []);
  const numEventsAttendedMaxCategory = numEventsAttendedCategories.at(-1);
  if (typeof numEventsAttendedMaxCategory === 'undefined') {
    h.throwError('numEventsAttendedMaxCategory should not be undefined');
    return null;
  }
  const numEventsAttendedMaxPlusText = i18n.t(
    'events.charts.num_events_attended.max_plus',
    {
      scope: i18nScope,
      count: numEventsAttendedMaxCategory,
    },
  );
  const numEventsAttendedDataKeys = numEventsAttendedCategories.map(
    (numEventsAttended, index) => {
      if (index + 1 < numEventsAttendedCategories.length) {
        return {
          attributeName: numEventsAttended.toString(),
          displayName: i18n.t(
            'events.charts.num_events_attended.data.discrete',
            {
              scope: i18nScope,
              count: numEventsAttended,
            },
          ),
        };
      } else {
        return {
          attributeName: numEventsAttendedMaxPlusText,
          displayName: i18n.t('events.charts.num_events_attended.data.max', {
            scope: i18nScope,
            values: { maxPlus: numEventsAttendedMaxPlusText },
          }),
        };
      }
    },
  );

  return (
    <div className="side-by-side-charts">
      <InsightsAreaChart<{ eventsActiveMembersCount: number }>
        heading={i18n.t('events.charts.engaged_members_count.heading', {
          scope: i18nScope,
        })}
        headingTooltip={i18n.t('events.charts.engaged_members_count.tooltip', {
          scope: i18nScope,
        })}
        dataKeys={[
          {
            attributeName: 'eventsActiveMembersCount',
            displayName: i18n.t(
              'events.charts.engaged_members_count.data.engaged_members',
              {
                scope: i18nScope,
              },
            ),
          },
        ]}
        xAxisFormatter={xAxisFormatter}
        getData={(clubMetricsSummary) => ({
          eventsActiveMembersCount: clubMetricsSummary.eventsActiveMembersCount,
        })}
        clubMetricsSummaries={clubMetricsSummaries}
      />

      <br />

      <InsightsStackedAreaChart<Record<string, number>>
        heading={i18n.t('events.charts.num_events_attended.heading', {
          scope: i18nScope,
        })}
        headingTooltip={i18n.t('events.charts.num_events_attended.tooltip', {
          scope: i18nScope,
        })}
        colorScheme={[
          RED_GRADIENT_COLOR_SCHEME[0],
          ...[...BLUE_GRADIENT_COLOR_SCHEME].reverse(),
        ]}
        clubMetricsSummaries={clubMetricsSummaries}
        dataKeys={numEventsAttendedDataKeys}
        stackOffset="expand"
        xAxisFormatter={xAxisFormatter}
        yAxisFormatter={(value) => toPercent(value as number, 0)}
        getData={(clubMetricsSummary) => {
          const numMembersByEventsAttendedCount: Record<string, number> = {};

          clubMetricsSummary.eventsNumMembersByEventsAttendedCountSnapshot.forEach(
            (row) => {
              const { numEvents, numMembers } = row;
              const key =
                numEvents < numEventsAttendedMaxCategory
                  ? numEvents.toString()
                  : numEventsAttendedMaxPlusText;

              numMembersByEventsAttendedCount[key] =
                numMembersByEventsAttendedCount[key] ?? 0;
              numMembersByEventsAttendedCount[key] += numMembers;
            },
          );

          const numMembersWhoAttended = Object.values(
            numMembersByEventsAttendedCount,
          ).reduce((acc, val) => acc + val, 0);

          // handles any discrepancies in data because numMembersWhoAttended might be
          // less than the number of folks who attended events. it shouldn't but it could.
          const totalNumberOfMembers = Math.max(
            clubMetricsSummary.membershipsActiveCount,
            numMembersWhoAttended,
          );

          // not the best that we're inferring the number of folks who attended
          // 0 events by looking at the number of active members - minus the
          // number of folks who attended events.
          // - a member can be included in the attended count even if they're
          //   not an active member. so we may be undercounting
          numMembersByEventsAttendedCount['0'] = Math.max(
            totalNumberOfMembers - numMembersWhoAttended,
            0,
          );

          return numMembersByEventsAttendedCount;
        }}
      />
    </div>
  );
}

function MembershipsInsights({
  clubMetricsSummaries,
  xAxisFormatter,
}: {
  clubMetricsSummaries: ClubMetricsSummary[];
  xAxisFormatter: ChartXAxisTickFormatter;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div>
      <InsightsAreaChart<{
        membershipsActiveCount: number;
        eventsActiveMembersCount: number;
      }>
        heading={i18n.t('memberships.charts.number_of_members.heading', {
          scope: i18nScope,
        })}
        headingTooltip={i18n.t('memberships.charts.number_of_members.tooltip', {
          scope: i18nScope,
        })}
        dataKeys={[
          {
            attributeName: 'membershipsActiveCount',
            displayName: i18n.t(
              'memberships.charts.number_of_members.data.members',
              {
                scope: i18nScope,
              },
            ),
          },
          {
            attributeName: 'eventsActiveMembersCount',
            displayName: 'engaged members',
          },
        ]}
        xAxisFormatter={xAxisFormatter}
        getData={(clubMetricsSummary) => ({
          membershipsActiveCount: clubMetricsSummary.membershipsActiveCount,
          eventsActiveMembersCount: clubMetricsSummary.eventsActiveMembersCount,
        })}
        clubMetricsSummaries={clubMetricsSummaries}
      />

      <br />

      <InsightsBarChart<{
        membershipsJoinedCount: number;
        membershipsExpiredCount: number;
      }>
        heading={i18n.t('memberships.charts.net_change.heading', {
          scope: i18nScope,
        })}
        headingTooltip={i18n.t('memberships.charts.net_change.tooltip', {
          scope: i18nScope,
        })}
        colorScheme={[
          BLUE_GRADIENT_COLOR_SCHEME[1],
          RED_GRADIENT_COLOR_SCHEME[0],
        ]}
        clubMetricsSummaries={clubMetricsSummaries}
        dataKeys={[
          {
            attributeName: 'membershipsJoinedCount',
            displayName: i18n.t('memberships.charts.net_change.data.joined', {
              scope: i18nScope,
            }),
          },
          {
            attributeName: 'membershipsExpiredCount',
            displayName: i18n.t('memberships.charts.net_change.data.expired', {
              scope: i18nScope,
            }),
          },
        ]}
        xAxisFormatter={xAxisFormatter}
        stackOffset="sign"
        getData={(clubMetricsSummary) => ({
          membershipsJoinedCount: clubMetricsSummary.membershipsJoinedCount,
          membershipsExpiredCount:
            -1 * clubMetricsSummary.membershipsExpiredCount,
        })}
      >
        <ReferenceLine y={0} stroke="#000" />
      </InsightsBarChart>

      <br />

      <InsightsAreaChart<{ retentionRate: number }>
        heading={i18n.t('memberships.charts.retention_rate.heading', {
          scope: i18nScope,
        })}
        headingTooltip={i18n.t('memberships.charts.retention_rate.tooltip', {
          scope: i18nScope,
        })}
        dataKeys={[
          {
            attributeName: 'retentionRate',
            displayName: i18n.t(
              'memberships.charts.retention_rate.data.retention_rate',
              {
                scope: i18nScope,
              },
            ),
          },
        ]}
        xAxisFormatter={xAxisFormatter}
        yAxisFormatter={(value) => `${value}%`}
        getData={(clubMetricsSummary) => {
          const numMembershipsAtStartOfPeriod =
            clubMetricsSummary.membershipsActiveCount -
            clubMetricsSummary.membershipsJoinedCount;
          const retentionRate =
            (1 -
              clubMetricsSummary.membershipsExpiredCount /
                numMembershipsAtStartOfPeriod) *
            100;

          return {
            retentionRate: roundNumberToTwoDecimalPlaces(retentionRate),
          };
        }}
        clubMetricsSummaries={clubMetricsSummaries}
      />
    </div>
  );
}

function OrdersInsights({
  clubMetricsSummaries,
  xAxisFormatter,
}: {
  clubMetricsSummaries: ClubMetricsSummary[];
  xAxisFormatter: ChartXAxisTickFormatter;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div>
      <InsightsBarChart<{
        membershipsTotalAmount: number;
        storefrontTotalAmount: number;
      }>
        heading={i18n.t('orders.charts.revenue.heading', {
          scope: i18nScope,
        })}
        headingTooltip={i18n.t('orders.charts.revenue.tooltip', {
          scope: i18nScope,
        })}
        colorScheme={MULTI_COLOR_SCHEME}
        clubMetricsSummaries={clubMetricsSummaries}
        dataKeys={[
          {
            attributeName: 'membershipsTotalAmount',
            displayName: i18n.t('orders.charts.revenue.data.memberships', {
              scope: i18nScope,
            }),
          },
          {
            attributeName: 'storefrontTotalAmount',
            displayName: i18n.t('orders.charts.revenue.data.storefront', {
              scope: i18nScope,
            }),
          },
        ]}
        xAxisFormatter={xAxisFormatter}
        yAxisFormatter={(value) =>
          i18n.c({ amountInCents: (value as number) * 100 })
        }
        getData={(clubMetricsSummary) => ({
          membershipsTotalAmount:
            clubMetricsSummary.ordersMembershipsTotalAmountInCents / 100,
          storefrontTotalAmount:
            clubMetricsSummary.ordersStorefrontTotalAmountInCents / 100,
        })}
      />
    </div>
  );
}

function InsightsSection({
  children,
  heading,
}: {
  children: React.ReactElement;
  heading: string;
}) {
  return (
    <div className="insights-section">
      <h3 className="mb-3">{heading}</h3>
      <div className="elevate-content">{children}</div>
    </div>
  );
}

function InsightsInner({
  clubMetricsSummaries,
  xAxisFormatter,
}: {
  clubMetricsSummaries: ClubMetricsSummary[];
  xAxisFormatter: ChartXAxisTickFormatter;
}) {
  const { i18n } = React.useContext(I18nContext);
  if (clubMetricsSummaries.length === 0) {
    return <div>{i18n.t('empty', { scope: i18nScope })}</div>;
  }
  return (
    <div>
      <InsightsSection heading={i18n.t('events.heading', { scope: i18nScope })}>
        <EventsInsights
          clubMetricsSummaries={clubMetricsSummaries}
          xAxisFormatter={xAxisFormatter}
        />
      </InsightsSection>
      <InsightsSection heading={i18n.t('orders.heading', { scope: i18nScope })}>
        <OrdersInsights
          clubMetricsSummaries={clubMetricsSummaries}
          xAxisFormatter={xAxisFormatter}
        />
      </InsightsSection>
      <InsightsSection
        heading={i18n.t('memberships.heading', { scope: i18nScope })}
      >
        <MembershipsInsights
          clubMetricsSummaries={clubMetricsSummaries}
          xAxisFormatter={xAxisFormatter}
        />
      </InsightsSection>
    </div>
  );
}

function InsightsLoadingPlaceholder() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div>
      <InsightsSection heading={i18n.t('events.heading', { scope: i18nScope })}>
        <div className="side-by-side-charts">
          <div className="insights-chart">
            <LoadingPlaceholder
              type={placeholderType.FULL}
              classes="rounded-lg"
            />
          </div>
          <div className="insights-chart">
            <LoadingPlaceholder
              type={placeholderType.FULL}
              classes="rounded-lg"
            />
          </div>
        </div>
      </InsightsSection>
      <InsightsSection heading={i18n.t('orders.heading', { scope: i18nScope })}>
        <div className="insights-chart">
          <LoadingPlaceholder
            type={placeholderType.FULL}
            classes="rounded-lg"
          />
        </div>
      </InsightsSection>
    </div>
  );
}

export default function InsightsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [periodType, setPeriodType] = useState<ClubMetricsSummaryPeriodType>(
    ClubMetricsSummaryPeriodType.WEEKLY,
  );

  const weeklyXAxisFormatter = useCallback<ChartXAxisTickFormatter>(
    (value) => Helpers.Utils.formatDate(value as string, { withYear: true }),
    [],
  );
  const monthlyXAxisFormatter = useCallback<ChartXAxisTickFormatter>(
    (value) => Helpers.Utils.formatDateOnlyMonthAndYear(value as string),
    [],
  );

  const xAxisFormatter =
    periodType === ClubMetricsSummaryPeriodType.WEEKLY
      ? weeklyXAxisFormatter
      : monthlyXAxisFormatter;

  const { loading, error, data } = useQuery(GET_CLUB_METRICS_SUMMARIES, {
    variables: {
      clubId: String(currentClubId),
      periodType,
    },
  });

  if (error) return <ErrorPage />;

  let content: React.ReactNode;
  if (loading) {
    content = <InsightsLoadingPlaceholder />;
  } else {
    const club = data?.club;
    assertNotNullOrUndefined(club);
    const clubMetricsSummaries = club.clubMetricsSummaries;
    content = (
      <InsightsInner
        clubMetricsSummaries={clubMetricsSummaries}
        xAxisFormatter={xAxisFormatter}
      />
    );
  }

  return (
    <div id="clubs-admin-insights-page">
      <AdminDashboard
        title={i18n.t('name', { scope: i18nScope })}
        contentClasses="min-height-page"
        actions={
          <PillGroup
            classes="ml-5 inline-block"
            pills={[
              { value: ClubMetricsSummaryPeriodType.WEEKLY, element: 'weekly' },
              {
                value: ClubMetricsSummaryPeriodType.MONTHLY,
                element: 'monthly',
              },
            ]}
            value={periodType}
            onClick={(newPeriodType) => setPeriodType(newPeriodType)}
          />
        }
      >
        {content}
      </AdminDashboard>
    </div>
  );
}
