import React from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ClubEvent,
  ClubEventEventCancelationCreateMutation,
} from '__generated__/graphql';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';

import { Formik, Form, FormikHelpers } from 'formik';
import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
  FormAutoExpandField,
} from 'components/forms/forms';

import I18nContext from 'contexts/i18n_context';

const i18nScope =
  'components.clubs.club_events.club_event_detail_view.event_cancelation_form';

const CLUB_EVENT_EVENT_CANCELATION_CREATE = gql(`
mutation ClubEventEventCancelationCreate($input: ClubEventEventCancelationCreateInput!) {
  clubEventEventCancelationCreate(input: $input) {
    eventCancelation {
      id
    }
    errors {
      attribute
      messages
    }
  }
}
`);

interface EventCancelationFormValues {
  message: string;
}

export default function EventCancelationForm({
  clubEvent,
  onSuccessCallback,
  onCancelCallback,
}: {
  clubEvent: Pick<ClubEvent, 'id' | 'name'>;
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [createMutation] = useMutation(CLUB_EVENT_EVENT_CANCELATION_CREATE);

  const _onSubmit = function (
    values: EventCancelationFormValues,
    actions: FormikHelpers<EventCancelationFormValues>,
  ) {
    sendMutationAndUpdateForm<
      EventCancelationFormValues,
      ClubEventEventCancelationCreateMutation
    >({
      actions,
      mutationName: 'clubEventEventCancelationCreate',
      main: () =>
        createMutation({
          variables: {
            input: {
              clubEventId: clubEvent.id,
              ...values,
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  return (
    <div id="event-cancelation-form">
      <h1>
        {i18n.t('heading', {
          scope: i18nScope,
          values: {
            eventName: clubEvent.name,
          },
        })}
      </h1>
      <Formik initialValues={{ message: '' }} onSubmit={_onSubmit}>
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <div className="mt-5">
              <FormAutoExpandField
                autoFocus={true}
                rows="5"
                name="message"
                label={i18n.t('fields.message.label', { scope: i18nScope })}
              />
            </div>
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('submit_button', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                text={i18n.t('back_button', { scope: i18nScope })}
                className="secondary"
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
