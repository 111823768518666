import React from 'react';
import classNames from 'classnames';
import { ClubEventHost } from '__generated__/graphql';

import { ProfilePic, picSize } from 'components/utils/profile_pic';
import { Club, Membership, User } from 'types/types';

export type MinEventHost = Pick<
  ClubEventHost,
  'subjectId' | 'subjectType' | 'name' | 'picUrl'
>;

// until we have type checking, going to include this function
// here so that in case we update the internals of the component
// it won't break all the other places
export function mockEventHostFromClub(club: Club): MinEventHost {
  return {
    subjectId: String(club.id),
    subjectType: 'Club',
    name: club.name,
    picUrl: club.logoUrl,
  };
}

export function mockEventHostFromUser(user: User): MinEventHost {
  return {
    subjectId: String(user.id),
    subjectType: 'User',
    name: user.fullName,
    picUrl: user.profilePicUrl,
  };
}

export function mockEventHostFromMembership(
  membership: Membership,
): MinEventHost {
  return {
    subjectId: String(membership.id),
    subjectType: 'Membership',
    name: membership.fullName,
    picUrl: membership.profilePicUrl,
  };
}

export { picSize };

export default function EventHostInfo({
  classes,
  eventHost,
  picSize,
}: {
  eventHost: Pick<ClubEventHost, 'name' | 'picUrl'>;
  picSize: picSize;
  classes?: string;
}) {
  return (
    <div className={classNames('event-host', classes)}>
      <ProfilePic
        classes="inline-block mr-2"
        size={picSize}
        picUrl={eventHost.picUrl}
      />
      <span className="event-host-name">{eventHost.name}</span>
    </div>
  );
}
