import { Club } from '__generated__/graphql';

export function getDoesClubHaveAddOnInstalled({
  club,
  addOnName,
}: {
  club: Pick<Club, 'addOnNames'>;
  addOnName: Club['addOnNames'][number];
}): boolean {
  return club.addOnNames.indexOf(addOnName) > -1;
}
