import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  GetMembershipNotificationsSettingQuery,
  MembershipNotificationsSettingUpdateMutation,
} from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';
import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
} from 'components/forms/forms';
import I18nContext from 'contexts/i18n_context';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import { ClubProfilePic, picSize } from 'components/utils/profile_pic';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import NotificationSettingRow from 'components/users/my_account/notifications/notification_setting_row';

const i18nScope = 'components.users.my_account.notifications';
const mnsFormI18nScope = `${i18nScope}.membership_notifications_setting.form`;

type CurrentUser = NonNullable<
  GetMembershipNotificationsSettingQuery['currentUser']
>;
type CurrentMembership = NonNullable<CurrentUser['membership']>;
type Club = NonNullable<CurrentMembership['club']>;
type MembershipNotificationsSetting =
  CurrentMembership['membershipNotificationsSetting'];

type MembershipNotificationsSettingFormValues = Pick<
  MembershipNotificationsSetting,
  | 'newsletterAndUpdatesEmail'
  | 'eventUpdatesEmail'
  | 'eventRemindersEmail'
  | 'eventRsvpConfirmationEmail'
  | 'commentsEmail'
>;

const GET_MEMBERSHIP_NOTIFICATIONS_SETTING = gql(`
  query GetMembershipNotificationsSetting($clubSlugName: String) {
    currentUser {
      id
      membership(clubSlugName: $clubSlugName) {
        club {
          id
          name
          logoUrl
        }
        membershipNotificationsSetting {
          id
          newsletterAndUpdatesEmail
          eventUpdatesEmail
          eventRemindersEmail
          eventRsvpConfirmationEmail
          commentsEmail
        }
      }
    }
  }
`);

const MEMBERSHIP_NOTIFICATIONS_SETTING_UPDATE = gql(`
  mutation MembershipNotificationsSettingUpdate($input: MembershipNotificationsSettingUpdateInput!) {
    membershipNotificationsSettingUpdate(input: $input) {
      membershipNotificationsSetting {
        id
        newsletterAndUpdatesEmail
        eventUpdatesEmail
        eventRemindersEmail
        eventRsvpConfirmationEmail
        commentsEmail
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

function MembershipNotificationsSettingForm({
  membershipNotificationsSetting,
  club,
  onSuccessCallback,
}: {
  club: Pick<Club, 'id' | 'name' | 'logoUrl'>;
  membershipNotificationsSetting: MembershipNotificationsSetting;
  onSuccessCallback: (updatedMNS: MembershipNotificationsSetting) => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [updateMutation] = useMutation(MEMBERSHIP_NOTIFICATIONS_SETTING_UPDATE);

  const onSubmit = function (
    values: MembershipNotificationsSettingFormValues,
    actions: FormikHelpers<MembershipNotificationsSettingFormValues>,
  ) {
    sendMutationAndUpdateForm<
      MembershipNotificationsSettingFormValues,
      MembershipNotificationsSettingUpdateMutation
    >({
      actions,
      mutationName: 'membershipNotificationsSettingUpdate',
      resetSpinnerAfterSuccess: true,
      main: () =>
        updateMutation({
          variables: {
            input: {
              id: membershipNotificationsSetting.id,
              ...values,
            },
          },
        }),
      successCallback: (mutationPayload) => {
        const updatedMNS =
          mutationPayload?.membershipNotificationsSettingUpdate
            ?.membershipNotificationsSetting;
        assertNotNullOrUndefined(updatedMNS);
        onSuccessCallback(updatedMNS);
      },
    });
  };

  const generalRowProps = { i18nRowScope: mnsFormI18nScope };
  const rows: {
    emailSettingName: keyof MembershipNotificationsSettingFormValues;
  }[] = [
    { emailSettingName: 'newsletterAndUpdatesEmail' },
    { emailSettingName: 'eventUpdatesEmail' },
    { emailSettingName: 'eventRemindersEmail' },
    { emailSettingName: 'eventRsvpConfirmationEmail' },
    { emailSettingName: 'commentsEmail' },
  ];

  const allAttributeNames: (keyof MembershipNotificationsSettingFormValues)[] =
    [];
  rows.map((row) => {
    if (row.emailSettingName) {
      allAttributeNames.push(row.emailSettingName);
    }
  });

  return (
    <div className="notifications-setting-grid-form">
      <h3>
        <ClubProfilePic
          classes="inline-block mr-1"
          club={club}
          size={picSize.MEDIUM}
        />
        {club.name}
      </h3>
      <Formik
        initialValues={_.pick(
          membershipNotificationsSetting,
          allAttributeNames,
        )}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            {rows.map((row, index) => (
              <NotificationSettingRow
                key={index}
                {...generalRowProps}
                {...row}
              />
            ))}
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('actions.submit', { scope: mnsFormI18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default function MembershipNotificationsPage() {
  const { i18n } = React.useContext(I18nContext);
  const { clubSlugName } = useParams();

  const [membershipNotificationsSetting, setMembershipNotificationsSetting] =
    useState<MembershipNotificationsSetting | null>(null);

  const { loading, error, data } = useQuery(
    GET_MEMBERSHIP_NOTIFICATIONS_SETTING,
    {
      variables: { clubSlugName },
      onCompleted: (newData) => {
        const currentMembership = newData.currentUser?.membership;
        assertNotNullOrUndefined(currentMembership);
        setMembershipNotificationsSetting(
          currentMembership.membershipNotificationsSetting,
        );
      },
    },
  );
  if (loading || membershipNotificationsSetting === null)
    return <LoadingPage />;
  if (error) return <ErrorPage />;

  const currentMembership = data?.currentUser?.membership;
  assertNotNullOrUndefined(currentMembership);
  const club = currentMembership.club;

  return (
    <div id="notifications-page">
      <div className="page-header">
        <div className="title">
          <h1>{i18n.t('title', { scope: i18nScope })}</h1>
        </div>
      </div>
      <div className="elevate-content">
        <div className="mb-3">
          <Link to=".."> {i18n.t('back', { scope: i18nScope })}</Link>
        </div>
        <MembershipNotificationsSettingForm
          club={club}
          membershipNotificationsSetting={membershipNotificationsSetting}
          onSuccessCallback={(unpdatedMembershipNotificationsSetting) => {
            setMembershipNotificationsSetting(
              unpdatedMembershipNotificationsSetting,
            );
          }}
        />
      </div>
    </div>
  );
}
