import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import SuperAdminCompanyForm from 'components/super_admin/dashboard/companies/form';

const i18nScope = 'components.super_admin.dashboard.companies.edit';

const GET_SUPER_ADMIN_COMPANIES_EDIT = gql(`
  query GetSuperAdminCompaniesEdit($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      website
      logoUrl
    }
  }
`);

export default function SuperAdminCompaniesEditPage() {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  assertNotNullOrUndefined(id);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(GET_SUPER_ADMIN_COMPANIES_EDIT, {
    variables: {
      companyId: id,
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const company = data?.company;
  assertNotNullOrUndefined(company);

  return (
    <div id="super-admin-dashboard-companies-edit-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page skinny-page"
      >
        <SuperAdminCompanyForm
          company={company}
          isEditing={true}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
