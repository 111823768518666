import _ from 'underscore';
import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormFieldWrapper,
  FormServerErrorMessages,
  FormUrlField,
} from 'components/forms/forms';
import CompanyLogoUploader from 'components/utils/images/company_logo_uploader';
import Helpers from 'helpers/helpers';
import { Company } from '__generated__/graphql';
import I18nContext from 'contexts/i18n_context';

export type CompanyFormValues = Pick<Company, 'name' | 'website'>;
export type CompanyFormValuesWithLogo = CompanyFormValues &
  Pick<Company, 'logoUrl'>;

const i18nScope = 'components.super_admin.dashboard.companies.form';

export function buildNewCompany(): CompanyFormValuesWithLogo {
  return {
    logoUrl: '',
    name: '',
    website: '',
  };
}

export default function SuperAdminCompanyForm({
  isEditing,
  company,
  onSuccessCallback,
  onCancelCallback,
}: {
  isEditing: boolean;
  company: CompanyFormValuesWithLogo & { id?: Company['id'] };
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const initialValues = _.pick(company, 'name', 'website', 'logo');
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().min(2).required(),
        website: Yup.string().url().required(),
      }),
    [],
  );

  const _onSubmit = function (
    values: CompanyFormValues,
    actions: FormikHelpers<CompanyFormValues>,
  ) {
    Helpers.Form.save<CompanyFormValues>({
      actions,
      main: () => {
        const options = { shouldUseFormData: true };
        const params = { company: values };

        const url = isEditing
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Helpers.Routes.getSuperAdminCompanyPath(Number(company.id!))
          : Helpers.Routes.getSuperAdminCompaniesPath();

        return isEditing
          ? Helpers.API.put({ url, params, options })
          : Helpers.API.post({ url, params, options });
      },
      successCallback: () => {
        onSuccessCallback?.();
      },
    });
  };

  return (
    <div className="company-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, setFieldValue, setFieldTouched, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormFieldWrapper name="logo">
              <CompanyLogoUploader
                name="logo"
                company={company}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </FormFieldWrapper>
            <FormField
              autoFocus={true}
              type="text"
              name="name"
              label={i18n.t('fields.name.label', { scope: i18nScope })}
            />
            <FormUrlField
              name="website"
              label={i18n.t('fields.website.label', { scope: i18nScope })}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
