import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useField } from 'formik';
import {
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';

import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';

import Select, {
  ThemeConfig,
  Props as SelectProps,
  SingleValue,
  MultiValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';

export type FormSelectFieldStringOptionType = {
  label: string;
  value: string;
};
export type FormSelectFieldNumberOptionType = {
  label: string;
  value: number;
};
export type FormSelectFieldOptionType =
  | FormSelectFieldStringOptionType
  | FormSelectFieldNumberOptionType;

type PartialFormFieldWrapperProps = Omit<FormFieldwrapperProps, 'children'>;
type CommonSelectInputTypes<T extends FormSelectFieldOptionType> =
  SelectProps<T>;

const SELECT_CLASS_NAME = 'react-select-wrapper';
const SELECT_CLASS_NAME_PREFIX = 'react-select';

const SELECT_THEME: ThemeConfig = (theme) => ({
  ...theme,
  borderRadius: 3,
  colors: {
    ...theme.colors,
    primary: 'var(--rs-theme-primary)',
    primary75: 'var(--rs-theme-primary-75)',
    primary50: 'var(--rs-theme-primary-50)',
    primary25: 'var(--rs-theme-primary-25)',
  },
});

// in formik we only store the value of the option in the field
// and not the entire OptionType. However, react-select expects the
// entire OptionType as the value. So that means we need to find all
// the Options from their value, so we created this helper method
export function findOptionFromValue<T extends FormSelectFieldOptionType>({
  value,
  options,
}: {
  value: T['value'];
  options: T[];
}): T {
  const foundOption = options.find((option) => option.value === value);
  assertNotNullOrUndefined(foundOption);
  return foundOption;
}

export default function FormSelectField<T extends FormSelectFieldOptionType>({
  // wrapper field props
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
  // select field props
  ...otherProps
}: PartialFormFieldWrapperProps & CommonSelectInputTypes<T>) {
  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const onChange = (option: MultiValue<T> | SingleValue<T>) => {
    setValue(option);
    setTouched(true);
  };

  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      label={label}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-select-field">
        <Select
          className={SELECT_CLASS_NAME}
          classNamePrefix={SELECT_CLASS_NAME_PREFIX}
          name={field.name}
          value={field.value}
          onChange={onChange}
          theme={SELECT_THEME}
          {...otherProps}
        />
      </div>
    </FormFieldWrapper>
  );
}

type FormAsyncSelectFieldProps<
  T extends FormSelectFieldOptionType,
  V extends OperationVariables, // gql query variables
  R, // gql query response
> = PartialFormFieldWrapperProps &
  CommonSelectInputTypes<T> & {
    gql: TypedDocumentNode<R, V>;
    getVariables: (inputValue: string) => V;
    transformResponseToOptions: (data: R) => T[];
  };

export function FormAsyncGqlSelectField<
  T extends FormSelectFieldOptionType,
  V extends OperationVariables, // gql query variables
  R, // gql query response
>({
  children,
  // wrapper field props
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
  // select field specific props
  gql,
  getVariables,
  transformResponseToOptions,
  // overriding is used by inline async field
  overrideOnChange,
  overrideValue,
  // async select field props
  ...otherProps
}: FormAsyncSelectFieldProps<T, V, R> & {
  children?: React.ReactElement;
  overrideValue?: MultiValue<T> | SingleValue<T>;
  overrideOnChange?: (option: MultiValue<T> | SingleValue<T>) => void;
}) {
  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const [searchQuery] = useLazyQuery(gql);

  const onChange = (option: MultiValue<T> | SingleValue<T>) => {
    setValue(option);
    setTouched(true);
  };
  // let's
  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      label={label}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-async-select-field">
        <AsyncSelect
          className={SELECT_CLASS_NAME}
          classNamePrefix={SELECT_CLASS_NAME_PREFIX}
          loadOptions={(inputValue, callback) => {
            searchQuery({
              variables: getVariables(inputValue),
              onCompleted: (newData) => {
                callback(transformResponseToOptions(newData));
              },
            });
          }}
          name={field.name}
          value={overrideValue ?? field.value}
          onChange={overrideOnChange ?? onChange}
          theme={SELECT_THEME}
          {...otherProps}
        />

        {children}
      </div>
    </FormFieldWrapper>
  );
}

// unlike the async select field, we show the options inline below.
// so we're essentially using react select for the select/autocomplete
// but we'll handle presentation of selected fields & removal. The
// motivation is in forms like permissions when managing members it looks
// pretty awful with having all the memberships in the input field especially
// when it's so big. It's not obvious that you can type in there
export function FormInlineAsyncGqlSelectField<
  T extends FormSelectFieldOptionType,
  V extends OperationVariables, // gql query variables
  R, // gql query response
>({
  name,
  renderInlineOption,
  removeActionText,
  ...props
}: FormAsyncSelectFieldProps<T, V, R> & {
  renderInlineOption: (option: T) => React.ReactElement;
  removeActionText: string;
}) {
  const [field, , helpers] = useField<T[]>(name);
  const { setValue, setTouched } = helpers;

  const _onRemove = (optionToRemove: T) => {
    setValue(
      field.value.filter((option) => option.value !== optionToRemove.value),
    );
  };

  return (
    <FormAsyncGqlSelectField
      name={name}
      {...props}
      overrideValue={[]}
      overrideOnChange={(option) => {
        const newOption = (option as MultiValue<T>)[0];
        assertNotNullOrUndefined(newOption);

        setValue([...field.value, newOption]);
        setTouched(true);
      }}
      filterOption={(option, input) => {
        const isAlreadySelected = field.value.find(
          (o: T) => o.value === option.value,
        );
        return !isAlreadySelected || !input;
      }}
    >
      <div className="inline-selected-options">
        {field.value.map((option, index) => (
          <div key={index} className="selected-option-wrapper">
            <span className="selected-option">
              {renderInlineOption(option)}
            </span>
            <span className="selected-option-actions">
              <a onClick={() => _onRemove(option)}>{removeActionText}</a>
            </span>
          </div>
        ))}
      </div>
    </FormAsyncGqlSelectField>
  );
}
