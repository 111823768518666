import _ from 'underscore';
import React, { useState } from 'react';
import classNames from 'classnames';
import h from 'h';

interface FlashHash {
  [key: string]: string;
}

interface BannerHash {
  type: string;
  message: string;
  redirectTo: string;
}

interface BannerProps {
  flash: FlashHash;
  banner: BannerHash;
}

// not ideal that this thing is used for flashes and banners
// we can probably create distinct ones for each
export default function Banner({ flash, banner }: BannerProps) {
  const [isHidden, setIsHidden] = useState(false);

  let key, message, onClick;
  if (flash) {
    key = _.first(_.keys(flash));
    if (typeof key === 'undefined') {
      // it's unforgiving but let's error because it means something
      // is probably wrong
      return h.throwError('we have a flash passed in but nothing to display');
    }
    message = flash[key];
  } else {
    key = banner.type;
    message = banner.message;
    onClick = () => (window.location.href = banner.redirectTo);
  }

  return (
    <div
      id="banner"
      className={classNames(key, {
        hide: isHidden,
        'action-banner': !!onClick,
      })}
      onClick={onClick}
    >
      {message}
      <i
        className="close-btn fas fa-times"
        onClick={(event) => {
          event.stopPropagation();
          setIsHidden(true);
        }}
      ></i>
    </div>
  );
}
