import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

interface WordRotatorProps {
  words: string[];
  speedInMs: number;
}

export default function WordRotator({
  words,
  speedInMs = 2000,
}: WordRotatorProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % words.length);
    }, speedInMs);
    // let's clean it up
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <span className="word-rotator">
      {words.map((word, index) => (
        <span
          key={index}
          className={classNames({ hide: index !== currentIndex })}
        >
          {word}
        </span>
      ))}
    </span>
  );
}
