import { arrayFilterNulls } from 'h';
import _ from 'underscore';
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import { AdminTableLoadingPlaceholder } from 'components/utils/admin/admin_table';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';

import { getUniqueClickedLinks } from 'models/email';
import LoadMore from 'components/utils/load_more';

const i18nScope = 'clubs.admin.memberships.show.emails';
const tableScope = `${i18nScope}.table`;

const GET_ADMIN_CLUB_MEMBERSHIP_EMAILS = gql(`
  query GetAdminClubMembershipEmails($clubId: ID!, $membershipId: ID!, $cursor: String) {
    club(id: $clubId) {
      id
      membership(id: $membershipId) {
        id
        emails(after: $cursor) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              title
              sentAt
              firstOpenAt
              firstClickAt
              clicks
            }
          }
        }
      }
    }
  }
`);

export default function MembershipShowEmailsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = useContext(I18nContext);
  const { id } = useParams();

  const { loading, error, data, fetchMore } = useQuery(
    GET_ADMIN_CLUB_MEMBERSHIP_EMAILS,
    {
      variables: {
        clubId: String(currentClubId),
        membershipId: String(id),
      },
    },
  );

  if (loading)
    return <AdminTableLoadingPlaceholder columnWidths={[40, 10, 10, 40]} />;
  if (error) return <ErrorPage />;

  const sentEmailsConnection = data?.club?.membership?.emails;
  const pageInfo = sentEmailsConnection?.pageInfo;
  const sentEmails = arrayFilterNulls(
    (sentEmailsConnection?.edges ?? []).map((e) => e?.node),
  );

  const sortedSentEmails = _.sortBy(
    sentEmails,
    (email) => email.sentAt,
  ).reverse();

  return (
    <div className="membership-emails-tab">
      {sortedSentEmails.length == 0 ? (
        <p>{i18n.t('none', { scope: i18nScope })}</p>
      ) : (
        <>
          <table className="basic-table emails-table vertical-scroll">
            <thead className="sticky">
              <tr>
                <th></th>
                <th>{i18n.t('heading.did_open', { scope: tableScope })}</th>
                <th>{i18n.t('heading.did_click', { scope: tableScope })}</th>
                <th>
                  {i18n.t('heading.links_clicked', { scope: tableScope })}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedSentEmails.map((email) => (
                <tr key={email.id}>
                  <td>
                    <div className={cn('max-w400', 'min-w200', 'pre-wrap')}>
                      {email.title}
                    </div>
                    <span className="small light-text-color">
                      {Helpers.Utils.formatDateTime(email.sentAt)}
                    </span>
                  </td>
                  <td>{Helpers.Utils.formatDateTime(email.firstOpenAt)}</td>
                  <td>{Helpers.Utils.formatDateTime(email.firstClickAt)}</td>
                  <td>
                    {getUniqueClickedLinks({ clicks: email.clicks }).map(
                      (link, index) => (
                        <div
                          key={index}
                          className={cn('max-w300', 'clip-long-text')}
                        >
                          <a
                            rel="noopener noreferrer nofollow"
                            target="_blank"
                            title={link}
                            href={link}
                          >
                            {link}
                          </a>
                        </div>
                      ),
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <LoadMore
            canLoadMore={pageInfo?.hasNextPage}
            onClick={() =>
              fetchMore({
                variables: {
                  cursor: pageInfo?.endCursor,
                },
              })
            }
          />
        </>
      )}
    </div>
  );
}
