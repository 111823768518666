import React from 'react';
import Helpers from 'helpers/helpers';
import BaseAuthenticateButton from 'components/utils/auth/base_authenticate_button';
import StaticImagesHelper from 'helpers/static_images_helper';

interface FacebookAuthenticateButtonProps {
  buttonText: string;
  redirectPath: string;
  children?: React.ReactNode;
}

export default function FacebookAuthenticateButton({
  children,
  buttonText,
  redirectPath,
}: FacebookAuthenticateButtonProps) {
  const url = Helpers.Routes.getAuthWithFacebook();

  return (
    <BaseAuthenticateButton
      classes="facebook-authenticate-button"
      redirectPath={redirectPath}
      authUrl={url}
    >
      {children}
      <button type="submit">
        <img className="auth-icon" src={StaticImagesHelper.facebookIconUrl()} />
        <span className="auth-text">{buttonText}</span>
      </button>
    </BaseAuthenticateButton>
  );
}
