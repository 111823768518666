import { assertNotNullOrUndefined, optionsForSelectField } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import ClubForm from 'components/super_admin/dashboard/clubs/form';

const i18nScope = 'components.super_admin.dashboard.clubs.edit';

const GET_SUPER_ADMIN_CLUBS_EDIT = gql(`
  query GetSuperAdminClubsEdit($clubId: ID!) {
    googleMapsApiPublicKey
    countries {
      id
      nameWithEmoji
    }
    timeZones {
      id
      nameWithOffset
    }
    clubTypes {
      id
      name
    }
    club(id: $clubId) {
      id
      slugName
      logoUrl
      name
      website
      city
      state
      countryAlpha3
      longitude
      latitude
      timeZone
      isListed
      clubTypeId
    }
  }
`);

export default function SuperAdminClubsEditPage() {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  assertNotNullOrUndefined(id);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(GET_SUPER_ADMIN_CLUBS_EDIT, {
    variables: { clubId: id },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  assertNotNullOrUndefined(data);
  const { googleMapsApiPublicKey, clubTypes, countries, timeZones, club } =
    data;
  assertNotNullOrUndefined(googleMapsApiPublicKey);
  assertNotNullOrUndefined(clubTypes);
  assertNotNullOrUndefined(countries);
  assertNotNullOrUndefined(timeZones);
  assertNotNullOrUndefined(club);

  const clubTypesForSelectField = optionsForSelectField({
    array: clubTypes,
    value: 'id',
    name: 'name',
    shouldSort: false,
  });

  const timeZonesForSelectField = optionsForSelectField({
    array: timeZones,
    value: 'id',
    name: 'nameWithOffset',
    shouldSort: false,
  });

  const countriesForSelectField = optionsForSelectField({
    array: countries,
    value: 'id',
    name: 'nameWithEmoji',
  });

  return (
    <div id="super-admin-dashboard-clubs-edit-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page"
      >
        <ClubForm
          googleMapsApiPublicKey={googleMapsApiPublicKey}
          clubTypesForSelectField={clubTypesForSelectField}
          timeZonesForSelectField={timeZonesForSelectField}
          countriesForSelectField={countriesForSelectField}
          club={club}
          isEditing={true}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
