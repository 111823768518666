import h from 'h';
import {
  ClubId,
  ClubProfileId,
  ClubProfilePageId,
  CompanyId,
  UserId,
  WaiverId,
} from 'types/types';

export default class RoutesHelper {
  static getAuthWithFacebook(): string {
    return '/auth/facebook/';
  }

  static getAuthWithGoogle(): string {
    return '/auth/google_oauth2/';
  }

  static getUsersPath(): string {
    return '/-/users';
  }

  static getUserPath(userId: UserId): string {
    h.assert.isNotNullOrUndefined(userId);
    return `/-/users/${userId}`;
  }

  static getOrdersPath(): string {
    return '/-/orders';
  }

  static getRemoteImagesPath(): string {
    return '/-/remote_images';
  }

  static getStripePaymentMethodsPath(): string {
    return '/-/stripe_payment_methods';
  }

  // --- AUTH ROUTES ---
  static getSessionsPath(): string {
    return '/-/sessions';
  }

  static getPasswordResetsPath(): string {
    return `/-/password_resets`;
  }

  static getPasswordResetPath(token: string): string {
    h.assert.isNotNullOrUndefined(token);
    return `/-/password_resets/${token}`;
  }

  static getUnclaimedUserPath(token: string): string {
    h.assert.isNotNullOrUndefined(token);
    return `/-/unclaimed_users/${token}`;
  }

  static getUnclaimedUserSendEmailPath(): string {
    return '/-/unclaimed_users/send_email';
  }

  // --- CLUB ROUTES ---
  static getClubPath(clubId: ClubId): string {
    h.assert.isNotNullOrUndefined(clubId);
    return `/-/clubs/${clubId}`;
  }

  static getClubClubEventsPath(clubId: ClubId): string {
    h.assert.isNotNullOrUndefined(clubId);
    return `/-/clubs/${clubId}/club_events`;
  }

  static getClubMembershipRenewalCancelationsPath(clubId: ClubId): string {
    h.assert.isNotNullOrUndefined(clubId);
    return `/-/clubs/${clubId}/membership_renewal_cancelations`;
  }

  static getClubMembershipRenewalResumptionsPath(clubId: ClubId): string {
    h.assert.isNotNullOrUndefined(clubId);
    return `/-/clubs/${clubId}/membership_renewal_resumptions`;
  }

  static getClubWaiverSignedWaiversPath(
    clubId: ClubId,
    waiverId: WaiverId,
  ): string {
    h.assert.isNotNullOrUndefined(clubId);
    h.assert.isNotNullOrUndefined(waiverId);
    return `/-/clubs/${clubId}/waivers/${waiverId}/signed_waivers`;
  }

  // --- CLUB ADMIN ROUTES ---
  static getClubAdminClubProfilePath(
    clubId: ClubId,
    clubProfileId: ClubProfileId,
  ): string {
    h.assert.isNotNullOrUndefined(clubId);
    h.assert.isNotNullOrUndefined(clubProfileId);
    return `/-/clubs/${clubId}/admin/club_profiles/${clubProfileId}`;
  }

  static getClubAdminMembershipsPath(clubId: ClubId): string {
    h.assert.isNotNullOrUndefined(clubId);
    return `/-/clubs/${clubId}/admin/memberships`;
  }

  static getClubAdminProfilePagePath(
    clubId: ClubId,
    profilePageId: ClubProfilePageId,
  ): string {
    h.assert.isNotNullOrUndefined(clubId);
    h.assert.isNotNullOrUndefined(profilePageId);
    return `/-/clubs/${clubId}/admin/profile_pages/${profilePageId}`;
  }

  //  -- SUPER ADMIN ROUTES ---
  static getSuperAdminCompaniesPath(): string {
    return '/-/superadmin/companies';
  }
  static getSuperAdminCompanyPath(companyId: CompanyId): string {
    return `/-/superadmin/companies/${companyId}`;
  }

  static getSuperAdminClubsPath(): string {
    return '/-/superadmin/clubs';
  }
  static getSuperAdminClubPath(clubId: ClubId): string {
    return `/-/superadmin/clubs/${clubId}`;
  }
}
