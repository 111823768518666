import React, { useState } from 'react';
import classNames from 'classnames';
import { useEditor, EditorContent } from '@tiptap/react';
import { useField } from 'formik';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import ImageExtension from '@tiptap/extension-image';
import { Small } from 'components/forms/form_content_editable_field/small';

import I18nContext from 'contexts/i18n_context';
import MenuBar from 'components/forms/form_content_editable_field/menu_bar';
import CustomLinkBubbleMenu from 'components/forms/form_content_editable_field/custom_link_bubble_menu';
import { FormFieldwrapperProps } from 'components/forms/form_field_wrapper';
import AddImageModal from 'components/forms/form_content_editable_field/add_image_modal';
import {
  RemoteImageUploadConfig,
  getRemoteImageUploadFn,
} from 'components/utils/remote_image_uploader';
import { dropImagePlugin } from 'components/forms/form_content_editable_field/image_drop_plugin';

// const i18nScope = 'components.forms.form_content_editable_field'

export default function FormContentEditableField({
  value,
  name,
  remoteImageUploadConfig,
  placeholder,
  classes,
  shouldShowMenuBar = true,
}: Omit<FormFieldwrapperProps, 'children'> & {
  value: string;
  remoteImageUploadConfig?: RemoteImageUploadConfig | null;
  placeholder?: string;
  shouldShowMenuBar?: boolean;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [, , helpers] = useField({ name });
  const { setValue, setTouched } = helpers;

  const [shouldShowBubbleMenu, setShouldShowBubbleMenu] = useState(false);
  const [shouldShowUploadModal, setShouldShowUploadModal] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Small,
      Placeholder.configure({ placeholder }),
      Link.configure({
        openOnClick: false,
      }),
      ImageExtension.extend({
        addProseMirrorPlugins: () => {
          if (
            remoteImageUploadConfig === null ||
            typeof remoteImageUploadConfig === 'undefined'
          ) {
            return [];
          } else {
            return [
              dropImagePlugin(
                getRemoteImageUploadFn(
                  remoteImageUploadConfig,
                  i18n.t('forms.errors.unknown_error'),
                ),
              ),
            ];
          }
        },
      }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      setValue(editor.getHTML());
      setTouched(true);
    },
  });

  return (
    <div className={classNames('form-content-editable-field', classes)}>
      {editor && shouldShowMenuBar && (
        <MenuBar
          editor={editor}
          onClickLinkCallback={() =>
            setShouldShowBubbleMenu(!shouldShowBubbleMenu)
          }
          onAddImageCallback={() => setShouldShowUploadModal(true)}
        />
      )}

      {editor && (shouldShowBubbleMenu || editor.isActive('link')) && (
        <CustomLinkBubbleMenu
          editor={editor}
          closeMenuCallback={() => setShouldShowBubbleMenu(false)}
        />
      )}

      {shouldShowUploadModal && editor && remoteImageUploadConfig && (
        <AddImageModal
          editor={editor}
          onCloseCallback={() => setShouldShowUploadModal(false)}
          remoteImageUploadConfig={remoteImageUploadConfig}
        />
      )}

      {/* editor && false && <BubbleMenuBar editor={editor} /> */}
      <EditorContent editor={editor} />
    </div>
  );
}
