import React, { useState } from 'react';
import { DateTimeZone, RawClubEvent } from 'types/types';
import I18nContext from 'contexts/i18n_context';

import EventPreview, {
  EventPreviewLoadingPlaceholder,
} from 'components/utils/events/event_preview';

const i18nScope = 'components.utils.events.list_view';

// only list club events now, but later will list
// races and club events

interface EventsListViewProps {
  currentTimeZone: DateTimeZone;
  rawClubEvents: RawClubEvent[];
  numberOfEventsVisible?: number;
  openLinksInNewTab: boolean;
}

function EventsListPreviewWrapper<T>({
  rawClubEventsToShow,
  renderPreview,
}: {
  rawClubEventsToShow: T[];
  renderPreview: (rawClubEvent: T) => React.ReactElement;
}) {
  return (
    <React.Fragment>
      {rawClubEventsToShow.map((rawClubEvent, index) => (
        <React.Fragment key={index}>
          {renderPreview(rawClubEvent)}
          {index + 1 < rawClubEventsToShow.length && <hr className="hr" />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export function EventsListViewLoadingPlaceholder() {
  const placeholderClubEventsToShow = [1, 2, 3];
  return (
    <div className="events-list-view">
      <EventsListPreviewWrapper
        rawClubEventsToShow={placeholderClubEventsToShow}
        renderPreview={() => <EventPreviewLoadingPlaceholder />}
      />
    </div>
  );
}

export default function EventsListView({
  currentTimeZone,
  rawClubEvents,
  numberOfEventsVisible,
  openLinksInNewTab,
}: EventsListViewProps) {
  const { i18n } = React.useContext(I18nContext);

  // unless specified, we'll show all events
  const [numberOfEventsToShow, setNumberOfEventsToShow] = useState(
    numberOfEventsVisible ?? rawClubEvents.length,
  );

  const rawClubEventsToShow = rawClubEvents.slice(0, numberOfEventsToShow);

  const _onLoadMore = () => setNumberOfEventsToShow(numberOfEventsToShow + 6);

  return (
    <div className="events-list-view">
      <EventsListPreviewWrapper
        rawClubEventsToShow={rawClubEventsToShow}
        renderPreview={(rawClubEvent: RawClubEvent) => (
          <EventPreview
            currentTimeZone={currentTimeZone}
            rawClubEvent={rawClubEvent}
            openInNewTab={openLinksInNewTab}
          />
        )}
      />
      {numberOfEventsToShow < rawClubEvents.length && (
        <div className="load-more">
          <a className="link" onClick={_onLoadMore}>
            {i18n.t('load_more', { scope: i18nScope })}
          </a>
        </div>
      )}
    </div>
  );
}
