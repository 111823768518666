import _ from 'underscore';
import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormUrlField,
  FormFieldWrapper,
  FormServerErrorMessages,
} from 'components/forms/forms';
import ClubLogoUploader from 'components/utils/images/club_logo_uploader';
import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import { UnparsedClub } from 'types/types';
import { AxiosResponse } from 'axios';
import { Club } from '__generated__/graphql';

const i18nScope = 'clubs.admin.settings.form';

// logo: File; TODO: it's a virtual field I guess
type SettingsFormValues = Pick<
  Club,
  'name' | 'shortName' | 'website' | 'replyTo'
>;

export default function SettingsForm({
  club,
  onSuccessCallback,
  onCancelCallback,
}: {
  club: Pick<
    Club,
    'id' | 'name' | 'shortName' | 'website' | 'replyTo' | 'logoUrl'
  >;
  onSuccessCallback: (unparsedClub: UnparsedClub) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const initialValues: SettingsFormValues = _.pick(
    club,
    'logo',
    'name',
    'shortName',
    'website',
    'replyTo',
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, i18n.t('forms.errors.min_length'))
      .required(i18n.t('forms.errors.required')),
    shortName: Yup.string().max(12, i18n.t('forms.errors.max_length')),
    website: Yup.string()
      .url(i18n.t('forms.errors.invalid_url'))
      .required(i18n.t('forms.errors.required')),
    replyTo: Yup.string()
      .trim()
      .required(i18n.t('forms.errors.required'))
      .email(i18n.t('forms.errors.invalid_email')),
  });

  const _onSubmit = function (
    values: SettingsFormValues,
    actions: FormikHelpers<SettingsFormValues>,
  ) {
    Helpers.Form.save({
      actions,
      main: () => {
        const url = Helpers.Routes.getClubPath(Number(club.id));
        const params = { club: values };
        return Helpers.API.put({
          url,
          params,
          options: { shouldUseFormData: true },
        });
      },
      successCallback: (response) => {
        onSuccessCallback?.((response as AxiosResponse<UnparsedClub>).data);
      },
    });
  };

  return (
    <div className="settings-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, setFieldValue, setFieldTouched, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormFieldWrapper name="logo" classes="logo-section">
              <ClubLogoUploader
                name="logo"
                club={club}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </FormFieldWrapper>
            <FormField
              autoFocus={true}
              type="text"
              name="name"
              label={i18n.t('fields.name.label', { scope: i18nScope })}
            />
            <FormField
              autoFocus={true}
              type="text"
              name="shortName"
              label={i18n.t('fields.short_name.label', { scope: i18nScope })}
              description={i18n.t('fields.short_name.description', {
                scope: i18nScope,
              })}
            />
            <FormUrlField
              name="website"
              label={i18n.t('fields.website.label', { scope: i18nScope })}
            />
            <FormField
              type="email"
              name="replyTo"
              label={i18n.t('fields.reply_to.label', { scope: i18nScope })}
              description={i18n.t('fields.reply_to.description', {
                scope: i18nScope,
              })}
            />
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('actions.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                className="secondary"
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
