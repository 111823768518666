import React from 'react';
import classNames from 'classnames';

export enum placeholderType {
  FULL = 'full',
  BAR = 'bar',
}

interface PlaceholderProps {
  type: placeholderType;
  widthPercent?: number | string;
  classes?: string;
  additionalClassName?: string;
}

export function MockPlaceholder(props: PlaceholderProps) {
  return <Placeholder {...props} additionalClassName="mock-placeholder" />;
}

export function LoadingPlaceholder(props: PlaceholderProps) {
  return <Placeholder {...props} additionalClassName="loading-placeholder" />;
}

function Placeholder({
  additionalClassName,
  type,
  classes,
  widthPercent,
}: PlaceholderProps) {
  const style: React.CSSProperties = {};
  if (widthPercent !== null && typeof widthPercent !== 'undefined') {
    style.width = widthPercent;
  }
  return (
    <div
      className={classNames('placeholder', additionalClassName, type, classes)}
      style={style}
    ></div>
  );
}
