import React from 'react';
import classNames from 'classnames';
import I18nContext from 'contexts/i18n_context';
import {
  SupportType,
  getSupportEmailAddress,
} from 'components/utils/contact_support';

const i18nScope = 'components.utils.error_message_view';

export default function ErrorMessageView({
  classes,
  supportType = SupportType.GENERAL,
}: {
  classes?: string;
  supportType?: SupportType;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className={classNames('error-message-view', classes)}>
      <h4 className="error-message-view-heading">
        {i18n.t('heading', { scope: i18nScope })}
      </h4>
      <div
        className="error-message-view-description mt-2"
        dangerouslySetInnerHTML={{
          __html: i18n.t('description_html', {
            scope: i18nScope,
            values: {
              supportEmail: getSupportEmailAddress(supportType),
            },
          }),
        }}
      />
    </div>
  );
}
