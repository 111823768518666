import _str from 'underscore.string';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { BubbleMenu, Editor } from '@tiptap/react';

import Icon, { iconType } from 'components/utils/icon';
import EditorHelper from 'components/forms/form_content_editable_field/editor_helper';

// const i18nScope = 'components.forms.form_content_editable_field'

const MenuAction = ({
  children,
  classes,
  ...props
}: {
  children: React.ReactElement[] | React.ReactElement;
  onClick: () => void;
  classes?: string;
}) => (
  <a className={classNames('menu-action', classes)} {...props}>
    {children}
  </a>
);

export default function BubbleMenuBar({ editor }: { editor: Editor }) {
  const setLink = useCallback(() => {
    const previousUrl = EditorHelper.getCurrentUrl({ editor });
    const url = window.prompt('URL', previousUrl);

    // canceled
    if (url === null) {
      return;
    }

    if (_str.isBlank(url)) {
      EditorHelper.unlink({ editor });
    } else {
      EditorHelper.convertToLink({ editor, url });
    }
  }, [editor]);

  let menu;
  if (editor.isActive('link')) {
    menu = (
      <>
        <MenuAction onClick={() => EditorHelper.unlink({ editor })}>
          <Icon type={iconType.DELETE} />
        </MenuAction>
      </>
    );
  } else {
    menu = (
      <>
        <MenuAction
          onClick={() => editor.chain().focus().toggleBold().run()}
          classes={classNames({ active: editor.isActive('bold') })}
        >
          <Icon type={iconType.BOLD} />
        </MenuAction>
        <MenuAction
          onClick={() => editor.chain().focus().toggleItalic().run()}
          classes={classNames({ active: editor.isActive('bold') })}
        >
          <Icon type={iconType.ITALIC} />
        </MenuAction>
        <MenuAction
          onClick={() => editor.chain().focus().toggleStrike().run()}
          classes={classNames({ active: editor.isActive('strike') })}
        >
          <Icon type={iconType.STRIKETHROUGH} />
        </MenuAction>
        <MenuAction
          onClick={setLink}
          classes={classNames({ active: editor.isActive('link') })}
        >
          <Icon type={iconType.LINK} />
        </MenuAction>
      </>
    );
  }

  return <BubbleMenu editor={editor}>{menu}</BubbleMenu>;
}
