import React from 'react';
import { picSize } from 'components/utils/profile_pic';

export const AUTHOR_PROFILE_PIC_SIZE = picSize.MEDIUM;

interface CommentWrapperProps {
  authorElement: React.ReactElement;
  bodyElement: React.ReactElement;
  onMouseLeaveCallback?: () => void;
  rightActionsElement?: React.ReactElement | null;
  tippyActionsElement?: React.ReactElement | null;
  footerElement?: React.ReactElement | null;
}

export default function CommentWrapper({
  authorElement,
  bodyElement,
  onMouseLeaveCallback,
  rightActionsElement,
  tippyActionsElement,
  footerElement,
}: CommentWrapperProps) {
  return (
    <div className="comment-wrapper" onMouseLeave={onMouseLeaveCallback}>
      <div className="comment">
        <div className="comment-author">{authorElement}</div>
        <div className="comment-body">{bodyElement}</div>
        {rightActionsElement && (
          <div className="comment-right-actions">{rightActionsElement}</div>
        )}
        {tippyActionsElement && (
          <div className="comment-tippy-actions">{tippyActionsElement}</div>
        )}
      </div>
      <div className="comment-footer">{footerElement}</div>
    </div>
  );
}
