import _ from 'underscore';
import React from 'react';

interface FormServerErrorMessagesProps {
  serverErrors: string[];
}

export default function FormServerErrorMessages({
  serverErrors = [],
}: FormServerErrorMessagesProps) {
  if (_.isString(serverErrors)) {
    serverErrors = [serverErrors];
  }
  const errorMessages = serverErrors.map((errorMessage, index) => (
    <div key={index} className="error-message">
      {errorMessage}
    </div>
  ));

  return <div className="form-server-error-messages">{errorMessages}</div>;
}
