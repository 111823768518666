import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import {
  FormActions,
  FormActionButton,
  FormFieldWrapper,
  FormServerErrorMessages,
  FormCheckboxField,
} from 'components/forms/forms';
import BasicModal from 'components/utils/basic_modal';
import Helpers from 'helpers/helpers';
import { tokenizeAndSavePaymentMethodAsync } from 'helpers/stripe_helpers';

import StripeCreditCardFormField from 'components/payments/stripe_credit_card_form_field';
import { UnparsedStripePaymentMethod } from 'types/types';
import I18nContext from 'contexts/i18n_context';
import h from 'h';

const i18nScope = 'views.payments.add_payment_method_modal';

interface AddPaymentMethodModalFormValues {
  stripeElementCard: string;
  isDefault: boolean;
}

interface AddPaymentMethodModalProps {
  onAddCallback: (
    unparsedStripePaymentMethod: UnparsedStripePaymentMethod,
  ) => void;
  onCancelCallback: () => void;
}

export default function AddPaymentMethodModal({
  onAddCallback,
  onCancelCallback,
}: AddPaymentMethodModalProps) {
  const { i18n } = React.useContext(I18nContext);

  const stripe = useStripe();
  const elements = useElements();

  if (stripe === null || elements === null) {
    return h.throwError('stripe or elements are null');
  }

  const initialValues = useMemo(
    () => ({ stripeElementCard: '', isDefault: false }),
    [],
  );

  const _onSubmit = async (
    values: AddPaymentMethodModalFormValues,
    actions: FormikHelpers<AddPaymentMethodModalFormValues>,
  ) => {
    Helpers.Form.resetErrorsAndMarkTouched({ actions });

    const savedPaymentMethod =
      await tokenizeAndSavePaymentMethodAsync<AddPaymentMethodModalFormValues>({
        stripe,
        elements,
        actions,
        clubsumStripePaymentMethodParams: {
          isDefault: values.isDefault,
        },
      });
    if (savedPaymentMethod === null) {
      // there was error tokenizing, abort
      actions.setSubmitting(false);
      return;
    }
    onAddCallback(savedPaymentMethod);
  };

  const onRequestClose = () => {
    onCancelCallback?.();
  };

  return (
    <BasicModal
      className="add-payment-method-form-modal"
      isOpen={true}
      onRequestCloseCallback={onRequestClose}
    >
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <h3 className="capitalize">
              {i18n.t('heading', { scope: i18nScope })}
            </h3>

            <FormFieldWrapper name="stripeElementCard">
              <StripeCreditCardFormField />
            </FormFieldWrapper>

            <FormCheckboxField
              name="isDefault"
              label={i18n.t('form.is_default.label', { scope: i18nScope })}
            />

            <FormActions>
              <FormActionButton
                text={i18n.t('form.actions.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
                isDisabled={!stripe}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </BasicModal>
  );
}
