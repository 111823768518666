import h from 'h';
import React, { useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';

import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';

import { AuthModes } from 'components/login_signup/auth_modes';
import LoginForm from 'components/login_signup/login_form';
import PasswordResetForm from 'components/login_signup/password_reset_form';
import SetPasswordForm, {
  PasswordReset,
} from 'components/login_signup/set_password_form';
import SignupForm from 'components/login_signup/signup_form';
import UnclaimedUserSendEmailForm from 'components/login_signup/unclaimed_user_send_email_form';

// Note this component can be rendered server side when loading the login/signup/... pages
// Or as part of the AuthenticationModal

// Note this component is also used from the ClubSignup components
interface LoginSignupProps {
  initialMode: AuthModes;
  afterAuthenticationUrl: string;
  passwordReset?: PasswordReset;
}
export default function LoginSignupWrapper(props: LoginSignupProps) {
  return (
    <RootAppWrapper>
      <LoginSignup {...props} />
    </RootAppWrapper>
  );
}

export function LoginSignup({
  initialMode,
  passwordReset,
  afterAuthenticationUrl,
}: LoginSignupProps) {
  const { i18n } = React.useContext(I18nContext);

  h.assert.isNotNullOrUndefined(
    afterAuthenticationUrl,
    'must tell us how to handle after authentication',
  );

  const [mode, setMode] = useState(initialMode);

  const _afterAuthenticationCallback = () =>
    Helpers.Utils.redirectTo(afterAuthenticationUrl);

  const formProps = {
    afterAuthenticationUrl,
    afterAuthenticationCallback: _afterAuthenticationCallback,
    setAuthenticationMode: setMode,
  };
  let show;
  switch (mode) {
    case AuthModes.LOGIN:
      show = (
        <div>
          <h1>{i18n.t('auth.login.heading')}</h1>
          <LoginForm {...formProps} />
        </div>
      );
      break;
    case AuthModes.UNCLAIMED_USER_SEND_EMAIL:
      show = (
        <div>
          <h1>{i18n.t('auth.unclaimed_user_send_email.heading')}</h1>
          <UnclaimedUserSendEmailForm {...formProps} />
        </div>
      );
      break;
    case AuthModes.PASSWORD_RESET:
      show = (
        <div>
          <h1>{i18n.t('auth.reset_password.heading')}</h1>
          <PasswordResetForm {...formProps} />
        </div>
      );
      break;
    case AuthModes.SET_PASSWORD:
      if (passwordReset === null || typeof passwordReset === 'undefined') {
        return h.throwError('missing password reset object');
      }

      show = (
        <div>
          <h1>{i18n.t('auth.set_new_password.heading')}</h1>
          <SetPasswordForm
            passwordReset={passwordReset}
            afterSetPasswordCallback={_afterAuthenticationCallback}
          />
        </div>
      );
      break;
    case AuthModes.SIGN_UP:
    default:
      show = (
        <div>
          <h1>{i18n.t('auth.sign_up.heading')}</h1>
          <SignupForm {...formProps} />
        </div>
      );
      break;
  }

  return <div className="login-signup">{show}</div>;
}
