import { EmailClick } from 'types/types';
import _ from 'underscore';

export function getUniqueClickedLinks({
  clicks,
}: {
  clicks: EmailClick[];
}): string[] {
  const links = _.sortBy(clicks, (click) => click.timestamp).map(
    (click) => click.url,
  );
  return _.uniq(links);
}
