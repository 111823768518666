import h, { assertIsTrue, assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import Icon, { iconType } from 'components/utils/icon';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';

type OnClickEditCallback<T> = (item: T) => void;

export type DestroyConfig<T> = {
  destroyCallback: (item: T) => void;
  getConfirmText?: (item: T) => string;
  getCanDestroy?: (item: T) => boolean;
  getTooltipText?: (item: T, canDestroy: boolean) => string;
};

export enum UpdatePositionDirection {
  LOWER,
  HIGHER,
}

export type UpdatePositionConfig<T> = {
  updatePositionCallback: (target: T, adjacent: T) => void;
  getPosition: (t: T) => number;
};

export function AdminTableLoadingPlaceholder({
  columnWidths = [40, 20, 10, 10],
  numRows = 8,
}: {
  columnWidths?: number[];
  numRows?: number;
}) {
  const rows: React.ReactElement[] = [];
  const columns: React.ReactElement[] = [];

  // verify the sum of the column widths is 100
  assertIsTrue(100 === columnWidths.reduce((a, b) => a + b, 0));

  for (let index = 0; index < columnWidths.length; index++) {
    columns.push(
      <td key={index} style={{ width: `${columnWidths[index]}%` }}>
        <LoadingPlaceholder type={placeholderType.BAR} widthPercent="100%" />
      </td>,
    );
  }

  for (let index = 0; index < numRows; index++) {
    rows.push(<tr key={index}>{columns}</tr>);
  }

  return (
    <div className="admin-table">
      <table className="basic-table">
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default function AdminTable<T>({
  items,
  tableHeaderRow,
  emptyTableElement,
  getRowClasses,
  renderItemColumns,
  renderAdditionalItemActions,
  onClickEditCallback,
  destroyConfig,
  updatePositionConfig,
}: {
  items: T[];
  tableHeaderRow?: React.ReactElement;
  emptyTableElement: React.ReactElement;
  getRowClasses?: (t: T) => string;
  renderItemColumns: (t: T) => React.ReactElement | React.ReactElement[];
  renderAdditionalItemActions?: (
    t: T,
  ) => React.ReactElement | React.ReactElement[];
  onClickEditCallback?: OnClickEditCallback<T>;
  destroyConfig?: DestroyConfig<T>;
  updatePositionConfig?: UpdatePositionConfig<T>;
}) {
  const hasRowActions =
    onClickEditCallback || destroyConfig || updatePositionConfig;

  if (items.length === 0) {
    return <div>{emptyTableElement}</div>;
  }

  const _onDestroy = (item: T) => {
    if (destroyConfig === null || typeof destroyConfig === 'undefined') {
      h.throwError('destroyConfig should exist');
      return;
    }

    destroyConfig.destroyCallback(item);
  };

  const _onUpdatePosition = (
    targetItem: T,
    direction: UpdatePositionDirection,
  ) => {
    if (
      updatePositionConfig === null ||
      typeof updatePositionConfig === 'undefined'
    ) {
      h.throwError('updatePositionConfig should exist');
      return;
    }

    // find the adjacent item
    const adjacentItem = _.find(items, (item) => {
      const targetItemPosition = updatePositionConfig.getPosition(targetItem);
      const currentItemPosition = updatePositionConfig.getPosition(item);
      return direction === UpdatePositionDirection.HIGHER
        ? currentItemPosition === targetItemPosition - 1
        : currentItemPosition === targetItemPosition + 1;
    });

    assertNotNullOrUndefined(adjacentItem, 'adjacent item cannot be null');
    updatePositionConfig.updatePositionCallback(targetItem, adjacentItem);
  };

  return (
    <div className="admin-table">
      <table className="basic-table">
        <thead>{tableHeaderRow}</thead>
        <tbody>
          {items.map((item, index) => {
            const canDestroy = destroyConfig?.getCanDestroy?.(item) ?? true;
            const additionalItemAcitons = renderAdditionalItemActions?.(item);

            return (
              <tr key={index} className={getRowClasses?.(item)}>
                {renderItemColumns(item)}

                {(hasRowActions || additionalItemAcitons) && (
                  <td className="right">
                    {additionalItemAcitons}

                    {updatePositionConfig && items.length > 1 && (
                      <>
                        <Icon
                          type={iconType.MOVE_UP}
                          classes="action"
                          isDisabled={index === 0}
                          onClick={() =>
                            _onUpdatePosition(
                              item,
                              UpdatePositionDirection.HIGHER,
                            )
                          }
                        />
                        <Icon
                          type={iconType.MOVE_DOWN}
                          classes="action ml-2"
                          isDisabled={index === items.length - 1}
                          onClick={() =>
                            _onUpdatePosition(
                              item,
                              UpdatePositionDirection.LOWER,
                            )
                          }
                        />
                      </>
                    )}

                    {onClickEditCallback && (
                      <Icon
                        type={iconType.EDIT}
                        classes="action ml-2"
                        onClick={() => onClickEditCallback(item)}
                      />
                    )}

                    {destroyConfig && (
                      <Icon
                        type={iconType.DELETE}
                        classes="action ml-2"
                        confirmationText={destroyConfig.getConfirmText?.(item)}
                        isDisabled={!canDestroy}
                        tooltipText={destroyConfig.getTooltipText?.(
                          item,
                          canDestroy,
                        )}
                        onClick={() => _onDestroy(item)}
                      />
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
