import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RootAppWrapper from 'components/utils/root_app_wrapper';

import {
  FourOFourPage,
  PagesGroup,
  PagesLayout,
} from 'components/utils/pages_sidebar';
import SuperAdminClubsIndexPage from 'components/super_admin/dashboard/clubs';
import SuperAdminCompaniesIndexPage from 'components/super_admin/dashboard/companies';
import SuperAdminCompaniesNewPage from 'components/super_admin/dashboard/companies/new';
import SuperAdminCompaniesEditPage from 'components/super_admin/dashboard/companies/edit';
import SuperAdminClubsNewPage from 'components/super_admin/dashboard/clubs/new';
import SuperAdminClubsEditPage from 'components/super_admin/dashboard/clubs/edit';
import SuperAdminTestsReactSelectPage from 'components/super_admin/tests/react_select';

// NOTE: these should be in sync with the ids in super_admin/sidebar_service.rb
enum SuperAdminDashboardPageId {
  CLUBS = 'clubs',
  COMPANIES = 'companies',
}

export default function SuperAdminDashboard({
  superAdminDashboardPath,
  sidebar,
}: {
  superAdminDashboardPath?: string;
  sidebar?: PagesGroup[];
}) {
  assertNotNullOrUndefined(superAdminDashboardPath);
  assertNotNullOrUndefined(sidebar);

  return (
    <RootAppWrapper>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${superAdminDashboardPath}/*`}
            element={
              <PagesLayout
                basePath={superAdminDashboardPath}
                groups={sidebar}
                getHasPermission={() => true}
              />
            }
          >
            <Route
              index
              element={
                <Navigate to={`${SuperAdminDashboardPageId.CLUBS}`} replace />
              }
            />
            <Route path={`${SuperAdminDashboardPageId.CLUBS}/*`}>
              <Route index element={<SuperAdminClubsIndexPage />} />
              <Route path="new" element={<SuperAdminClubsNewPage />} />
              <Route path=":id/edit" element={<SuperAdminClubsEditPage />} />
            </Route>
            <Route path={`${SuperAdminDashboardPageId.COMPANIES}/*`}>
              <Route index element={<SuperAdminCompaniesIndexPage />} />
              <Route path="new" element={<SuperAdminCompaniesNewPage />} />
              <Route
                path=":id/edit"
                element={<SuperAdminCompaniesEditPage />}
              />
            </Route>
            <Route
              path="tests/reactselect"
              element={<SuperAdminTestsReactSelectPage />}
            />
            <Route path="*" element={<FourOFourPage />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </RootAppWrapper>
  );
}
