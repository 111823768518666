import React from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import Store from 'helpers/store';
import { DeprecatedAny } from 'types/types';

import PurchaseSubscriptionForm from 'components/clubs/subscriptions/purchase_subscription_form';
import StripeWrapper from 'components/utils/stripe_wrapper';

export default function PurchaseSubscription(props: DeprecatedAny) {
  const store = Store.parseInitData(props);

  return (
    <RootAppWrapper>
      <StripeWrapper>
        <PurchaseSubscriptionForm store={store} />
      </StripeWrapper>
    </RootAppWrapper>
  );
}
