import React from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  FourOFourPage,
  PagesGroup,
  PagesLayout,
} from 'components/utils/pages_sidebar';
import UserProfilePage from 'components/users/my_account/user_profile_page';
import UserPreferencesPage from 'components/users/my_account/user_preferences_page';
import UserNotificationsPage from 'components/users/my_account/user_notifications_page';
import MembershipNotificationsPage from 'components/users/my_account/membership_notifications_page';
import { assertNotNullOrUndefined } from 'h';

// NOTE: these should be in sync with the ids in my_account_sidebar_service.rb
enum MyAccountPageId {
  NOTIFICATIONS = 'notifications',
  PROFILE = 'profile',
  PREFERENCES = 'preferences',
}

export default function MyAccount({
  myAccountPath,
  sidebar,
}: {
  myAccountPath: string;
  sidebar: PagesGroup[];
}) {
  assertNotNullOrUndefined(myAccountPath);
  assertNotNullOrUndefined(sidebar);

  return (
    <RootAppWrapper>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${myAccountPath}/*`}
            element={
              <PagesLayout
                basePath={myAccountPath}
                groups={sidebar}
                getHasPermission={() => true}
              />
            }
          >
            <Route
              index
              path={MyAccountPageId.PROFILE}
              element={<UserProfilePage />}
            />
            <Route path={`${MyAccountPageId.NOTIFICATIONS}/*`}>
              <Route index element={<UserNotificationsPage />} />
              <Route
                path=":clubSlugName"
                element={<MembershipNotificationsPage />}
              />
            </Route>
            <Route
              path={MyAccountPageId.PREFERENCES}
              element={<UserPreferencesPage />}
            />
            <Route path="*" element={<FourOFourPage />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </RootAppWrapper>
  );
}
