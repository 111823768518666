import React from 'react';
import classNames from 'classnames';

import TippyMenu from 'components/utils/tippy_menu';
import Icon, { iconType } from 'components/utils/icon';
import I18nContext from 'contexts/i18n_context';
import { Editor } from '@tiptap/react';

const i18nScope = 'components.forms.form_content_editable_field.menu_bar';

const MenuAction = ({
  children,
  classes,
  ...props
}: {
  children: React.ReactElement | React.ReactElement[];
  classes?: string;
  onClick?: () => void;
}) => (
  <a className={classNames('menu-action', classes)} {...props}>
    {children}
  </a>
);

const MenuGroupDivider = () => <div className="menu-group-divider"></div>;
const MenuGroup = ({
  children,
}: {
  children: React.ReactElement[] | React.ReactElement;
}) => <div className="menu-group">{children}</div>;

const TextStyleMenuAction = ({
  optionIcon,
  optionText,
  classes,
  onClick,
}: {
  optionIcon: React.ReactElement | string;
  optionText: string;
  classes: string;
  onClick: () => void;
}) => (
  <MenuAction onClick={onClick} classes={classes}>
    <span className="option-icon">{optionIcon}</span>
    <span>{optionText}</span>
  </MenuAction>
);

const TextStyleHeaderMenuAction = ({
  editor,
  headerLevel,
}: {
  editor: Editor;
  headerLevel: 1 | 2 | 3 | 4 | 5 | 6;
}) => {
  const { i18n } = React.useContext(I18nContext);
  return (
    <TextStyleMenuAction
      onClick={() =>
        editor.chain().focus().toggleHeading({ level: headerLevel }).run()
      }
      classes={classNames({
        active: editor.isActive('heading', { level: headerLevel }),
      })}
      optionIcon={`h${headerLevel}`}
      optionText={i18n.t(`heading${headerLevel}`, { scope: i18nScope })}
    />
  );
};
const TextStyleDropdown = ({ editor }: { editor: Editor }) => {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="text-style-dropdown">
      <TextStyleMenuAction
        onClick={() => editor.chain().focus().setParagraph().run()}
        classes={classNames({ active: editor.isActive('paragraph') })}
        optionIcon={<Icon type={iconType.PARAGRAPH} />}
        optionText={i18n.t('paragraph', { scope: i18nScope })}
      />
      <TextStyleHeaderMenuAction editor={editor} headerLevel={1} />
      <TextStyleHeaderMenuAction editor={editor} headerLevel={2} />
      <TextStyleHeaderMenuAction editor={editor} headerLevel={3} />
      <TextStyleHeaderMenuAction editor={editor} headerLevel={4} />
      <TextStyleHeaderMenuAction editor={editor} headerLevel={5} />
      <TextStyleHeaderMenuAction editor={editor} headerLevel={6} />
      <TextStyleMenuAction
        onClick={() => editor.chain().focus().toggleSmall().run()}
        classes={classNames({ active: editor.isActive('small') })}
        optionIcon={'S'}
        optionText={i18n.t('small', { scope: i18nScope })}
      />
    </div>
  );
};

export default function MenuBar({
  editor,
  onClickLinkCallback,
  onAddImageCallback,
}: {
  editor: Editor;
  onClickLinkCallback: () => void;
  onAddImageCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  return (
    <div className="content-editable-menu-bar">
      <MenuGroup>
        <MenuAction
          onClick={() => editor.chain().focus().toggleBold().run()}
          classes={classNames({ active: editor.isActive('bold') })}
        >
          <Icon type={iconType.BOLD} />
        </MenuAction>
        <MenuAction
          onClick={() => editor.chain().focus().toggleItalic().run()}
          classes={classNames({ active: editor.isActive('italic') })}
        >
          <Icon type={iconType.ITALIC} />
        </MenuAction>
        <MenuAction
          onClick={() => editor.chain().focus().toggleStrike().run()}
          classes={classNames({ active: editor.isActive('strike') })}
        >
          <Icon type={iconType.STRIKETHROUGH} />
        </MenuAction>

        <MenuAction
          onClick={onClickLinkCallback}
          classes={classNames({ active: editor.isActive('link') })}
        >
          <Icon type={iconType.LINK} />
        </MenuAction>

        <MenuAction
          onClick={() =>
            editor.chain().focus().unsetAllMarks().clearNodes().run()
          }
        >
          <Icon type={iconType.REMOVE_FORMATTING} />
        </MenuAction>
      </MenuGroup>

      <MenuGroupDivider />

      <MenuGroup>
        <TippyMenu
          trigger="click"
          classes="text-style-tippy-menu"
          offset={[0, 0]}
          placement="top-start"
          content={<TextStyleDropdown editor={editor} />}
        >
          <span className="faux-menu-action">
            <span className="mr-2">
              {i18n.t('text_style_dropdown', { scope: i18nScope })}
            </span>
            <Icon type={iconType.CARET_DOWN} />
          </span>
        </TippyMenu>
      </MenuGroup>

      <MenuGroupDivider />

      <MenuGroup>
        <MenuAction
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          classes={classNames({ active: editor.isActive('bulletList') })}
        >
          <Icon type={iconType.LIST_UL} />
        </MenuAction>
        <MenuAction
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          classes={classNames({ active: editor.isActive('orderedList') })}
        >
          <Icon type={iconType.LIST_OL} />
        </MenuAction>
      </MenuGroup>

      <MenuGroupDivider />

      <MenuGroup>
        <MenuAction onClick={onAddImageCallback}>
          <Icon type={iconType.IMAGE} />
        </MenuAction>

        <MenuAction
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          classes={classNames({ active: editor.isActive('blockquote') })}
        >
          <Icon type={iconType.QUOTE} />
        </MenuAction>
        <MenuAction
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <Icon type={iconType.DIVIDER} />
        </MenuAction>
        <MenuAction onClick={() => editor.chain().focus().setHardBreak().run()}>
          <Icon type={iconType.NEW_LINE} />
        </MenuAction>
      </MenuGroup>

      <MenuGroupDivider />

      <MenuGroup>
        <MenuAction onClick={() => editor.chain().focus().undo().run()}>
          <Icon type={iconType.UNDO} />
        </MenuAction>
        <MenuAction onClick={() => editor.chain().focus().redo().run()}>
          <Icon type={iconType.REDO} />
        </MenuAction>
      </MenuGroup>
    </div>
  );
}
