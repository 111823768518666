import React from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  AdminClubPermissionGroupManageMembershipsMutation,
  Club,
  ClubPermissionGroup,
} from '__generated__/graphql';
import { Formik, Form, FormikHelpers } from 'formik';

import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
} from 'components/forms/forms';
import FormInlineClubMembershipsSelectField, {
  convertMembershipToMembershipOption,
  ClubMembershipOptionType,
  ClubMembershipForSelectField,
} from 'components/forms/form_inline_club_memberships_select_field';

import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'clubs.admin.permission_groups.manage_form';

type PermissionGroupForForm = {
  memberships: ClubMembershipForSelectField[];
};

type ManagePermissionGroupFormValues = {
  membershipOptions: ClubMembershipOptionType[];
};

const MEMBERSHIP_OPTIONS_FIELD_NAME = 'membershipOptions';

const ADMIN_CLUB_PERMISSION_GROUP_MANAGE_MEMBERSHIPS = gql(`
  mutation AdminClubPermissionGroupManageMemberships($input: ClubPermissionGroupManageMembershipsInput!) {
    clubPermissionGroupManageMemberships(input: $input) {
      permissionGroup {
        id
        name
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function PermissionGroupManageMembershipsForm({
  clubId,
  permissionGroup,
  onSuccessCallback,
  onCancelCallback,
}: {
  clubId: Club['id'];
  permissionGroup: PermissionGroupForForm & Pick<ClubPermissionGroup, 'id'>;
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [updateMutation] = useMutation(
    ADMIN_CLUB_PERMISSION_GROUP_MANAGE_MEMBERSHIPS,
  );

  const _onSubmit = function (
    values: ManagePermissionGroupFormValues,
    actions: FormikHelpers<ManagePermissionGroupFormValues>,
  ) {
    sendMutationAndUpdateForm<
      ManagePermissionGroupFormValues,
      AdminClubPermissionGroupManageMembershipsMutation
    >({
      actions,
      mutationName: 'clubPermissionGroupManageMemberships',
      main: () =>
        updateMutation({
          variables: {
            input: {
              id: permissionGroup.id,
              membershipIds: values[MEMBERSHIP_OPTIONS_FIELD_NAME].map(
                (membershipOption) => membershipOption.value,
              ),
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  const initialValues = {
    [MEMBERSHIP_OPTIONS_FIELD_NAME]: permissionGroup.memberships.map(
      convertMembershipToMembershipOption,
    ),
  };

  return (
    <div className="permission-group-manage-memberships-form">
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {({ status, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormInlineClubMembershipsSelectField
              clubId={clubId}
              name={MEMBERSHIP_OPTIONS_FIELD_NAME}
            />
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('actions.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
