export default class StaticImagesHelper {
  static googleIconUrl(): string {
    return this.cdnHost('/icons/google.svg');
  }

  static facebookIconUrl(): string {
    return this.cdnHost('/icons/facebook.svg');
  }

  static discordIconUrl(): string {
    return this.cdnHost('/third-party-logos/discord-logo.png');
  }

  static slackIconUrl(): string {
    return this.cdnHost('/third-party-logos/slack-logo.png');
  }

  static cdnHost(path: string): string {
    return 'https://cdn-static-assets.clubsum.com' + path;
  }
}
