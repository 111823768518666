import { arrayFilterNulls } from 'h';
import React from 'react';
import { gql } from '__generated__/gql';
import {
  AdminFormSearchClubMembershipsSelectFieldQuery,
  AdminFormSearchClubMembershipsSelectFieldQueryVariables,
  Club,
  ClubMembership,
} from '__generated__/graphql';

import {
  FormInlineAsyncGqlSelectField,
  FormSelectFieldStringOptionType,
} from 'components/forms/form_select_field';
import { MembershipProfilePic, picSize } from 'components/utils/profile_pic';
import { OptionProps, components } from 'react-select';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'forms.inline_club_memberships_select_field';

export type ClubMembershipForSelectField = Pick<
  ClubMembership,
  'id' | 'fullName' | 'profilePicUrl' | 'emailAddress'
>;

export type ClubMembershipOptionType = FormSelectFieldStringOptionType & {
  object: ClubMembershipForSelectField;
};

const ADMIN_FORM_SEARCH_CLUB_MEMBERSHIPS_SELECT_FIELD = gql(`
  query AdminFormSearchClubMembershipsSelectField($clubId: ID!, $search: String) {
    club(id: $clubId) {
      id
      memberships(search: $search) {
        totalCount
        edges {
          node {
            id
            fullName
            profilePicUrl
            emailAddress
          }
        }
      }
    }
  }
`);

export function convertMembershipToMembershipOption(
  membership: ClubMembershipForSelectField,
): ClubMembershipOptionType {
  return {
    label: membership.fullName,
    value: membership.id,
    object: membership,
  };
}

function MembershipOption({
  membership,
}: {
  membership: ClubMembershipForSelectField;
}) {
  return (
    <div className="membership-option">
      <MembershipProfilePic membership={membership} size={picSize.MEDIUM} />
      <div className="membership-info">
        <div>{membership.fullName}</div>
        <div className="small light-text-color">{membership.emailAddress}</div>
      </div>
    </div>
  );
}

// SELECT FIELD COMPONENT OVERRIDES
const Option = (props: OptionProps<ClubMembershipOptionType>) => {
  return (
    <components.Option {...props}>
      <MembershipOption membership={props.data.object} />
    </components.Option>
  );
};

export default function FormInlineClubMembershipsSelectField({
  clubId,
  name,
}: {
  clubId: Club['id'];
  name: string;
}) {
  const { i18n } = React.useContext(I18nContext);

  return (
    <span className="form-inline-club-memberships-select-field">
      <FormInlineAsyncGqlSelectField<
        ClubMembershipOptionType,
        AdminFormSearchClubMembershipsSelectFieldQueryVariables,
        AdminFormSearchClubMembershipsSelectFieldQuery
      >
        isMulti={true}
        isClearable={false}
        name={name}
        components={{
          Option,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        placeholder={i18n.t('placeholder', { scope: i18nScope })}
        gql={ADMIN_FORM_SEARCH_CLUB_MEMBERSHIPS_SELECT_FIELD}
        getVariables={(inputValue) => ({
          clubId,
          search: inputValue,
        })}
        transformResponseToOptions={(data) => {
          const membershipsConnection = data?.club?.memberships;
          const memberships = arrayFilterNulls(
            (membershipsConnection?.edges ?? []).map((e) => e?.node),
          );
          return memberships.map(convertMembershipToMembershipOption);
        }}
        noOptionsMessage={() => i18n.t('no_suggestions', { scope: i18nScope })}
        renderInlineOption={(option) => (
          <MembershipOption membership={option.object} />
        )}
        removeActionText={i18n.t('remove', { scope: i18nScope })}
      />
    </span>
  );
}
