import React, { useCallback, useMemo, useState } from 'react';
import { Editor } from '@tiptap/react';
import BasicModal from 'components/utils/basic_modal';
import ModalSidebar, { Page } from 'components/utils/modal_sidebar';
import I18nContext from 'contexts/i18n_context';
import { UnparsedRemoteImage } from 'types/types';
import RemoteImageUploader, {
  RemoteImageUploadConfig,
} from 'components/utils/remote_image_uploader';
import Icon, { iconType } from 'components/utils/icon';
import h from 'h';

// Search web page will be powered by unsplash https://unsplash.com/developers
// We should add a giphy search page  https://developers.giphy.com/docs/sdk#web

const i18nScope =
  'components.forms.form_content_editable_field.add_image_modal';

enum PageId {
  UPLOAD = 'upload',
  SEARCH_WEB = 'search_web',
}

function UploadImagePage({
  remoteImageUploadConfig,
  setImageAndCloseModalCallback,
}: {
  remoteImageUploadConfig: RemoteImageUploadConfig;
  setImageAndCloseModalCallback: (imageUrl: string) => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="upload-image-page">
      <h1>{i18n.t('upload_page.title', { scope: i18nScope })}</h1>

      <RemoteImageUploader
        remoteImageUploadConfig={remoteImageUploadConfig}
        name="newImageId"
        onChangeCallback={(unparsedRemoteImage: UnparsedRemoteImage) => {
          setImageAndCloseModalCallback(
            unparsedRemoteImage.data.attributes.imageUrl,
          );
        }}
        cropModalSubmit={i18n.t('upload_page.crop_modal_submit', {
          scope: i18nScope,
        })}
        cropModalTitle={i18n.t('upload_page.crop_modal_title', {
          scope: i18nScope,
        })}
        aspectRatio={16 / 9}
        errorMessage={i18n.t('forms.errors.unknown_error')}
      >
        <div className="upload-image-wrapper full vertical-center center cursor mt-2">
          <div>
            <Icon type={iconType.FILE_IMAGE} />
            <h5 className="my-1">
              {i18n.t('upload_page.description.primary', { scope: i18nScope })}
            </h5>
            <div>
              {i18n.t('upload_page.description.secondary', {
                scope: i18nScope,
              })}
            </div>
          </div>
        </div>
      </RemoteImageUploader>
    </div>
  );
}

function SearchWebPage() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="search-web-page full vertical-center center">
      <h3>{i18n.t('search_web_page.coming_soon', { scope: i18nScope })}</h3>
    </div>
  );
}

function AddImageModalPage({
  selectedPage,
  remoteImageUploadConfig,
  setImageAndCloseModalCallback,
}: {
  selectedPage: Page<PageId>;
  remoteImageUploadConfig: RemoteImageUploadConfig;
  setImageAndCloseModalCallback: (imageUrl: string) => void;
}) {
  const pageAttributes = {
    remoteImageUploadConfig,
    setImageAndCloseModalCallback,
  };

  switch (selectedPage.id) {
    case PageId.UPLOAD:
      return <UploadImagePage {...pageAttributes} />;
    case PageId.SEARCH_WEB:
      return <SearchWebPage />;
    default:
      return h.throwExhaustiveError(
        "don't know how to render page",
        selectedPage?.id,
      );
  }
}

export default function AddImageModal({
  editor,
  remoteImageUploadConfig,
  onCloseCallback,
}: {
  editor: Editor;
  remoteImageUploadConfig: RemoteImageUploadConfig;
  onCloseCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const pages = useMemo(
    () => [
      {
        id: PageId.UPLOAD,
        displayTextOrElement: (
          <span>
            <Icon type={iconType.ATTACH} classes="mr-1" />
            {i18n.t('sidebar.upload', { scope: i18nScope })}
          </span>
        ),
      },
      {
        id: PageId.SEARCH_WEB,
        displayTextOrElement: (
          <span>
            <Icon type={iconType.SEARCH} classes="mr-1" />
            {i18n.t('sidebar.search_web', { scope: i18nScope })}
          </span>
        ),
      },
    ],
    [],
  );

  const [selectedPage, setSelectedPage] = useState(pages[0]);

  const setImageAndCloseModalCallback = useCallback(
    (imageUrl: string) => {
      editor
        .chain()
        .focus()
        .setImage({
          src: imageUrl,
        })
        .run();
      onCloseCallback();
    },
    [onCloseCallback],
  );

  return (
    <BasicModal
      className="basic-modal-content basic-form form-content-editable-field-add-image-modal"
      onRequestCloseCallback={onCloseCallback}
      isOpen={true}
    >
      <ModalSidebar
        pages={pages}
        selectedPage={selectedPage}
        onSelectPageCallback={(page) => setSelectedPage(page)}
      >
        <AddImageModalPage
          selectedPage={selectedPage}
          remoteImageUploadConfig={remoteImageUploadConfig}
          setImageAndCloseModalCallback={setImageAndCloseModalCallback}
        />
      </ModalSidebar>
    </BasicModal>
  );
}
