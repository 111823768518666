import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { HelpArticleSlugEnum } from '__generated__/graphql';
import BasicModal from 'components/utils/basic_modal';
import { ContentEditablePresenter } from 'components/utils/content_editable_presenter';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';
import ErrorMessageView from 'components/utils/error_message_view';

const GET_HELP_ARTICLE = gql(`
  query GetHelpArticle($slug: HelpArticleSlugEnum!) {
    helpArticle(slug: $slug) {
      slug
      title
      bodyHtml
    }
  }
`);

function HelpArticleLoadingPlaceholder({
  style,
}: {
  style?: React.CSSProperties;
}) {
  return (
    <div className="help-article-loading-placeholder" style={style}>
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="py-2"
        widthPercent="60%"
      />
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="mt-2"
        widthPercent="90%"
      />
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="mt-2"
        widthPercent="95%"
      />
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="mt-2"
        widthPercent="90%"
      />
      <LoadingPlaceholder
        type={placeholderType.BAR}
        classes="mt-2"
        widthPercent="93%"
      />
    </div>
  );
}

function HelpArticleError({ style }: { style?: React.CSSProperties }) {
  return (
    <div className="help-article-error vertical-center center" style={style}>
      <ErrorMessageView />
    </div>
  );
}

export function HelpArticleModalPresenter({
  slug,
  onRequestCloseCallback,
}: {
  slug: HelpArticleSlugEnum;
  onRequestCloseCallback: () => void;
}) {
  return (
    <BasicModal
      className="basic-modal-content help-article-modal-presenter"
      onRequestCloseCallback={onRequestCloseCallback}
      shouldCloseOnOverlay={true}
      isOpen={true}
      style={{ content: { maxWidth: 650 } }}
    >
      <div>
        <HelpArticlePresenter slug={slug} style={{ minHeight: 300 }} />
      </div>
    </BasicModal>
  );
}

export default function HelpArticlePresenter({
  slug,
  style,
}: {
  slug: HelpArticleSlugEnum;
  style?: React.CSSProperties;
}) {
  const { loading, error, data } = useQuery(GET_HELP_ARTICLE, {
    variables: {
      slug,
    },
  });

  if (loading) return <HelpArticleLoadingPlaceholder style={style} />;
  if (error) return <HelpArticleError style={style} />;

  const helpArticle = data?.helpArticle;
  assertNotNullOrUndefined(helpArticle);

  return (
    <div className="help-article-loader">
      <h2>{helpArticle.title}</h2>

      <ContentEditablePresenter content={helpArticle.bodyHtml} />
    </div>
  );
}
