import React from 'react';

export default function CollectionWrapper<T>({
  items,
  emptyElement,
  renderItem,
}: {
  items: T[];
  emptyElement: React.ReactElement;
  renderItem: (t: T, index: number) => React.ReactElement;
}) {
  if (items.length === 0) {
    return <div>{emptyElement}</div>;
  }

  return (
    <div className="collection-wrapper">
      {items.map((item, index) => (
        <span key={index}>{renderItem(item, index)}</span>
      ))}
    </div>
  );
}
