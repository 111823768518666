import React from 'react';
import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import Icon, { iconType } from 'components/utils/icon';

const i18nScope = 'components.utils.code_block';

export function CodeBlock({ codeText }: { codeText: string }) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="code-block">
      <div className="code-block-text">{codeText}</div>
      <span className="code-block-actions">
        <Icon
          type={iconType.COPY}
          tooltipText={i18n.t('actions.copy', { scope: i18nScope })}
          onClick={() => Helpers.Utils.copyStringToClipboard(codeText)}
        />
      </span>
    </div>
  );
}
