import _str from 'underscore.string';

type FirstAndLastNameResponse = [null, null] | [string, string];

export function getFirstAndLastNameFromFullName(
  fullName: string,
): FirstAndLastNameResponse {
  if (_str.isBlank(fullName)) {
    return [null, null];
  }

  const [firstName, ...lastNames] = fullName.trim().split(' ');
  const trimmedFirstName = firstName.trim();
  const trimmedLastName = lastNames.join(' ').trim();

  return [trimmedFirstName, trimmedLastName];
}
