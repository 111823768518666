import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ClubEvent,
  ClubEventDiscussion,
  ClubEventDiscussionCommentCreateMutation,
  ClubMembership,
} from '__generated__/graphql';
import { MembershipProfilePic } from 'components/utils/profile_pic';
import DiscussionThreads, {
  Comment,
  DestroyCommentCallback,
  LoadingPlaceholderDiscussionThreads,
} from 'components/utils/discussions/discussion_threads';
import {
  AddCommentCallback,
  CommentFormValues,
} from 'components/utils/discussions/add_comment';
import {
  sendMutation,
  sendMutationAndUpdateForm,
} from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';

const GET_CLUB_EVENT_DISCUSSION_AND_COMMENTS = gql(`
  query GetClubEventDiscussionAndComments($eventId: ID!) {
    clubEvent(id: $eventId) {
      id
      discussion {
        id
        numberOfComments
        comments {
          id
          body
          createdAt
          author {
            id
            fullName
            profilePicUrl
          }
        }
      }
    }
  }
`);

const CLUB_EVENT_DISCUSSION_COMMENT_CREATE = gql(`
  mutation ClubEventDiscussionCommentCreate($input: ClubEventDiscussionCommentCreateInput!) {
    clubEventDiscussionCommentCreate(input: $input) {
      comment {
        id
        body
        createdAt
        author {
          id
          fullName
          profilePicUrl
        }
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

const CLUB_EVENT_DISCUSSION_COMMENT_DESTROY = gql(`
  mutation ClubEventDiscussionCommentDestroy($input: ClubEventDiscussionCommentDestroyInput!) {
    clubEventDiscussionCommentDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function ClubEventDiscussionThread({
  clubEventId,
  discussion,
  currentMembership,
  canComment,
}: {
  clubEventId: ClubEvent['id'];
  discussion: Pick<ClubEventDiscussion, 'id' | 'numberOfComments'>;
  canComment: boolean;
  currentMembership: Pick<
    ClubMembership,
    'id' | 'fullName' | 'profilePicUrl'
  > | null;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [createMutation] = useMutation(CLUB_EVENT_DISCUSSION_COMMENT_CREATE);
  const [destroyMutation] = useMutation(CLUB_EVENT_DISCUSSION_COMMENT_DESTROY);

  const { loading, error, data } = useQuery(
    GET_CLUB_EVENT_DISCUSSION_AND_COMMENTS,
    {
      variables: { eventId: clubEventId },
    },
  );

  if (loading || error) {
    return <LoadingPlaceholderDiscussionThreads discussion={discussion} />;
  }

  const addCommentCallback: AddCommentCallback<Comment> = ({
    values,
    actions,
    onSuccessCallback,
  }) => {
    sendMutationAndUpdateForm<
      CommentFormValues,
      ClubEventDiscussionCommentCreateMutation
    >({
      actions,
      mutationName: 'clubEventDiscussionCommentCreate',
      main: () =>
        createMutation({
          variables: {
            input: {
              discussionId: discussion.id,
              ...values,
            },
          },
          update: (cache, result) => {
            const newComment =
              result.data?.clubEventDiscussionCommentCreate?.comment;
            assertNotNullOrUndefined(newComment);
            // Retrieve the existing event data from the cache
            const existingData = cache.readQuery({
              query: GET_CLUB_EVENT_DISCUSSION_AND_COMMENTS,
              variables: { eventId: clubEventId },
            });

            assertNotNullOrUndefined(
              existingData?.clubEvent?.discussion?.comments,
            );
            // Add the new comment to the existing comments array
            const updatedComments = [
              newComment,
              ...existingData.clubEvent.discussion.comments,
            ];

            // Write the updated data back to the cache
            cache.writeQuery({
              query: GET_CLUB_EVENT_DISCUSSION_AND_COMMENTS,
              variables: { eventId: clubEventId },
              data: {
                clubEvent: {
                  ...existingData?.clubEvent,
                  discussion: {
                    ...existingData?.clubEvent?.discussion,
                    comments: updatedComments,
                    __typename: 'ClubEventDiscussion',
                  },
                },
              },
            });
          },
        }),
      successCallback: (mutationPayload) => {
        const comment =
          mutationPayload?.clubEventDiscussionCommentCreate?.comment;
        assertNotNullOrUndefined(comment);
        onSuccessCallback(comment);
      },
    });
  };

  const destroyCommentCallback: DestroyCommentCallback = (comment) => {
    // optimistic remove, and then we add it back in if it fails
    sendMutation({
      mutationName: 'clubEventDiscussionCommentDestroy',
      main: () =>
        destroyMutation({
          variables: { input: { id: comment.id } },
          // // TODO: the update function runs but the component
          // // does not re-render so there is no optimistic update
          // optimisticResponse: {
          //   clubEventDiscussionCommentDestroy: {
          //     deletedId: comment.id,
          //     errors: [],
          //   },
          // },
          update: (cache, result, options) => {
            const deletedId = options?.variables?.input?.id;
            assertNotNullOrUndefined(deletedId);

            const idToRemove = cache.identify({
              __typename: 'ClubEventComment',
              id: deletedId,
            });

            cache.evict({ id: idToRemove });
            cache.gc();
          },
        }),
      allErrorsCallback: () => {
        alert(i18n.t('forms.errors.unknown_error'));
      },
    });
  };

  const comments = data?.clubEvent?.discussion?.comments;
  assertNotNullOrUndefined(comments);

  return (
    <DiscussionThreads
      currentAuthor={currentMembership}
      getAuthorProfilePicCallback={(author, size) => (
        <MembershipProfilePic membership={author} size={size} />
      )}
      canComment={canComment}
      comments={comments}
      addCommentCallback={addCommentCallback}
      destroyCommentCallback={destroyCommentCallback}
    />
  );
}
