import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAdminClubProductsQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import { sendMutation } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminTable, {
  UpdatePositionConfig,
} from 'components/utils/admin/admin_table';
import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';

const i18nScope = 'clubs.admin.storefront.index';

type Club = NonNullable<GetAdminClubProductsQuery['club']>;
type Storefront = NonNullable<Club['storefront']>;
type Product = Storefront['products'][number];

const GET_ADMIN_CLUB_PRODUCTS = gql(`
  query GetAdminClubProducts($clubId: ID!) {
    club(id: $clubId) {
      id
      storefront {
        id
        products {
          id
          name
          priceInCents
          isPubliclyVisible
          position
        }
      }
    }
  }
`);

const ADMIN_CLUB_PRODUCT_SWAP_POSITION = gql(`
  mutation AdminClubProductSwapPosition($input: ClubStorefrontProductSwapPositionInput!) {
    clubStorefrontProductSwapPosition(input: $input) {
      product1 {
        id
        position
      }
      product2 {
        id
        position
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function StorefrontPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [swapPositionMutation] = useMutation(ADMIN_CLUB_PRODUCT_SWAP_POSITION);

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_PRODUCTS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading || data === null)
    return <AdminDashboardLoadingPlaceholder columnWidths={[60, 10, 20, 10]} />;
  if (error) return <ErrorPage />;
  const storefront = data?.club?.storefront;
  assertNotNullOrUndefined(storefront);
  const products = storefront.products;
  const sortedProducts = _.sortBy(products, (product) => product.position);

  const updatePositionConfig: UpdatePositionConfig<Product> = {
    getPosition: (product) => product.position ?? Infinity,
    updatePositionCallback: (target, adjacent) => {
      sendMutation({
        mutationName: 'clubStorefrontProductSwapPosition',
        main: () =>
          swapPositionMutation({
            variables: {
              input: {
                product1Id: target.id,
                product2Id: adjacent.id,
              },
            },
            // optimistic update for speedy UI
            optimisticResponse: {
              clubStorefrontProductSwapPosition: {
                product1: {
                  id: target.id,
                  __typename: 'ClubProduct',
                  position: adjacent.position,
                },
                product2: {
                  id: adjacent.id,
                  __typename: 'ClubProduct',
                  position: target.position,
                },
                errors: [],
              },
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <div id="clubs-admin-storefront-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_product_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
        contentClasses="elevate-content min-height-page"
      >
        <AdminTable
          items={sortedProducts}
          emptyTableElement={
            <p>{i18n.t('empty_dashboard', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('table.heading.name', { scope: i18nScope })}</th>
              <th>{i18n.t('table.heading.price', { scope: i18nScope })}</th>
              <th>{i18n.t('table.heading.visiblity', { scope: i18nScope })}</th>
              <th>{i18n.t('table.heading.actions', { scope: i18nScope })}</th>
            </tr>
          }
          renderItemColumns={(product) => {
            const visiblityTranslationKey = product.isPubliclyVisible
              ? 'table.body.visiblity.public'
              : 'table.body.visiblity.hidden';

            return (
              <>
                <td>{product.name}</td>
                <td>{i18n.c({ amountInCents: product.priceInCents })}</td>
                <td>{i18n.t(visiblityTranslationKey, { scope: i18nScope })}</td>
              </>
            );
          }}
          onClickEditCallback={(product) => navigate(`${product.id}/edit`)}
          updatePositionConfig={updatePositionConfig}
        />
      </AdminDashboard>
    </div>
  );
}
