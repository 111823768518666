import _str from 'underscore.string';
import React, { useState, useEffect } from 'react';
import { isNodeSelection, posToDOMRect } from '@tiptap/core';

import TippyMenu from 'components/utils/tippy_menu';
import LinkForm from 'components/forms/form_content_editable_field/link_form';
import EditorHelper from 'components/forms/form_content_editable_field/editor_helper';
import I18nContext from 'contexts/i18n_context';
import { Editor } from '@tiptap/react';

const i18nScope = 'components.forms.form_content_editable_field';

function LinkTippy({
  editor,
  closeMenuCallback,
}: {
  editor: Editor;
  closeMenuCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const url = EditorHelper.getCurrentUrl({ editor });
  const getShouldShowForm = () =>
    _str.isBlank(EditorHelper.getCurrentUrl({ editor }));
  const [shouldShowForm, setShouldShowForm] = useState(getShouldShowForm());

  useEffect(() => {
    const onSelectionUpdate = () => setShouldShowForm(getShouldShowForm());
    editor.on('selectionUpdate', onSelectionUpdate);
    return () => {
      editor.off('selectionUpdate', onSelectionUpdate);
    };
  }, [editor]);

  let content;
  if (!shouldShowForm) {
    content = (
      <div className="link-preview">
        <div className="link-wrapper">
          <a href={url} target="_blank">
            {url}
          </a>
        </div>
        &nbsp; - &nbsp;
        <a onClick={() => setShouldShowForm(true)}>
          {i18n.t('link.change', { scope: i18nScope })}
        </a>
        &nbsp; | &nbsp;
        <a
          onClick={() => {
            EditorHelper.unlink({ editor });
            closeMenuCallback();
          }}
        >
          {i18n.t('link.remove', { scope: i18nScope })}
        </a>
      </div>
    );
  } else {
    content = (
      <LinkForm
        editor={editor}
        onDoneCallback={() => {
          const latestUrl = EditorHelper.getCurrentUrl({ editor });
          setShouldShowForm(false);
          if (_str.isBlank(latestUrl)) {
            closeMenuCallback();
          }
        }}
      />
    );
  }

  return <div className="link-tippy-content">{content}</div>;
}

export default function CustomLinkBubbleMenu({
  editor,
  closeMenuCallback,
}: {
  editor: Editor;
  closeMenuCallback: () => void;
}) {
  return (
    <TippyMenu
      classes="custom-link-bubble-menu-tippy"
      visible={true}
      onClickOutside={closeMenuCallback}
      offset={[0, 0]}
      duration={0}
      placement="bottom-start"
      content={
        <LinkTippy closeMenuCallback={closeMenuCallback} editor={editor} />
      }
      onHide={() => {
        editor.chain().focus().run();
      }}
      zIndex={10000}
      appendTo={document.body}
      getReferenceClientRect={() => {
        // The logic here is taken from the positioning implementation in Tiptap's BubbleMenuPlugin
        // https://github.com/ueberdosis/tiptap/blob/16bec4e9d0c99feded855b261edb6e0d3f0bad21/packages/extension-bubble-menu/src/bubble-menu-plugin.ts#L183-L193
        const { ranges } = editor.state.selection;
        const from = Math.min(...ranges.map((range) => range.$from.pos));
        const to = Math.max(...ranges.map((range) => range.$to.pos));

        if (isNodeSelection(editor.state.selection)) {
          const node = editor.view.nodeDOM(from);

          if (node instanceof Element) {
            return node.getBoundingClientRect();
          }
        }

        return posToDOMRect(editor.view, from, to);
      }}
    >
      <span></span>
    </TippyMenu>
  );
}
