import h from 'h';
import React, { useContext, useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';
import Store, { ModelType } from 'helpers/store';
import Helpers from 'helpers/helpers';

import { AddOnNames } from 'components/add_ons/names';
import { getDoesClubHaveAddOnInstalled } from 'models/club';
import EmergencyContactForm, {
  buildNewEmergencyContact,
} from 'components/add_ons/emergency_contacts/emergency_contact_form';

import ProfileStepForm from 'components/clubs/onboarding/profile_step_form';
import { Club, DeprecatedAny, RawWaiver, UserAsOwner } from 'types/types';
import PendingWaiversForm from 'components/utils/waivers/pending_waivers_form';

const i18nScope = 'components.clubs.onboarding';

enum OnboardingSteps {
  PROFILE_STEP,
  EMERGENCY_CONTACTS_STEP,
  WAIVERS_STEP,
}

interface StepProps {
  store: Store;
  currentUser: UserAsOwner;
  goToNextStep: () => void;
}

function ProfileStep({ store, currentUser, goToNextStep }: StepProps) {
  const { i18n } = useContext(I18nContext);
  const stepI18nScope = `${i18nScope}.steps.profile`;

  return (
    <div id="profile-step">
      <h1>{i18n.t('heading', { scope: stepI18nScope })}</h1>
      <p>{i18n.t('description', { scope: stepI18nScope })}</p>
      <ProfileStepForm
        currentUser={currentUser}
        onSuccessCallback={(rawCurrentUser) => {
          store.parseModel(rawCurrentUser);
          goToNextStep();
        }}
        onCancelCallback={() => {
          goToNextStep();
        }}
      />
    </div>
  );
}

function WaiversStep({ store, goToNextStep }: StepProps) {
  const { i18n } = useContext(I18nContext);
  const stepI18nScope = `${i18nScope}.steps.waivers`;

  const club = store.getCurrentClub();
  const rawPendingWaivers = store.getAllRawModels<RawWaiver>(ModelType.WAIVER);

  return (
    <div id="waivers-step">
      <h1>
        {i18n.t('heading', {
          scope: stepI18nScope,
          count: rawPendingWaivers.length,
        })}
      </h1>
      <p>
        {i18n.t('description', {
          scope: stepI18nScope,
          count: rawPendingWaivers.length,
        })}
      </p>
      <PendingWaiversForm
        getSignedWaiversApiUrl={({ waiverId }) =>
          Helpers.Routes.getClubWaiverSignedWaiversPath(club.id, waiverId)
        }
        overrideSubmitActionText={i18n.t('form.actions.submit', {
          scope: stepI18nScope,
        })}
        rawWaivers={rawPendingWaivers}
        onSuccessCallback={goToNextStep}
        formActionClasses="rtl"
      />
    </div>
  );
}

function EmergencyContactsStep({ goToNextStep }: StepProps) {
  const { i18n } = useContext(I18nContext);
  const stepI18nScope = `${i18nScope}.steps.emergency_contacts`;

  const isEditingNotAdding = false;
  const emergencyContact = buildNewEmergencyContact();

  return (
    <div id="emergency-contact-step">
      <h1>{i18n.t('heading', { scope: stepI18nScope })}</h1>
      <p>{i18n.t('description', { scope: stepI18nScope })}</p>
      <EmergencyContactForm
        isEditing={isEditingNotAdding}
        emergencyContact={emergencyContact}
        hideTitle={true}
        overrideCancelActionText={i18n.t('form.actions.cancel', {
          scope: stepI18nScope,
        })}
        overrideSubmitActionText={i18n.t('form.actions.submit', {
          scope: stepI18nScope,
        })}
        onSuccessCallback={goToNextStep}
        onCancelCallback={goToNextStep}
      />
    </div>
  );
}

function _getSteps({
  club,
  hasEmergencyContact,
  hasPendingWaivers,
}: {
  club: Club;
  hasEmergencyContact: boolean;
  hasPendingWaivers: boolean;
}): OnboardingSteps[] {
  const steps = [OnboardingSteps.PROFILE_STEP];
  if (
    getDoesClubHaveAddOnInstalled({
      club,
      addOnName: AddOnNames.WAIVERS,
    }) &&
    hasPendingWaivers
  ) {
    steps.push(OnboardingSteps.WAIVERS_STEP);
  }
  // TODO issue#129 currently we skip the step if the user already has
  // an emergency contact, eventually we want them to review it to make
  // sure the info is still valid.
  if (
    getDoesClubHaveAddOnInstalled({
      club,
      addOnName: AddOnNames.EMERGENCY_CONTACTS,
    }) &&
    !hasEmergencyContact
  ) {
    steps.push(OnboardingSteps.EMERGENCY_CONTACTS_STEP);
  }
  return steps;
}

export default function OnboardingWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <Onboarding {...props} />
    </RootAppWrapper>
  );
}

function Onboarding(props: DeprecatedAny) {
  const [store] = useState(() => Store.parseInitData(props));
  const club = store.getCurrentClub();
  const currentUser = store.getCurrentUser();

  const onSuccessRedirectToPath = store.getOrThrow<string>(
    'onSuccessRedirectToPath',
  );
  const hasEmergencyContact = store.getOrThrow<boolean>('hasEmergencyContact');

  const rawPendingWaivers = store.getAllRawModels<RawWaiver>(ModelType.WAIVER);
  const hasPendingWaivers = rawPendingWaivers.length > 0;

  const steps = _getSteps({ club, hasEmergencyContact, hasPendingWaivers });
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const index = steps.indexOf(currentStep);

  // for now can only go forward
  const goToNextStep = () => {
    if (index + 1 >= steps.length) {
      window.location.href = onSuccessRedirectToPath;
    } else {
      setCurrentStep(steps[index + 1]);
    }
  };

  let content;
  const stepParams = {
    store,
    club,
    currentUser,
    goToNextStep,
    onSuccessRedirectToPath,
  };
  switch (currentStep) {
    case OnboardingSteps.PROFILE_STEP:
      content = <ProfileStep {...stepParams} />;
      break;
    case OnboardingSteps.WAIVERS_STEP:
      content = <WaiversStep {...stepParams} />;
      break;
    case OnboardingSteps.EMERGENCY_CONTACTS_STEP:
      content = <EmergencyContactsStep {...stepParams} />;
      break;
    default:
      h.throwExhaustiveError("don't know how to render step", currentStep);
  }

  return (
    <div id="club-onboarding">
      <div className=""></div>
      <div className="step">{content}</div>
    </div>
  );
}
