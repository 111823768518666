import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import { ClubId } from 'types/types';
import { CodeBlock } from 'components/utils/code_block';

const i18nScope = 'clubs.admin.events.embed';

const GET_ADMIN_CLUB_CALENDAR_EMBED = gql(`
  query GetAdminClubCalendarEmbed($clubId: ID!) {
    club(id: $clubId) {
      id
      addOnNames
      embedCalendarCode
    }
  }
`);

export default function EmbedsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_CALENDAR_EMBED, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const embedCalendarCode = data?.club?.embedCalendarCode;
  assertNotNullOrUndefined(embedCalendarCode);

  return (
    <div id="clubs-admin-embeds-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page"
      >
        <p>{i18n.t('description', { scope: i18nScope })}</p>
        <CodeBlock codeText={embedCalendarCode} />
      </AdminDashboard>
    </div>
  );
}
