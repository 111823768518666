import UtilsHelper from 'helpers/utils_helper';
import { FormikHelpers } from 'formik';
import { AxiosError, AxiosResponse } from 'axios';

type APIServerErrorMessages = {
  [key: string]: string[];
  // TODO: might be able to get stronger type checking. It should only
  // have errors for attributes on the model or `base` so we might be
  // able to do something like below, but wasn't able to get it working
  // quickly so moving on
  // [key in T]: string[];
  // base: string[];
};

type APIError422 = AxiosError<APIServerErrorMessages>;
type APIError500 = AxiosError<null>;
type APIError = APIError422 | APIError500;

interface SaveProps<T, U> {
  actions: FormikHelpers<T>;
  main: () => Promise<U>;
  successCallback: (response: U) => void;
  errorCallback?: (error: AxiosError<APIError>) => void;
}

export default class FormHelper {
  static resetErrorsAndMarkTouched<T>({
    actions,
  }: {
    actions: FormikHelpers<T>;
  }): void {
    actions.setTouched({});
    actions.setErrors({});
    actions.setStatus({ serverErrors: null });
  }

  static save<T, U = AxiosResponse<unknown>>({
    actions,
    main,
    successCallback,
    errorCallback,
  }: SaveProps<T, U>) {
    this.resetErrorsAndMarkTouched({ actions });

    const defaultErrorCallback = (error: APIError): void => {
      actions.setSubmitting(false);
      const serverErrors = this._convertNetworkErrorsForUser(error);
      actions.setStatus({ serverErrors: serverErrors });
    };

    const promise = main();
    return promise
      .then((response: U) => {
        try {
          // it's up to the success callback to decide
          // whether to setSubmitting(false). depending
          // on the context we may wish to keep the
          // spinner going
          successCallback(response);
        } catch (error) {
          // catch if the callback failed
          console?.error(error);
          actions.setSubmitting(false);
          actions.setStatus({ serverErrors: this._getUnknownServerErrors() });
        }
      })
      .catch(errorCallback || defaultErrorCallback);
  }

  static async saveAsync<T, U = AxiosResponse<unknown>>({
    actions,
    main,
  }: Pick<SaveProps<T, U>, 'actions' | 'main'>): Promise<U> {
    this.resetErrorsAndMarkTouched<T>({ actions });

    try {
      const result = await main();
      return result;
    } catch (error) {
      const isAPIError =
        error !== null && typeof error === 'object' && 'response' in error;

      if (isAPIError) {
        const serverErrors = this._convertNetworkErrorsForUser(
          error as APIError,
        );
        actions.setStatus({ serverErrors: serverErrors });
      } else {
        console?.error(error);
        actions.setStatus({ serverErrors: this._getUnknownServerErrors() });
      }

      actions.setSubmitting(false);
      throw error; // propagate it upwards in case there's any additional error handle logic we want
    }
  }

  static _convertNetworkErrorsForUser(error: APIError): APIServerErrorMessages {
    const response = error.response;
    if (
      typeof response !== 'undefined' &&
      'status' in response &&
      response.status === 422 &&
      response.data !== null
    ) {
      return UtilsHelper.deepTransformKeysToCamelCase(response.data);
    } else {
      return this._getUnknownServerErrors();
    }
  }

  static _getUnknownServerErrors() {
    return { base: [window.translations.forms.errors.unknown_error] };
  }
}
