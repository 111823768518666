import { arrayFilterNulls, assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ClubMembershipSearchFiltersEnum,
  GetAdminClubsMembershipsListQuery,
} from '__generated__/graphql';

import { picSize } from 'components/utils/profile_pic';
import { ClubId } from 'types/types';

import {
  AdminClickableMembershipPreview,
  AdminClickableMembershipPreviewLoadingPlaceholder,
} from 'components/utils/admin/admin_membership_preview';

// const i18nScope = 'clubs.admin.overview.membership_list';;

type Membership = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        GetAdminClubsMembershipsListQuery['club']
      >['memberships']['edges']
    >[number]
  >['node']
>;

const MEMBERSHIPS_PER_ROW = 3;

const GET_ADMIN_CLUBS_MEMBERSHIPS_LIST = gql(`
  query GetAdminClubsMembershipsList($clubId: ID!, $filters: [ClubMembershipSearchFiltersEnum!]) {
    club(id: $clubId) {
      id
      memberships(first: 20, filters: $filters) {
        totalCount
        edges {
          node {
            id
            fullName
            profilePicUrl
            startsAt
            expiresAt
          }
        }
      }
    }
  }
`);

function MembershipsListWrapper<T>({
  heading,
  expectedNumberOfMemberships,
  memberships,
  renderMembership,
}: {
  heading: string;
  expectedNumberOfMemberships?: number;
  memberships: T[];
  renderMembership: (membership: T) => React.ReactElement;
}) {
  // we include empty-membership elements to ensure there are 3 items per row.
  // it's important because if a list only has 2 elements then they will each
  // take up half the space
  return (
    <div className="memberships-list">
      <h4>
        {heading}{' '}
        {expectedNumberOfMemberships && `(${expectedNumberOfMemberships})`}
      </h4>

      <div className="memberships">
        {memberships.map((membership, index) => (
          <div key={index} className="membership">
            {renderMembership(membership)}
          </div>
        ))}
        {_.times(
          MEMBERSHIPS_PER_ROW - (memberships.length % MEMBERSHIPS_PER_ROW),
          (n) => (
            <div key={n} className="empty-membership"></div>
          ),
        )}
      </div>
    </div>
  );
}

function LoadingMembershipListPlaceholder({ heading }: { heading: string }) {
  // it's just an array of numbers
  const placeholderMemberships = Array.from(
    { length: MEMBERSHIPS_PER_ROW * 2 },
    (x, i) => i,
  );
  return (
    <MembershipsListWrapper
      heading={heading}
      memberships={placeholderMemberships}
      renderMembership={() => (
        <AdminClickableMembershipPreviewLoadingPlaceholder />
      )}
    />
  );
}

export default function MembershipsList({
  clubId,
  heading,
  getInfo,
  filter,
}: {
  clubId: ClubId;
  heading: string;
  getInfo: (membership: Membership) => string;
  filter: ClubMembershipSearchFiltersEnum;
}) {
  const { loading, error, data } = useQuery(GET_ADMIN_CLUBS_MEMBERSHIPS_LIST, {
    variables: {
      clubId: String(clubId),
      filters: [filter],
    },
  });

  if (loading || data === null)
    return <LoadingMembershipListPlaceholder heading={heading} />;
  if (error) return null;

  const membershipsConnection = data?.club?.memberships;
  assertNotNullOrUndefined(membershipsConnection);
  const memberships = arrayFilterNulls(
    (membershipsConnection?.edges ?? []).map((e) => e?.node),
  );
  const expectedNumberOfMemberships = membershipsConnection?.totalCount;

  if (expectedNumberOfMemberships === 0) {
    return null;
  }

  return (
    <MembershipsListWrapper
      heading={heading}
      expectedNumberOfMemberships={expectedNumberOfMemberships}
      memberships={memberships}
      renderMembership={(membership) => (
        <AdminClickableMembershipPreview
          to={`../members/list/${membership.id}`}
          membership={membership}
          size={picSize.MEDIUM}
          secondaryInfo={getInfo(membership)}
        />
      )}
    />
  );
}
