import { assertIsNumber, assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  Club,
  ClubSubscriptionPlan,
  ClubSubscriptionPlanCreateOrUpdateMutation,
  ClubSubscriptionPlanFrquencyEnum,
} from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import {
  FormActions,
  FormActionButton,
  FormField,
  FormCurrencyField,
  FormSwitchField,
  FormServerErrorMessages,
} from 'components/forms/forms';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'clubs.admin.subscription_plans.form';

const CLUB_SUBSCRIPTION_PLAN_CREATE_OR_UPDATE = gql(`
  mutation ClubSubscriptionPlanCreateOrUpdate($input: ClubSubscriptionPlanCreateOrUpdateInput!) {
    clubSubscriptionPlanCreateOrUpdate(input: $input) {
      subscriptionPlan {
        id
        name
        frequency
        priceInCents
        renewalPriceInCents
        initiationFeeInCents
        available
        autoRenew
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

type SubscriptionPlanForForm = Pick<
  ClubSubscriptionPlan,
  'name' | 'available' | 'autoRenew'
> & {
  frequency: '' | ClubSubscriptionPlanFrquencyEnum;
  // TODO: issue#200 we need a better way of handling placeholder values
  // priceInCents is a number but I want to make it a string too
  // because when creating a new SubscriptionPlan I want to initialize
  // it with '' and not 0 so that it is blank for the user to fill out
  priceInCents: number | string;
};

type SubscriptionPlanFormValues = Pick<
  ClubSubscriptionPlan,
  'name' | 'available' | 'autoRenew'
> & {
  frequency: '' | ClubSubscriptionPlanFrquencyEnum;
  priceInDollars: number | string;
  // renewalPriceInDollars: number;
  // initiationFeeInDollars: number;
};

export function buildNewSubscriptionPlan(): SubscriptionPlanForForm {
  return {
    name: '',
    priceInCents: '',
    // initiationFeeInCents: '',
    // renewalPriceInCents: '',
    frequency: '',
    autoRenew: true,
    available: false,
  };
}

export default function SubscriptionPlanForm({
  isEditing,
  club,
  subscriptionPlan,
  onSuccessCallback,
  onCancelCallback,
}: {
  isEditing: boolean;
  club: Pick<Club, 'id' | 'currency'>;
  subscriptionPlan: SubscriptionPlanForForm & {
    id?: ClubSubscriptionPlan['id'];
  };
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [createOrUpdateMutation] = useMutation(
    CLUB_SUBSCRIPTION_PLAN_CREATE_OR_UPDATE,
  );

  const currency = club.currency;
  assertNotNullOrUndefined(currency);

  const initialValues = {
    ..._.pick(subscriptionPlan, 'name', 'frequency', 'available', 'autoRenew'),
    priceInDollars: _.isNumber(subscriptionPlan.priceInCents)
      ? subscriptionPlan.priceInCents / 100
      : subscriptionPlan.priceInCents, // handle if it's an empty string
    // renewalPriceInDollars: subscriptionPlan.renewalPriceInCents / 100,
    // initiationFeeInDollars: subscriptionPlan.initiationFeeInCents / 100,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, i18n.t('forms.errors.min_length'))
      .required(i18n.t('forms.errors.required')),
    frequency: Yup.string().required(i18n.t('forms.errors.required')),
    priceInDollars: Yup.number()
      .moreThan(0.99, ({ more }) =>
        i18n.t('forms.errors.more_than', { values: { number: more } }),
      )
      .required(i18n.t('forms.errors.required')),
    //initiationFeeInCents: Yup.number(),
    //renewalPriceInCents: Yup.number()
  });

  const _onSubmit = function (
    values: SubscriptionPlanFormValues,
    actions: FormikHelpers<SubscriptionPlanFormValues>,
  ) {
    const { priceInDollars } = values;
    assertIsNumber(priceInDollars);

    sendMutationAndUpdateForm<
      SubscriptionPlanFormValues,
      ClubSubscriptionPlanCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubSubscriptionPlanCreateOrUpdate',
      main: () =>
        createOrUpdateMutation({
          variables: {
            input: {
              clubId: club.id,
              id: subscriptionPlan?.id,
              ..._.omit(
                values,
                'priceInDollars',
                // 'renewalPriceInDollars',
                // 'initiationFeeInDollars',
              ),
              // HACK: figure out how to avoid type casting
              frequency: values.frequency as ClubSubscriptionPlanFrquencyEnum,
              priceInCents: priceInDollars * 100,
              //renewalPriceInCents: values.renewalPriceInDollars * 100,
              //initiationFeeInCents: values.initiationFeeInDollars * 100,
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  return (
    <div className="subscription-plan-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              autoFocus={true}
              type="text"
              name="name"
              label={i18n.t('name.label', { scope: i18nScope })}
            />
            <FormCurrencyField
              name="priceInDollars"
              prefix={currency}
              label={i18n.t('price_in_dollars.label', { scope: i18nScope })}
            />
            <FormField
              as="select"
              name="frequency"
              label={i18n.t('frequency.label', { scope: i18nScope })}
              description={i18n.t('frequency.description', {
                scope: i18nScope,
              })}
            >
              <option value=""></option>
              <option value={ClubSubscriptionPlanFrquencyEnum.Annually}>
                {i18n.t('frequency.options.annually', { scope: i18nScope })}
              </option>
              <option value={ClubSubscriptionPlanFrquencyEnum.Biennially}>
                {i18n.t('frequency.options.biennially', { scope: i18nScope })}
              </option>
              <option value={ClubSubscriptionPlanFrquencyEnum.Triennially}>
                {i18n.t('frequency.options.triennially', { scope: i18nScope })}
              </option>
              <option value={ClubSubscriptionPlanFrquencyEnum.Quarterly}>
                {i18n.t('frequency.options.quarterly', { scope: i18nScope })}
              </option>
            </FormField>
            <FormSwitchField
              name="available"
              label={i18n.t('available.label', { scope: i18nScope })}
              description={i18n.t('available.description', {
                scope: i18nScope,
              })}
            />
            <FormSwitchField
              name="autoRenew"
              label={i18n.t('auto_renew.label', { scope: i18nScope })}
              description={i18n.t('auto_renew.description', {
                scope: i18nScope,
              })}
            />

            {/* <FormCurrencyField
              name="initiationFeeInDollars"
              prefix={currency}
              label={i18n.t('initiation_fee_in_dollars.label', {
                scope: i18nScope,
              })}
              description={i18n.t('initiation_fee_in_dollars.description', {
                scope: i18nScope,
              })}
            />
            <FormCurrencyField
              name="renewalPriceInDollars"
              prefix={currency}
              label={i18n.t('renewal_price_in_dollars.label', {
                scope: i18nScope,
              })}
              description={i18n.t('renewal_price_in_dollars.description', {
                scope: i18nScope,
              })}
            /> */}

            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
