import React, { useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import Store from 'helpers/store';

import { ClubProfilePic, picSize } from 'components/utils/profile_pic';
import Location from 'components/utils/location';
import { DeprecatedAny } from 'types/types';

// const i18nScope = 'components.clubs.unclaimed_club_profile';

export default function UnclaimedClubProfileWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <UnclaimedClubProfile {...props} />
    </RootAppWrapper>
  );
}

function UnclaimedClubProfile(props: DeprecatedAny) {
  const [store] = useState(() => Store.parseInitData(props));
  const club = store.getCurrentClub();

  return (
    <div id="unclaimed-club-profile">
      <div className="center-content section header-section">
        <div className="club-logo-outer">
          <ClubProfilePic
            classes="inline-block"
            club={club}
            size={picSize.EXTRA_LARGE}
          />
        </div>
        <div className="club-summary">
          <h1 className="bold">{club.name}</h1>
          <div className="info">
            <Location text={`${club.city}, ${club.state}`} />
            <div className="website">
              <i className="fas fa-globe-americas mr-2"></i>
              <a href={club.website} target="_blank">
                {club.website}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
