import { arrayFilterNulls } from 'h';
import React, { useState } from 'react';
import { gql } from '__generated__/gql';
import { useQuery } from '@apollo/client';

import I18nContext from 'contexts/i18n_context';
import { ClubId } from 'types/types';
import MembershipInfoCard from 'components/utils/memberships/membership_info_card';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import LoadMore from 'components/utils/load_more';

const i18nScope = 'clubs.clubhouse.memberships';

const GET_CLUBHOUSE_MEMBERSHIPS = gql(`
  query GetClubhouseMemberships($clubId: ID!, $search: String, $cursor: String) {
    club(id: $clubId) {
      id
      memberships(first: 20, search: $search, filters: [active], after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            fullName
            profilePicUrl
            firstJoinedAt
          }
        }
      }
    }
  }
`);

export default function MembershipsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [isInitialLoad, setIsInitalLoad] = useState(true);

  const { loading, error, data, fetchMore } = useQuery(
    GET_CLUBHOUSE_MEMBERSHIPS,
    {
      variables: {
        clubId: String(currentClubId),
      },
      onCompleted: () => setIsInitalLoad(false),
    },
  );

  if (loading && isInitialLoad) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const membershipsConnection = data?.club?.memberships;
  const pageInfo = membershipsConnection?.pageInfo;
  const memberships = arrayFilterNulls(
    (membershipsConnection?.edges ?? []).map((e) => e?.node),
  );

  return (
    <div id="clubhouse-memberships-page">
      <div className="page-header">
        <div className="title">
          <h1 className="capitalize">
            {i18n.t('title', { scope: i18nScope })}
          </h1>
        </div>
      </div>
      <div>
        <div className="memberships">
          {memberships.map((membership, index) => (
            <MembershipInfoCard key={index} membership={membership} />
          ))}
        </div>
        <LoadMore
          canLoadMore={pageInfo?.hasNextPage}
          onClick={() => {
            fetchMore({
              variables: {
                cursor: pageInfo?.endCursor,
              },
            });
          }}
        />
      </div>
    </div>
  );
}
