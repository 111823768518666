import React, { useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';
import Store, { ModelType } from 'helpers/store';

import AuthenticateThenRedirectLink from 'components/utils/authenticate_then_redirect_link';

import ClubHeaderSection from 'components/clubs/club_profile/sections/club_header_section';
import ClubSponsorsSection from 'components/clubs/club_profile/sections/club_sponsors_section';
import ClubEventsSection from 'components/clubs/club_profile/sections/club_events_section';
import {
  Club,
  DeprecatedAny,
  RawClubEvent,
  User,
  WithRequired,
} from 'types/types';
import h from 'h';

const i18nScope = 'components.clubs.club_profile';

function ActionBar({
  currentUser,
  ctaRedirectPath,
}: {
  currentUser: User | null;
  ctaRedirectPath: string;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="action-bar">
      <div className="center-content">
        <h2 className="inline mr-3">
          {i18n.t('action_bar.message', { scope: i18nScope })}
        </h2>
        <AuthenticateThenRedirectLink
          isLoggedIn={!h.isNullOrUndefined(currentUser)}
          classes="inline"
          redirectPath={ctaRedirectPath}
        >
          <button className="btn btn-primary">
            {i18n.t('action_bar.join_cta', { scope: i18nScope })}
          </button>
        </AuthenticateThenRedirectLink>
      </div>
    </div>
  );
}

export default function ClubProfileWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <ClubProfile {...props} />
    </RootAppWrapper>
  );
}

function ClubProfile(props: DeprecatedAny) {
  const { i18n } = React.useContext(I18nContext);
  const [store] = useState(() => Store.parseInitData(props));
  const club = store.getCurrentClub([
    'clubProfile',
    'sponsors',
  ]) as WithRequired<Club, 'clubProfile' | 'sponsors'>;
  const rawClubEvents = store.getAllRawModels<RawClubEvent>(
    ModelType.CLUB_EVENT,
  );
  const ctaRedirectPath = store.getOrThrow<string>('ctaRedirectPath');

  return (
    <div id="club-profile">
      <ClubHeaderSection club={club} clubProfile={club.clubProfile} />
      <ActionBar
        currentUser={store.getCurrentUserIfExists()}
        ctaRedirectPath={ctaRedirectPath}
      />
      <ClubEventsSection
        club={club}
        rawClubEvents={rawClubEvents}
        title={i18n.t('events.title', { scope: i18nScope })}
      />
      <ClubSponsorsSection
        sponsors={club.sponsors}
        titleHtml={
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t('sponsors.title_html', { scope: i18nScope }),
            }}
          />
        }
      />
    </div>
  );
}
