import React from 'react';
import I18nContext from 'contexts/i18n_context';
import ImageUploader from 'components/utils/image_uploader';
import { Club } from 'types/types';

const i18nScope = 'components.utils.images.club_logo_uploader';

interface ClubLogoUploaderProps {
  name: string;
  club: Pick<Club, 'logoUrl'>;
  setFieldTouched: (name: string, wasTouched: boolean) => void;
  setFieldValue: (name: string, value: File) => void;
}

export default function ClubLogoUploader({
  name,
  club,
  setFieldValue,
  setFieldTouched,
}: ClubLogoUploaderProps) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <span className="club-logo-uploader">
      <ImageUploader
        name={name}
        classes="logo-uploader"
        existingImageUrl={club.logoUrl}
        aspectRatio={1}
        onChangeCallback={(name, croppedImage) => {
          setFieldValue(name, croppedImage);
          setFieldTouched(name, true);
        }}
        cropModalTitle={i18n.t('crop_modal.title', { scope: i18nScope })}
        cropModalSubmit={i18n.t('crop_modal.submit', { scope: i18nScope })}
      />
    </span>
  );
}
