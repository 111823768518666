import _ from 'underscore';
import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormServerErrorMessages,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import { EmailAddress } from 'types/types';
import I18nContext from 'contexts/i18n_context';

export interface PasswordReset {
  emailAddress: EmailAddress;
  token: string;
}

interface SetPasswordFormValues {
  password: string;
  passwordConfirmation: string;
}

export default function SetPasswordForm({
  passwordReset,
  afterSetPasswordCallback,
}: {
  passwordReset: PasswordReset;
  afterSetPasswordCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const initialValues: SetPasswordFormValues = {
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .min(6, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
        passwordConfirmation: Yup.string()
          .min(6, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
      }),
    [],
  );

  const _onSubmit = function (
    values: SetPasswordFormValues,
    actions: FormikHelpers<SetPasswordFormValues>,
  ) {
    Helpers.Form.save({
      actions: actions,
      main: () => {
        const passwordResetParams = _.extend(values, {
          emailAddress: passwordReset.emailAddress,
        });
        const url = Helpers.Routes.getPasswordResetPath(passwordReset.token);
        return Helpers.API.put({
          url,
          params: { passwordReset: passwordResetParams },
        });
      },
      successCallback: () => {
        // keep form spinning, do don't reset setSubmitting
        afterSetPasswordCallback?.();
      },
    });
  };

  return (
    <div className="password-reset-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              autoFocus={true}
              type="password"
              name="password"
              placeholder={i18n.t(
                'auth.set_new_password.form.new_password_placeholder',
              )}
            />
            <FormField
              type="password"
              name="passwordConfirmation"
              placeholder={i18n.t(
                'auth.set_new_password.form.password_confirmation_placeholder',
              )}
            />
            <FormActions>
              <FormActionButton
                text={i18n.t('auth.set_new_password.form.submit')}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
