import classNames from 'classnames';
import Icon, { iconType } from 'components/utils/icon';
import React, { useState } from 'react';
import { DeprecatedAny } from 'types/types';

function CtaButton({ calendlyUrl }: { calendlyUrl: string }) {
  return (
    <button
      className="btn btn-primary"
      onClick={() => window.Calendly?.initPopupWidget({ url: calendlyUrl })}
    >
      Get a demo
    </button>
  );
}

function HeroSection({
  heroImageUrl,
  calendlyUrl,
}: {
  heroImageUrl: string;
  calendlyUrl: string;
}) {
  return (
    <div className="section section-hero vertical-center">
      <div className="center-content hero-content">
        <div className="hero-text">
          <h1>
            Manage your club <span className="hero-highlight">all-in-one</span>{' '}
            place, effortlessly
          </h1>
          <p>Schedule events, collect payments, send emails, and much more.</p>
          <div className="hero-cta">
            <CtaButton calendlyUrl={calendlyUrl} />
          </div>
        </div>
        <div className="hero-image vertical-center">
          <img src={heroImageUrl} />
        </div>
      </div>
    </div>
  );
}

function ClubTypeCardsSection({
  clubTypeCards,
}: {
  clubTypeCards: { name: string; imageUrl: string }[];
}) {
  return (
    <div className="section section-club-types no-border">
      <div className="center-content">
        <div className="section-header">
          <h2>Built for communities like yours</h2>
        </div>
        <div className="club-type-cards">
          {clubTypeCards.map((clubTypeCard, index) => (
            <div className="club-type-card" key={index}>
              <div className="club-type-card-image">
                <img src={clubTypeCard.imageUrl} alt={clubTypeCard.name} />
              </div>
              <div className="club-type-card-content">
                <h3>{clubTypeCard.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
function TrustedBySection({ sftriclubLogoUrl }: { sftriclubLogoUrl: string }) {
  return (
    <div className="section section-trusted-by">
      <div className="center-content center">
        <h3>Trusted by the</h3>
        <img src={sftriclubLogoUrl} alt="San Francisco Triathlon Club logo" />
      </div>
    </div>
  );
}

function FeaturesSection({
  featureGroups,
}: {
  featureGroups: {
    name: string;
    features: { name: string; isComingSoon?: boolean }[];
  }[];
}) {
  return (
    <div className="section section-features yellow-bg">
      <div className="center-content">
        <div className="section-header">
          <h2>All the features you need to run your club effortlessly</h2>
        </div>

        <div className="features">
          {featureGroups.map((featureGroup, index) => (
            <div className="feature" key={index}>
              <div className="feature-category">{featureGroup.name}</div>
              <div className="feature-description">
                <ul>
                  {featureGroup.features.map((feature, i) => (
                    <li key={i}>
                      <i className="far fa-check-circle mr-1"></i>
                      {feature.name}
                      {feature.isComingSoon && (
                        <span className="coming-soon ml-1">coming soon</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function AddOnsSection({
  addOns,
}: {
  addOns: { icon: string; name: string; description: string }[];
}) {
  return (
    <div className="section section-add-ons">
      <div className="center-content">
        <div className="section-header">
          <h2>Customize with add-ons</h2>
          <p className="mt-0">
            Mix and match powerful add-ons to fit your community perfectly.
          </p>
        </div>

        <div className="add-ons">
          {addOns.map((addOn, index) => (
            <div className="add-on" key={index}>
              <div className="add-on-name">
                {addOn.icon} &nbsp; {addOn.name}
              </div>
              <div className="feature-description">{addOn.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function MigrateSection({ migrateImageUrl }: { migrateImageUrl: string }) {
  return (
    <div className="section section-migrate light-red-bg">
      <div className="center-content">
        <div className="section-header">
          <h2>Using another platform? Switch over easily.</h2>
          <p className="mt-0">
            Switching platforms can be a pain but we make it easy. Get up and
            running within a day.
          </p>
        </div>

        <div className="two-column-layout">
          <div className="column migrate-image">
            <img src={migrateImageUrl} />
          </div>

          <div className="column steps">
            <div className="step">
              <div className="step-number-wrapper">
                <span className="step-number">1</span>
              </div>
              <div className="step-text">
                <h3>We learn about your club</h3>
                <p>
                  We'll start with a quick call to better understand your needs
                  and see if it's a good fit. If so, we'll walk you through the
                  process of switching over. We can even get you up and running
                  the same day!
                </p>
              </div>
            </div>

            <div className="step">
              <div className="step-number-wrapper">
                <span className="step-number">2</span>
              </div>
              <div className="step-text">
                <h3>Setup</h3>
                <p>
                  We'll do the heavy lifting to import your member data, events,
                  sponsors, and more. Then review and customize to make sure
                  it's just right!
                </p>
              </div>
            </div>

            <div className="step">
              <div className="step-number-wrapper">
                <span className="step-number">3</span>
              </div>
              <div className="step-text">
                <h3>Launch</h3>
                <p>
                  Finally, we're ready to go! We'll invite your members to setup
                  their accounts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FaqSection({
  faqs,
}: {
  faqs: { question: string; answer: string }[];
}) {
  const [expandedFaqs, setExpandedFaqs] = useState<number[]>([]);

  const toggleFaq = (index: number) => {
    if (expandedFaqs.includes(index)) {
      setExpandedFaqs(expandedFaqs.filter((i) => i !== index));
    } else {
      setExpandedFaqs([...expandedFaqs, index]);
    }
  };

  return (
    <div className="section section-faq">
      <div className="center-content two-column-layout">
        <div className="column section-header">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="column faqs">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={classNames('faq', {
                'faq-expanded': expandedFaqs.includes(index),
              })}
            >
              <div className="faq-caret" onClick={() => toggleFaq(index)}>
                <Icon type={iconType.CARET_DOWN} />
              </div>
              <div className="faq-inner">
                <div className="faq-question" onClick={() => toggleFaq(index)}>
                  {faq.question}
                </div>
                <div
                  className="faq-answer"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function CloserSection({ calendlyUrl }: { calendlyUrl: string }) {
  return (
    <div className="section section-closer yellow-bg">
      <div className="closer-content center-content">
        <h2 className="mb-3">
          Save time. Get more members. An all-in-one, modern platform you and
          your members will <span className="closer-highlight">love.</span>
        </h2>
        <CtaButton calendlyUrl={calendlyUrl} />
      </div>
    </div>
  );
}

export default function Organizers(props: DeprecatedAny) {
  const {
    faqs,
    addOns,
    featureGroups,
    clubTypeCards,
    heroImageUrl,
    calendlyUrl,
    sftriclubLogoUrl,
    migrateImageUrl,
  } = props;
  return (
    <div id="organizers-page">
      <HeroSection heroImageUrl={heroImageUrl} calendlyUrl={calendlyUrl} />
      <ClubTypeCardsSection clubTypeCards={clubTypeCards} />
      <TrustedBySection sftriclubLogoUrl={sftriclubLogoUrl} />
      <FeaturesSection featureGroups={featureGroups} />
      <AddOnsSection addOns={addOns} />
      <MigrateSection migrateImageUrl={migrateImageUrl} />
      <FaqSection faqs={faqs} />
      <CloserSection calendlyUrl={calendlyUrl} />
    </div>
  );
}
