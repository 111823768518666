import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  Club,
  ClubWaiver,
  ClubWaiverCreateOrUpdateMutation,
} from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import I18nContext from 'contexts/i18n_context';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import {
  FormActions,
  FormActionButton,
  FormField,
  FormAutoExpandField,
  FormSwitchField,
  FormServerErrorMessages,
} from 'components/forms/forms';

const i18nScope = 'clubs.admin.waivers.form';

export type WaiverFormValues = Pick<
  ClubWaiver,
  'name' | 'content' | 'isRequired'
>;

const CLUB_WAIVER_CREATE_OR_UPDATE = gql(`
  mutation ClubWaiverCreateOrUpdate($input: ClubWaiverCreateOrUpdateInput!) {
    clubWaiverCreateOrUpdate(input: $input) {
      waiver {
        id
        name
        isRequired
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export function buildNewWaiver(): WaiverFormValues {
  return {
    name: '',
    content: '',
    isRequired: false,
  };
}

export default function WaiverForm({
  clubId,
  isEditing,
  waiver,
  onSuccessCallback,
  onCancelCallback,
}: {
  clubId: Club['id'];
  isEditing: boolean;
  // id is optional because it is only provided when editing, not adding
  waiver: WaiverFormValues & { id?: ClubWaiver['id'] };
  onSuccessCallback: (
    savedWaiver: Pick<ClubWaiver, 'id' | 'name' | 'isRequired'>,
  ) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [createOrUpdateMutation] = useMutation(CLUB_WAIVER_CREATE_OR_UPDATE);

  const initialValues: WaiverFormValues = _.pick(
    waiver,
    'name',
    'content',
    'isRequired',
  );
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(i18n.t('forms.errors.required'))
      .min(5, i18n.t('forms.errors.min_length')),
    content: Yup.string()
      .min(20, i18n.t('forms.errors.min_length'))
      .required(i18n.t('forms.errors.required')),
  });

  const _onSubmit = function (
    values: WaiverFormValues,
    actions: FormikHelpers<WaiverFormValues>,
  ) {
    sendMutationAndUpdateForm<
      WaiverFormValues,
      ClubWaiverCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubWaiverCreateOrUpdate',
      main: () =>
        createOrUpdateMutation({
          variables: {
            input: {
              clubId,
              id: waiver?.id,
              ...values,
            },
          },
        }),
      successCallback: (mutationPayload) => {
        const savedWaiver = mutationPayload?.clubWaiverCreateOrUpdate?.waiver;
        assertNotNullOrUndefined(savedWaiver);
        onSuccessCallback(savedWaiver);
      },
    });
  };

  return (
    <div className="waiver-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormSwitchField
              name="isRequired"
              style={{ maxWidth: 350 }}
              label={i18n.t('is_required.label', { scope: i18nScope })}
              description={i18n.t('is_required.description', {
                scope: i18nScope,
              })}
            />
            <FormField
              autoFocus={true}
              type="text"
              name="name"
              label={i18n.t('name.label', { scope: i18nScope })}
            />
            <FormAutoExpandField
              rows="10"
              name="content"
              label={i18n.t('content.label', { scope: i18nScope })}
            />
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
