import h from 'h';
import {
  Club,
  DeprecatedAny,
  MembershipAsOwner,
  RawClub,
  RawModel,
  UserAsOwner,
} from 'types/types';
import _ from 'underscore';
import _str from 'underscore.string';

export enum ModelType {
  CLUB = 'club',
  CLUB_EVENT = 'clubEvent',
  CLUB_METRICS_SUMMARY = 'clubMetricsSummary',
  CLUB_PROFILE_PAGE = 'clubProfilePage',
  COMMENT = 'comment',
  COMPANY = 'company',
  CUSTOM_EMAIL = 'customEmail',
  DISCUSSION = 'discussion',
  EMAIL_BLAST = 'emailBlast',
  EMERGENCY_CONTACT = 'emergencyContact',
  EVENT_HOST = 'eventHost',
  EVENT_RSVP = 'eventRsvp',
  MEMBERSHIP = 'membership',
  MEMBERSHIP_NOTIFICATIONS_SETTING = 'membershipNotificationsSetting',
  PERMISSION_GROUP = 'permissionGroup',
  PRODUCT = 'product',
  SPONSORSHIP = 'sponsorship',
  STOREFRONT = 'storefront',
  STRIPE_PAYMENT_METHOD = 'stripePaymentMethod',
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_PLAN = 'subscriptionPlan',
  USER = 'user',
  USER_NOTIFICATIONS_SETTING = 'userNotificationsSetting',
  USER_PREFERENCE = 'userPreference',
  WAIVER = 'waiver',
}

enum CurrentModelIdKeys {
  CURRENT_USER_ID = 'currentUserId',
  CURRENT_USER_PREFERENCE_ID = 'currentUserPreferenceId',
  CURRENT_MEMBERSHIP_ID = 'currentMembershipId',
  CURRENT_CLUB_ID = 'currentClubId',
}

type ModelId = number;

// I tried to make it more typesafe but couldn't get it working
// quickly and don't have more time to spend
// type IncludedRelationshipName<T> = keyof T;
// type IncludedRelationshipNames<T = null> = IncludedRelationshipName<T>[] | null;
type IncludedRelationshipName = string;
type IncludedRelationshipNames = IncludedRelationshipName[] | null;

export default class Store {
  data: DeprecatedAny;

  static parseInitData(props: DeprecatedAny) {
    const store = new Store();
    store.parseModels(props);
    return store;
  }

  constructor() {
    this.data = {};
  }

  set(name: string, data: string | number | object): void {
    this.data[name] = data;
  }

  getOrThrow<T = unknown>(name: string): T {
    const val = this.get<T>(name);
    if (val === null) {
      return h.throwError(`missing value for key ${name}`);
    }
    return val;
  }

  get<T = unknown>(name: string): T | null {
    return this.data[name] ?? null;
  }

  // note this will overwrite existing data if it exists which may be problematic
  parseModels(objectsByName: DeprecatedAny) {
    for (const name in objectsByName) {
      const object = objectsByName[name];
      if (Array.isArray(object)) {
        if (this._getIsSerializedModel(object[0])) {
          for (const o of object) {
            this.parseModel(o);
          }
        } else {
          this.set(name, object);
        }
      } else if (object instanceof Object) {
        if (this._getIsSerializedModel(object)) {
          this.parseModel(object);
        } else {
          this.set(name, object);
        }
      } else {
        this.set(name, object);
      }
    }
  }

  parseModel<T extends { data: DeprecatedAny; included: DeprecatedAny }>(
    object: T,
  ): void {
    if (!this._getIsSerializedModel(object)) {
      throw new Error('expecting a serialized model');
    }
    this._parseModel(object.data, object.included);
  }

  _parseModel(rawModel: DeprecatedAny, included?: DeprecatedAny) {
    const name = _str.camelize(rawModel.type);
    this.data[name] = this.data[name] ?? {};

    this.data[name][rawModel.id] = rawModel;
    if (included) {
      for (const includedObject of included) {
        this._parseModel(includedObject);
      }
    }
  }

  getRawModelOrThrow<T = unknown>(modelType: ModelType, modelId: ModelId): T {
    const rawModel = this.getRawModel<T>(modelType, modelId);
    if (rawModel === null) {
      return h.throwError(`missing model ${modelType}, id: ${modelId}`);
    }
    return rawModel;
  }

  getRawModel<T = unknown>(
    modelType: ModelType,
    modelId: ModelId | null,
  ): T | null {
    const records = this.data[_str.camelize(modelType)] || {};
    const targetId = modelId?.toString() ?? null;
    return _.findWhere(records, { id: targetId }) ?? null;
  }

  getModelOrThrow<T = unknown>(
    modelType: ModelType,
    modelId: ModelId,
    includedRelationshipNames: IncludedRelationshipNames = null,
  ): T | never {
    const model = this.getModel<T>(
      modelType,
      modelId,
      includedRelationshipNames,
    );
    if (model === null) {
      return h.throwError(`missing model ${modelType}, id: ${modelId}`);
    }
    return model;
  }

  // includedModels helps us break infinite loops, some
  // models include relationships that reference back to
  // that same model resulting in infinite loops, so if it
  // has already been included then we won't include it again
  getModel<T = unknown>(
    modelType: ModelType,
    modelId: ModelId | null,
    includedRelationshipNames: IncludedRelationshipNames = null,
  ): T | null {
    const rawModel = this.getRawModel<RawModel<ModelId, ModelType, T>>(
      modelType,
      modelId,
    );
    if (rawModel === null) {
      return null;
    }

    // TODO issue#180 - we should've created a new object instead of
    // reusing the attributes object because we append relationships
    // to it and that persists across function calls. So if you call
    // getModel which pulls in all models then subsequent call will
    // include them too whether you wanted or not.

    // HACK: casting as DeprecatedAny here and then casting back to T
    // below because I can't seem to get the typechecker passing.
    // specifically, down below where I do
    // model[relationshipName] = .... relationshipName is a string
    // and it complains because that doesn't exist on the model T.
    // I don't have anymore time to spend on this so will contain
    // the hack to this function. Since we do cast back to T at the
    // end of the function then all other functions that call this
    // can get typechecking as T
    const model = rawModel.attributes as DeprecatedAny;
    const relationshipParser = new IncludedRelationshipParser(
      includedRelationshipNames,
    );

    if (rawModel.relationships) {
      for (const relationshipName in rawModel.relationships) {
        if (!relationshipParser.getIsIncluded(relationshipName)) {
          continue; // skip it unless included
        }
        const remainingIncludedRelationshipNames =
          relationshipParser.getNestedRelationshipNamesFor(relationshipName);

        const relationship = rawModel.relationships[relationshipName];
        if (!relationship.data) {
          continue;
        }
        if (Array.isArray(relationship.data)) {
          model[relationshipName] = [];
          for (const relation of relationship.data) {
            model[relationshipName].push(
              this.getModel(
                relation.type,
                relation.id,
                remainingIncludedRelationshipNames,
              ),
            );
          }
        } else {
          const relation = relationship.data;
          model[relationshipName] = this.getModel(
            relation.type,
            relation.id,
            remainingIncludedRelationshipNames,
          );
        }
      }
    }

    // HACK: I type casted it as DeprecatedAny above, so casting
    // back to T here, this way all functions that call this can
    // get typechecking. Learn more in the comment above in this
    // function
    return model as T;
  }

  getAllModels<T = unknown>(
    modelType: ModelType,
    includedRelationshipNames: IncludedRelationshipNames = null,
  ): T[] {
    const records = this.data[modelType] || {};
    const stringIds = _.keys(records);
    const models = stringIds.map((stringId) => {
      // HACK: issue#200 account for weird 'null' ids. it's rare but
      // it does happen when we serialize the a model that wasn't persisted
      // on the backend (eg. creating a new event).
      const id = stringId === 'null' ? null : Number(stringId);
      return this.getModel<T>(modelType, id, includedRelationshipNames);
    });
    return _.compact(models);
  }

  getAllRawModels<T = unknown>(modelType: ModelType): T[] {
    const records = this.data[modelType] || {};
    const ids = _.keys(records);
    const models = ids.map((id) => this.getRawModel<T>(modelType, Number(id)));
    return _.compact(models);
  }

  getFirstModelOrThrow<T = unknown>(
    modelType: ModelType,
    includedRelationshipNames: IncludedRelationshipNames = null,
  ) {
    const results = this.getAllModels<T>(modelType, includedRelationshipNames);
    if (results.length !== 1) {
      throw new Error(
        `expecting a single ${modelType} but found ${results.length}`,
      );
    }
    return results[0];
  }

  getCurrentUser(): UserAsOwner {
    return this.getModelOrThrow<UserAsOwner>(
      ModelType.USER,
      this.getOrThrow<number>(CurrentModelIdKeys.CURRENT_USER_ID),
    );
  }

  getCurrentUserIfExists(): UserAsOwner | null {
    const currentUserId = this.get<number>(CurrentModelIdKeys.CURRENT_USER_ID);
    if (currentUserId === null) {
      return null;
    }
    // if the user id exists then we expect it to be there
    return this.getModelOrThrow<UserAsOwner>(ModelType.USER, currentUserId);
  }

  getCurrentMembership(): MembershipAsOwner {
    return this.getModelOrThrow<MembershipAsOwner>(
      ModelType.MEMBERSHIP,
      this.getOrThrow(CurrentModelIdKeys.CURRENT_MEMBERSHIP_ID),
    );
  }

  getCurrentMembershipIfExists(): MembershipAsOwner | null {
    const currentMembershipId = this.get<number>(
      CurrentModelIdKeys.CURRENT_MEMBERSHIP_ID,
    );
    if (currentMembershipId === null) {
      return null;
    }
    // if the user id exists then we expect it to be there
    return this.getModelOrThrow<MembershipAsOwner>(
      ModelType.MEMBERSHIP,
      currentMembershipId,
    );
  }

  getCurrentClub<T extends Club>(
    includedRelationshipNames: IncludedRelationshipNames = null,
  ): T {
    return this.getModelOrThrow<T>(
      ModelType.CLUB,
      this.getOrThrow(CurrentModelIdKeys.CURRENT_CLUB_ID),
      includedRelationshipNames,
    );
  }

  getCurrentClubIfExists(): Club | null {
    const currentClubId = this.get<number>(CurrentModelIdKeys.CURRENT_CLUB_ID);
    if (currentClubId === null) {
      return null;
    }
    // if the user id exists then we expect it to be there
    return this.getModelOrThrow<Club>(ModelType.CLUB, currentClubId);
  }

  getRawCurrentClub(): RawClub {
    return this.getRawModelOrThrow<RawClub>(
      ModelType.CLUB,
      this.getOrThrow(CurrentModelIdKeys.CURRENT_CLUB_ID),
    );
  }

  removeModel<T = unknown>(modelType: ModelType, modelId: ModelId): T {
    const modelKey = _str.camelize(modelType);
    const records = this.data[modelKey] || {};
    const model = records[modelId];
    delete records[modelId];
    this.data[modelKey] = { ...records };
    return model;
  }

  addModel<T extends { id: ModelId }>(modelType: ModelType, model: T): void {
    const modelKey = _str.camelize(modelType);
    const records = this.data[modelKey];
    this.data[modelKey] = {
      ...records,
      [model.id]: model,
    };
  }

  // doesn't need to be on the store, but adding it
  // here so it centralized in one place
  swapModelPosition<T = unknown>(
    modelA: T,
    modelB: T,
    attributeName: keyof T,
  ): void {
    const positionA = modelA[attributeName];
    const positionB = modelB[attributeName];
    modelA[attributeName] = positionB;
    modelB[attributeName] = positionA;
  }

  _getIsSerializedModel(object: DeprecatedAny) {
    return (
      object instanceof Object &&
      _.has(object, 'data') &&
      _.has(object.data, 'id') &&
      _.has(object.data, 'type') &&
      _.has(object.data, 'attributes')
    );
  }
}

// TODO issue#165 there is a temporary hack where when relationshipNames
// is null then we assume it means include all relationships. This is a
// hack because I haven't had a chance to update all instances of getModel
// to specify the desired included relationships
export class IncludedRelationshipParser {
  includedRelationshipNames: IncludedRelationshipNames;

  // included relationship names are given as an
  // array of ['emails', 'user', 'club.events', 'club.users']
  // nested relationships have a '.' between
  constructor(relationshipNames: IncludedRelationshipNames) {
    this.includedRelationshipNames = relationshipNames;
  }

  // checks to see if a relationship is included
  // accounting for '.' in cases where there are
  // nested relationships
  getIsIncluded(relationshipName: IncludedRelationshipName): boolean {
    // issue#165 Temporary hack until all getModel specify relationship names
    if (
      this.includedRelationshipNames === null ||
      typeof this.includedRelationshipNames === 'undefined'
    ) {
      return true;
    }

    for (const includedRelationshipName of this.includedRelationshipNames) {
      if (typeof includedRelationshipName !== 'string') {
        return h.throwError('icnluded relationship name is now a string');
      }

      const firstIncludedRelationshipName =
        includedRelationshipName.split('.')[0];
      if (firstIncludedRelationshipName === relationshipName) {
        return true;
      }
    }
    return false;
  }

  // if given array of ['emails', 'user', 'club.events', 'club.users']
  // and we ask for nested relationship of 'club' then it returns
  // ['events', 'users']
  getNestedRelationshipNamesFor(
    relationshipName: IncludedRelationshipName,
  ): IncludedRelationshipNames {
    // issue#165 Temporary hack until all getModel specify relationship names
    if (
      this.includedRelationshipNames === null ||
      typeof this.includedRelationshipNames === 'undefined'
    ) {
      return null;
    }

    const nestedRelationshipNames = [];
    for (const includedRelationshipName of this.includedRelationshipNames) {
      if (typeof includedRelationshipName !== 'string') {
        return h.throwError('icnluded relationship name is now a string');
      }

      const [first, ...remainder] = includedRelationshipName.split('.');
      if (first === relationshipName && remainder.length > 0) {
        nestedRelationshipNames.push(remainder.join('.'));
      }
    }
    return nestedRelationshipNames;
  }
}
