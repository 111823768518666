import { GetClubStorefrontPageQuery } from '__generated__/graphql';

// TODO: this is too brittle. We shouldn't have to import these types
// it should be written in a way where it's flexible
type Club = NonNullable<GetClubStorefrontPageQuery['club']>;
type Storefront = NonNullable<Club['storefront']>;
type Product = NonNullable<Storefront['products']>[number];
type ProductVariant = NonNullable<Product['productVariants']>[number];

export interface CartItem {
  product: Product;
  quantity: number;
  variant: ProductVariant | null;
}

export function getTotalItemsInCart(cartItems: CartItem[]): number {
  return cartItems.reduce(
    (accumulator, cartItem) => accumulator + cartItem.quantity,
    0,
  );
}

export function getTotalAmountInCentsOfCart(cartItems: CartItem[]): number {
  return cartItems.reduce(
    (accumulator, cartItem) =>
      accumulator + cartItem.product.priceInCents * cartItem.quantity,
    0,
  );
}
