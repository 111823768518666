import React, { useState } from 'react';
import ConfirmationModal from 'components/utils/confirmation_modal';

export default function ConfirmationWrapper({
  children,
  confirmationText,
  overrideConfirmButtonText,
  onClick,
}: {
  children: React.ReactNode;
  confirmationText: string;
  overrideConfirmButtonText?: string;
  onClick: () => void;
}) {
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] =
    useState(false);

  return (
    <span className="confirmation-wrapper">
      <span onClick={() => setShouldShowConfirmationModal(true)}>
        {children}
      </span>
      {shouldShowConfirmationModal && (
        <ConfirmationModal
          text={confirmationText}
          overrideConfirmButtonText={overrideConfirmButtonText}
          onCancel={() => setShouldShowConfirmationModal(false)}
          onConfirm={() => {
            onClick();
            setShouldShowConfirmationModal(false);
          }}
        />
      )}
    </span>
  );
}
