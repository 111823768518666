import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import WaiverForm, {
  buildNewWaiver,
} from 'components/clubs/admin/waivers_page/waiver_form';

const i18nScope = 'clubs.admin.waivers';

const GET_CLUB_EDIT_WAIVER = gql(`
  query GetClubEditWaiver($clubId: ID!, $waiverId: ID!) {
    club(id: $clubId) {
      id
      waiver(id: $waiverId) {
        id
        name
        content
        isRequired
      }
    }
  }
`);

export function WaiverNewPage({ currentClubId }: { currentClubId: ClubId }) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const newWaiver = useMemo(() => buildNewWaiver(), []);

  return (
    <div id="clubs-admin-waivers-page">
      <AdminDashboard
        title={i18n.t('new.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page form-page"
      >
        <WaiverForm
          clubId={String(currentClubId)}
          isEditing={false}
          waiver={newWaiver}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}

export function WaiverEditPage({ currentClubId }: { currentClubId: ClubId }) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(GET_CLUB_EDIT_WAIVER, {
    variables: {
      clubId: String(currentClubId),
      waiverId: String(id),
    },
  });
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const waiver = club.waiver;
  assertNotNullOrUndefined(waiver);

  return (
    <div id="clubs-admin-waivers-page">
      <AdminDashboard
        title={i18n.t('edit.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page form-page"
      >
        <WaiverForm
          clubId={String(currentClubId)}
          isEditing={true}
          waiver={waiver}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
