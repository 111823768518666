import h from 'h';
import React, { useMemo } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';
import Store from 'helpers/store';

import AuthenticateThenRedirectLink from 'components/utils/authenticate_then_redirect_link';
import WordRotator from 'components/utils/word_rotator';
import { DeprecatedAny } from 'types/types';

const i18nScope = 'components.welcome.homepage';

interface HeroPictureVariant {
  media: string;
  webpSrcset: string;
  jpegSrcset: string;
}

export default function HomepageWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <Homepage {...props} />
    </RootAppWrapper>
  );
}

function Homepage(props: DeprecatedAny) {
  const { i18n } = React.useContext(I18nContext);
  const store = useMemo(() => Store.parseInitData(props), []);

  const heroPictureVariants = store.getOrThrow<HeroPictureVariant[]>(
    'heroPictureVariants',
  );
  const defaultHeroPictureUrl = store.getOrThrow<string>(
    'defaultHeroPictureUrl',
  );
  const afterAuthenticateUrl = store.getOrThrow<string>('afterAuthenticateUrl');

  return (
    <div id="homepage">
      <div className="hero vertical-center">
        <div className="hero-img">
          <picture>
            {heroPictureVariants.map((heroPictureVariant, index) => (
              <React.Fragment key={index}>
                <source
                  type="image/webp"
                  srcSet={heroPictureVariant.webpSrcset}
                  media={heroPictureVariant.media}
                />
                <source
                  type="image/jpeg"
                  srcSet={heroPictureVariant.jpegSrcset}
                  media={heroPictureVariant.media}
                />
              </React.Fragment>
            ))}
            <img
              src={defaultHeroPictureUrl}
              alt={i18n.t('hero.img_alt', { scope: i18nScope })}
            />
          </picture>
        </div>
        <div className="hero-inner center-content">
          <h1>
            {i18n.t('hero.title.base', { scope: i18nScope })}{' '}
            <WordRotator
              speedInMs={1500}
              words={i18n.tArray('hero.title.rotating_words', {
                scope: i18nScope,
              })}
            />
          </h1>
          <AuthenticateThenRedirectLink
            isLoggedIn={!h.isNullOrUndefined(store.getCurrentUserIfExists())}
            redirectPath={afterAuthenticateUrl}
          >
            <button className="btn btn-primary mt-2">
              {i18n.t('hero.cta_button', { scope: i18nScope })}
            </button>
          </AuthenticateThenRedirectLink>
        </div>
      </div>
    </div>
  );
}
