import _ from 'underscore';
import { Latitude, Longitude } from 'types/types';

const DEFAULT_NUMBER_OF_MILES_WIDE = 5;
const MILES_PER_ONE_DEGREE_LONGITUDE = 54.6;
const MILES_PER_ONE_DEGREE_LATITUDE = 69;

function parseCoordinateIfNeeded(val: string | number): number {
  return _.isString(val) ? parseFloat(val) : val;
}

// HACK: we shouldn't be loading all the libraries we use any time we try
// to use google maps. We should just load the ones needed for that page.
// However, due to turbolinks if we load a page with a subset of libraries
// and then navigate to another page which uses a different set of libraries
// it will error. We would need to do a full page refresh so it will load
// the google libraries needed for that page. This is all because of the
// spaghetti way the front end and back end are working together. Can't
// wait to fix this properly.
// https://developers.google.com/maps/documentation/javascript/libraries
export const GOOGLE_MAPS_LIBRARIES_TO_LOAD: ['places'] = ['places'];

// we want to bias results within N miles from this point (presumably the club's city)
// Eg. if a user searches for A Runner's Mind it returns the one in SF and not
// one of its other locations
// https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Viewports
export function buildBounds({
  latitude,
  longitude,
  milesWide = DEFAULT_NUMBER_OF_MILES_WIDE,
}: {
  latitude: Latitude;
  longitude: Longitude;
  milesWide?: number;
}) {
  const latitudeDiff = milesWide / MILES_PER_ONE_DEGREE_LATITUDE;
  const longitudeDiff = milesWide / MILES_PER_ONE_DEGREE_LONGITUDE;

  const latitudeNumber = parseCoordinateIfNeeded(latitude);
  const longitudeNumber = parseCoordinateIfNeeded(longitude);

  const northeast = new window.google.maps.LatLng(
    latitudeNumber + latitudeDiff,
    longitudeNumber + longitudeDiff,
  );

  const southwest = new window.google.maps.LatLng(
    latitudeNumber - latitudeDiff,
    longitudeNumber - longitudeDiff,
  );

  return new window.google.maps.LatLngBounds(southwest, northeast);
}

enum AddressComponentType {
  STREET_NUMBER = 'street_number', // eg. 397
  ROUTE = 'route', // eg. Arguello Blvd
  NEIGHBORHOOD = 'neighborhood', // eg. Inner Richmond
  LOCALITY = 'locality', // eg. San Francisco
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1', // eg. California (long name) or CA (short name)
  COUNTRY = 'country', // eg. United States (long name) or US (short name)
  POSTAL_CODE = 'postal_code', // eg. 94118
  POLITICAL = 'political', // can be a neighborhood, locality, country
}

export function getAddressCity(
  addressComponents: google.maps.GeocoderAddressComponent[],
): google.maps.GeocoderAddressComponent | null {
  return getAddressComponent(addressComponents, AddressComponentType.LOCALITY);
}

export function getAddressState(
  addressComponents: google.maps.GeocoderAddressComponent[],
): google.maps.GeocoderAddressComponent | null {
  return getAddressComponent(
    addressComponents,
    AddressComponentType.ADMINISTRATIVE_AREA_LEVEL_1,
  );
}

// NOTE: I've noticed 2 types of address components
// - addresses with number and street (eg. 397 Arguello Blvd, San Francisco, CA 94118, USA)
// - addresses with just a neighborhood (without number and street) (eg. ByWard Market, Ottawa, ON K1N, Canada)
export function getAddressComponent(
  addressComponents: google.maps.GeocoderAddressComponent[],
  type: AddressComponentType,
): google.maps.GeocoderAddressComponent | null {
  return (
    addressComponents.find((component) => component.types.includes(type)) ??
    null
  );
}
