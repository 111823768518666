import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import SuperAdminCompanyForm, {
  buildNewCompany,
} from 'components/super_admin/dashboard/companies/form';

const i18nScope = 'components.super_admin.dashboard.companies.new';

export default function SuperAdminCompaniesNewPage() {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const newCompany = useMemo(() => buildNewCompany(), []);

  return (
    <div id="super-admin-dashboard-companies-new-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page skinny-page"
      >
        <SuperAdminCompanyForm
          company={newCompany}
          isEditing={false}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
