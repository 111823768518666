import React, { useState } from 'react';
import AuthenticationModal from 'components/utils/authentication_modal';

// users will be prompted with a modal to sign up or login to access the page.
// they won't be able to dismiss the modal. it's better than just redirecting
// to the login page since they get a bit of a sneak preview of the page that
// they're trying to access
export default function AuthenticationWall({
  isLoggedOut,
  children,
}: {
  isLoggedOut: boolean;
  children: React.ReactNode;
}) {
  const [shouldShowLoginModal] = useState(isLoggedOut);

  return (
    <>
      {children}
      {shouldShowLoginModal && (
        <AuthenticationModal
          canClose={false}
          afterAuthenticationUrl={window.location.href}
          onCancel={() => null}
        />
      )}
    </>
  );
}
