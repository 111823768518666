import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import { ErrorPage } from 'components/utils/pages_sidebar';
import AdminDashboard, {
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import cn from 'classnames';
import { ClubId } from 'types/types';

const i18nScope = 'clubs.admin.emails.logs.index';
const tableScope = `${i18nScope}.table`;

const GET_ADMIN_CLUB_EMAIL_BLASTS = gql(`
  query GetAdminClubEmailBlasts($clubId: ID!) {
    club(id: $clubId) {
      id
      replyTo
      allEmailBlasts {
        id
        title
        createdAt
        numberOfEmails
        numberOfEmailsSent
        numberOfEmailsDelivered
        numberOfEmailsOpened
        numberOfEmailsClicked
      }
    }
  }
`);

function StatsCell({
  totalEmails,
  count,
}: {
  totalEmails: number;
  count: number;
}) {
  return (
    <span>
      <span className="percent">
        {Math.floor((count / totalEmails) * 100)}%
      </span>
      <br />
      <span className="count small light-text-color">{count}</span>
    </span>
  );
}

export default function EmailLogsIndexPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_EMAIL_BLASTS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading)
    return (
      <AdminDashboardLoadingPlaceholder columnWidths={[60, 10, 10, 10, 10]} />
    );
  if (error) return <ErrorPage />;

  const club = data?.club;
  assertNotNullOrUndefined(club);
  const emailBlasts = club.allEmailBlasts;
  assertNotNullOrUndefined(emailBlasts);

  const sortedEmailBlasts = _.sortBy(
    emailBlasts,
    (emailBlast) => emailBlast.createdAt,
  ).reverse();

  if (sortedEmailBlasts.length === 0) {
    return (
      <div>
        <p>{i18n.t('empty_table', { scope: i18nScope })}</p>
      </div>
    );
  }

  return (
    <AdminDashboard
      title={i18n.t('title', { scope: i18nScope })}
      contentClasses="elevate-content min-height-page"
    >
      <table className="basic-table">
        <thead className="sticky">
          <tr>
            <th></th>
            <th>{i18n.t('heading.number_of_emails', { scope: tableScope })}</th>
            <th>
              {i18n.t('heading.number_of_emails_delivered', {
                scope: tableScope,
              })}
            </th>
            <th>
              {i18n.t('heading.number_of_emails_opened', { scope: tableScope })}
            </th>
            <th>
              {i18n.t('heading.number_of_emails_clicked', {
                scope: tableScope,
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedEmailBlasts.map((emailBlast) => (
            <tr key={emailBlast.id}>
              <td>
                <div className={cn('max-w400', 'pre-wrap')}>
                  <a onClick={() => navigate(emailBlast.id)}>
                    {emailBlast.title}
                  </a>
                </div>
                <div className="small light-text-color">
                  {Helpers.Utils.formatDateTime(emailBlast.createdAt)}
                </div>
              </td>
              <td>
                {emailBlast.numberOfEmails}
                <br />
                &nbsp;
              </td>
              <td>
                <StatsCell
                  totalEmails={emailBlast.numberOfEmails}
                  count={emailBlast.numberOfEmailsDelivered}
                />
              </td>
              <td>
                <StatsCell
                  totalEmails={emailBlast.numberOfEmailsSent}
                  count={emailBlast.numberOfEmailsOpened}
                />
              </td>
              <td>
                <StatsCell
                  totalEmails={emailBlast.numberOfEmailsSent}
                  count={emailBlast.numberOfEmailsClicked}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AdminDashboard>
  );
}
