import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import cn from 'classnames';

import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { getUniqueClickedLinks } from 'models/email';
import { ClubId } from 'types/types';

import AdminDashboard, {
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import DuplicateCustomEmailButton from 'components/clubs/admin/emails_page/logs/duplicate_custom_email_button';
import ShowCustomEmailContentButton from 'components/clubs/admin/emails_page/logs/show_custom_email_content_button';

const i18nScope = 'clubs.admin.emails.logs.show';
const tableScope = `${i18nScope}.table`;

const GET_ADMIN_CLUB_EMAIL_BLAST = gql(`
  query GetAdminClubEmailBlast($clubId: ID!, $emailBlastId: ID!) {
    club(id: $clubId) {
      id
      emailBlast(id: $emailBlastId) {
        id
        title
        subjectType
        subjectId
        emails {
          id
          firstOpenAt
          firstClickAt
          clicks
          recipient {
            id
            fullName
          }
        }
      }
    }
  }
`);

export default function EmailLogsShowPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_EMAIL_BLAST, {
    variables: {
      clubId: String(currentClubId),
      emailBlastId: String(id),
    },
  });

  if (loading)
    return <AdminDashboardLoadingPlaceholder columnWidths={[35, 10, 10, 45]} />;
  if (error) return <ErrorPage />;

  const club = data?.club;
  assertNotNullOrUndefined(club);
  const { emailBlast } = club;
  assertNotNullOrUndefined(emailBlast);
  const { emails } = emailBlast;
  assertNotNullOrUndefined(emails);

  const sortedEmails = _.sortBy(emails, (email) =>
    email.recipient.fullName.toLowerCase(),
  );

  return (
    <div id="clubs-admin-emails-page">
      <AdminDashboard
        title={emailBlast.title}
        contentClasses="elevate-content min-height-page"
        actions={
          <>
            <ShowCustomEmailContentButton
              classes="mr-2"
              clubId={club.id}
              targetCustomEmailId={emailBlast.subjectId}
              tooltipText={i18n.t('show_content_button', { scope: i18nScope })}
            />
            <DuplicateCustomEmailButton
              clubId={club.id}
              targetCustomEmailId={emailBlast.subjectId}
              tooltipText={i18n.t('duplicate_button', { scope: i18nScope })}
            />
          </>
        }
      >
        <div id="clubs-admin-email-log-detail-view">
          <div className="mb-4">
            <a onClick={() => navigate(-1)}>
              {i18n.t('back', { scope: i18nScope })}
            </a>
          </div>

          <div className="email-log">
            <table className="emails-table basic-table">
              <thead className="sticky">
                <tr>
                  <th>{i18n.t('heading.recipient', { scope: tableScope })}</th>
                  <th>{i18n.t('heading.did_open', { scope: tableScope })}</th>
                  <th>{i18n.t('heading.did_click', { scope: tableScope })}</th>
                  <th>
                    {i18n.t('heading.links_clicked', { scope: tableScope })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedEmails.map((email) => (
                  <tr key={email.id}>
                    <td>
                      <div className={cn('max-w300', 'pre-wrap')}>
                        <NavLink
                          to={`../../members/list/${email.recipient.id}`}
                        >
                          {email.recipient.fullName}
                        </NavLink>
                      </div>
                    </td>
                    <td>{Helpers.Utils.formatDateTime(email.firstOpenAt)}</td>
                    <td>{Helpers.Utils.formatDateTime(email.firstClickAt)}</td>
                    <td>
                      {getUniqueClickedLinks({ clicks: email.clicks }).map(
                        (link, index) => (
                          <div
                            key={index}
                            className={cn('max-w300', 'clip-long-text')}
                          >
                            <a
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                              title={link}
                              href={link}
                            >
                              {link}
                            </a>
                          </div>
                        ),
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AdminDashboard>
    </div>
  );
}
