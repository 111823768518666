import React from 'react';
import classNames from 'classnames';
import Icon, { iconType } from 'components/utils/icon';
import VerticalMenu from 'components/utils/menus/vertical_menu';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import ErrorBoundary from 'components/utils/error_boundary';
import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import { getSupportEmailAddress } from 'components/utils/contact_support';

const i18nScope = 'components.utils.pages_sidebar';

export type PageId = string;
export interface Page {
  id: PageId;
  name: string;
  canAccess?: boolean;
  emoji?: string;
  redirectTo?: string;
  cannotAccessOnMobile?: boolean;
}

export interface PagesGroup {
  name: string;
  pages: Page[];
}

// HACK: not great that we're infering the selected page from the url
// we should clean this up eventually.
function inferSelectedPageFromCurrentUrl(
  basePath: string,
  groups: PagesGroup[],
): Page | null {
  const location = useLocation();
  const pages = groups.map((g) => g.pages).flat();
  return (
    pages.find((page) =>
      location.pathname.startsWith(`${basePath}/${page.id}`),
    ) ?? null
  );
}

function PagesSidebarLink({
  page,
  getHasPermission,
}: {
  page: Page;
  getHasPermission: (page: Page) => boolean;
}) {
  const hasPermission = getHasPermission(page);

  return (
    <div>
      <NavLink
        className={({ isActive }) =>
          classNames('pages-sidebar-link', {
            'active-link': isActive,
            locked: !hasPermission,
          })
        }
        to={page.redirectTo ?? page.id}
        reloadDocument={(page.redirectTo ?? null) !== null}
      >
        <span className="pages-sidebar-item-emoji">
          {hasPermission ? page.emoji : '🔒'}
        </span>
        <span className="pages-sidebar-item-name">{page.name}</span>
      </NavLink>
    </div>
  );
}

function PagesSidebarGroup({
  group,
  getHasPermission,
}: {
  group: PagesGroup;
  getHasPermission: (page: Page) => boolean;
}) {
  return (
    <div className="pages-sidebar-group">
      <div className="pages-sidebar-group-name">{group.name}</div>
      <div className="pages-sidebar-links">
        {group.pages.map((page, index) => (
          <PagesSidebarLink
            key={index}
            page={page}
            getHasPermission={getHasPermission}
          />
        ))}
      </div>
    </div>
  );
}

function PagesSidebarDesktopMenu({
  groupElements,
}: {
  groupElements: React.ReactElement[];
}) {
  return <div className="pages-sidebar-desktop-menu">{groupElements}</div>;
}

function PagesSidebarMobileMenu({
  selectedPage,
  groupElements,
  getHasPermission,
}: {
  selectedPage: Page | null;
  groupElements: React.ReactElement[];
  getHasPermission: (page: Page) => boolean;
}) {
  return (
    <div className="pages-sidebar-mobile-menu">
      <VerticalMenu
        triggerElement={
          <div className="flex vertical-center">
            <span className="flex-1">
              {selectedPage && (
                <PagesSidebarLink
                  page={selectedPage}
                  getHasPermission={getHasPermission}
                />
              )}
            </span>
            <span style={{ marginLeft: 'auto' }}>
              <Icon type={iconType.COLLAPSED_MENU} classes="toggle-icon" />
            </span>
          </div>
        }
      >
        {groupElements}
      </VerticalMenu>
    </div>
  );
}

export function PagesLayout({
  basePath,
  groups,
  getHasPermission,
}: {
  basePath: string;
  groups: PagesGroup[];
  getHasPermission: (page: Page) => boolean;
}) {
  // HACK: not great that we're infering the selected page from the url
  // we should clean this up eventually.
  const selectedPage = inferSelectedPageFromCurrentUrl(basePath, groups);

  let outletOrBlocked = <Outlet />;
  if (selectedPage !== null) {
    const isBlockedOnMobile =
      selectedPage.cannotAccessOnMobile &&
      Helpers.ScreenSize.getIsTabletOrSmaller();

    const hasPermission = getHasPermission(selectedPage);
    if (!hasPermission) {
      outletOrBlocked = <LockedPage />;
    } else if (isBlockedOnMobile) {
      outletOrBlocked = <MobileBlockedPage />;
    }
  }

  return (
    <div id="pages">
      <div id="pages-inner">
        <PagesSidebar
          selectedPage={selectedPage}
          groups={groups}
          getHasPermission={getHasPermission}
        />
        <div id="page-content">
          <div className="page">
            <ErrorBoundary errorElement={<ErrorPage />}>
              {outletOrBlocked}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}

function PagesSidebar({
  selectedPage,
  groups,
  getHasPermission,
}: {
  selectedPage: Page | null;
  groups: PagesGroup[];
  getHasPermission: (page: Page) => boolean;
}) {
  const groupElements = groups.map((group, index) => (
    <PagesSidebarGroup
      key={index}
      group={group}
      getHasPermission={getHasPermission}
    />
  ));

  return (
    <div id="pages-sidebar">
      <PagesSidebarDesktopMenu groupElements={groupElements} />
      <PagesSidebarMobileMenu
        selectedPage={selectedPage}
        groupElements={groupElements}
        getHasPermission={getHasPermission}
      />
    </div>
  );
}

// --- REUSABLE PAGES ---

function PageScaffold({
  shouldElevateContent = false,
  heading,
  description,
}: {
  shouldElevateContent?: boolean;
  heading: string;
  description?: string | React.ReactElement;
}) {
  return (
    <div
      className={classNames('min-height-page vertical-center center', {
        'elevate-content': shouldElevateContent,
      })}
    >
      <div className="page-header" style={{ maxWidth: 550, margin: 'auto' }}>
        <div className="title">
          <h1 className="mb-0">{heading}</h1>
          {description && <p>{description}</p>}
        </div>
      </div>
    </div>
  );
}
export function LoadingPage() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <PageScaffold
      heading={i18n.t('loading_page.heading', { scope: i18nScope })}
    />
  );
}

export function ErrorPage() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <PageScaffold
      heading={i18n.t('error_page.heading', { scope: i18nScope })}
      description={
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('error_page.description_html', {
              scope: i18nScope,
              values: {
                supportEmail: getSupportEmailAddress(),
              },
            }),
          }}
        />
      }
    />
  );
}

// 404 page
export function FourOFourPage() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <PageScaffold
      heading={i18n.t('four_o_four_page.heading', { scope: i18nScope })}
      description={
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('four_o_four_page.description_html', {
              scope: i18nScope,
              values: {
                supportEmail: getSupportEmailAddress(),
              },
            }),
          }}
        />
      }
    />
  );
}

export function MobileBlockedPage() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <PageScaffold
      shouldElevateContent={true}
      heading={i18n.t('mobile_blocked_page.heading', { scope: i18nScope })}
      description={i18n.t('mobile_blocked_page.description', {
        scope: i18nScope,
      })}
    />
  );
}

export function LockedPage() {
  const { i18n } = React.useContext(I18nContext);
  return (
    <PageScaffold
      shouldElevateContent={true}
      heading={i18n.t('locked_page.heading', { scope: i18nScope })}
    />
  );
}
