import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import SubscriptionPlanForm, {
  buildNewSubscriptionPlan,
} from 'components/clubs/admin/subscription_plans/subscription_plan_form';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';

const i18nScope = 'clubs.admin.subscription_plans';

const GET_CLUB_NEW_SUBSCRIPTION_PLAN = gql(`
  query GetClubNewSubscriptionPlan($clubId: ID!) {
    club(id: $clubId) {
      id
      currency
    }
  }
`);

const GET_CLUB_EDIT_SUBSCRIPTION_PLAN = gql(`
  query GetClubEditSubscriptionPlan($clubId: ID!, $subscriptionPlanId: ID!) {
    club(id: $clubId) {
      id
      currency
      subscriptionPlan(id: $subscriptionPlanId) {
        id
        name
        available
        frequency
        priceInCents
        autoRenew
      }
    }
  }
`);

export function SubscriptionPlanNewPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const backToPlansIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const newSubscriptionPlan = useMemo(() => buildNewSubscriptionPlan(), []);

  const { loading, error, data } = useQuery(GET_CLUB_NEW_SUBSCRIPTION_PLAN, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  assertNotNullOrUndefined(club.currency);

  return (
    <div id="clubs-admin-subscription-plans-page">
      <AdminDashboard
        title={i18n.t('new.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page skinny-page"
      >
        <SubscriptionPlanForm
          club={club}
          isEditing={false}
          subscriptionPlan={newSubscriptionPlan}
          onSuccessCallback={backToPlansIndexPage}
          onCancelCallback={backToPlansIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}

export function SubscriptionPlanEditPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToPlansIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(GET_CLUB_EDIT_SUBSCRIPTION_PLAN, {
    variables: {
      clubId: String(currentClubId),
      subscriptionPlanId: String(id),
    },
  });
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  assertNotNullOrUndefined(club.currency);
  const subscriptionPlan = club.subscriptionPlan;
  assertNotNullOrUndefined(subscriptionPlan);

  return (
    <div id="clubs-admin-subscription-plans-page">
      <AdminDashboard
        title={i18n.t('edit.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page skinny-page"
      >
        <SubscriptionPlanForm
          club={club}
          isEditing={true}
          subscriptionPlan={subscriptionPlan}
          onSuccessCallback={backToPlansIndexPage}
          onCancelCallback={backToPlansIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
