import _ from 'underscore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';
import { Latitude, Longitude } from 'types/types';
import classNames from 'classnames';
import Map, {
  DEFAULT_DETAILED_ZOOM_LEVEL,
  DEFAULT_BROAD_ZOOM_LEVEL,
} from 'components/utils/map';
import I18nContext from 'contexts/i18n_context';
import { buildBounds } from 'helpers/google_maps_helper';

const i18nScope = 'forms.autocomplete_location_field';

type FormAutocompleteLocationFieldProps = FormFieldwrapperProps & {
  googleMapsApiPublicKey: string;
  longitude: Longitude | null;
  latitude: Latitude | null;
  defaultLatitude: Latitude;
  defaultLongitude: Longitude;
  broadZoomLevel?: number;
  detailedZoomLevel?: number;
  onSelectLocationCallback: (
    place: google.maps.places.PlaceResult | null,
  ) => void;
};

function parseCoordinate(val: string | number): number {
  return _.isString(val) ? parseFloat(val) : val;
}

function MapPreview({
  googleMapsApiPublicKey,
  longitude,
  latitude,
  defaultLongitude,
  defaultLatitude,
  broadZoomLevel,
  detailedZoomLevel,
}: Pick<
  FormAutocompleteLocationFieldProps,
  | 'googleMapsApiPublicKey'
  | 'longitude'
  | 'latitude'
  | 'defaultLatitude'
  | 'defaultLongitude'
  | 'broadZoomLevel'
  | 'detailedZoomLevel'
>) {
  const parsedLongitude = longitude ? parseCoordinate(longitude) : null;
  const parsedLatitude = latitude ? parseCoordinate(latitude) : null;

  const shouldShowDefaultMap =
    !_.isNumber(parsedLatitude) || !_.isNumber(parsedLongitude);
  let targetLongitude, targetLatitude;

  if (shouldShowDefaultMap) {
    targetLongitude = parseCoordinate(defaultLongitude);
    targetLatitude = parseCoordinate(defaultLatitude);
  } else {
    targetLongitude = parsedLongitude;
    targetLatitude = parsedLatitude;
  }

  return (
    <Map
      classes="mt-1"
      apiKey={googleMapsApiPublicKey}
      longitude={targetLongitude}
      latitude={targetLatitude}
      zoom={shouldShowDefaultMap ? broadZoomLevel : detailedZoomLevel}
      hideMarker={shouldShowDefaultMap}
    />
  );
}

export default function FormAutocompleteLocationField({
  children,
  name,
  classes,
  label,
  description,
  withoutErrorMessage,
  // location specific fields
  onSelectLocationCallback,
  googleMapsApiPublicKey,
  defaultLongitude,
  defaultLatitude,
  longitude,
  latitude,
  broadZoomLevel = DEFAULT_BROAD_ZOOM_LEVEL,
  detailedZoomLevel = DEFAULT_DETAILED_ZOOM_LEVEL,
}: FormAutocompleteLocationFieldProps) {
  const { i18n } = React.useContext(I18nContext);

  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const [shouldShowSearch, setShouldShowSearch] = useState(
    longitude == null && latitude == null,
  );
  const [searchText, setSearchText] = useState(field.value);

  const ref = useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();

  const onChangeLocation = useCallback(() => {
    onSelectLocationCallback(null);
    setTouched(false);
    setShouldShowSearch(true);
    setSearchText('');
    setTimeout(() => ref.current?.focus());
  }, [setValue, setTouched, setShouldShowSearch]);

  useEffect(() => {
    if (ref.current && !autocomplete) {
      const bounds = buildBounds({
        latitude: defaultLatitude,
        longitude: defaultLongitude,
      });

      const newAutocomplete = new window.google.maps.places.Autocomplete(
        ref.current,
        {
          bounds,
          strictBounds: false,
          fields: [
            'place_id',
            'address_component',
            'adr_address',
            'formatted_address',
            'name',
            'geometry',
          ],
        },
      );

      // NOTE: there can be a delay between when the user
      // selected an address and when the place_changed callback
      // is triggered. This is because a request is being sent
      // to google to fetch the data about the place. Ideally, we
      // show some sort of loading state in that period but I couldn't
      // find the appropriate listener. Something to consider in
      // the future.

      newAutocomplete.addListener('place_changed', () => {
        const place = newAutocomplete.getPlace();

        if (place !== null && typeof place.formatted_address !== 'undefined') {
          onSelectLocationCallback(place);
          setShouldShowSearch(false);
        }
      });

      setAutocomplete(newAutocomplete);
    }
  }, [ref, setSearchText, setShouldShowSearch]);

  return (
    <div className="form-location-autocomplete-field-outer">
      <FormFieldWrapper
        name={name}
        classes={classes}
        label={label}
        description={description}
        withoutErrorMessage={withoutErrorMessage}
      >
        <div className="form-field form-autocomplete-location-field">
          <input
            autoComplete="off"
            className={classNames({ hide: !shouldShowSearch })}
            ref={ref}
            type="text"
            name={name}
            value={searchText}
            onChange={(event) => {
              const text = event.target?.value ?? '';
              setSearchText(text);
              setTouched(true);
            }}
            onKeyDown={(event) => {
              // on enter
              if (event.keyCode === 13) {
                // we don't want to submit the form when the user
                // hits enter, most likely they're simply selecting
                // an option from the drop down
                event.preventDefault();
              }
            }}
          />

          <div
            className={classNames({ hide: shouldShowSearch })}
            style={{ marginLeft: '5px' }}
          >
            <div>{children ?? field.value}</div>
            <a onClick={onChangeLocation}>
              {i18n.t('change', { scope: i18nScope })}
            </a>
          </div>
        </div>
      </FormFieldWrapper>
      <MapPreview
        googleMapsApiPublicKey={googleMapsApiPublicKey}
        longitude={longitude}
        latitude={latitude}
        defaultLongitude={defaultLongitude}
        defaultLatitude={defaultLatitude}
        broadZoomLevel={broadZoomLevel}
        detailedZoomLevel={detailedZoomLevel}
      />
    </div>
  );
}
