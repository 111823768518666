// I created this wrapper component around Linkify because
// we may need to replace it at some point. Not sure if it's
// being maintained. I chose it because it was a quick wrapper
// around react, small in size, and seemed to have quite a few
// downloads still.
//
// However, there's a bug in the latest alpha version where it
// doesn't respect the properties prop used to do things like
// <Linkify properties={{target: '_blank'}}> ....
// which will add those properties to every link detected. So
// using a decorator pattern as someone commented in this issue
// https://github.com/tasti/react-linkify/issues/96

import React from 'react';
import Linkify from 'react-linkify';

// "noopener noreferrer" are due to security concerns
// "nofollow" is for seo reasons, for now we're assuming allow linkified content
// is user generated so we don't want to share our reputation with unverified websites
const _decorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number,
): React.ReactNode => (
  <a
    target="_blank"
    rel="noopener noreferrer nofollow"
    href={decoratedHref}
    key={key}
  >
    {decoratedText}
  </a>
);

export default function Linkifier({
  children,
}: {
  children: React.ReactElement | React.ReactNode | string;
}) {
  return <Linkify componentDecorator={_decorator}>{children}</Linkify>;
}
