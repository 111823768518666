import React from 'react';
import { GetClubStorefrontPageQuery } from '__generated__/graphql';
import I18nContext from 'contexts/i18n_context';
import {
  CartItem,
  getTotalItemsInCart,
} from 'components/clubs/storefronts/cart';
import CheckoutButton from 'components/clubs/storefronts/checkout_button';

const i18nScope = 'components.clubs.storefronts.cart_detail_view';

// TODO: this is too brittle. We shouldn't have to import these types
// it should be written in a way where it's flexible
type Club = NonNullable<GetClubStorefrontPageQuery['club']>;
type Storefront = NonNullable<Club['storefront']>;
type Product = NonNullable<Storefront['products']>[number];
type ProductVariant = NonNullable<Product['productVariants']>[number];

function EmptyCart({ onDoneCallback }: { onDoneCallback: () => void }) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="empty-cart vertical-center center">
      <div>
        <h2>{i18n.t('empty_cart.heading', { scope: i18nScope })}</h2>
        <button className="btn btn-primary mt-2" onClick={onDoneCallback}>
          {i18n.t('empty_cart.button_cta', { scope: i18nScope })}
        </button>
      </div>
    </div>
  );
}

function NotEmptyCart({
  cartItems,
  addCartItemCallback,
  removeCartItemCallback,
  goToCheckout,
  onDoneCallback,
}: {
  cartItems: CartItem[];
  addCartItemCallback: ({
    product,
    variant,
  }: {
    product: Product;
    variant: ProductVariant | null;
  }) => void;
  removeCartItemCallback: ({
    product,
    variant,
  }: {
    product: Product;
    variant: ProductVariant | null;
  }) => void;
  goToCheckout: () => void;
  onDoneCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="not-empty-cart">
      <h2>
        {i18n.t('heading', {
          scope: i18nScope,
          count: getTotalItemsInCart(cartItems),
        })}
      </h2>

      <div className="cart-items">
        {cartItems.map((cartItem, index) => (
          <div className="cart-item" key={index}>
            <div className="cart-item-info-wrapper">
              <span className="cart-item-img-preview">
                <img src={cartItem.product.imageUrls?.[0]} />
              </span>
              <span className="cart-item-info">
                <span className="bold">{cartItem.product.name}</span>
                <br />
                {cartItem.variant !== null && (
                  <>
                    <span className="small">{cartItem.variant.variant}</span>
                    <br />
                  </>
                )}
                <span className="small">
                  {i18n.c({ amountInCents: cartItem.product.priceInCents })}
                </span>
              </span>
            </div>
            <div className="cart-item-actions-wrapper vertical-center">
              <span className="quantity-wrapper">
                <span
                  className="quantity-toggle left-quantity-toggle"
                  onClick={() =>
                    removeCartItemCallback({
                      product: cartItem.product,
                      variant: cartItem.variant,
                    })
                  }
                >
                  -
                </span>
                {cartItem.quantity}
                <span
                  className="quantity-toggle right-quantity-toggle"
                  onClick={() =>
                    addCartItemCallback({
                      product: cartItem.product,
                      variant: cartItem.variant,
                    })
                  }
                >
                  +
                </span>
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="cart-actions">
        <button className="btn btn-secondary mr-2" onClick={onDoneCallback}>
          {i18n.t('actions.back', { scope: i18nScope })}
        </button>

        <CheckoutButton
          cartItems={cartItems}
          goToCheckout={() => {
            goToCheckout();
            onDoneCallback();
          }}
        />
      </div>
    </div>
  );
}

export default function CartDetailView({
  cartItems,
  addCartItemCallback,
  removeCartItemCallback,
  goToCheckout,
  onDoneCallback,
}: {
  cartItems: CartItem[];
  addCartItemCallback: ({
    product,
    variant,
  }: {
    product: Product;
    variant: ProductVariant | null;
  }) => void;
  removeCartItemCallback: ({
    product,
    variant,
  }: {
    product: Product;
    variant: ProductVariant | null;
  }) => void;
  goToCheckout: () => void;
  onDoneCallback: () => void;
}) {
  const totalItemsInCarts = getTotalItemsInCart(cartItems);

  return (
    <div className="storefronts-cart-detail-view">
      {totalItemsInCarts === 0 ? (
        <EmptyCart onDoneCallback={onDoneCallback} />
      ) : (
        <NotEmptyCart
          cartItems={cartItems}
          addCartItemCallback={addCartItemCallback}
          removeCartItemCallback={removeCartItemCallback}
          goToCheckout={goToCheckout}
          onDoneCallback={onDoneCallback}
        />
      )}
    </div>
  );
}
