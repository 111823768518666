import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { StripeAccountOnboardingStateEnum } from '__generated__/graphql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import { ClubId } from 'types/types';

const i18nScope = 'clubs.admin.money.bank_account';

const GET_ADMIN_CLUB_BANK_ACCOUNT = gql(`
  query GetAdminClubBankAccount($clubId: ID!) {
    club(id: $clubId) {
      id
      stripeAccount {
        onboardingDetails {
          state
          ctaOnboardingUrl
          errors
        }
      }
    }
  }
`);

export default function BankAccountPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);

  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_BANK_ACCOUNT, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const stripeAccount = data?.club?.stripeAccount;
  assertNotNullOrUndefined(stripeAccount);
  const onboardingDetails = stripeAccount.onboardingDetails;
  assertNotNullOrUndefined(onboardingDetails);

  const i18nScopeWithState = `${i18nScope}.state.${onboardingDetails.state}`;

  const _renderErrorsWithCta = () => {
    const errors = onboardingDetails.errors;
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n.t('errors_description_html', {
              scope: i18nScopeWithState,
              count: errors.length,
            }),
          }}
        />
        <ul>
          {_.map(errors, (error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
        <a href={onboardingDetails.ctaOnboardingUrl} target="_blank">
          <button className="btn btn-primary">
            {i18n.t('cta', {
              scope: i18nScopeWithState,
              count: onboardingDetails.errors.length,
            })}
          </button>
        </a>
      </div>
    );
  };

  const _renderCta = () => {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n.t('description_html', { scope: i18nScopeWithState }),
          }}
        />
        <a href={onboardingDetails.ctaOnboardingUrl} target="_blank">
          <button className="btn btn-primary">
            {i18n.t('cta', { scope: i18nScopeWithState })}
          </button>
        </a>
      </div>
    );
  };

  return (
    <div id="clubs-admin-bank-account-page">
      <AdminDashboard
        title={i18n.t('heading', { scope: i18nScopeWithState })}
        contentClasses="elevate-content min-height-page"
      >
        <div style={{ width: '500px' }}>
          {onboardingDetails.state === StripeAccountOnboardingStateEnum.Errors
            ? _renderErrorsWithCta()
            : _renderCta()}
        </div>
      </AdminDashboard>
    </div>
  );
}
