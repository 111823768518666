import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import _str from 'underscore.string';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAdminClubCustomEmailDraftsQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';

import { sendMutation } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import { ErrorPage } from 'components/utils/pages_sidebar';
import AdminDashboard, {
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import AdminTable, { DestroyConfig } from 'components/utils/admin/admin_table';
import { ClubId } from 'types/types';
import CreateCustomEmailButton from 'components/clubs/admin/emails_page/drafts/create_custom_email_button';

const i18nScope = 'clubs.admin.emails.drafts.index';
const tableScope = `${i18nScope}.table`;

type Club = NonNullable<GetAdminClubCustomEmailDraftsQuery['club']>;
type ClubCustomEmail = Club['customEmails'][number];

const GET_ADMIN_CLUB_CUSTOM_EMAIL_DRAFTS = gql(`
  query GetAdminClubCustomEmailDrafts($clubId: ID!) {
    club(id: $clubId) {
      id
      replyTo
      customEmails(filters: [draft]) {
        id
        title
        updatedAt
        createdBy {
          id
          fullName
        }
      }
    }
  }
`);

const ADMIN_CLUB_CUSTOM_EMAIL_DESTROY = gql(`
  mutation ClubCustomEmailDestroy($input: ClubCustomEmailDestroyInput!) {
    clubCustomEmailDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

function _getCustomEmailTitle({
  customEmail,
}: {
  customEmail: ClubCustomEmail;
}) {
  const { i18n } = React.useContext(I18nContext);
  return _str.isBlank(customEmail.title ?? '')
    ? i18n.t('blank_email_title', { scope: i18nScope })
    : customEmail.title;
}

export default function EmailDraftsIndexPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [destroyMutation] = useMutation(ADMIN_CLUB_CUSTOM_EMAIL_DESTROY);

  const navigate = useNavigate();
  const { loading, error, data } = useQuery(
    GET_ADMIN_CLUB_CUSTOM_EMAIL_DRAFTS,
    {
      variables: {
        clubId: String(currentClubId),
      },
    },
  );

  if (loading)
    return <AdminDashboardLoadingPlaceholder columnWidths={[85, 15]} />;
  if (error) return <ErrorPage />;

  const club = data?.club;
  assertNotNullOrUndefined(club);
  const customEmailDrafts = club.customEmails;
  assertNotNullOrUndefined(customEmailDrafts);

  const sortedCustomEmailDrafts = _.sortBy(
    customEmailDrafts,
    (customEmail) => customEmail.updatedAt,
  ).reverse();

  const destroyConfig: DestroyConfig<ClubCustomEmail> = {
    getConfirmText: (customEmail) =>
      i18n.t('table.actions.delete_confirmation', {
        scope: i18nScope,
        values: {
          title: _getCustomEmailTitle({ customEmail }),
        },
      }),
    destroyCallback: (customEmail) => {
      // optimistic remove, and then we add it back in if it fails
      sendMutation({
        mutationName: 'clubCustomEmailDestroy',
        main: () =>
          destroyMutation({
            variables: { input: { id: customEmail.id } },
            // // TODO: the update function runs but the component
            // // does not re-render so there is no optimistic update
            // optimisticResponse: {
            //   clubCustomEmailDestroy: {
            //     deletedId: customEmail.id,
            //     errors: [],
            //   },
            // },
            update: (cache, result, options) => {
              const deletedId = options?.variables?.input?.id;
              assertNotNullOrUndefined(deletedId);

              const idToRemove = cache.identify({
                __typename: 'ClubCustomEmail',
                id: deletedId,
              });

              cache.evict({ id: idToRemove });
              cache.gc();
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <AdminDashboard
      title={i18n.t('title', { scope: i18nScope })}
      actions={
        <CreateCustomEmailButton
          club={club}
          buttonText={i18n.t('create_custom_email_button', {
            scope: i18nScope,
          })}
        />
      }
      contentClasses="elevate-content min-height-page"
    >
      <div id="clubs-admin-emails-drafts-tab">
        <AdminTable
          items={sortedCustomEmailDrafts}
          emptyTableElement={
            <p>{i18n.t('empty_table', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.title', { scope: tableScope })}</th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(customEmail) => (
            <td>
              {_getCustomEmailTitle({ customEmail })}
              <br />
              <span className="small light-text-color">
                {customEmail.createdBy.fullName}, &nbsp;
                {Helpers.Utils.formatDateTime(customEmail.updatedAt)}
              </span>
            </td>
          )}
          onClickEditCallback={(customEmailDraft) =>
            navigate(`${customEmailDraft.id}/edit`)
          }
          destroyConfig={destroyConfig}
        />
      </div>
    </AdminDashboard>
  );
}
