import { arrayFilterNulls } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import I18nContext from 'contexts/i18n_context';
import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import AdminTable from 'components/utils/admin/admin_table';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { useNavigate } from 'react-router-dom';
import { ClubProfilePic, picSize } from 'components/utils/profile_pic';
import LoadMore from 'components/utils/load_more';

const i18nScope = 'components.super_admin.dashboard.clubs.index';
const tableScope = `${i18nScope}.table`;

const GET_SUPER_ADMIN_CLUBS = gql(`
  query GetSuperAdminClubs($cursor: String) {
    clubs(after: $cursor) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          logoUrl
          name
          website
          city
          state
        }
      }
    }
  }
`);

export default function SuperAdminClubsIndexPage() {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();

  const { loading, error, data, fetchMore } = useQuery(GET_SUPER_ADMIN_CLUBS);

  if (loading)
    return <AdminDashboardLoadingPlaceholder columnWidths={[35, 20, 35, 10]} />;
  if (error) return <ErrorPage />;

  const clubsConnection = data?.clubs;
  const pageInfo = clubsConnection?.pageInfo;
  const clubs = arrayFilterNulls(
    (clubsConnection?.edges ?? []).map((e) => e?.node),
  );

  return (
    <div id="super-admin-dashboard-clubs-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page"
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_club_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
      >
        <AdminTable
          items={clubs}
          emptyTableElement={
            <p>{i18n.t('empty_table', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.name', { scope: tableScope })}</th>
              <th>{i18n.t('heading.location', { scope: tableScope })}</th>
              <th>{i18n.t('heading.website', { scope: tableScope })}</th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(club) => (
            <>
              <td>
                <ClubProfilePic
                  classes="inline-block mr-1"
                  club={club}
                  size={picSize.SMALL}
                />
                <span>{club.name}</span>
              </td>
              <td>
                {club.city}, {club.state}
              </td>
              <td>
                {club.website && (
                  <a href={club.website} target="_blank">
                    {club.website}
                  </a>
                )}
              </td>
            </>
          )}
          onClickEditCallback={(club) => navigate(`${club.id}/edit`)}
        />
        <LoadMore
          canLoadMore={pageInfo?.hasNextPage}
          onClick={() =>
            fetchMore({
              variables: {
                cursor: pageInfo?.endCursor,
              },
            })
          }
        />
      </AdminDashboard>
    </div>
  );
}
