// centralizing all color logic to one file
import { isDarkMode } from 'helpers/dark_mode_helpers';

type HexColor = string;

interface StripeCreditCardFieldColors {
  errorTextColor: HexColor;
  errorIconColor: HexColor;
  iconColor: HexColor;
  textColor: HexColor;
  placeholderColor: HexColor;
}

export default class ColorHelper {
  static getStripeCreditCardFieldColors(): StripeCreditCardFieldColors {
    const errorColors = {
      errorTextColor: '#db0a23',
      errorIconColor: '#db0a23',
    };
    if (isDarkMode()) {
      return {
        ...errorColors,
        iconColor: '#ADB5BD',
        textColor: '#E9ECEF',
        placeholderColor: '#CED4DA',
      };
    } else {
      return {
        ...errorColors,
        iconColor: '#6C757D',
        textColor: '#1B0E11',
        placeholderColor: '#aab7c4',
      };
    }
  }
}
