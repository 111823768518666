import React from 'react';
import classNames from 'classnames';

export default function FormActionButton({
  children,
  className,
  style,
  text,
  isSubmitting,
  isDisabled,
  handleClick,
}: {
  children?: React.ReactElement | React.ReactElement[];
  className?: string;
  style?: React.CSSProperties;
  text?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  handleClick?: (e: React.MouseEvent) => void;
}) {
  let contents = children || text || 'Save';
  if (isSubmitting) {
    contents = <i className="fas fa-circle-notch fa-spin"></i>;
  }

  const _onClick = (e: React.MouseEvent) => {
    if (handleClick) {
      e.preventDefault();
      handleClick?.(e);
    }
  };

  return (
    <button
      type="submit"
      className={classNames('form-action-button', className)}
      style={style}
      disabled={isSubmitting || isDisabled}
      onClick={_onClick}
    >
      {contents}
    </button>
  );
}
