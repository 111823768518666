import _ from 'underscore';
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

import ImageCropperModal from 'components/utils/image_cropper_modal';

export interface ImageUploaderProps {
  name: string;
  children?: React.ReactElement; // either children or existingImageUrl
  classes?: string;
  existingImageUrl?: string; // or children
  cropModalTitle: string;
  cropModalSubmit: string;
  aspectRatio: number;
  onChangeCallback: (name: string, croppedImage: File) => void;
}

export default function ImageUploader({
  name,
  children,
  classes,
  existingImageUrl,
  cropModalTitle,
  cropModalSubmit,
  aspectRatio,
  onChangeCallback,
}: ImageUploaderProps) {
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [isCropMode, setIsCropMode] = useState(false);
  const ref = useRef(null);

  const onDrop = function (acceptedFiles: File[]) {
    if (_.isEmpty(acceptedFiles)) {
      return;
    }
    const image = acceptedFiles[0];

    // preview and crop image before uploading
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const imageUrl = event.target?.result;
      if (imageUrl === null || typeof imageUrl === 'undefined') {
        return; // do nothing or throw an error
      }
      if (typeof imageUrl !== 'string') {
        // hmm, it could be an ArrayBuffer. Not sure when that could
        // happen and how to handle that
        return;
      }

      setUploadedImageUrl(imageUrl);
      setIsCropMode(true);
    };
    reader.readAsDataURL(image);
    setUploadedImage(image);
  };

  const onCancelCropCallback = () => {
    setUploadedImageUrl(null);
    setCroppedImageUrl(null);
    setIsCropMode(false);
  };
  const onSuccessCropCallback = (
    croppedImage: File,
    croppedImageUrl: string,
  ) => {
    const result = onChangeCallback(name, croppedImage);
    Promise.resolve(result).finally(() => {
      setIsCropMode(false);
      setCroppedImageUrl(croppedImageUrl);
    });
  };

  // if the user hasn't uploaded and cropped anything then
  // we show the current selection
  const _renderImage = () => {
    if (croppedImageUrl) {
      return <img src={croppedImageUrl} />;
    } else {
      return <img src={existingImageUrl} />;
    }
  };

  return (
    <div ref={ref} className={classNames('image-uploader', classes)}>
      <Dropzone accept="image/jpeg, image/png" multiple={false} onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={classNames('dropzone', {
                'dropzone--isActive': isDragActive,
              })}
            >
              <input {...getInputProps()} />
              {children ?? _renderImage()}
            </div>
          );
        }}
      </Dropzone>
      {isCropMode && uploadedImage !== null && uploadedImageUrl !== null && (
        <ImageCropperModal
          title={cropModalTitle}
          submitText={cropModalSubmit}
          image={uploadedImage}
          imageUrl={uploadedImageUrl}
          aspectRatio={aspectRatio}
          onCancelCallback={onCancelCropCallback}
          onSuccessCallback={onSuccessCropCallback}
        />
      )}
    </div>
  );
}
