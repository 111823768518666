import _str from 'underscore.string';

import { FormikHelpers } from 'formik';
import { FetchResult } from '@apollo/client';
import { ResponseError } from '__generated__/graphql';

type APIServerErrorMessages = {
  [key: string]: string[];
  // TODO: might be able to get stronger type checking. It should only
  // have errors for attributes on the model or `base` so we might be
  // able to do something like below, but wasn't able to get it working
  // quickly so moving on
  // [key in T]: string[];
  // base: string[];
};

type GraphqlMutationTopLevelErrorResponse = {
  errors: Record<string, string>;
};

// Note: a success mutation response, doesn't mean there were no errors.
// It means there were no top level errors but there could be mutation
// errors. eg create a new emergency contact cannot have a blank name
type GraphqlMutationSuccessResponse = {
  // the mutation name is dynamic so we support
  // any key. there probably is a better way to
  // narrow it down using generics
  [key: string]:
    | {
        errors: ResponseError[];
        [key: string]: unknown;
      }
    | string
    | undefined
    | null;
};

type GraphqlMutationResponse =
  | GraphqlMutationTopLevelErrorResponse
  | GraphqlMutationSuccessResponse;

export function sendMutation<T extends GraphqlMutationResponse>({
  mutationName,
  main,
  successCallback,
  errorCallback,
  unknownServerErrorCallback,
  allErrorsCallback,
}: {
  mutationName: keyof T;
  main: () => Promise<FetchResult<T>>;
  successCallback?: (mutationPayload: T) => void;
  errorCallback?: (errors: ResponseError[]) => void;
  unknownServerErrorCallback?: () => void;
  // it works for mutation errors + server errors
  allErrorsCallback?: () => void;
}) {
  if (allErrorsCallback) {
    unknownServerErrorCallback =
      unknownServerErrorCallback ?? allErrorsCallback;
    errorCallback = errorCallback ?? allErrorsCallback;
  }

  // the graphql response can fail in multiple ways
  // - the request returns a 500 or 422, so the request didn't make it to graphql
  // - the request returns a 200 but the graphql mutation was invalid
  // - the request returns a 200 but the mutation failed (our own errors)
  const promise = main();
  return promise
    .then((response) => {
      const mutationPayload = response.data;

      if (
        mutationPayload === null ||
        typeof mutationPayload === 'undefined' ||
        ('errors' in mutationPayload &&
          typeof mutationPayload.errors === 'object')
      ) {
        // graphql mutation was invalid probably
        unknownServerErrorCallback?.();
        return;
      }

      const mutationResponseData = mutationPayload[mutationName];

      const errors: ResponseError[] =
        mutationResponseData !== null &&
        typeof mutationResponseData === 'object' &&
        'errors' in mutationResponseData
          ? (mutationResponseData.errors as ResponseError[])
          : [];

      if (errors.length > 0) {
        errorCallback?.(errors);
        return;
      } else {
        try {
          successCallback?.(mutationPayload);
        } catch (error) {
          // catch if the callback failed
          console?.error(error);
          unknownServerErrorCallback?.();
        }
        return;
      }
    })
    .catch(() => {
      // handles 500 errors
      unknownServerErrorCallback?.();
    });
}

export function sendMutationAndUpdateForm<
  T,
  U extends GraphqlMutationResponse,
>({
  mutationName,
  actions,
  resetSpinnerAfterSuccess = false,
  main,
  successCallback,
  // this is the mutation error callback, not the server error callback
  errorCallback,
}: {
  mutationName: keyof U;
  actions: FormikHelpers<T>;
  resetSpinnerAfterSuccess?: boolean;
  main: () => Promise<FetchResult<U>>;
  successCallback?: (mutationPayload: U) => void;
  errorCallback?: (errors: ResponseError[] | null) => void;
}) {
  resetFormErrorsAndMarkTouched({ actions });

  sendMutation({
    main,
    mutationName,
    successCallback: (mutationPayload) => {
      if (resetSpinnerAfterSuccess) {
        actions.setSubmitting(false);
      }
      // it's up to the success callback to decide
      // whether to setSubmitting(false). depending
      // on the context we may wish to keep the
      // spinner going
      successCallback?.(mutationPayload);
    },
    errorCallback: (errors: ResponseError[]): void => {
      actions.setSubmitting(false);
      const serverErrors = _convertGqlErrorsForUser(errors);
      actions.setStatus({ serverErrors: serverErrors });
      errorCallback?.(errors);
    },
    unknownServerErrorCallback: () => {
      actions.setSubmitting(false);
      actions.setStatus({ serverErrors: _getUnknownServerErrors() });
      errorCallback?.(null);
    },
  });
}

export function resetFormErrorsAndMarkTouched<T>({
  actions,
}: {
  actions: FormikHelpers<T>;
}): void {
  actions.setTouched({});
  actions.setErrors({});
  actions.setStatus({ serverErrors: null });
}

function _convertGqlErrorsForUser(
  errors: ResponseError[],
): APIServerErrorMessages {
  const errorMessages: APIServerErrorMessages = {};
  errors.forEach((error) => {
    errorMessages[_str.camelize(error.attribute)] = error.messages;
  });
  return errorMessages;
}

function _getUnknownServerErrors() {
  return { base: [window.translations.forms.errors.unknown_error] };
}
