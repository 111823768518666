import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import BasicModal from 'components/utils/basic_modal';

import { ClubId } from 'types/types';
import CustomEmailForm from 'components/clubs/admin/emails_page/drafts/custom_email_form';

// const i18nScope = 'clubs.admin.emails.drafts.edit';

const GET_ADMIN_CLUB_EDIT_CUSTOM_EMAIL_DRAFT = gql(`
  query GetClubAdminEditCustomEmailDraft($clubId: ID!, $customEmailId: ID!) {
    club(id: $clubId) {
      id
      customEmail(id: $customEmailId) {
        id
        title
        body
        audienceType
        replyTo
        sentAt
      }
    }
    currentUser {
      id
      emailAddress
    }
  }
`);

export default function EmailDraftEditPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(
    GET_ADMIN_CLUB_EDIT_CUSTOM_EMAIL_DRAFT,
    {
      variables: {
        clubId: String(currentClubId),
        customEmailId: String(id),
      },
    },
  );
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const { currentUser, club } = data ?? {};
  assertNotNullOrUndefined(currentUser);
  assertNotNullOrUndefined(club);
  const { customEmail } = club;
  assertNotNullOrUndefined(customEmail);

  if (customEmail.sentAt !== null) {
    backToIndexPage();
    return null;
  }

  return (
    <div id="clubs-admin-emails-page">
      <BasicModal
        className="basic-modal-content basic-form custom-email-form-modal full-screen"
        onRequestCloseCallback={backToIndexPage}
        shouldCloseOnEsc={false}
        shouldShowCloseButton={false}
        isOpen={true}
      >
        <CustomEmailForm
          currentUserEmailAddress={currentUser.emailAddress}
          clubId={club.id}
          customEmail={customEmail}
          onDoneCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </BasicModal>
    </div>
  );
}
