import React from 'react';

// this component is used to handle the presentation fo content
// written with the form content editable field, and specifically
// TipTap / ProseMirror

// .ProseMirror enforces a min-height when used in forms but when
// presenting we just want the height to be that of the content.

export function ContentEditablePresenter({ content }: { content: string }) {
  return (
    <div
      className="ProseMirror"
      style={{ minHeight: 'fit-content' }}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
}
