import React from 'react';

import BasicModal from 'components/utils/basic_modal';

export default function ConfirmationModal({
  text,
  overrideConfirmButtonText,
  overrideCancelButtonText,
  onConfirm,
  onCancel,
}: {
  text: string;
  overrideConfirmButtonText?: string;
  overrideCancelButtonText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  // TODO i18n, ideally there's a way to read from a i18n provider
  // instead of having to pass it down all the way to this modal
  return (
    <BasicModal
      className="basic-modal-content confirmation-modal"
      onRequestCloseCallback={onCancel}
      isOpen={true}
    >
      <>
        <h3 className="text">{text}</h3>
        <div className="actions mt-4">
          <button type="button" className="btn btn-primary" onClick={onConfirm}>
            {overrideConfirmButtonText ?? 'Ok'}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            {overrideCancelButtonText ?? 'Cancel'}
          </button>
        </div>
      </>
    </BasicModal>
  );
}
