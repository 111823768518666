import { assertNotNullOrUndefined } from 'h';
import React, { useCallback, useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';

import { ClubId } from 'types/types';
import {
  FourOFourPage,
  Page,
  PagesGroup,
  PagesLayout,
} from 'components/utils/pages_sidebar';
import AdminPageLayout from 'components/utils/admin/admin_page_layout';

import AddOnsPage from 'components/clubs/admin/add_ons_page';
import InsightsPage from 'components/clubs/admin/insights_page';
import OverviewPage from 'components/clubs/admin/overview_page';
import HelpPage from 'components/clubs/admin/help_page';

// plans
import SubscriptionPlansPage from 'components/clubs/admin/subscription_plans_page';
import {
  SubscriptionPlanNewPage,
  SubscriptionPlanEditPage,
} from 'components/clubs/admin/subscription_plans/subscription_plan_new_or_edit_page';
// events
import EventsSettingsPage from 'components/clubs/admin/events/settings';
import EventsEmbedPage from 'components/clubs/admin/events/embed';
// storefront
import StorefrontPage from 'components/clubs/admin/storefront_page';
import {
  StorefrontProductEditPage,
  StorefrontProductNewPage,
} from 'components/clubs/admin/storefront_page/product_new_or_edit_page';
// waivers
import WaiversPage from 'components/clubs/admin/waivers_page';
import {
  WaiverEditPage,
  WaiverNewPage,
} from 'components/clubs/admin/waivers_page/waivers_new_or_edit_page';
// settings
import SettingsPage from 'components/clubs/admin/settings_page';
import { SettingsEditPage } from 'components/clubs/admin/settings_page/settings_edit_page';
// settings, notifications
import ClubNotificationsSettingsIndexPage from 'components/clubs/admin/settings_page/notifications';
import ClubNotificationsSettingNewPage from 'components/clubs/admin/settings_page/notifications/new';
import ClubNotificationsSettingEditPage from 'components/clubs/admin/settings_page/notifications/edit';
// sponsorships
import DealsIndexPage from 'components/clubs/admin/deals_page';
import {
  SponsorshipEditPage,
  SponsorshipNewPage,
} from 'components/clubs/admin/deals_page/sponsorship_new_or_edit_page';
import SponsorshipsEmbedPage from 'components/clubs/admin/deals_page/embed';

// profile
import ClubProfilePage from 'components/clubs/admin/club_profile_page';
import { ClubProfileEditPage } from 'components/clubs/admin/club_profile_page/club_profile_edit_page';
// issue#122
// import ProfilePage from 'components/clubs/admin/profile_page'

// permission groups
import PermissionGroupsPage from 'components/clubs/admin/permission_groups_page';
import {
  PermissionGroupEditPage,
  PermissionGroupManagePage,
  PermissionGroupNewPage,
} from 'components/clubs/admin/permission_groups_page/permission_group_new_or_edit_page';
// emails
import EmailDraftsIndexPage from 'components/clubs/admin/emails_page/drafts';
import EmailDraftEditPage from 'components/clubs/admin/emails_page/drafts/edit';
import EmailLogsIndexPage from 'components/clubs/admin/emails_page/logs';
import EmailLogsShowPage from 'components/clubs/admin/emails_page/logs/show';
// custom pages
import CustomPagesIndexPage from 'components/clubs/admin/pages';
import CustomPagesEditPage from 'components/clubs/admin/pages/edit';
// memberships
import MembershipsIndexPage from 'components/clubs/admin/memberships_page';
import MembershipShowLayout from 'components/clubs/admin/memberships_page/show/layout';
import MembershipShowInfoPage from 'components/clubs/admin/memberships_page/show/info';
import MembershipShowEmailsPage from 'components/clubs/admin/memberships_page/show/emails';
import MembershipShowEmergencyPage from 'components/clubs/admin/memberships_page/show/emergency';
import MembershipShowLogsPage from 'components/clubs/admin/memberships_page/show/logs';
import MembershipsNewPage from 'components/clubs/admin/memberships_page/new';
// membership groups
import MembershipTagsIndexPage from 'components/clubs/admin/memberships_page/tags';
import MembershipTagsNewPage from 'components/clubs/admin/memberships_page/tags/new';
import MembershipTagsEditPage from 'components/clubs/admin/memberships_page/tags/edit';
// money - orders
import OrdersIndexPage from 'components/clubs/admin/money/orders';
import OrdersShowPage from 'components/clubs/admin/money/orders/show';
// money - bank account
import BankAccountPage from 'components/clubs/admin/money/bank_account';

const i18nScope = 'clubs.admin';

// TODO: issue#382 we need to keep these in sync with
// app/services/clubs/admin/sidebar_service.rb
enum AdminDashboardPageId {
  ADD_ONS = 'add_ons',
  MONEY = 'money',
  DEALS = 'deals',
  EMAILS = 'emails',
  EVENTS = 'events',
  INSIGHTS = 'insights',
  MEMBERS = 'members',
  PAGES = 'pages',
  PERMISSIONS = 'permissions',
  PROFILE = 'profile',
  PLANS = 'plans',
  OVERVIEW = 'overview',
  HELP = 'help',
  SETTINGS = 'settings',
  WAIVERS = 'waivers',
  STOREFRONT = 'storefront',
}

enum AdminDashboardMoneyPageId {
  BANK_ACCOUNT = 'bank_account',
  ORDERS = 'orders',
}

export default function AdminDashboard({
  adminDashboardPath,
  sidebar,
  currentClubId,
}: {
  adminDashboardPath?: string;
  sidebar?: PagesGroup[];
  currentClubId?: ClubId;
}) {
  assertNotNullOrUndefined(adminDashboardPath);
  assertNotNullOrUndefined(sidebar);
  assertNotNullOrUndefined(currentClubId);

  const { i18n } = useContext(I18nContext);

  const _getHasPermission = useCallback((page: Page) => !!page.canAccess, []);

  const pageProps = {
    currentClubId,
  };

  return (
    <RootAppWrapper>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${adminDashboardPath}/*`}
            element={
              <PagesLayout
                basePath={adminDashboardPath}
                groups={sidebar}
                getHasPermission={_getHasPermission}
              />
            }
          >
            <Route
              index
              path={AdminDashboardPageId.OVERVIEW}
              element={<OverviewPage {...pageProps} />}
            />
            <Route
              path={AdminDashboardPageId.ADD_ONS}
              element={<AddOnsPage {...pageProps} />}
            />
            <Route
              path={`${AdminDashboardPageId.MONEY}/*`}
              element={
                <AdminPageLayout
                  id="clubs-admin-money-layout"
                  navbarLinkConfigs={[
                    {
                      text: i18n.t('money.layout.tabs.orders', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/money/orders`,
                    },
                    {
                      text: i18n.t('money.layout.tabs.bank_account', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/money/bank_account`,
                    },
                  ]}
                />
              }
            >
              <Route
                index
                element={
                  <Navigate to={AdminDashboardMoneyPageId.ORDERS} replace />
                }
              />
              <Route path={AdminDashboardMoneyPageId.ORDERS}>
                <Route index element={<OrdersIndexPage {...pageProps} />} />
                <Route path=":id" element={<OrdersShowPage {...pageProps} />} />
              </Route>
              <Route
                path={AdminDashboardMoneyPageId.BANK_ACCOUNT}
                element={<BankAccountPage {...pageProps} />}
              />
            </Route>
            <Route
              path={`${AdminDashboardPageId.DEALS}/*`}
              element={
                <AdminPageLayout
                  id="clubs-admin-deals-layout"
                  navbarLinkConfigs={[
                    {
                      text: i18n.t('deals.layout.tabs.deals', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/deals/list`,
                    },
                    {
                      text: i18n.t('deals.layout.tabs.embed', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/deals/embed`,
                    },
                  ]}
                />
              }
            >
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list">
                <Route index element={<DealsIndexPage {...pageProps} />} />
                <Route
                  path="new"
                  element={<SponsorshipNewPage {...pageProps} />}
                />
                <Route
                  path=":id/edit"
                  element={<SponsorshipEditPage {...pageProps} />}
                />
              </Route>
              <Route
                path="embed"
                element={<SponsorshipsEmbedPage {...pageProps} />}
              />
            </Route>
            <Route
              path={`${AdminDashboardPageId.EVENTS}/*`}
              element={
                <AdminPageLayout
                  id="clubs-admin-events-layout"
                  navbarLinkConfigs={[
                    {
                      text: i18n.t('events.layout.tabs.settings', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/events/settings`,
                    },
                    {
                      text: i18n.t('events.layout.tabs.embed', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/events/embed`,
                    },
                  ]}
                />
              }
            >
              <Route index element={<Navigate to="settings" replace />} />
              <Route path="settings" element={<EventsSettingsPage />} />
              <Route
                path="embed"
                element={<EventsEmbedPage {...pageProps} />}
              />
            </Route>
            <Route
              path={`${AdminDashboardPageId.PAGES}/*`}
              element={<AdminPageLayout id="clubs-admin-custom-pages-layout" />}
            >
              <Route index element={<CustomPagesIndexPage {...pageProps} />} />
              <Route
                path=":id/edit"
                element={<CustomPagesEditPage {...pageProps} />}
              />
            </Route>
            <Route
              path={`${AdminDashboardPageId.EMAILS}/*`}
              element={
                <AdminPageLayout
                  id="clubs-admin-emails-page"
                  navbarLinkConfigs={[
                    {
                      text: i18n.t('emails.layout.tabs.drafts', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/emails/drafts`,
                    },
                    {
                      text: i18n.t('emails.layout.tabs.logs', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/emails/logs`,
                    },
                  ]}
                />
              }
            >
              <Route index element={<Navigate to="drafts" replace />} />
              <Route
                path="drafts"
                element={<EmailDraftsIndexPage {...pageProps} />}
              />
              <Route
                path="drafts/:id/edit"
                element={<EmailDraftEditPage {...pageProps} />}
              />
              <Route
                path="logs"
                element={<EmailLogsIndexPage {...pageProps} />}
              />
              <Route
                path="logs/:id"
                element={<EmailLogsShowPage {...pageProps} />}
              />
            </Route>
            <Route
              path={AdminDashboardPageId.INSIGHTS}
              element={<InsightsPage {...pageProps} />}
            />
            <Route
              path={`${AdminDashboardPageId.MEMBERS}/*`}
              element={
                <AdminPageLayout
                  id="clubs-admin-members-layout"
                  navbarLinkConfigs={[
                    {
                      text: i18n.t('memberships.layout.tabs.members', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/members/list`,
                    },
                    {
                      text: i18n.t('memberships.layout.tabs.tags', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/members/tags`,
                    },
                  ]}
                />
              }
            >
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list">
                <Route
                  index
                  element={<MembershipsIndexPage {...pageProps} />}
                />
                <Route
                  path=":id"
                  element={<MembershipShowLayout {...pageProps} />}
                >
                  <Route index element={<Navigate to="info" replace />} />
                  <Route
                    path="info"
                    element={<MembershipShowInfoPage {...pageProps} />}
                  />
                  <Route
                    path="emails"
                    element={<MembershipShowEmailsPage {...pageProps} />}
                  />
                  <Route
                    path="emergency"
                    element={<MembershipShowEmergencyPage {...pageProps} />}
                  />
                  <Route
                    path="logs"
                    element={<MembershipShowLogsPage {...pageProps} />}
                  />
                </Route>
                <Route
                  path="new"
                  element={<MembershipsNewPage {...pageProps} />}
                />
              </Route>

              <Route path="tags">
                <Route
                  index
                  element={<MembershipTagsIndexPage {...pageProps} />}
                />
                <Route
                  path="new"
                  element={<MembershipTagsNewPage {...pageProps} />}
                />
                <Route
                  path=":id/edit"
                  element={<MembershipTagsEditPage {...pageProps} />}
                />
              </Route>
            </Route>
            <Route path={`${AdminDashboardPageId.PERMISSIONS}/*`}>
              <Route index element={<PermissionGroupsPage {...pageProps} />} />
              <Route
                path="new"
                element={<PermissionGroupNewPage {...pageProps} />}
              />
              <Route
                path=":id/edit"
                element={<PermissionGroupEditPage {...pageProps} />}
              />
              <Route
                path=":id/manage"
                element={<PermissionGroupManagePage {...pageProps} />}
              />
            </Route>
            <Route path={`${AdminDashboardPageId.PROFILE}/*`}>
              <Route index element={<ClubProfilePage {...pageProps} />} />
              <Route
                path="edit"
                element={<ClubProfileEditPage {...pageProps} />}
              />
            </Route>
            <Route path={`${AdminDashboardPageId.PLANS}/*`}>
              <Route index element={<SubscriptionPlansPage {...pageProps} />} />
              <Route
                path="new"
                element={<SubscriptionPlanNewPage {...pageProps} />}
              />
              <Route
                path=":id/edit"
                element={<SubscriptionPlanEditPage {...pageProps} />}
              />
            </Route>

            <Route
              path={AdminDashboardPageId.HELP}
              element={<HelpPage {...pageProps} />}
            />
            <Route path={`${AdminDashboardPageId.WAIVERS}/*`}>
              <Route index element={<WaiversPage {...pageProps} />} />
              <Route path="new" element={<WaiverNewPage {...pageProps} />} />
              <Route
                path=":id/edit"
                element={<WaiverEditPage {...pageProps} />}
              />
            </Route>
            <Route
              path={`${AdminDashboardPageId.SETTINGS}/*`}
              element={
                <AdminPageLayout
                  id="clubs-admin-settings-layout"
                  navbarLinkConfigs={[
                    {
                      text: i18n.t('settings.layout.tabs.settings', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/settings/settings`,
                    },
                    {
                      text: i18n.t('settings.layout.tabs.notifications', {
                        scope: i18nScope,
                      }),
                      to: `${adminDashboardPath}/settings/notifications`,
                    },
                  ]}
                />
              }
            >
              <Route index element={<Navigate to="settings" replace />} />
              <Route path="settings">
                <Route index element={<SettingsPage {...pageProps} />} />
                <Route
                  path="edit"
                  element={<SettingsEditPage {...pageProps} />}
                />
              </Route>

              <Route path="notifications">
                <Route
                  index
                  element={
                    <ClubNotificationsSettingsIndexPage {...pageProps} />
                  }
                />
                <Route
                  path="new"
                  element={<ClubNotificationsSettingNewPage {...pageProps} />}
                />
                <Route
                  path=":id/edit"
                  element={<ClubNotificationsSettingEditPage {...pageProps} />}
                />
              </Route>
            </Route>
            <Route path={`${AdminDashboardPageId.STOREFRONT}/*`}>
              <Route index element={<StorefrontPage {...pageProps} />} />
              <Route
                path="new"
                element={<StorefrontProductNewPage {...pageProps} />}
              />
              <Route
                path=":id/edit"
                element={<StorefrontProductEditPage {...pageProps} />}
              />
            </Route>
            <Route path="*" element={<FourOFourPage />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </RootAppWrapper>
  );
}
