import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  EmergencyContact,
  EmergencyContactDestroyMutation,
} from '__generated__/graphql';

import I18nContext from 'contexts/i18n_context';
import { sendMutation } from 'helpers/gql_form_helpers';
import CollectionWrapper from 'components/utils/collection_wrapper';
import Icon, { iconType } from 'components/utils/icon';
import BasicModal from 'components/utils/basic_modal';
import EmergencyContactInfo from 'components/utils/emergency_contact_info';
import EmergencyContactForm, {
  buildNewEmergencyContact,
  EmergencyContactFormValues,
} from 'components/add_ons/emergency_contacts/emergency_contact_form';

const i18nScope = 'clubs.clubhouse.settings.emergency_contacts.list';

const MAX_NUMBER_OF_EMERGENCY_CONTACTS = 3;

type EmergencyContactData = Pick<
  EmergencyContact,
  'id' | 'name' | 'phoneNumber' | 'relationship'
>;

const EMERGENCY_CONTACT_DESTROY = gql(`
  mutation EmergencyContactDestroy($input: EmergencyContactDestroyInput!) {
    emergencyContactDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function EmergencyContactsList({
  emergencyContacts,
  onSaveCallback,
  onDestroyCallback,
  onUndestroyCallback,
}: {
  emergencyContacts: EmergencyContactData[];
  onSaveCallback: (updatedEmergencyContact: EmergencyContactData) => void;
  onDestroyCallback: (
    emergencyContact: EmergencyContactData,
  ) => EmergencyContactData;
  onUndestroyCallback: (emergencyContact: EmergencyContactData) => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [emergencyContactDestroyMutation] = useMutation(
    EMERGENCY_CONTACT_DESTROY,
  );

  const [emergencyContactForForm, setEmergencyContactForForm] =
    useState<EmergencyContactFormValues | null>(null);
  const [isEditingNotAdding, setIsEditingNotAdding] = useState<boolean | null>(
    null,
  );

  const _onClickAddEmergencyContact = () => {
    setIsEditingNotAdding(false);
    setEmergencyContactForForm(buildNewEmergencyContact());
  };

  const _onClickEditEmergencyContact = (
    emergencyContact: EmergencyContactData,
  ) => {
    setIsEditingNotAdding(true);
    setEmergencyContactForForm(emergencyContact);
  };

  const _onSaveEmergencyContact = (
    savedEmergencyContact: EmergencyContactData,
  ) => {
    setIsEditingNotAdding(null);
    setEmergencyContactForForm(null);
    onSaveCallback(savedEmergencyContact);
  };

  const _onClickDestroyEmergencyContact = (
    emergencyContact: EmergencyContactData,
  ) => {
    const destroyedEmergencyContact = onDestroyCallback(emergencyContact);

    sendMutation<EmergencyContactDestroyMutation>({
      mutationName: 'emergencyContactDestroy',
      main: () =>
        emergencyContactDestroyMutation({
          variables: { input: { id: emergencyContact.id } },
        }),
      allErrorsCallback: () => {
        // there was an error, let's add it back in
        alert(i18n.t('forms.errors.unknown_error'));
        onUndestroyCallback(destroyedEmergencyContact);
      },
    });
  };

  const onCancelModal = () => setEmergencyContactForForm(null);

  return (
    <div className="emergency-contacts-list">
      <CollectionWrapper
        items={emergencyContacts}
        emptyElement={
          <a onClick={_onClickAddEmergencyContact}>
            {i18n.t('add_contact', { scope: i18nScope })}
          </a>
        }
        renderItem={(emergencyContact, index) => (
          <div key={index} className="emergency-contact-wrapper">
            <EmergencyContactInfo
              classes={index !== 0 ? 'mt-4' : null}
              emergencyContact={emergencyContact}
            />
            <div className="actions">
              <Icon
                type={iconType.EDIT}
                classes="mr-1"
                onClick={() => _onClickEditEmergencyContact(emergencyContact)}
              />
              <br />
              <Icon
                type={iconType.DELETE}
                confirmationText={i18n.t('confirm_destroy', {
                  scope: i18nScope,
                  values: {
                    name: emergencyContact.name,
                  },
                })}
                onClick={() =>
                  _onClickDestroyEmergencyContact(emergencyContact)
                }
              />
            </div>
          </div>
        )}
      />

      {emergencyContacts.length === 0 ||
        (emergencyContacts.length < MAX_NUMBER_OF_EMERGENCY_CONTACTS && (
          <div className="mt-4">
            <a onClick={_onClickAddEmergencyContact}>
              {i18n.t('add_contact', { scope: i18nScope })}
            </a>
          </div>
        ))}

      {emergencyContactForForm && isEditingNotAdding !== null && (
        <BasicModal
          className="basic-modal-content basic-form emergency-contact-form-modal"
          onRequestCloseCallback={onCancelModal}
          isOpen={true}
        >
          <EmergencyContactForm
            isEditing={isEditingNotAdding}
            emergencyContact={emergencyContactForForm}
            onSuccessCallback={_onSaveEmergencyContact}
            onCancelCallback={onCancelModal}
          />
        </BasicModal>
      )}
    </div>
  );
}
