import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import I18nContext from 'contexts/i18n_context';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClubId } from 'types/types';
import { picSize, UserProfilePic } from 'components/utils/profile_pic';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';

const i18nScope = 'clubs.admin.help';

const GET_ACCOUNT_MANAGER = gql(`
  query GetAccountManager($clubId: ID!) {
    club(id: $clubId) {
      accountManager {
        fullName
        phoneNumber
        emailAddress
        profilePicUrl
      }
    }
  }
`);

export default function HelpPage({ currentClubId }: { currentClubId: ClubId }) {
  const { i18n } = React.useContext(I18nContext);
  const { loading, error, data } = useQuery(GET_ACCOUNT_MANAGER, {
    variables: { clubId: String(currentClubId) },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const accountManager = data?.club?.accountManager;
  assertNotNullOrUndefined(accountManager);

  return (
    <div id="clubs-admin-help-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page"
      >
        <>
          <p className="mt-0">{i18n.t('help_prompt', { scope: i18nScope })}</p>

          <div className="account-manager">
            <UserProfilePic
              classes="inline-block mr-1"
              profilePicUrl={accountManager.profilePicUrl}
              size={picSize.EXTRA_LARGE}
            />
            <div className="account-manager-label">
              <span>{accountManager.fullName}</span>
              <a href={'mailto:' + accountManager.emailAddress}>
                {accountManager.emailAddress}
              </a>
              <span>{accountManager.phoneNumber}</span>
            </div>
          </div>
        </>
      </AdminDashboard>
    </div>
  );
}
