import React from 'react';
import { Field } from 'formik';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';

type FormCheckboxFieldProps = Omit<
  FormFieldwrapperProps,
  'label' | 'children'
> & {
  label?: string | React.ReactNode;
};

export default function FormCheckboxField({
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
}: FormCheckboxFieldProps) {
  // we remove the label, because we don't want the label to be from
  // in the wrapper because it's not in the right position so we don't
  // pass it on

  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-checkbox-field">
        <label className="ml-1">
          <Field type="checkbox" name={name} />
          <span className="ml-2 label">{label}</span>
        </label>
      </div>
    </FormFieldWrapper>
  );
}
