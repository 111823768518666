// we should keep these in sync with app/constants/dark_mode.rb
const DARK_MODE_CLASS = 'dark-mode';

export function isDarkMode(): boolean {
  const body = document.body;
  return body.classList.contains(DARK_MODE_CLASS);
}

export function toggleDarkMode(): void {
  const body = document.body;
  if (isDarkMode()) {
    // set to light mode
    body.classList.remove(DARK_MODE_CLASS);
  } else {
    // set to dark mode
    body.classList.add(DARK_MODE_CLASS);
  }
}
