import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import ClubNotificationsSettingForm, {
  buildNewClubNotificationsSetting,
} from 'components/clubs/admin/settings_page/notifications/club_notifications_setting_form';

const i18nScope = 'clubs.admin.settings.notifications.new';

export default function ClubNotificationsSettingNewPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const newClubNotificationsSetting = useMemo(
    () => buildNewClubNotificationsSetting(),
    [],
  );

  return (
    <AdminDashboard
      title={i18n.t('title', { scope: i18nScope })}
      contentClasses="elevate-content min-height-page skinny-page"
    >
      <ClubNotificationsSettingForm
        clubId={String(currentClubId)}
        isEditing={false}
        clubNotificationsSetting={newClubNotificationsSetting}
        onSuccessCallback={() => navigate(`..`)}
        onCancelCallback={backToIndexPage}
      />
    </AdminDashboard>
  );
}
