import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import I18nContext from 'contexts/i18n_context';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import { ClubId } from 'types/types';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';

const i18nScope = 'clubs.admin.add_ons';

const GET_ADD_ONS = gql(`
  query GetAddOns($clubId: ID!) {
    club(id: $clubId) {
      id
      addOnNames
    }
  }
`);

export default function AddOnsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);

  const { loading, error, data } = useQuery(GET_ADD_ONS, {
    variables: { clubId: String(currentClubId) },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const addOnNames = data?.club?.addOnNames;
  assertNotNullOrUndefined(addOnNames);

  const addOns = addOnNames.map((addOnName) => ({
    icon: i18n.t(`${addOnName}.icon`, { scope: i18nScope }),
    name: i18n.t(`${addOnName}.name`, { scope: i18nScope }),
    description: i18n.t(`${addOnName}.description`, {
      scope: i18nScope,
    }),
  }));

  return (
    <div id="clubs-admin-add-ons-page">
      <AdminDashboard
        title={i18n.t('name', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page"
      >
        <div className="add-ons-grid-view">
          {addOns.map((addOn, index) => (
            <div className="add-on-card" key={index}>
              <div className="add-on-name">
                {addOn.icon} &nbsp; {addOn.name}
              </div>
              <div className="feature-description">{addOn.description}</div>
            </div>
          ))}
        </div>
      </AdminDashboard>
    </div>
  );
}
