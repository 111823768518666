import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import {
  Club,
  ClubMembership,
  ClubSubscription,
  ClubSubscriptionPlan,
} from '__generated__/graphql';
import { Formik, Form, FormikHelpers } from 'formik';
import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
} from 'components/forms/forms';

const i18nScope =
  'clubs.clubhouse.settings.membership.membership_renewal_resumption_form';

type SubscriptionPlanParams = Pick<
  ClubSubscriptionPlan,
  'priceInCents' | 'frequency'
>;
type MembershipParams = Required<Pick<ClubMembership, 'renewsAt'>>;

type MembershipRenewalResumptionFormValues = SubscriptionPlanParams &
  MembershipParams;

export default function MembershipRenewalResumptionForm({
  club,
  membership,
  subscriptions,
  onSuccessCallback,
  onCancelCallback,
}: {
  club: Pick<Club, 'id' | 'name'>;
  membership: Pick<ClubMembership, 'renewsAt'>;
  subscriptions: (Pick<ClubSubscription, 'expiresAt'> & {
    subscriptionPlan: SubscriptionPlanParams;
  })[];
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const latestSubscription = _.last(_.sortBy(subscriptions, 'expiresAt'));
  assertNotNullOrUndefined(latestSubscription, 'subscription is missing');

  const { priceInCents, frequency } = latestSubscription.subscriptionPlan;
  const renewsAt = membership.renewsAt;
  const initialValues = { priceInCents, frequency, renewsAt };

  const _onSubmit = function (
    values: MembershipRenewalResumptionFormValues,
    actions: FormikHelpers<MembershipRenewalResumptionFormValues>,
  ) {
    Helpers.Form.save({
      actions,
      main: () => {
        const url = Helpers.Routes.getClubMembershipRenewalResumptionsPath(
          Number(club.id),
        );
        const params = { membershipRenewalResumption: values };
        return Helpers.API.post({ url, params });
      },
      successCallback: () => onSuccessCallback(),
    });
  };

  return (
    <div className="membership-renewal-resumption-form">
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {({ status, isSubmitting }) => (
          <Form>
            <h3 className="mt-0">
              {i18n.t('title', {
                scope: i18nScope,
                values: { clubName: club.name },
              })}
            </h3>
            <p>
              {i18n.t('description', {
                scope: i18nScope,
                values: {
                  priceInCents: i18n.c({ amountInCents: priceInCents }),
                  frequency: i18n.t(
                    `models.subscription_plans.frequency.every.${frequency}`,
                  ),
                  renewsAt: Helpers.Utils.formatDate(membership.renewsAt, {
                    withYear: true,
                  }),
                },
              })}
            </p>
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('rejoin_button', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                text={i18n.t('cancel_button', { scope: i18nScope })}
                className="secondary"
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
