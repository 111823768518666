import { Editor } from '@tiptap/react';

const unlink = ({ editor }: { editor: Editor }) =>
  editor.chain().focus().extendMarkRange('link').unsetLink().run();
const convertToLink = ({ editor, url }: { editor: Editor; url: string }) =>
  editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
const getCurrentUrl = ({ editor }: { editor: Editor }) =>
  editor.getAttributes('link').href ?? '';

export default {
  unlink,
  convertToLink,
  getCurrentUrl,
};
