import _ from 'underscore';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  AdminClubNotificationsSettingCreateOrUpdateMutation,
  Club,
  ClubNotificationsSetting,
  ClubNotificationsSettingNotificationTypeEnum,
  HelpArticleSlugEnum,
} from '__generated__/graphql';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
  FormField,
  FormUrlField,
  FormPillsField,
} from 'components/forms/forms';

import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import StaticImagesHelper from 'helpers/static_images_helper';
import { HelpArticleModalPresenter } from 'components/utils/help_article_presenter';

const i18nScope = 'clubs.admin.settings.notifications.form';

type ClubNotificationsSettingFormValues = Pick<
  ClubNotificationsSetting,
  'notificationType' | 'slackWebhookUrl' | 'slackChannel' | 'discordWebhookUrl'
>;

type ClubNotificationsSettingForForm = ClubNotificationsSettingFormValues;

const ADMIN_CLUB_NOTIFICATIONS_SETTING_CREATE_OR_UPDATE = gql(`
  mutation AdminClubNotificationsSettingCreateOrUpdate($input: ClubNotificationsSettingCreateOrUpdateInput!) {
    clubNotificationsSettingCreateOrUpdate(input: $input) {
      clubNotificationsSetting {
        id
        discordWebhookUrl
        slackWebhookUrl
        slackChannel
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export function buildNewClubNotificationsSetting(): ClubNotificationsSettingForForm {
  return {
    notificationType: ClubNotificationsSettingNotificationTypeEnum.Slack,
    discordWebhookUrl: '',
    slackWebhookUrl: '',
    slackChannel: '',
  };
}

export default function ClubNotificationsSettingForm({
  isEditing,
  clubId,
  clubNotificationsSetting,
  onSuccessCallback,
  onCancelCallback,
}: {
  isEditing: boolean;
  clubId: Club['id'];
  clubNotificationsSetting: ClubNotificationsSettingForForm & {
    id?: ClubNotificationsSetting['id'];
  };
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [updateMutation] = useMutation(
    ADMIN_CLUB_NOTIFICATIONS_SETTING_CREATE_OR_UPDATE,
  );

  const [shouldShowDiscordInstructions, setShouldShowDiscordInstructions] =
    useState(false);

  const [shouldShowSlackInstructions, setShhouldShowSlackInstructions] =
    useState(false);

  const initialValues = _.pick(
    clubNotificationsSetting,
    'notificationType',
    'discordWebhookUrl',
    'slackWebhookUrl',
    'slackChannel',
  );

  const validationSchema = Yup.object().shape({
    notificationType: Yup.string().required(i18n.t('forms.errors.required')),
    discordWebhookUrl: Yup.string().when('notificationType', {
      is: ClubNotificationsSettingNotificationTypeEnum.Discord,
      then: Yup.string()
        .trim()
        .url(i18n.t('forms.errors.invalid_url'))
        .required(i18n.t('forms.errors.required')),
      otherwise: Yup.string().notRequired(),
    }),
    slackWebhookUrl: Yup.string().when('notificationType', {
      is: ClubNotificationsSettingNotificationTypeEnum.Slack,
      then: Yup.string()
        .trim()
        .url(i18n.t('forms.errors.invalid_url'))
        .required(i18n.t('forms.errors.required')),
      otherwise: Yup.string().notRequired(),
    }),
    slackChannel: Yup.string().when('notificationType', {
      is: ClubNotificationsSettingNotificationTypeEnum.Slack,
      then: Yup.string()
        .trim()
        .min(2, i18n.t('forms.errors.min_length'))
        .required(i18n.t('forms.errors.required')),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const _onSubmit = function (
    values: ClubNotificationsSettingFormValues,
    actions: FormikHelpers<ClubNotificationsSettingFormValues>,
  ) {
    sendMutationAndUpdateForm<
      ClubNotificationsSettingFormValues,
      AdminClubNotificationsSettingCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubNotificationsSettingCreateOrUpdate',
      main: () =>
        updateMutation({
          variables: {
            input: {
              clubId,
              id: clubNotificationsSetting.id,
              ...values,
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  return (
    <div className="club-notifications-setting-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, values, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormPillsField
              classes="mb-3"
              name="notificationType"
              pills={[
                {
                  value: ClubNotificationsSettingNotificationTypeEnum.Slack,
                  element: (
                    <span>
                      <img
                        className="mr-1"
                        style={{ height: 20, verticalAlign: 'middle' }}
                        src={StaticImagesHelper.slackIconUrl()}
                      />
                      {i18n.t('notification_type.slack', { scope: i18nScope })}
                    </span>
                  ),
                },
                {
                  value: ClubNotificationsSettingNotificationTypeEnum.Discord,
                  element: (
                    <span>
                      <img
                        className="mr-1"
                        style={{ height: 20, verticalAlign: 'middle' }}
                        src={StaticImagesHelper.discordIconUrl()}
                      />
                      {i18n.t('notification_type.discord', {
                        scope: i18nScope,
                      })}
                    </span>
                  ),
                },
              ]}
            />

            {values.notificationType ==
              ClubNotificationsSettingNotificationTypeEnum.Discord && (
              <>
                <a onClick={() => setShouldShowDiscordInstructions(true)}>
                  {i18n.t('instructions.discord.link', {
                    scope: i18nScope,
                  })}
                </a>
                <FormUrlField
                  autoFocus={true}
                  name="discordWebhookUrl"
                  label={i18n.t('discord_webhook_url.label', {
                    scope: i18nScope,
                  })}
                />
              </>
            )}

            {values.notificationType ==
              ClubNotificationsSettingNotificationTypeEnum.Slack && (
              <>
                <a onClick={() => setShhouldShowSlackInstructions(true)}>
                  {i18n.t('instructions.slack.link', {
                    scope: i18nScope,
                  })}
                </a>
                <FormUrlField
                  autoFocus={true}
                  name="slackWebhookUrl"
                  label={i18n.t('slack_webhook_url.label', {
                    scope: i18nScope,
                  })}
                />
                <FormField
                  type="text"
                  name="slackChannel"
                  label={i18n.t('slack_channel.label', { scope: i18nScope })}
                />
              </>
            )}
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>

      {shouldShowDiscordInstructions && (
        <HelpArticleModalPresenter
          slug={HelpArticleSlugEnum.DiscordWebhookInstructions}
          onRequestCloseCallback={() => {
            setShouldShowDiscordInstructions(false);
          }}
        />
      )}

      {shouldShowSlackInstructions && (
        <HelpArticleModalPresenter
          slug={HelpArticleSlugEnum.SlackWebhookInstructions}
          onRequestCloseCallback={() => {
            setShhouldShowSlackInstructions(false);
          }}
        />
      )}
    </div>
  );
}
