import _ from 'underscore';
import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';

interface TippyMenuProps extends TippyProps {
  classes?: string;
  children?: React.ReactElement;
}

export default function TippyMenu({
  children,
  classes,
  ...props
}: TippyMenuProps) {
  const config = Object.assign({}, props);
  _.defaults(config, {
    arrow: false,
    offset: [15, -2],
    placement: 'bottom-end',
    trigger: 'click',
    interactive: true,
    zIndex: 999, // modals begin in the 1000s
  });

  // if manually controlled then no trigger
  if (Object.prototype.hasOwnProperty.call(config, 'visible')) {
    delete config.trigger;
  }

  return (
    <Tippy className={classNames('tippy-menu', classes)} {...config}>
      <span>
        {children ?? <i className="tippy-menu-ellipsis fas fa-ellipsis-h"></i>}
      </span>
    </Tippy>
  );
}
