import h from 'h';
import _ from 'underscore';
import _str from 'underscore.string';
import React, { useMemo, useState } from 'react';
import {
  ClubMembershipAuditLog,
  AuditLogTypeEnum,
  ClubAuditLogLogTypeEnum,
  ClubMembershipAuditLogLogTypeEnum,
  ClubAuditLog,
} from '__generated__/graphql';
import I18nContext from 'contexts/i18n_context';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import Icon, { iconType } from 'components/utils/icon';
import Helpers from 'helpers/helpers';

type AuditLog = Pick<
  ClubAuditLog | ClubMembershipAuditLog,
  | 'id'
  | 'type'
  | 'logType'
  | 'loggedAt'
  | 'isSystemGenerated'
  | 'createdByFullName'
  | 'data'
>;

const i18nScope = 'components.utils.audit_logs.audit_logs_list';

function AuditLogWrapper({ auditLog }: { auditLog: AuditLog }) {
  const { i18n } = React.useContext(I18nContext);
  const [shouldShowExpanded, setShouldShowExpanded] = useState(false);

  const createdByFullName =
    auditLog.isSystemGenerated || auditLog.createdByFullName == null
      ? i18n.t('info.system_generated', { scope: i18nScope })
      : auditLog.createdByFullName;

  const loggedAtDate = new Date(auditLog.loggedAt);
  const toLocaleOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const loggedAtText = loggedAtDate.toLocaleDateString([], toLocaleOptions);
  const loggedAtTooltip = Helpers.Utils.formatDateTimeLong(auditLog.loggedAt);

  let summary: string;
  let expandedContent: React.ReactElement | null = null;
  const modelType = auditLog.type;
  switch (modelType) {
    case AuditLogTypeEnum.ClubAuditLog:
      summary = i18n.t(`club_audit_log.${auditLog.logType}`, {
        scope: i18nScope,
      });

      switch (auditLog.logType) {
        case ClubAuditLogLogTypeEnum.AdminNote:
          if (!_str.isBlank(auditLog.data.message ?? '')) {
            expandedContent = (
              <div className="audit-log-data-text">{auditLog.data.message}</div>
            );
          }
          break;
      }

      break;
    case AuditLogTypeEnum.MembershipAuditLog:
      summary = i18n.t(`membership_audit_log.${auditLog.logType}`, {
        scope: i18nScope,
      });

      switch (auditLog.logType) {
        case ClubMembershipAuditLogLogTypeEnum.AdminNote:
        case ClubMembershipAuditLogLogTypeEnum.MemberImported:
        case ClubMembershipAuditLogLogTypeEnum.ManualMemberImport:
          if (!_str.isBlank(auditLog.data.message ?? '')) {
            expandedContent = (
              <div className="audit-log-data-text">{auditLog.data.message}</div>
            );
          }
          break;
        case ClubMembershipAuditLogLogTypeEnum.RenewalCanceled:
          if (!_str.isBlank(auditLog.data.feedback ?? '')) {
            expandedContent = (
              <div className="audit-log-data-text">
                {auditLog.data.feedback}
              </div>
            );
          }
          break;
      }

      break;
    default:
      return h.throwExhaustiveError('unknown audit log type', modelType);
  }

  const isExpandable = expandedContent !== null;

  return (
    <div className="audit-log-wrapper">
      <div
        className={classNames('audit-log-summary', {
          expandable: isExpandable,
          expanded: shouldShowExpanded,
        })}
        onClick={() => setShouldShowExpanded(!shouldShowExpanded)}
      >
        <div className="audit-log-summary-content">
          {createdByFullName} — {summary} <br />
          <Tippy content={loggedAtTooltip} placement="right">
            <span className="small light-text-color">{loggedAtText}</span>
          </Tippy>
        </div>
        {isExpandable && (
          <div className="expand-caret center vertical-center">
            <Icon type={iconType.CARET_DOWN} />
          </div>
        )}
      </div>

      {isExpandable && shouldShowExpanded && (
        <div className="mt-2">{expandedContent}</div>
      )}
    </div>
  );
}

export default function AuditLogsList({
  auditLogs,
}: {
  auditLogs: AuditLog[];
}) {
  const sortedAuditLogsByMostRecent = useMemo(
    () =>
      _.sortBy(auditLogs, (auditLog) => new Date(auditLog.loggedAt)).reverse(),
    [auditLogs],
  );

  return (
    <div className="audit-logs-list">
      {sortedAuditLogsByMostRecent.map((auditLog, index) => (
        <span key={index}>
          <AuditLogWrapper auditLog={auditLog} />
          {index + 1 < sortedAuditLogsByMostRecent.length && (
            <hr className="hr" />
          )}
        </span>
      ))}
    </div>
  );
}
