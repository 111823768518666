import { isDevelopmentEnv, isTestEnv } from 'helpers/environment_helper';
import _str from 'underscore.string';
import React from 'react';

const PLACEHOLDER_REGEX = /(?:%\{)(.*?)(?:\})/gm;

type StringTranslation = string;
type ArrayStringTranslation = string[];
type PluralizableTranslation = {
  zero?: StringTranslation;
  one: StringTranslation;
  other: StringTranslation;
};

type Translation =
  | StringTranslation
  | PluralizableTranslation
  | ArrayStringTranslation;

type I18nTranslations = { [key: string]: Translation | I18nTranslations };

type PlaceholderValue = React.ReactNode | string | number;

interface Placeholder {
  nameWithBrackets: string;
  value: PlaceholderValue;
}

interface I18nTranslationOptions {
  scope?: string;
  count?: number;
  values?: { [key: string]: PlaceholderValue };
}

export default class I18n {
  _translations: I18nTranslations;

  constructor(translations: I18nTranslations) {
    this._translations = translations;
  }

  t(key: string, opts: I18nTranslationOptions = {}): string {
    let translation = this._getTranslation({ key, opts });

    if (Array.isArray(translation)) {
      this._throwUnlessProduction(
        `received array instead of string for key ${key}`,
      );
      return '';
    }

    const placeholders = this._extractPlaceholders(translation, opts);
    for (const placeholder of placeholders) {
      translation = translation.replaceAll(
        placeholder.nameWithBrackets,
        String(placeholder.value),
      );
    }

    return translation;
  }

  // returns an object/array, and not a string. one caveat, since it's not
  // a string we skip interpolation. any placeholders will not be filled
  tArray(
    key: string,
    opts: I18nTranslationOptions = {},
  ): ArrayStringTranslation {
    const result = this._getTranslation({
      key,
      opts,
      allowArray: true,
    });
    if (result === null || typeof result === 'string') {
      this._throwUnlessProduction('translation is not an array or object');
      return [];
    }
    return result;
  }

  // the R stands for react, so it will return a react component. this is helpful
  // when the placeholder we want to interpolate are react components
  rt(key: string, opts: I18nTranslationOptions = {}): React.ReactNode {
    let translation = this._getTranslation({ key, opts });

    if (Array.isArray(translation)) {
      this._throwUnlessProduction(
        `received array instead of string for key ${key}`,
      );
      return '';
    }

    const elements: React.ReactNode[] = [];

    const placeholders = this._extractPlaceholders(translation, opts);
    for (const placeholder of placeholders) {
      const [first, ...remainder] = translation.split(
        placeholder.nameWithBrackets,
      );

      if (!_str.isBlank(first)) {
        elements.push(first);
      }
      elements.push(placeholder.value);

      // technically the placeholder could be present multiple times, we only
      // want to process the first instance not all instances, so we'll do a
      // join to ensure we process any remaining instances later
      translation = remainder.join(placeholder.nameWithBrackets);
    }

    // any remaining parts should be added
    if (!_str.isBlank(translation)) {
      elements.push(translation);
    }

    return (
      <>
        {elements.map((el, index) => (
          <span key={index}>{el}</span>
        ))}
      </>
    );
  }

  c({
    amountInCents,
    stripInsignificantZeros = true,
  }: {
    amountInCents: number;
    stripInsignificantZeros?: boolean;
  }): string {
    // TODO: this is hard coded for US but we should generalize

    // TODO: trailingZeroDisplay is only available on newer node versions
    // so we have to do some workarounds below

    // const USDollar = new Intl.NumberFormat('en-US', {
    //   style: 'currency',
    //   currency: 'USD',
    //   trailingZeroDisplay: stripInsignificantZeros ? 'stripIfInteger' : 'auto',
    // });

    const amountInDollars = amountInCents / 100;

    const options: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: 'USD',
    };

    if (Number.isInteger(amountInDollars) && stripInsignificantZeros) {
      options.maximumFractionDigits = 0;
    }

    return new Intl.NumberFormat('en-US', options).format(amountInDollars);
  }

  _getTranslation({
    key,
    opts,
    allowArray = false,
  }: {
    key: string;
    opts: I18nTranslationOptions;
    allowArray?: boolean;
  }): StringTranslation | ArrayStringTranslation {
    const keyWithScope = Object.prototype.hasOwnProperty.call(opts, 'scope')
      ? `${opts.scope}.${key}`
      : key;

    let translation = this._getValueForKey(keyWithScope);

    if (translation === null) {
      this._throwUnlessProduction(
        `missing translation for key '${keyWithScope}'`,
      );
      return ''; // empty string
    } else if (typeof translation === 'string') {
      return translation;
    } else if (allowArray && Array.isArray(translation)) {
      return translation;
    }

    if (!('one' in translation) || !('other' in translation)) {
      this._throwUnlessProduction(
        `translation is not pluralizable for key ${key}`,
      );
      return '';
    }

    switch (opts.count) {
      case 0:
        translation = translation.zero ?? translation.other;
        break;
      case 1:
        translation = translation.one;
        break;
      default:
        translation = translation.other;
    }

    // verify the user has defined zero, other, one
    if (translation === null || typeof translation === 'undefined') {
      this._throwUnlessProduction(
        `missing pluralization translation, key: ${keyWithScope}`,
      );
      return ''; // empty translation
    } else if (typeof translation !== 'string') {
      this._throwUnlessProduction(
        `pluralized translation is not a string, key: ${keyWithScope}`,
      );
      return ''; // empty translation
    }

    return translation;
  }

  _throwUnlessProduction(errorMessage: string): never | void {
    // let's be more forgiving in production
    if (isDevelopmentEnv() || isTestEnv()) {
      throw new Error(errorMessage);
    }
  }

  // returns an array of placeholders, which we'll then use above to interpolate
  // and fill them in with desired values
  _extractPlaceholders(
    translation: StringTranslation,
    opts: I18nTranslationOptions,
  ): Placeholder[] {
    const placeholders: Placeholder[] = [];
    const values = opts.values || {};
    const count = opts.count;

    // interpolate
    // in translations we write variables in snake case, eg 'the name is %{club_name}'
    // but in our react components with use camelCase so when a user passes in
    // the value within the options we expected there to be `clubName`. So we need
    // then map it to `club_name`. this is probably a little confusing and need to
    // be more standardized
    const placeholderNamesWithBrackets =
      this._extractPlaceholderNamesWithBrackets(translation);

    for (const placeholderNameWithBrackets of placeholderNamesWithBrackets) {
      // removes the leading %{ and closing }
      const placeholderName = placeholderNameWithBrackets.slice(2, -1);
      const camelizedPlaceholderName = _str.camelize(placeholderName);

      let placeholderValue: PlaceholderValue;
      if (
        Object.prototype.hasOwnProperty.call(values, camelizedPlaceholderName)
      ) {
        placeholderValue = values[camelizedPlaceholderName];
      } else {
        // let's checks if it's a %{count} placeholder name, if so then we
        // can use the count from I18nTranslationOptions if it was provided.
        // we support this feature because it would be annoying for engineers to
        // have to pass count both at the high level of I18nTranslationOptions
        // AND in the values object
        if (
          placeholderNameWithBrackets === '%{count}' &&
          typeof count !== 'undefined'
        ) {
          placeholderValue = count;
        } else {
          this._throwUnlessProduction(
            `interpolation error, missing value for ${camelizedPlaceholderName}`,
          );
          continue;
        }
      }

      placeholders.push({
        nameWithBrackets: placeholderNameWithBrackets,
        value: placeholderValue,
      });
    }

    return placeholders;
  }

  // given a translation like `some text %{clubName} has %{numberOfMembers}`
  // it will return an array of the placeholder ['%{clubName}', '%{numberOfMembers}']
  _extractPlaceholderNamesWithBrackets(str: string): RegExpMatchArray | [] {
    return str?.match(PLACEHOLDER_REGEX) ?? [];
  }

  _getValueForKey(key: string): I18nTranslations | Translation | null {
    const keys = key.split('.');
    let translation: I18nTranslations | Translation = this._translations;
    for (const k of keys) {
      if (
        typeof translation === 'undefined' ||
        typeof translation === 'string' ||
        Array.isArray(translation) ||
        !(k in translation)
      ) {
        this._throwUnlessProduction(
          `cannot retrieve translation for key ${key}`,
        );
        return '';
      }

      // translation at this point be more I18nTranslations or PluralizableTranslation
      // I checked key in translation above but it's not realizing that it's okay so
      // going to just cast to get it passing
      translation = (translation as I18nTranslations)[k];
    }

    return translation ?? null;
  }
}
