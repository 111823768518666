import React, { useState } from 'react';
import Switch from 'rc-switch';

import { CookieNames } from 'helpers/cookies_helper';
import Helpers from 'helpers/helpers';
import { toggleDarkMode } from 'helpers/dark_mode_helpers';

export default function DarkModeSwitch({
  initialValue,
}: {
  initialValue: boolean;
}) {
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(initialValue);

  const _onToggleDarkMode = () => {
    const newIsDarkModeEnabled = !isDarkModeEnabled;
    setIsDarkModeEnabled(newIsDarkModeEnabled);
    toggleDarkMode();
    if (newIsDarkModeEnabled) {
      Helpers.Cookies.set(
        CookieNames.IS_DARK_MODE,
        String(newIsDarkModeEnabled),
      );
    } else {
      // if we're setting to false, then we can remove this cookie
      // since it's the default
      if (Helpers.Cookies.get(CookieNames.IS_DARK_MODE) != null) {
        Helpers.Cookies.remove(CookieNames.IS_DARK_MODE);
      }
    }
  };

  return (
    <span className="dark-mode-switch">
      <Switch
        checked={isDarkModeEnabled}
        onClick={_onToggleDarkMode}
        checkedChildren="🌜"
        unCheckedChildren="🌞"
      />
    </span>
  );
}
