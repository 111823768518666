import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard, {
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import NewMembershipForm, {
  NewMembershipFormLoadingPlaceholder,
  buildNewMembership,
} from 'components/clubs/admin/memberships_page/new_membership_form';

const i18nScope = 'clubs.admin.memberships';

const GET_ADMIN_CLUB_NEW_MEMBERSHIP = gql(`
  query GetAdminClubNewMembership($clubId: ID!) {
    club(id: $clubId) {
      id
      subscriptionPlans {
        id
        available
        name
      }
    }
  }
`);

export default function MembershipsNewPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const newMembership = useMemo(() => buildNewMembership(), []);

  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_NEW_MEMBERSHIP, {
    variables: {
      clubId: String(currentClubId),
    },
  });
  if (loading)
    return (
      <div id="clubs-admin-memberships-new">
        <AdminDashboardLoadingPlaceholder>
          <NewMembershipFormLoadingPlaceholder />
        </AdminDashboardLoadingPlaceholder>
      </div>
    );
  if (error) return <ErrorPage />;
  const subscriptionPlans = data?.club?.subscriptionPlans;
  assertNotNullOrUndefined(subscriptionPlans);

  return (
    <div id="clubs-admin-memberships-new">
      <AdminDashboard
        title={i18n.t('new.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page form-page"
      >
        <NewMembershipForm
          currentClubId={currentClubId}
          membership={newMembership}
          subscriptionPlans={subscriptionPlans}
          onSuccessCallback={(membershipId) => navigate(`../${membershipId}`)}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
