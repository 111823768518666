import { assertNotNullOrUndefined } from 'h';
import _str from 'underscore.string';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useNavigate } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import Icon, { iconType } from 'components/utils/icon';
import { ClubProfilePic, picSize } from 'components/utils/profile_pic';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import { ClubId } from 'types/types';
import SettingsGridView, {
  SettingFields,
} from 'components/clubs/admin/shared/settings_grid_view';

const i18nScope = 'clubs.admin.settings.show';

const GET_CLUB_SETTINGS = gql(`
  query GetClubSettings($clubId: ID!) {
    club(id: $clubId) {
      id
      logoUrl
      name
      shortName
      website
      city
      state
      currency
      timeZone
      replyTo
    }
  }
`);

export default function SettingsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_CLUB_SETTINGS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);

  const settingFields: SettingFields = [
    {
      value: <ClubProfilePic club={club} size={picSize.LARGE} />,
    },
    {
      label: i18n.t('info.name', { scope: i18nScope }),
      value: club.name,
    },
    {
      label: i18n.t('info.short_name', { scope: i18nScope }),
      value: club.shortName,
      shouldHide: _str.isBlank(club.shortName),
    },
    {
      label: i18n.t('info.website', { scope: i18nScope }),
      value: (
        <a href={club.website ?? ''} className="link" target="_blank">
          {club.website}
        </a>
      ),
      shouldHide: _str.isBlank(club.website ?? ''),
    },
    {
      label: i18n.t('info.location', { scope: i18nScope }),
      value: `${club.city}, ${club.state}`,
    },
    {
      label: i18n.t('info.currency', { scope: i18nScope }),
      value: club.currency,
    },
    {
      label: i18n.t('info.time_zone', { scope: i18nScope }),
      value: club.timeZone,
    },
    {
      label: i18n.t('info.reply_to', { scope: i18nScope }),
      value: club.replyTo,
    },
  ];

  return (
    <div id="clubs-admin-settings-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        titleActions={
          <Icon type={iconType.EDIT} onClick={() => navigate('edit')} />
        }
        contentClasses="elevate-content min-height-page"
      >
        <SettingsGridView settingFields={settingFields} />
      </AdminDashboard>
    </div>
  );
}
