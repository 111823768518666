import _str from 'underscore.string';
import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { AuthModes } from 'components/login_signup/auth_modes';
import {
  FormActions,
  FormActionButton,
  FormField,
  FormServerErrorMessages,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import { getFirstAndLastNameFromFullName } from 'models/user';
import { EmailAddress } from 'types/types';
import I18nContext from 'contexts/i18n_context';
import OmniAuthOptionsWrapper from 'components/utils/auth/omni_auth_options_wrapper';

interface SignupFormValues {
  fullName: string;
  emailAddress: EmailAddress;
  password: string;
}

export default function SignupForm({
  setAuthenticationMode,
  afterAuthenticationUrl,
  afterAuthenticationCallback,
}: {
  setAuthenticationMode: (newMode: AuthModes) => void;
  afterAuthenticationUrl: string;
  afterAuthenticationCallback?: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const initialValues: SignupFormValues = {
    fullName: '',
    emailAddress: '',
    password: '',
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        // we ask for full name instead of first and last
        // name to reduce the number of fields on sign up
        fullName: Yup.string()
          .trim()
          .required(i18n.t('forms.errors.required'))
          .test(
            'first-and-last-name',
            i18n.t('forms.errors.required_first_and_last_name'),
            (value) => {
              if (value === null || typeof value === 'undefined') {
                return false;
              }
              const [firstName, lastName] =
                getFirstAndLastNameFromFullName(value);
              return (
                firstName !== null &&
                !_str.isBlank(firstName) &&
                lastName !== null &&
                !_str.isBlank(lastName)
              );
            },
          ),
        emailAddress: Yup.string()
          .trim()
          .required(i18n.t('forms.errors.required'))
          .email(i18n.t('forms.errors.invalid_email')),
        password: Yup.string()
          .required(i18n.t('forms.errors.required'))
          .min(6, i18n.t('forms.errors.min_length')),
      }),
    [],
  );

  const _onSubmit = function (
    values: SignupFormValues,
    actions: FormikHelpers<SignupFormValues>,
  ) {
    Helpers.Form.save({
      actions: actions,
      main: () => {
        const url = Helpers.Routes.getUsersPath();
        const [firstName, lastName] = getFirstAndLastNameFromFullName(
          values.fullName,
        );
        const params = { user: { ...values, firstName, lastName } };
        return Helpers.API.post({ url, params });
      },
      successCallback: () => {
        // keep form spinning, do don't reset setSubmitting
        afterAuthenticationCallback?.();
      },
    });
  };

  return (
    <div className="signup-form">
      <div className="other-authenticate-option small">
        {i18n.t('auth.sign_up.login_instead')} &nbsp;
        <a onClick={() => setAuthenticationMode(AuthModes.LOGIN)}>
          {i18n.t('auth.login.heading')}
        </a>
      </div>
      <OmniAuthOptionsWrapper
        facebookButtonText={i18n.t('auth.sign_up.facebook_button')}
        googleButtonText={i18n.t('auth.sign_up.google_button')}
        afterAuthenticationUrl={afterAuthenticationUrl}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_onSubmit}
        >
          {({ status, isSubmitting }) => (
            <Form className="basic-form" noValidate>
              <FormField
                type="text"
                name="fullName"
                placeholder={i18n.t('auth.sign_up.form.full_name.placeholder')}
              />
              <FormField
                type="email"
                name="emailAddress"
                placeholder={i18n.t(
                  'auth.sign_up.form.email_address.placeholder',
                )}
              />
              <FormField
                type="password"
                name="password"
                placeholder={i18n.t('auth.sign_up.form.password.placeholder')}
              />
              <FormActions>
                <FormActionButton
                  text={i18n.t('auth.sign_up.form.submit')}
                  className="primary"
                  isSubmitting={isSubmitting}
                />
                <FormServerErrorMessages
                  serverErrors={status?.serverErrors?.base}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </OmniAuthOptionsWrapper>
    </div>
  );
}
