import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import _str from 'underscore.string';
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAdminClubMembershipInfoQuery } from '__generated__/graphql';
import { useParams } from 'react-router-dom';

import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';

import { ClubId } from 'types/types';
import { getDoesClubHaveAddOnInstalled } from 'models/club';
import { AddOnNames } from 'components/add_ons/names';

import Icon, { iconType } from 'components/utils/icon';
import { useClubContext } from 'components/clubs/admin/memberships_page/show/layout';

const i18nScope = 'clubs.admin.memberships.show.info';

type Club = NonNullable<GetAdminClubMembershipInfoQuery['club']>;
type Waiver = Club['waivers'][number];
type Membership = NonNullable<Club['membership']>;
type SignedWaiver = Membership['signedWaivers'][number];

const GET_ADMIN_CLUB_MEMBERSHIP_INFO = gql(`
  query GetAdminClubMembershipInfo($clubId: ID!, $membershipId: ID!) {
    club(id: $clubId) {
      id
      waivers {
        id
        position
        name
        isRequired
        showUrl
      }
      membership(id: $membershipId) {
        id
        emailAddress
        state
        isActive
        hasJoinedRecently
        hasExpiredRecently
        firstJoinedAt
        startsAt
        expiresAt
        renewsAt
        isRenewalCanceled
        signedWaivers {
          id
          waiverId
          signedAt
          wasSignedOffPlatform
          signedOffPlatformMessage
        }
      }
    }
  }
`);

const _findSignedWaiverIfExists = (
  waiver: Waiver,
  signedWaivers: SignedWaiver[],
): SignedWaiver | null => {
  return _.find(signedWaivers, (sw) => sw.waiverId === waiver.id) ?? null;
};

function WaiverInfo({
  waiver,
  signedWaivers,
}: {
  waiver: Waiver;
  signedWaivers: SignedWaiver[];
}) {
  const { i18n } = useContext(I18nContext);
  const signedWaiver = _findSignedWaiverIfExists(waiver, signedWaivers);

  let signedOnElement: React.ReactElement | string;
  if (signedWaiver === null) {
    signedOnElement = i18n.t('waivers.has_not_signed', { scope: i18nScope });
  } else if (signedWaiver.wasSignedOffPlatform) {
    signedOnElement = (
      <span>
        {i18n.t('waivers.was_signed_off_platform', {
          scope: i18nScope,
        })}
        {!_str.isBlank(signedWaiver.signedOffPlatformMessage) && (
          <Icon
            type={iconType.INFO}
            classes="ml-1"
            tooltipText={signedWaiver.signedOffPlatformMessage}
          />
        )}
      </span>
    );
  } else {
    signedOnElement = i18n.t('waivers.signed_on', {
      scope: i18nScope,
      values: {
        date: Helpers.Utils.formatDateTime(signedWaiver.signedAt, {
          withYear: true,
        }),
      },
    });
  }

  return (
    <div className="mt-2">
      <a href={waiver.showUrl} target="_blank">
        {waiver.name}
      </a>
      : &nbsp;
      {signedOnElement}
    </div>
  );
}

// any currently required waivers we'll show signed or not
// however, any past waivers (no longer required) we'll only show it
// if the user had signed it at one point. Don't think this is
// critical but more of a nice to have
function WaiversSection({
  waivers,
  signedWaivers,
}: {
  waivers: Waiver[];
  signedWaivers: SignedWaiver[];
}) {
  const { i18n } = useContext(I18nContext);

  const sortedRawWaivers = _.sortBy(waivers, (waiver) => waiver.position);
  const [requiredRawWaivers, pastRawWaivers] = _.partition(
    sortedRawWaivers,
    (waiver) => waiver.isRequired,
  );
  const signedPastRawWaivers = pastRawWaivers.filter(
    (waiver) => _findSignedWaiverIfExists(waiver, signedWaivers) !== null,
  );

  const shouldShowRequiredWaivers = requiredRawWaivers.length > 0;
  const shouldShowPastWaivers = signedPastRawWaivers.length > 0;
  const shouldShowBothSections =
    shouldShowRequiredWaivers && shouldShowPastWaivers;

  if (!shouldShowRequiredWaivers && !shouldShowPastWaivers) {
    return null;
  }

  // we'll only show the titles if both sections are display
  return (
    <div className="section">
      <h5>{i18n.t('waivers.title', { scope: i18nScope })}</h5>
      {shouldShowRequiredWaivers && (
        <div className="mt-2">
          {shouldShowBothSections && (
            <h6>{i18n.t('waivers.required', { scope: i18nScope })}</h6>
          )}
          {requiredRawWaivers.map((waiver) => (
            <WaiverInfo
              key={waiver.id}
              waiver={waiver}
              signedWaivers={signedWaivers}
            />
          ))}
        </div>
      )}
      {shouldShowPastWaivers && (
        <div className="mt-2">
          {shouldShowBothSections && (
            <h6>{i18n.t('waivers.past', { scope: i18nScope })}</h6>
          )}
          {signedPastRawWaivers.map((signedPastRawWaiver) => (
            <WaiverInfo
              key={signedPastRawWaiver.id}
              waiver={signedPastRawWaiver}
              signedWaivers={signedWaivers}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default function MembershipShowInfoPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = useContext(I18nContext);
  const { id } = useParams();
  const clubContext = useClubContext();

  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_MEMBERSHIP_INFO, {
    variables: {
      clubId: String(currentClubId),
      membershipId: String(id),
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const membership = club?.membership;
  assertNotNullOrUndefined(membership);

  return (
    <div>
      {i18n.t('member_since', { scope: i18nScope })}{' '}
      {Helpers.Utils.formatDate(membership.firstJoinedAt, { withYear: true })}
      <br />
      {i18n.t(membership.isActive ? 'expires_on' : 'expired_on', {
        scope: i18nScope,
      })}{' '}
      {Helpers.Utils.formatDate(membership.expiresAt, { withYear: true })}
      <br />
      <br />
      <a
        className="break-word"
        href={`mailto:${membership.emailAddress}`}
        target="_blank"
      >
        {membership.emailAddress}
      </a>
      {getDoesClubHaveAddOnInstalled({
        club: clubContext,
        addOnName: AddOnNames.WAIVERS,
      }) && (
        <WaiversSection
          waivers={club.waivers}
          signedWaivers={membership.signedWaivers}
        />
      )}
    </div>
  );
}
