import _ from 'underscore';
import React, { useState, useMemo } from 'react';
import {
  ClubEventComment,
  ClubEventDiscussion,
  ClubMembership,
} from '__generated__/graphql';

import Icon, { iconType } from 'components/utils/icon';
import TippyMenu from 'components/utils/tippy_menu';
import {
  LoadingPlaceholderProfilePic,
  MockPlaceholderProfilePic,
  picSize,
} from 'components/utils/profile_pic';
import {
  LoadingPlaceholder,
  MockPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';

import AddComment, {
  buildNewComment,
  AddCommentCallback,
} from 'components/utils/discussions/add_comment';
import CommentWrapper, {
  AUTHOR_PROFILE_PIC_SIZE,
} from 'components/utils/discussions/comment_wrapper';
import I18nContext from 'contexts/i18n_context';

import Timestamp, { TippyPlacement } from 'components/utils/timestamp';
import Linkifier from 'components/utils/linkifier';

type CommentAuthor = Pick<ClubMembership, 'id' | 'fullName' | 'profilePicUrl'>;
type Discussion = ClubEventDiscussion;
export type Comment = Pick<ClubEventComment, 'id' | 'body' | 'createdAt'> & {
  author: CommentAuthor;
};

const i18nScope = 'components.utils.discussions.discussion_threads';

// let's cap the number of placeholer comments we show
// when loading or mocking so it doesn't take over the
// page
const MIN_NUM_PLACEHOLDER_COMMENTS = 2;
const MAX_NUM_PLACEHOLDER_COMMENTS = 6;

export type GetAuthorProfilePicCallback = (
  author: CommentAuthor,
  size: picSize,
) => React.ReactElement;

export type DestroyCommentCallback = (comment: Pick<Comment, 'id'>) => void;

function CommentInfo({
  comment,
  currentAuthor,
  getAuthorProfilePicCallback,
  destroyCommentCallback,
}: {
  comment: Comment;
  currentAuthor: CommentAuthor | null;
  getAuthorProfilePicCallback: GetAuthorProfilePicCallback;
  destroyCommentCallback: DestroyCommentCallback;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [shouldShowTippyActionsMenu, setShouldShowTippyActionsMenu] =
    useState(false);
  const { author, body, createdAt } = comment;

  const closeTippyActionsMenuCallback = () =>
    setShouldShowTippyActionsMenu(false);
  const toggleTippyActionsMenuCallback = () =>
    setShouldShowTippyActionsMenu(!shouldShowTippyActionsMenu);

  const profilePicElement = useMemo(
    () => getAuthorProfilePicCallback(author, AUTHOR_PROFILE_PIC_SIZE),
    [getAuthorProfilePicCallback, author],
  );

  const onDelete = () => {
    destroyCommentCallback(comment);
    closeTippyActionsMenuCallback();
  };

  // TODO for now only owners can delete a comment but eventually
  // we should allow admins / maybe create a new permission
  // TODO ideally this field is sent from the server, so that the logic
  // isn't duplicated in 2 places and can deviate leading to issues where
  // we may show the action to delete but they can't resulting in an error.
  // One option is to always show tippy actions icon to owners, and then
  // on click we send a network request to see what actions they're able
  // to take.
  const canDelete = currentAuthor !== null && author.id === currentAuthor.id;

  let tippyActionsElement: React.ReactElement | null = null;
  if (canDelete) {
    tippyActionsElement = (
      <TippyMenu
        visible={shouldShowTippyActionsMenu}
        onClickOutside={closeTippyActionsMenuCallback}
        content={
          <>
            <div className="tippy-menu-item">
              <a onClick={onDelete}>
                {i18n.t('comment.actions.delete', { scope: i18nScope })}
              </a>
            </div>
          </>
        }
      >
        <span>
          <Icon
            type={iconType.ELLIPSIS}
            onClick={toggleTippyActionsMenuCallback}
          />
        </span>
      </TippyMenu>
    );
  }

  return (
    <CommentWrapper
      onMouseLeaveCallback={closeTippyActionsMenuCallback}
      authorElement={profilePicElement}
      bodyElement={
        <>
          <span className="bold">{author.fullName}</span>
          <br />
          <Linkifier>{body}</Linkifier>
        </>
      }
      footerElement={
        <div className="comment-footer-inner">
          <span className="right inline-block">
            <Timestamp
              classes="small light-text-color"
              createdAt={createdAt}
              placement={TippyPlacement.LEFT}
            ></Timestamp>
          </span>
        </div>
      }
      tippyActionsElement={tippyActionsElement}
    />
  );
}

function PlaceholderDiscussionThreads({
  discussion,
  authorElement,
  bodyElement,
  minNumberOfComments,
}: {
  discussion: Pick<Discussion, 'numberOfComments'>;
  authorElement: React.ReactElement;
  bodyElement: React.ReactElement;
  minNumberOfComments: number;
}) {
  const numberOfComments = Math.max(
    discussion.numberOfComments,
    minNumberOfComments,
  ); // at least 2 comments
  const cappedNumberOfComments = Math.min(
    numberOfComments,
    MAX_NUM_PLACEHOLDER_COMMENTS,
  ); // at most 6 comments
  const commentElements: React.ReactElement[] = [];
  for (let index = 0; index < cappedNumberOfComments; index++) {
    commentElements.push(
      <CommentWrapper
        key={index}
        authorElement={authorElement}
        bodyElement={bodyElement}
      />,
    );
  }

  return (
    <div className="placeholder-discussion-threads">{commentElements}</div>
  );
}

export function LoadingPlaceholderDiscussionThreads({
  discussion,
}: {
  discussion: Pick<Discussion, 'numberOfComments'>;
}) {
  return (
    <PlaceholderDiscussionThreads
      discussion={discussion}
      minNumberOfComments={discussion.numberOfComments}
      authorElement={
        <LoadingPlaceholderProfilePic size={AUTHOR_PROFILE_PIC_SIZE} />
      }
      bodyElement={
        <>
          <LoadingPlaceholder
            type={placeholderType.BAR}
            widthPercent="20%"
            classes="mb-2"
          />
          <LoadingPlaceholder type={placeholderType.BAR} widthPercent="100%" />
        </>
      }
    />
  );
}

// used when we want to mock the comments (eg. when it's hidden for the public, and only members can view
export function MockPlaceholderDiscussionThreads({
  discussion,
}: {
  discussion: Pick<Discussion, 'numberOfComments'>;
}) {
  return (
    <PlaceholderDiscussionThreads
      discussion={discussion}
      minNumberOfComments={MIN_NUM_PLACEHOLDER_COMMENTS}
      authorElement={
        <MockPlaceholderProfilePic size={AUTHOR_PROFILE_PIC_SIZE} />
      }
      bodyElement={
        <>
          <MockPlaceholder
            type={placeholderType.BAR}
            widthPercent="20%"
            classes="mb-2"
          />
          <MockPlaceholder type={placeholderType.BAR} widthPercent="100%" />
        </>
      }
    />
  );
}

export default function DiscussionThreads({
  currentAuthor,
  getAuthorProfilePicCallback,
  canComment,
  comments,
  addCommentCallback,
  destroyCommentCallback,
}: {
  currentAuthor: CommentAuthor | null;
  getAuthorProfilePicCallback: GetAuthorProfilePicCallback;
  canComment: boolean;
  comments: Comment[];
  addCommentCallback: AddCommentCallback<Comment>;
  destroyCommentCallback: DestroyCommentCallback;
}) {
  const [commentForForm, setCommentForForm] = useState(() => buildNewComment());
  const sortedComments = _.sortBy(
    comments,
    (comment) => new Date(comment.createdAt),
  );

  return (
    <div className="discussion-threads">
      {sortedComments.map((comment, index) => (
        <CommentInfo
          key={index}
          comment={comment}
          currentAuthor={currentAuthor}
          getAuthorProfilePicCallback={getAuthorProfilePicCallback}
          destroyCommentCallback={destroyCommentCallback}
        />
      ))}
      {canComment && currentAuthor !== null && (
        <AddComment<CommentAuthor, Comment>
          currentAuthor={currentAuthor}
          getAuthorProfilePicCallback={getAuthorProfilePicCallback}
          comment={commentForForm}
          addCommentCallback={({ values, actions, onSuccessCallback }) =>
            addCommentCallback({
              values,
              actions,
              onSuccessCallback: (createdComment) => {
                setCommentForForm(buildNewComment());
                onSuccessCallback(createdComment);
              },
            })
          }
        />
      )}
    </div>
  );
}
