import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import {
  ClubEvent,
  ClubEventEventRsvpCreateOrUpdateMutation,
  ClubEventEventRsvpDestroyMutation,
  ClubEventRsvpStateEnum,
} from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';

import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
} from 'components/forms/forms';
import I18nContext from 'contexts/i18n_context';
import ConfirmationWrapper from 'components/utils/confirmation_wrapper';

const i18nScope =
  'components.clubs.club_events.club_event_detail_view.rsvp_button';

enum EventRsvpActions {
  Rsvp = 'RSVP',
  Cancel = 'CANCEL',
  GuestChange = 'GUEST_CHANGE',
}
interface EventRsvpFormValues {
  numberOfGuests: number;
  action: EventRsvpActions;
}

type ClubEventRsvp = NonNullable<
  NonNullable<
    ClubEventEventRsvpCreateOrUpdateMutation['clubEventEventRsvpCreateOrUpdate']
  >['eventRsvp']
>;

const CLUB_EVENT_EVENT_RSVP_CREATE_OR_UPDATE = gql(`
  mutation ClubEventEventRsvpCreateOrUpdate($input: ClubEventEventRsvpCreateOrUpdateInput!) {
    clubEventEventRsvpCreateOrUpdate(input: $input) {
      eventRsvp {
        id
        eventId
        state
        position
        numberOfGuests
        createdAt
        attendee {
          id
          fullName
          profilePicUrl
          firstJoinedAt
        }
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

const CLUB_EVENT_EVENT_RSVP_DESTROY = gql(`
  mutation ClubEventEventRsvpDestroy($input: ClubEventEventRsvpDestroyInput!) {
    clubEventEventRsvpDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function EventRsvpButton({
  clubEventId,
  areGuestsAllowed,
  currentMembershipEventRsvp = null,
  currentNumberOfGuests = 0,
  onCreateOrUpdateRsvpCallback,
  onCancelCallback,
}: {
  clubEventId: ClubEvent['id'];
  areGuestsAllowed: ClubEvent['areGuestsAllowed'];
  currentMembershipEventRsvp: ClubEventRsvp | null;
  currentNumberOfGuests: number;
  onCreateOrUpdateRsvpCallback: (eventRsvp: ClubEventRsvp) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [createOrUpdateMutation] = useMutation(
    CLUB_EVENT_EVENT_RSVP_CREATE_OR_UPDATE,
  );

  const [destroyMutation] = useMutation(CLUB_EVENT_EVENT_RSVP_DESTROY);

  const initialValues = {
    numberOfGuests: currentNumberOfGuests,
    action: EventRsvpActions.Rsvp,
  };
  const doesRsvpExist = currentMembershipEventRsvp !== null;

  const _onSubmit = (
    values: EventRsvpFormValues,
    actions: FormikHelpers<EventRsvpFormValues>,
  ) => {
    switch (values.action) {
      case EventRsvpActions.Rsvp: {
        sendMutationAndUpdateForm<
          EventRsvpFormValues,
          ClubEventEventRsvpCreateOrUpdateMutation
        >({
          actions,
          mutationName: 'clubEventEventRsvpCreateOrUpdate',
          main: () =>
            createOrUpdateMutation({
              variables: {
                input: {
                  clubEventId,
                  numberOfGuests: Number(values.numberOfGuests),
                },
              },
            }),
          successCallback: (mutationPayload) => {
            const eventRsvp =
              mutationPayload?.clubEventEventRsvpCreateOrUpdate?.eventRsvp;
            assertNotNullOrUndefined(eventRsvp);
            onCreateOrUpdateRsvpCallback(eventRsvp);
            actions.setSubmitting(false);
          },
        });
        break;
      }
      case EventRsvpActions.GuestChange: {
        if (!doesRsvpExist) return;

        sendMutationAndUpdateForm<
          EventRsvpFormValues,
          ClubEventEventRsvpCreateOrUpdateMutation
        >({
          actions,
          mutationName: 'clubEventEventRsvpCreateOrUpdate',
          main: () =>
            createOrUpdateMutation({
              variables: {
                input: {
                  id: currentMembershipEventRsvp.id,
                  clubEventId,
                  numberOfGuests: Number(values.numberOfGuests),
                },
              },
            }),
          successCallback: (mutationPayload) => {
            const eventRsvp =
              mutationPayload?.clubEventEventRsvpCreateOrUpdate?.eventRsvp;
            assertNotNullOrUndefined(eventRsvp);
            onCreateOrUpdateRsvpCallback(eventRsvp);
            actions.setSubmitting(false);
          },
        });
        break;
      }
      case EventRsvpActions.Cancel: {
        if (!doesRsvpExist) return;

        sendMutationAndUpdateForm<
          EventRsvpFormValues,
          ClubEventEventRsvpDestroyMutation
        >({
          actions,
          mutationName: 'clubEventEventRsvpDestroy',
          main: () =>
            destroyMutation({
              variables: {
                input: { id: currentMembershipEventRsvp.id },
              },
            }),
          successCallback: () => {
            values.numberOfGuests = 0; // in case user RSVPs again, they don't see the last (cached) value
            onCancelCallback();
            actions.setSubmitting(false);
          },
        });

        break;
      }
    }
  };

  // HACK: probably should move this to a presenter somewhere
  const alreadyRsvpedI18nKey =
    currentMembershipEventRsvp?.state === ClubEventRsvpStateEnum.Waitlisted
      ? 'already_rsvped.waitlisted'
      : 'already_rsvped.confirmed';

  const cancelConfirmationModalTextI18nKey =
    currentMembershipEventRsvp?.state === ClubEventRsvpStateEnum.Waitlisted
      ? 'cancel_confirmation_modal.text.waitlisted'
      : 'cancel_confirmation_modal.text.confirmed';

  return (
    <div id="event-rsvp-button">
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {({ status, isSubmitting, handleSubmit, setFieldValue, values }) => (
          <Form className="basic-form">
            <FormActions>
              {doesRsvpExist ? (
                <>
                  <span>
                    {i18n.rt(alreadyRsvpedI18nKey, {
                      scope: i18nScope,
                      values: {
                        guestSelect: areGuestsAllowed && (
                          <select
                            id="numberOfGuests"
                            name="numberOfGuests"
                            value={values.numberOfGuests}
                            onChange={(e) => {
                              e.preventDefault();
                              setFieldValue('numberOfGuests', e.target.value);
                              setFieldValue(
                                'action',
                                EventRsvpActions.GuestChange,
                              );
                              handleSubmit();
                            }}
                          >
                            <option value={0}>
                              {i18n.t('plus_zero', { scope: i18nScope })}
                            </option>
                            <option value={1}>
                              {i18n.t('plus_one', { scope: i18nScope })}
                            </option>
                            <option value={2}>
                              {i18n.t('plus_two', { scope: i18nScope })}
                            </option>
                            <option value={3}>
                              {i18n.t('plus_three', { scope: i18nScope })}
                            </option>
                            <option value={4}>
                              {i18n.t('plus_four', { scope: i18nScope })}
                            </option>
                            <option value={5}>
                              {i18n.t('plus_five', { scope: i18nScope })}
                            </option>
                          </select>
                        ),
                      },
                    })}
                  </span>{' '}
                  <br className="show-on-tablet-or-smaller" />
                  <ConfirmationWrapper
                    onClick={() => {
                      setFieldValue('action', EventRsvpActions.Cancel);
                      handleSubmit();
                    }}
                    confirmationText={i18n.t(
                      cancelConfirmationModalTextI18nKey,
                      {
                        scope: i18nScope,
                      },
                    )}
                    overrideConfirmButtonText={i18n.t(
                      'cancel_confirmation_modal.confirm_button',
                      {
                        scope: i18nScope,
                      },
                    )}
                  >
                    <a href="#">{i18n.t('cancel', { scope: i18nScope })}</a>
                  </ConfirmationWrapper>
                  <hr className="hr hide-on-tablet-or-smaller" />
                </>
              ) : (
                <FormActionButton
                  text={i18n.t('rsvp', { scope: i18nScope })}
                  className="primary"
                  isSubmitting={isSubmitting}
                  handleClick={() => {
                    setFieldValue('action', EventRsvpActions.Rsvp);
                    handleSubmit();
                  }}
                />
              )}
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
