import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAdminClubSponsorshipsQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { sendMutation } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import Linkifier from 'components/utils/linkifier';
import CompanyLogo, { logoSize } from 'components/utils/company_logo';

import AdminTable, {
  DestroyConfig,
  UpdatePositionConfig,
} from 'components/utils/admin/admin_table';
import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';

const i18nScope = 'clubs.admin.deals.index';
const tableScope = `${i18nScope}.table`;

type Club = NonNullable<GetAdminClubSponsorshipsQuery['club']>;
type ClubSponsorship = Club['sponsorships'][number];

const GET_ADMIN_CLUB_SPONSORSHIPS = gql(`
  query GetAdminClubSponsorships($clubId: ID!) {
    club(id: $clubId) {
      id
      sponsorships {
        id
        position
        deals {
          description
          redeemInstructions
        }
        company {
          id
          name
          logoUrl
        }
      }
    }
  }
`);

const ADMIN_CLUB_SPONSORSHIP_DESTROY = gql(`
  mutation ClubSponsorshipDestroy($input: ClubSponsorshipDestroyInput!) {
    clubSponsorshipDestroy(input: $input) {
      deletedId
      errors {
        attribute
        messages
      }
    }
  }
`);

const ADMIN_CLUB_SPONSOSHIP_SWAP_POSITION = gql(`
  mutation ClubSponsorshipSwapPosition($input: ClubSponsorshipSwapPositionInput!) {
    clubSponsorshipSwapPosition(input: $input) {
      sponsorship1 {
        id
        position
      }
      sponsorship2 {
        id
        position
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function DealsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [destroyMutation] = useMutation(ADMIN_CLUB_SPONSORSHIP_DESTROY);
  const [swapPositionMutation] = useMutation(
    ADMIN_CLUB_SPONSOSHIP_SWAP_POSITION,
  );
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_SPONSORSHIPS, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading || data === null)
    return <AdminDashboardLoadingPlaceholder columnWidths={[35, 55, 10]} />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const sponsorships = club.sponsorships;
  assertNotNullOrUndefined(sponsorships);
  const sortedSponsorships = _.sortBy(
    sponsorships,
    (sponsorship) => sponsorship.position,
  );

  const updatePositionConfig: UpdatePositionConfig<ClubSponsorship> = {
    getPosition: (sponsorship) => sponsorship.position ?? Infinity,
    updatePositionCallback: (target, adjacent) => {
      sendMutation({
        mutationName: 'clubSponsorshipSwapPosition',
        main: () =>
          swapPositionMutation({
            variables: {
              input: {
                sponsorship1Id: target.id,
                sponsorship2Id: adjacent.id,
              },
            },
            // optimistic update for speedy UI
            optimisticResponse: {
              clubSponsorshipSwapPosition: {
                sponsorship1: {
                  id: target.id,
                  __typename: 'ClubSponsorship',
                  position: adjacent.position,
                },
                sponsorship2: {
                  id: adjacent.id,
                  __typename: 'ClubSponsorship',
                  position: target.position,
                },
                errors: [],
              },
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  const destroyConfig: DestroyConfig<ClubSponsorship> = {
    getConfirmText: (sponsorship) =>
      i18n.t('actions.delete_confirmation', {
        scope: tableScope,
        values: {
          companyName: sponsorship.company.name,
        },
      }),
    destroyCallback: (sponsorship) => {
      // optimistic remove, and then we add it back in if it fails
      sendMutation({
        mutationName: 'clubSponsorshipDestroy',
        main: () =>
          destroyMutation({
            variables: { input: { id: sponsorship.id } },
            // // TODO: the update function runs but the component
            // // does not re-render so there is no optimistic update
            // optimisticResponse: {
            //   clubSponsorshipDestroy: {
            //     deletedId: sponsorship.id,
            //     errors: [],
            //   },
            // },
            update: (cache, result, options) => {
              const deletedId = options?.variables?.input?.id;
              assertNotNullOrUndefined(deletedId);

              const idToRemove = cache.identify({
                __typename: 'ClubSponsorship',
                id: deletedId,
              });

              cache.evict({ id: idToRemove });
              cache.gc();
            },
          }),
        allErrorsCallback: () => {
          alert(i18n.t('forms.errors.unknown_error'));
        },
      });
    },
  };

  return (
    <div id="clubs-admin-deals-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_sponsorship_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
        contentClasses="elevate-content min-height-page"
      >
        <AdminTable
          items={sortedSponsorships}
          emptyTableElement={
            <p>{i18n.t('empty_dashboard', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.company', { scope: tableScope })}</th>
              <th>{i18n.t('heading.deals', { scope: tableScope })}</th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(sponsorship) => (
            <>
              <td className="center">
                <div className="my-2">
                  <CompanyLogo
                    size={logoSize.SMALL}
                    company={sponsorship.company}
                  />
                  <div className="mt-1">{sponsorship.company.name}</div>
                </div>
              </td>
              <td style={{ maxWidth: '250px' }}>
                {sponsorship.deals.map((deal, index) => (
                  <div key={index} className={classNames(index > 0 && 'mt-2')}>
                    <Linkifier>{deal.description}</Linkifier>
                  </div>
                ))}
              </td>
            </>
          )}
          onClickEditCallback={(sponsorship) =>
            navigate(`${sponsorship.id}/edit`)
          }
          destroyConfig={destroyConfig}
          updatePositionConfig={updatePositionConfig}
        />
      </AdminDashboard>
    </div>
  );
}
