import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { AuthModes } from 'components/login_signup/auth_modes';
import {
  FormActions,
  FormActionButton,
  FormField,
  FormServerErrorMessages,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import { EmailAddress } from 'types/types';
import OmniAuthOptionsWrapper from 'components/utils/auth/omni_auth_options_wrapper';

interface LoginFormValues {
  emailAddress: EmailAddress;
  password: string;
}

export default function LoginForm({
  afterAuthenticationUrl,
  setAuthenticationMode,
  afterAuthenticationCallback,
}: {
  setAuthenticationMode: (newMode: AuthModes) => void;
  afterAuthenticationUrl: string;
  afterAuthenticationCallback?: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const initialValues: LoginFormValues = { emailAddress: '', password: '' };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        emailAddress: Yup.string()
          .email(i18n.t('forms.errors.invalid_email'))
          .required(i18n.t('forms.errors.required')),
        password: Yup.string()
          .min(6, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
      }),
    [],
  );

  const _onSubmit = function (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>,
  ) {
    Helpers.Form.save({
      actions,
      main: () => {
        const url = Helpers.Routes.getSessionsPath();
        const params = { session: values };
        return Helpers.API.post({ url, params });
      },
      successCallback: () => {
        // keep form spinning, so don't reset setSubmitting
        afterAuthenticationCallback?.();
      },
    });
  };

  return (
    <div className="login-form">
      <div className="other-authenticate-option small">
        {i18n.t('auth.login.sign_up_instead')} &nbsp;
        <a onClick={() => setAuthenticationMode(AuthModes.SIGN_UP)}>
          {i18n.t('auth.sign_up.heading')}
        </a>
      </div>
      <OmniAuthOptionsWrapper
        facebookButtonText={i18n.t('auth.login.facebook_button')}
        googleButtonText={i18n.t('auth.login.google_button')}
        afterAuthenticationUrl={afterAuthenticationUrl}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_onSubmit}
        >
          {({ status, isSubmitting }) => (
            <Form className="basic-form" noValidate>
              <FormField
                type="email"
                name="emailAddress"
                placeholder={i18n.t(
                  'auth.login.form.email_address_placeholder',
                )}
              />
              <FormField
                type="password"
                name="password"
                placeholder={i18n.t('auth.login.form.password_placeholder')}
              />
              <FormActions>
                <FormActionButton
                  text={i18n.t('auth.login.form.submit')}
                  className="primary"
                  isSubmitting={isSubmitting}
                />
                <div className="other-form-actions">
                  <a
                    onClick={() =>
                      setAuthenticationMode(AuthModes.PASSWORD_RESET)
                    }
                  >
                    {i18n.t('auth.login.reset_password_instead')}
                  </a>
                </div>
                <FormServerErrorMessages
                  serverErrors={status?.serverErrors?.base}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </OmniAuthOptionsWrapper>
    </div>
  );
}
