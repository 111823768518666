import React from 'react';
import classNames from 'classnames';

interface LocationProps {
  text: string;
  classes?: string;
}

const Location = ({ text, classes }: LocationProps) => {
  return (
    <div className={classNames('location', classes)}>
      <span className="icon mr-1">
        <i className="fas fa-map-marker-alt"></i>
      </span>
      <span className="text">{text}</span>
    </div>
  );
};

export default Location;
