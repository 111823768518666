import { assertNotNullOrUndefined, optionsForSelectField } from 'h';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import SuperAdminClubForm, {
  buildNewClub,
} from 'components/super_admin/dashboard/clubs/form';

const i18nScope = 'components.super_admin.dashboard.clubs.new';

const GET_SUPER_ADMIN_CLUBS_NEW = gql(`
  query GetSuperAdminClubsNew {
    googleMapsApiPublicKey
    countries {
      id
      nameWithEmoji
    }
    timeZones {
      id
      nameWithOffset
    }
    clubTypes {
      id
      name
    }
    clubs {
      nodes {
        id
      }
    }
  }
`);

export default function SuperAdminClubsNewPage() {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);
  const newClub = useMemo(() => buildNewClub(), []);

  const { loading, error, data } = useQuery(GET_SUPER_ADMIN_CLUBS_NEW, {});

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  assertNotNullOrUndefined(data);
  const { googleMapsApiPublicKey, clubTypes, countries, timeZones } = data;
  assertNotNullOrUndefined(googleMapsApiPublicKey);
  assertNotNullOrUndefined(clubTypes);
  assertNotNullOrUndefined(countries);
  assertNotNullOrUndefined(timeZones);

  const clubTypesForSelectField = optionsForSelectField({
    array: clubTypes,
    value: 'id',
    name: 'name',
    shouldSort: false,
  });

  const timeZonesForSelectField = optionsForSelectField({
    array: timeZones,
    value: 'id',
    name: 'nameWithOffset',
    shouldSort: false,
  });

  const countriesForSelectField = optionsForSelectField({
    array: countries,
    value: 'id',
    name: 'nameWithEmoji',
  });

  return (
    <div id="super-admin-dashboard-clubs-new-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page"
      >
        <SuperAdminClubForm
          googleMapsApiPublicKey={googleMapsApiPublicKey}
          clubTypesForSelectField={clubTypesForSelectField}
          timeZonesForSelectField={timeZonesForSelectField}
          countriesForSelectField={countriesForSelectField}
          isEditing={false}
          club={newClub}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
