import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import CustomPageForm from 'components/clubs/admin/pages/custom_page_form';

const i18nScope = 'clubs.admin.pages.edit';

const GET_ADMIN_CLUB_EDIT_CUSTOM_PAGE = gql(`
  query GetAdminClubEditCustomPage($clubId: ID!, $customPageId: ID!) {
    club(id: $clubId) {
      id
      customPage(id: $customPageId) {
        id
        isPublished
        title
        icon
        body
      }
    }
  }
`);

export default function CustomPageEditPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_EDIT_CUSTOM_PAGE, {
    variables: {
      clubId: String(currentClubId),
      customPageId: String(id),
    },
  });
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const customPage = club.customPage;
  assertNotNullOrUndefined(customPage);

  return (
    <AdminDashboard
      title={i18n.t('title', { scope: i18nScope })}
      contentClasses="elevate-content min-height-page custom-page-form-page"
    >
      <CustomPageForm
        clubId={String(currentClubId)}
        isEditing={true}
        customPage={customPage}
        onSuccessCallback={backToIndexPage}
        onCancelCallback={backToIndexPage}
      />
    </AdminDashboard>
  );
}
