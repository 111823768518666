import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import { ClubId } from 'types/types';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { CustomPagePresenter } from 'components/utils/custom_page_presenter';

const GET_CLUBHOUSE_WELCOME_PAGE = gql(`
  query GetClubhouseWelcomePage($clubId: ID!) {
    club(id: $clubId) {
      id
      welcomePage {
        id
        title
        body
      }
    }
  }
`);

export default function WelcomePage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_CLUBHOUSE_WELCOME_PAGE, {
    variables: { clubId: String(currentClubId), customPageId: String(id) },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const welcomePage = data?.club?.welcomePage;
  assertNotNullOrUndefined(welcomePage);

  return (
    <div id="clubhouse-welcome-page">
      <div className="page-header">
        <div className="title">
          <h1>👋 {welcomePage.title}</h1>
        </div>
      </div>
      <CustomPagePresenter classes="elevate-content" customPage={welcomePage} />
    </div>
  );
}
