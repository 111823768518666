import h from 'h';
import _ from 'underscore';
import _str from 'underscore.string';
import React from 'react';
import classNames from 'classnames';

import AuthenticateThenRedirectLink from 'components/utils/authenticate_then_redirect_link';
import { MembershipProfilePic } from 'components/utils/profile_pic';
import CompanyLogo, { logoSize } from 'components/utils/company_logo';
import Location from 'components/utils/location';
import EventTime from 'components/utils/event_time';

function ProfilePageSection({ i18n, section, canEdit = false, ...props }) {
  let sectionHtml;
  switch (section.sectionType) {
    case 'banner':
      sectionHtml = _renderBannerSection(props, section);
      break;
    case 'header':
      sectionHtml = _renderHeaderSection(props, section);
      break;
    case 'members':
      sectionHtml = _renderMembersSection(props, section);
      break;
    case 'programs':
      sectionHtml = _renderProgramsSection(props, section);
      break;
    case 'races':
      sectionHtml = _renderRacesSection(props, section);
      break;
    case 'sponsors':
      sectionHtml = _renderSponsorsSection(props, section);
      break;
    default:
      throw new Error(`unknown section ${section.sectionType}`);
  }

  const _removeSection = function () {
    if (confirm(i18n.t('clubs.admin.profile.section.delete_confirmation'))) {
      props.removeSectionCallback(section);
    }
  };

  const sectionClassName = `${section.sectionType.replace('_', '-')}-section`;

  const classes = classNames('section', sectionClassName, {
    editable: canEdit,
  });
  return (
    <div className={classes}>
      {canEdit && (
        <div className="edit-toolbar">
          <i
            onClick={() => props.editSectionCallback(section)}
            className="fas fa-pen"
          ></i>
          <i
            onClick={() =>
              !props.isFirst && props.moveSectionCallback(section, -1)
            }
            className={classNames('fas fa-arrow-up', {
              disabled: props.isFirst,
            })}
          ></i>
          <i
            onClick={() =>
              !props.isLast && props.moveSectionCallback(section, 1)
            }
            className={classNames('fas fa-arrow-down', {
              disabled: props.isLast,
            })}
          ></i>
          <i onClick={_removeSection} className="far fa-trash-alt"></i>
        </div>
      )}
      {sectionHtml}
    </div>
  );
}

export default ProfilePageSection;

function _renderBannerSection(props, section) {
  return (
    <>
      <h2 className="section-heading">{section.config.banner}</h2>
    </>
  );
}

function _renderHeaderSection(props, section) {
  const { store } = props;
  const club = store.getRawCurrentClub();
  return (
    <>
      <div className="image-wrapper">
        <img
          className="rounded-lg"
          src={section.remoteImageUrlsById?.[section.config.heroImageId]}
        />
      </div>
      <div className="club-summary">
        <h1 className="bold">{club.attributes.name}</h1>
        <div className="info">
          <Location text="San Francisco, CA" />
          <div className="website">
            <i className="fas fa-globe-americas mr-2"></i>
            <a href={club.website} target="_blank">
              {club.attributes.website}
            </a>
          </div>
        </div>
        <p>{section.config.description}</p>
        <div className="action">
          <AuthenticateThenRedirectLink
            isLoggedIn={!h.isNullOrUndefined(store.getCurrentUserIfExists())}
            redirectPath={club.links.clubJoinPath}
          >
            <button className="btn btn-primary">Join</button>
          </AuthenticateThenRedirectLink>
        </div>
      </div>
    </>
  );
}

function _renderProgramsSection(props, section) {
  const programs = [
    {
      name: '0 to 60',
      imageUrl: 'https://via.placeholder.com/300x175',
      description:
        "Over 12 weeks we'll take you from beginner to your first triathlon. It's the highlight of the every new member. You'll get ready for race shape and make awesome friends in the process.",
    },
    {
      name: 'Women For Tri',
      imageUrl: 'https://via.placeholder.com/300x175',
      description:
        "A 10 week program for women to where you'll complete the sprint distance program.",
    },
    {
      name: 'Ironman',
      imageUrl: 'https://via.placeholder.com/300x175',
      description:
        "If you're a seasoned triathlete we have coached workouts and training weekends.",
    },
  ];
  const programsHtml = _.map(programs, (program, index) => {
    return (
      <div className="program" key={index}>
        <img src={program.imageUrl} className="rounded-md" />
        <h4 className="bold">{program.name}</h4>
        <p>{program.description}</p>
      </div>
    );
  });

  return (
    <>
      {!_str.isBlank(section.config.heading) && (
        <h2 className="section-heading">{section.config.heading}</h2>
      )}
      <div className="programs">{programsHtml}</div>
    </>
  );
}

function _renderRacesSection(props, section) {
  const races = [
    {
      name: 'Napa HITS',
      locationText: 'Lake Berryessa, CA',
      event: { startDate: '2022-04-10', endDate: '2022-04-10', isAllDay: true },
    },
    {
      name: 'Napa HITS',
      locationText: 'Lake Berryessa, CA',
      event: { startDate: '2022-04-10', endDate: '2022-04-10', isAllDay: true },
    },
    {
      name: 'Napa HITS',
      locationText: 'Lake Berryessa, CA',
      event: { startDate: '2022-04-10', endDate: '2022-04-10', isAllDay: true },
    },
  ];
  const racesHtml = _.map(races, (race, index) => {
    return (
      <div className="race" key={index}>
        <img src="https://via.placeholder.com/300x175" className="rounded-md" />
        <div className="race-info">
          <h4 className="bold">{race.name}</h4>
          <Location text={race.locationText} />
          <EventTime event={race.event} />
        </div>
      </div>
    );
  });

  return (
    <>
      <h2 className="section-heading">{section.config.heading}</h2>
      <div className="races">{racesHtml}</div>
    </>
  );
}

function _renderMembersSection(props, section) {
  const { store } = props;
  const memberships = store.getAllModels('membership');
  const membersHtml = _.map(memberships, (membership) => {
    return <MembershipProfilePic key={membership.id} membership={membership} />;
  });

  return (
    <>
      <h2 className="section-heading">{section.config.heading}</h2>
      <div className="members">{membersHtml}</div>
    </>
  );
}

function _renderSponsorsSection(props, section) {
  const { store } = props;
  const sponsors = store.getAllModels('company');
  return (
    <>
      <h2 className="section-heading">{section.config.heading}</h2>
      <div className="sponsors">
        {sponsors.map((sponsor, index) => (
          <CompanyLogo
            key={index}
            classes="inline-block sponsor"
            company={sponsor}
            size={logoSize.LARGE}
          />
        ))}
      </div>
    </>
  );
}
