import React, { useState } from 'react';
import classNames from 'classnames';
import AuthenticationModal from 'components/utils/authentication_modal';
import Helpers from 'helpers/helpers';

export default function AuthenticateThenRedirectLink({
  isLoggedIn,
  children,
  classes,
  redirectPath,
}: {
  isLoggedIn: boolean;
  children: React.ReactElement;
  redirectPath: string;
  classes?: string;
}) {
  const [shouldShowAuthenticationModal, setShouldShowAuthenticationModal] =
    useState(false);

  const _redirect = () => Helpers.Utils.redirectTo(redirectPath);

  const _onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isLoggedIn) {
      // they're logged in
      _redirect();
    } else {
      // show the modal to login
      e.preventDefault();
      setShouldShowAuthenticationModal(true);
    }
  };

  const _onCancelModal = () => setShouldShowAuthenticationModal(false);

  return (
    <div className={classNames('authenticate-then-redirect-link', classes)}>
      <a href={redirectPath} onClick={_onClick}>
        {children}
      </a>
      {shouldShowAuthenticationModal && (
        <AuthenticationModal
          afterAuthenticationUrl={redirectPath}
          onCancel={_onCancelModal}
        />
      )}
    </div>
  );
}
