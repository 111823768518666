import React, { useState } from 'react';
import Store from 'helpers/store';
import { DeprecatedAny } from 'types/types';
import ClubEventsCalendarOrListView from 'components/utils/events/club_events_calendar_or_list_view';
import RootAppWrapper from 'components/utils/root_app_wrapper';

export default function EmbedCalendar(props: DeprecatedAny) {
  const [store] = useState(() => Store.parseInitData(props));
  const club = store.getCurrentClub();

  return (
    <RootAppWrapper>
      <div
        className="embed-calendar"
        style={{ maxWidth: '900px', margin: 'auto' }}
      >
        <ClubEventsCalendarOrListView
          club={club}
          openLinksInNewTab={true}
          shouldShowCalendarIfPossible={true}
        />
      </div>
    </RootAppWrapper>
  );
}
