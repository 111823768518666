import _ from 'underscore';
import React, { useMemo, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';
import Store from 'helpers/store';
import {
  FormActions,
  FormActionButton,
  FormField,
  FormServerErrorMessages,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import { DeprecatedAny, EmailAddress } from 'types/types';
import OmniAuthOptionsWrapper from 'components/utils/auth/omni_auth_options_wrapper';

const i18nScope = 'components.unclaimed_users.setup_account';

interface SetupAccountFormValues {
  password: string;
  passwordConfirmation: string;
}

export default function SetupAccountFormWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <SetupAccountForm {...props} />
    </RootAppWrapper>
  );
}

function SetupAccountForm(props: DeprecatedAny) {
  const { i18n } = React.useContext(I18nContext);
  const [store] = useState(() => Store.parseInitData(props));

  const initialValues = { password: '', passwordConfirmation: '' };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .required(i18n.t('forms.errors.required'))
          .min(6, i18n.t('forms.errors.min_length')),
        passwordConfirmation: Yup.string()
          .required(i18n.t('forms.errors.required'))
          .min(6, i18n.t('forms.errors.min_length')),
      }),
    [],
  );

  const emailAddress = store.getOrThrow<EmailAddress>('emailAddress');
  const claimToken = store.getOrThrow<string>('claimToken');
  const onSuccessRedirectToPath = store.getOrThrow<string>(
    'onSuccessRedirectToPath',
  );

  const _onSubmit = function (
    values: SetupAccountFormValues,
    actions: FormikHelpers<SetupAccountFormValues>,
  ) {
    Helpers.Form.save<SetupAccountFormValues>({
      actions: actions,
      main: () => {
        const params = _.extend(values, { emailAddress });
        const url = Helpers.Routes.getUnclaimedUserPath(claimToken);
        return Helpers.API.put({ url, params: { unclaimedUser: params } });
      },
      successCallback: () => {
        // keep form spinning while we redirect
        window.location.href = onSuccessRedirectToPath;
      },
    });
  };

  const authenticateButtonHiddenFields = (
    <span>
      <input type="hidden" name="claim_token" value={claimToken} />
      <input
        type="hidden"
        name="unclaimed_user_email_address"
        value={emailAddress}
      />
    </span>
  );

  return (
    <div className="unclaimed-user-setup-account">
      <h1 className="mb-4">{i18n.t('title', { scope: i18nScope })}</h1>
      <OmniAuthOptionsWrapper
        facebookButtonText={i18n.t('facebook_button', { scope: i18nScope })}
        googleButtonText={i18n.t('google_button', { scope: i18nScope })}
        authenticateButtonHiddenFields={authenticateButtonHiddenFields}
        afterAuthenticationUrl={onSuccessRedirectToPath}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_onSubmit}
        >
          {({ status, isSubmitting }) => (
            <Form className="basic-form" noValidate>
              <FormField
                type="password"
                name="password"
                placeholder={i18n.t('form.password.placeholder', {
                  scope: i18nScope,
                })}
              />
              <FormField
                type="password"
                name="passwordConfirmation"
                placeholder={i18n.t('form.password_confirmation.placeholder', {
                  scope: i18nScope,
                })}
              />
              <FormActions>
                <FormActionButton
                  text={i18n.t('form.actions.submit', { scope: i18nScope })}
                  className="primary"
                  isSubmitting={isSubmitting}
                />
                <FormServerErrorMessages
                  serverErrors={status?.serverErrors?.base}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </OmniAuthOptionsWrapper>
    </div>
  );
}
