import h from 'h';
import _ from 'underscore';
import { DependencyList, useEffect, useRef } from 'react';

export const useSkipInitialEffect = (
  effectFn: React.EffectCallback,
  deps?: DependencyList,
) => {
  const initialRender = useRef(true);

  useEffect(() => {
    let effectCleanupFn = null;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectCleanupFn = effectFn();
    }

    if (
      !h.isNullOrUndefined(effectCleanupFn) &&
      _.isFunction(effectCleanupFn)
    ) {
      return effectCleanupFn;
    }
  }, deps);
};
