import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';
import { DeprecatedAny, Latitude, Longitude } from 'types/types';
import { GOOGLE_MAPS_LIBRARIES_TO_LOAD } from 'helpers/google_maps_helper';

export const DEFAULT_DETAILED_ZOOM_LEVEL = 14;
export const DEFAULT_BROAD_ZOOM_LEVEL = 11;

// Folllowed tutorial here, thank you google
// https://developers.google.com/maps/documentation/javascript/react-map

function MapView({
  center,
  zoom,
  children,
}: google.maps.MapOptions & { children?: DeprecatedAny }) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          disableDefaultUI: true,
        }),
      );
    }
  }, [ref, map]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useEffect(() => map?.panTo(center!), [center]);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useEffect(() => map?.setZoom(zoom!), [zoom]);

  return (
    <>
      <div id="map" ref={ref} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child as React.ReactElement, { map });
        }
      })}
    </>
  );
}

function Marker(options: google.maps.MarkerOptions) {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
}

const renderFn = (status: Status) => {
  switch (status) {
    case Status.LOADING:
    case Status.FAILURE:
      return <LoadingPlaceholder type={placeholderType.FULL} />;
    case Status.SUCCESS:
      return <></>;
  }
};

interface MapProps {
  apiKey: string;
  longitude: Longitude;
  latitude: Latitude;
  hideMarker?: boolean;
  zoom?: number;
  classes?: string;
}

export default function Map({
  classes,
  apiKey,
  longitude,
  latitude,
  hideMarker = false,
  zoom = DEFAULT_DETAILED_ZOOM_LEVEL,
}: MapProps) {
  const position = { lat: latitude, lng: longitude };

  return (
    <div className={classNames('map', classes)}>
      <Wrapper
        apiKey={apiKey}
        libraries={GOOGLE_MAPS_LIBRARIES_TO_LOAD}
        render={renderFn}
      >
        <MapView center={position} zoom={zoom}>
          {!hideMarker && <Marker position={position} />}
        </MapView>
      </Wrapper>
    </div>
  );
}
