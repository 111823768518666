import React from 'react';
import Tippy from '@tippyjs/react';
import { DateTimeISOString } from 'types/types';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'components.utils.timestamp';

export enum TippyPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',

  TOP_START = 'top-start',
  BOTTOM_START = 'bottom-start',
  RIGHT_START = 'right-start',
  LEFT_START = 'left-start',

  TOP_END = 'top-end',
  RIGHT_END = 'right-end',
  BOTTOM_END = 'bottom-end',
  LEFT_END = 'left-end',
}

export default function Timestamp({
  createdAt,
  placement,
  classes,
}: {
  createdAt: DateTimeISOString;
  placement: TippyPlacement;
  classes?: string;
}) {
  const { i18n } = React.useContext(I18nContext);

  const msPerSecond = 1000;
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const msPerYear = msPerDay * 365;

  const timestamp = Date.parse(createdAt);

  const diff = Math.abs(+timestamp - +new Date());

  let unit: Intl.RelativeTimeFormatUnit = 'second';
  let divisor: number;

  if (diff < msPerMinute) {
    unit = 'second';
    divisor = msPerSecond;
  } else if (diff < msPerHour) {
    unit = 'minute';
    divisor = msPerMinute;
  } else if (diff < msPerDay) {
    unit = 'hour';
    divisor = msPerHour;
  } else if (diff < msPerWeek) {
    unit = 'day';
    divisor = msPerDay;
  } else if (diff < msPerYear) {
    unit = 'week';
    divisor = msPerWeek;
  } else {
    unit = 'year';
    divisor = msPerYear;
  }

  const unitsAgo = Math.floor(diff / divisor);

  const content = i18n.t(`${unit}`, {
    values: { unitsAgo: unitsAgo },
    scope: i18nScope,
  });
  const tooltipDate = new Date(createdAt);
  const tooltipContent =
    new Date().toDateString() == tooltipDate.toDateString()
      ? tooltipDate.toLocaleTimeString()
      : tooltipDate.toDateString();

  return (
    <Tippy content={tooltipContent} placement={placement}>
      <div className={classes}>{content}</div>
    </Tippy>
  );
}
