import h from 'h';
import _ from 'underscore';
import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import * as Yup from 'yup';

import { FormFieldWrapper } from 'components/forms/forms';

import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import Store, { ModelType } from 'helpers/store';
import {
  OrderItemSubjectType,
  StripePaymentMethod,
  SubscriptionPlan,
  SubscriptionPlanId,
} from 'types/types';
import CheckoutWrapper from 'components/utils/orders/checkout_wrapper';

const i18nBaseScope = 'components.clubs.subscriptions.purchase_subscription';
const i18nScope = `${i18nBaseScope}.form`;

interface PurchaseSubscriptionFormValues {
  subscriptionPlanId: SubscriptionPlanId;
}

// NOTE: be careful if renaming because we use these values for
// get the appropriate translation
enum JoinOrRenew {
  JOIN = 'join',
  RENEW = 'renew',
}

export default function PurchaseSubscriptionForm({ store }: { store: Store }) {
  const { i18n } = React.useContext(I18nContext);
  const joinOrRenewMode = store.getOrThrow<JoinOrRenew>('joinOrRenewMode');
  const i18nJoinOrRenewScope = `${i18nBaseScope}.${joinOrRenewMode}`;

  // used to ensure we don't accidentally double charge the user
  const orderIdempotencyKey = store.getOrThrow<string>('orderIdempotencyKey');

  const onSuccessRedirectToPath = store.getOrThrow<string>(
    'onSuccessRedirectToPath',
  );

  const club = store.getCurrentClub();
  const sortedSubscriptionPlans = _.sortBy(
    store.getAllModels<SubscriptionPlan>(ModelType.SUBSCRIPTION_PLAN),
    (subscriptionPlan) => subscriptionPlan.position,
  );
  const defaultSubscriptionPlan = _.first(sortedSubscriptionPlans) ?? null;
  if (defaultSubscriptionPlan === null) {
    return h.throwError('no subscription plans provided');
  }

  const stripePaymentMethods = store
    .getAllModels<StripePaymentMethod>(ModelType.STRIPE_PAYMENT_METHOD)
    .map((spm) => ({ ...spm, id: String(spm.id) }));

  const additionalInitialValues = {
    subscriptionPlanId: defaultSubscriptionPlan.id,
  };
  const additionalValidationSchema = {
    subscriptionPlanId: Yup.string().required(i18n.t('forms.errors.required')),
  };

  return (
    <div id="purchase-subscription">
      <CheckoutWrapper<PurchaseSubscriptionFormValues>
        orderIdempotencyKey={orderIdempotencyKey}
        additionalInitialValues={additionalInitialValues}
        additionalValidationSchema={additionalValidationSchema}
        platformStripePaymentMethods={stripePaymentMethods}
        overrideSubmitButtonText={i18n.t('submit', {
          scope: i18nJoinOrRenewScope,
        })}
        createOrderItemsCallback={({ values }) => [
          {
            subjectType: OrderItemSubjectType.SUBSCRIPTION_PLAN,
            subjectId: values.subscriptionPlanId,
            priceInCents: store.getModelOrThrow<SubscriptionPlan>(
              ModelType.SUBSCRIPTION_PLAN,
              Number(values.subscriptionPlanId),
            ).priceInCents,
            quantity: 1,
          },
        ]}
        onSuccessCallback={() =>
          Helpers.Utils.redirectTo(
            (window.location.href = onSuccessRedirectToPath),
          )
        }
      >
        {({
          paymentMethodFieldFormFieldElement,
          setFieldTouched,
          setFieldValue,
          values,
        }) => (
          <div>
            <h1>
              {i18n.t('heading', {
                scope: i18nJoinOrRenewScope,
                values: {
                  clubName: club.name,
                },
              })}
            </h1>
            <div className="form-section">
              <div
                className="form-section-header"
                dangerouslySetInnerHTML={{
                  __html: i18n.t('plan_section.heading_html', {
                    scope: i18nScope,
                  }),
                }}
              />
              <div className="form-section-body">
                <FormFieldWrapper name="subscriptionPlanId">
                  <SubscriptionPlanOptionsField
                    name="subscriptionPlanId"
                    subscriptionPlans={sortedSubscriptionPlans}
                    values={values}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                </FormFieldWrapper>
              </div>
            </div>
            <div className="form-section mb-2">
              <div
                className="form-section-header"
                dangerouslySetInnerHTML={{
                  __html: i18n.t('payment_section.heading_html', {
                    scope: i18nScope,
                  }),
                }}
              />
            </div>
            <div className="form-section-body">
              {paymentMethodFieldFormFieldElement}
            </div>
          </div>
        )}
      </CheckoutWrapper>
    </div>
  );
}

function SubscriptionPlanOptionsField({
  subscriptionPlans,
  name,
  values,
  setFieldTouched,
  setFieldValue,
}: {
  subscriptionPlans: SubscriptionPlan[];
  name: keyof PurchaseSubscriptionFormValues;
  values: PurchaseSubscriptionFormValues;
  setFieldTouched: (name: string, wasTouched: boolean) => void;
  setFieldValue: (name: string, value: SubscriptionPlanId) => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const selectedSubscriptionPlanId = values[name];

  const subscriptionPlanOptions = subscriptionPlans.map((subscriptionPlan) => {
    const subscriptionPlanId = subscriptionPlan.id;
    const isSelected = selectedSubscriptionPlanId === subscriptionPlanId;

    return (
      <div
        key={subscriptionPlan.id}
        className={classNames('subscription-plan', {
          selected: isSelected,
        })}
        onClick={() => {
          setFieldValue(name, subscriptionPlanId);
          setFieldTouched(name, true);
        }}
      >
        <Field
          className="mr-1"
          type="radio"
          name={name}
          value={subscriptionPlanId}
        />
        <span className="label">
          {i18n.t('subscription_plan_name', {
            scope: i18nScope,
            values: {
              name: subscriptionPlan.name,
              cost: i18n.c({ amountInCents: subscriptionPlan.priceInCents }),
            },
          })}
          <br />
          <span className="small light-text-color">
            {i18n.t('duration', {
              scope: i18nScope,
              values: {
                duration: i18n.t(
                  `models.subscription_plans.frequency.singular.${subscriptionPlan.frequency}`,
                ),
              },
            })}
            <br />
            {subscriptionPlan.autoRenew
              ? i18n.t('does_auto_renew', { scope: i18nScope })
              : i18n.t('does_not_auto_renew', { scope: i18nScope })}
          </span>
        </span>
      </div>
    );
  });

  return <div className="subscription-plans">{subscriptionPlanOptions}</div>;
}
