import { arrayFilterNulls, assertNotNullOrUndefined } from 'h';
import React from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { getDoesClubHaveAddOnInstalled } from 'models/club';
import { AddOnNames } from 'components/add_ons/names';
import { TabLinkConfig, TabNavbar } from 'components/utils/tabbed_view';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';
import {
  MembershipProfilePic,
  LoadingPlaceholderProfilePic,
  picSize,
} from 'components/utils/profile_pic';
import MembershipBadges from 'components/clubs/admin/memberships_page/membership_badges';
import { ClubId } from 'types/types';
import { Club } from '__generated__/graphql';

type ClubContext = Pick<Club, 'addOnNames'>;

const i18nScope = 'clubs.admin.memberships.show';

const GET_ADMIN_CLUB_MEMBERSHIP_LAYOUT = gql(`
  query GetAdminClubMembershipLayout($clubId: ID!, $membershipId: ID!) {
    club(id: $clubId) {
      id
      addOnNames
      membership(id: $membershipId) {
        id
        profilePicUrl
        fullName
        emailAddress
        state
        isActive
        hasJoinedRecently
        hasExpiredRecently
        membershipTags {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`);

function LoadingMembershipPlaceholder() {
  return (
    <div id="clubs-admin-memberships-page">
      <div
        id="clubs-admin-membership-show"
        className="elevate-content min-height-page"
      >
        <div className="membership">
          <div className="header mb-4">
            <LoadingPlaceholderProfilePic
              classes="inline-block mr-1"
              size={picSize.EXTRA_LARGE}
            />
            <div className="info">
              <div style={{ width: 200 }}>
                <LoadingPlaceholder
                  type={placeholderType.BAR}
                  widthPercent="100%"
                  classes="p-2 inline-block"
                />
              </div>
            </div>
          </div>

          <LoadingPlaceholder
            type={placeholderType.BAR}
            widthPercent="50%"
            classes="mb-2"
          />
          <LoadingPlaceholder
            type={placeholderType.BAR}
            widthPercent="45%"
            classes="mb-2"
          />
          <LoadingPlaceholder
            type={placeholderType.BAR}
            widthPercent="40%"
            classes="mb-2"
          />
        </div>
      </div>
    </div>
  );
}

export default function MembershipShowLayout({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();

  assertNotNullOrUndefined(id);

  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_MEMBERSHIP_LAYOUT, {
    variables: {
      clubId: String(currentClubId),
      membershipId: id,
    },
  });

  if (loading) return <LoadingMembershipPlaceholder />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const membership = club.membership;
  assertNotNullOrUndefined(membership);

  // actions: suspened, renew (with or without charge)
  // info: upcoming races, events joined
  // maybe even an engagement score

  const tabLinkConfigs: TabLinkConfig[] = [];

  tabLinkConfigs.push({
    text: i18n.t('tabs.info', { scope: i18nScope }),
    to: 'info',
    replace: true,
  });

  tabLinkConfigs.push({
    text: i18n.t('tabs.emails', { scope: i18nScope }),
    to: 'emails',
    replace: true,
  });

  tabLinkConfigs.push({
    text: i18n.t('tabs.logs', { scope: i18nScope }),
    to: 'logs',
    replace: true,
  });

  const hasEmergencyContactsApp = getDoesClubHaveAddOnInstalled({
    club,
    addOnName: AddOnNames.EMERGENCY_CONTACTS,
  });

  if (hasEmergencyContactsApp) {
    tabLinkConfigs.push({
      text: i18n.t('tabs.emergency', {
        scope: i18nScope,
      }),
      to: 'emergency',
      replace: true,
    });
  }

  const membershipTagsConnection = membership.membershipTags;
  const membershipTags = arrayFilterNulls(
    (membershipTagsConnection?.edges ?? []).map((e) => e?.node),
  );

  return (
    <div id="clubs-admin-membership-show">
      <div className="elevate-content min-height-page">
        <div className="mb-4">
          <a onClick={() => navigate(-1)}>
            {i18n.t('back', { scope: i18nScope })}
          </a>
        </div>

        <div className="membership">
          <div className="header mb-4">
            <MembershipProfilePic
              classes="inline-block  mr-1"
              membership={membership}
              size={picSize.EXTRA_LARGE}
            />
            <div className="info">
              <h1 className="mb-1">{membership.fullName}</h1>
              <MembershipBadges
                classes="inline-block"
                membership={membership}
                membershipTags={membershipTags}
              />
            </div>
          </div>

          <TabNavbar tabLinkConfigs={tabLinkConfigs} />
          <Outlet context={club} />
        </div>
      </div>
    </div>
  );
}

export function useClubContext() {
  return useOutletContext<ClubContext>();
}
