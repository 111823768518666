import React from 'react';
import { ClubMembership } from '__generated__/graphql';
import { MembershipProfilePic } from 'components/utils/profile_pic';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'components.utils.memberships.membership_info_card';

export default function MembershipInfoCard({
  membership,
}: {
  membership: Pick<
    ClubMembership,
    'fullName' | 'profilePicUrl' | 'firstJoinedAt'
  >;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="membership-info-card elevate-content">
      <MembershipProfilePic membership={membership} />
      <div className="full-name mt-1" title={membership.fullName}>
        {membership.fullName}
      </div>
      <div className="small light-text-color mt-1">
        {i18n.t('joined_on', {
          scope: i18nScope,
          values: {
            date: new Date(membership.firstJoinedAt).toLocaleDateString([], {
              year: 'numeric',
              month: 'short',
            }),
          },
        })}
      </div>
    </div>
  );
}
