import React, { useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';
import Store from 'helpers/store';
import Helpers from 'helpers/helpers';

import CancelRenewalForm from 'components/clubs/membership_renewal_cancelations/cancel_renewal_form';
import { DeprecatedAny } from 'types/types';

const i18nScope = 'views.clubs.membership_renewal_cancelations';

export default function CancelRenewalWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <CancelRenewal {...props} />
    </RootAppWrapper>
  );
}

function CancelRenewal(props: DeprecatedAny) {
  const { i18n } = React.useContext(I18nContext);
  const [store] = useState(() => Store.parseInitData(props));

  const [wasCancelationCompleted, setWasCancelationCompleted] = useState(false);

  const backToClubhouseSettingsPath = store.getOrThrow<string>(
    'backToClubhouseSettingsPath',
  );

  const club = store.getCurrentClub();
  const currentMembership = store.getCurrentMembership();
  const expiresAt = Helpers.Utils.formatDate(currentMembership.expiresAt, {
    withYear: true,
  });

  return (
    <div id="cancel-renewal">
      <h1>
        {i18n.t('heading', {
          scope: i18nScope,
          values: { clubName: club.name },
        })}
      </h1>

      {wasCancelationCompleted ? (
        <p className="mt-5">
          {i18n.t('cancelation_completed', {
            scope: i18nScope,
            values: {
              expiresAt: expiresAt,
            },
          })}
        </p>
      ) : (
        <>
          <p
            className="mt-5"
            dangerouslySetInnerHTML={{
              __html: i18n.t('description_html', {
                scope: i18nScope,
                values: {
                  expiresAt: expiresAt,
                },
              }),
            }}
          />
          <CancelRenewalForm
            clubId={store.getCurrentClub().id}
            onBackRedirectToPath={backToClubhouseSettingsPath}
            onSuccessCallback={() => setWasCancelationCompleted(true)}
          />
        </>
      )}
    </div>
  );
}
