import _ from 'underscore';
import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Store, { ModelType } from 'helpers/store';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormCheckboxField,
  FormDateField,
  FormPillsField,
  FormUrlField,
  FormErrorMessage,
  FormServerErrorMessages,
  FormAutocompleteLocationField,
  FormSwitchField,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';

import EventHostInfo, {
  MinEventHost,
  mockEventHostFromClub,
  mockEventHostFromMembership,
  picSize,
} from 'components/utils/event_host_info';
import {
  ClubEvent,
  DeprecatedAny,
  EventHost,
  OptionsForSelectField,
  RemoteImageImageType,
  RemoteImageSubjectType,
} from 'types/types';
import h, { assertNotNullOrUndefined } from 'h';
import { Wrapper } from '@googlemaps/react-wrapper';
import { EventLocation } from 'components/utils/event_location_or_virtual';
import { GOOGLE_MAPS_LIBRARIES_TO_LOAD } from 'helpers/google_maps_helper';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import { ClubEventCreateOrUpdateMutation } from '__generated__/graphql';
import classNames from 'classnames';
import Icon, { iconType } from 'components/utils/icon';
import FormContentEditableField from 'components/forms/form_content_editable_field';

const i18nScope = 'components.clubs.club_events.club_event_form';

const CLUB_EVENT_CREATE_OR_UPDATE = gql(`
  mutation ClubEventCreateOrUpdate($input: ClubEventCreateOrUpdateInput!) {
    clubEventCreateOrUpdate(input: $input) {
      clubEvent {
        id
        clubEventUrl
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

type ClubEventFormValues = Pick<
  ClubEvent,
  | 'name'
  | 'description'
  | 'isAllDay'
  | 'timeZone'
  | 'isVirtual'
  | 'virtualMeetingUrl'
  | 'googlePlaceId'
  | 'longitude'
  | 'latitude'
  | 'locationName'
  | 'location'
  | 'areGuestsAllowed'
  | 'shouldCapNumberOfAttendees'
> & {
  startDate: Date;
  startTimeInMinutes: Date;
  endDate: Date;
  endTimeInMinutes: Date;
  eventHost: MinEventHost;
  // TODO: issue#200 we need a better way of handling placeholder values
  // maxNumberOfAttendees ideally is null until the user sets a default
  // but formik doesn't like null so I'm setting a default when initializing
  maxNumberOfAttendees: number;
};

export default function ClubEventFormWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <ClubEventForm {...props} />
    </RootAppWrapper>
  );
}

// HACK: interim hack while we finish migratiing over JsonAPI serialization
// to graphql serialization
function convertJsonApiEventHostToGraphqlEventHost(
  eventHost: EventHost,
): MinEventHost {
  let convertedEventHost: MinEventHost;
  const subjectType = eventHost.subjectType;

  switch (subjectType) {
    case 'Membership':
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      convertedEventHost = mockEventHostFromMembership(eventHost.subject!);
      break;
    case 'Club':
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      convertedEventHost = mockEventHostFromClub(eventHost.subject!);
      break;
    case 'User':
      h.throwError('unsupported User host');
      break;
    default:
      h.throwExhaustiveError('unknown view mode', subjectType);
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return convertedEventHost!;
}

function ClubEventFormSection({
  name,
  canToggleExpandable = false,
  isExpandedInitially = true,
  children,
}: {
  name: string;
  canToggleExpandable?: boolean;
  isExpandedInitially?: boolean;
  children: React.ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState(isExpandedInitially);

  return (
    <div className="section">
      <h5
        className={classNames('section-name', {
          'section-expandable': canToggleExpandable,
          'section-expanded': isExpanded,
        })}
        onClick={() => canToggleExpandable && setIsExpanded(!isExpanded)}
      >
        {canToggleExpandable && (
          <Icon type={iconType.CARET_DOWN} classes="mr-1" />
        )}
        {name}
      </h5>
      {isExpanded && children}
    </div>
  );
}

// Dates with time zones are a tricky thing, here's a quick summary of how we're working around it
// We receive the date string from the server in the format of YYYY-MM-DD
// We need to convert it to a Date so we can use it with react-datepicker
//
// If we call new Date(startDate) it essentially does new Date(Date.parse(startDate))
// The Date.parse return the number of milliseconds since epoch which is Jan 1, 1970 UTC
// It then initializes a date with that integer. So essentially it initializes the date
// in UTC then converts it to the local time zone. This can cause some weirdness where
// the time is 1 day behind or ahead depending on where you are in the world.
// For that reason we call the Helpers.Utils.convertYYYY_MM_DDToDateInLocalTimeZone which
// will parse the date string into a year, month, and day and initialize a date object
// using new Date(year, month, day) which will be in the local timezone.
//
// When responding to the server we then convert the date in the local timezone
// back into a YYYY-MM-DD string so it can be processed directly. Basically in
// the db we'll store the plain date objects and then transform it on the client.
function ClubEventForm(props: DeprecatedAny) {
  const { i18n } = React.useContext(I18nContext);
  const [store] = useState(() => Store.parseInitData(props));

  const [createOrUpdateMutation] = useMutation(CLUB_EVENT_CREATE_OR_UPDATE);

  const [currentMembership] = useState(() => store.getCurrentMembership());
  const [currentClub] = useState(() => store.getCurrentClub());

  const [clubEvent] = useState(() =>
    store.getFirstModelOrThrow<ClubEvent>(ModelType.CLUB_EVENT, [
      'eventHosts.subject',
    ]),
  );

  const [shouldShowTimeZone, setShouldShowTimeZone] = useState(
    () => currentClub.timeZone !== clubEvent.timeZone,
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required(i18n.t('forms.errors.required'))
          .min(2, i18n.t('forms.errors.min_length')),
        description: Yup.string()
          .required(i18n.t('forms.errors.required'))
          .min(20, i18n.t('forms.errors.min_length')),
        startDate: Yup.date()
          .required(i18n.t('forms.errors.required'))
          .typeError(i18n.t('forms.errors.invalid')),
        startTimeInMinutes: Yup.string().when('isAllDay', {
          is: false,
          then: Yup.string()
            .required(i18n.t('forms.errors.required'))
            .typeError(i18n.t('forms.errors.invalid')),
          otherwise: Yup.string().nullable(),
        }),
        endDate: Yup.date()
          .required(i18n.t('forms.errors.required'))
          .typeError(i18n.t('forms.errors.invalid')),
        endTimeInMinutes: Yup.string()
          .nullable()
          .when('isAllDay', {
            is: false,
            then: Yup.string()
              .required(i18n.t('forms.errors.required'))
              .typeError(i18n.t('forms.errors.invalid')),
            otherwise: Yup.string().nullable(),
          }),
        timeZone: Yup.string().when('isAllDay', {
          is: false,
          then: Yup.string().required(i18n.t('forms.errors.required')),
        }),
        location: Yup.string().when('isVirtual', {
          is: false,
          then: Yup.string().required(i18n.t('forms.errors.required')),
        }),
        virtualMeetingUrl: Yup.string().when('isVirtual', {
          is: true,
          then: Yup.string()
            // I had the .url validation but it was too strict
            .required(i18n.t('forms.errors.required'))
            .min(5, i18n.t('forms.errors.min_length')),
        }),
        maxNumberOfAttendees: Yup.number().when('shouldCapNumberOfAttendees', {
          is: true,
          then: Yup.number()
            .required(i18n.t('forms.errors.required'))
            .moreThan(0, ({ more }) =>
              i18n.t('forms.errors.more_than', { values: { number: more } }),
            ),
        }),
      }),
    [],
  );

  const timeZones = store.getOrThrow<OptionsForSelectField>(
    'timeZonesForSelectField',
  );

  const googleMapsApiPublicKey = store.getOrThrow<string>(
    'googleMapsApiPublicKey',
  );

  const defaultLongitude = currentClub.longitude;
  const defaultLatitude = currentClub.latitude;

  if (defaultLongitude === null || defaultLatitude === null) {
    return h.throwError('club has empty longitude and latitude values');
  }

  // 1) for now we're not supporting a way to add multiple hosts. At the db
  // level we support it but we'd need to update the front end and backend to
  // support it. We can probably use accepts_nested_attributes_for. We don't
  // do that for permission group members but I think it makes sense here

  // 2) Maybe we also have an "invite" to be a host for member led events where
  // they want to invite others to host with them, plus it's good that there's
  // an explicity acknowledge step that they're hosting

  // TODO clean up this hacky mess. Admins can only 1) set the host to the club
  // 2) make themselves host, or 3) do nothing and let the current host remain.
  // They can't set anybody to become a host.

  const firstEventHostIfExists = _.first(clubEvent.eventHosts ?? []) ?? null; // can be empty for new events
  const initialEventHost = firstEventHostIfExists
    ? convertJsonApiEventHostToGraphqlEventHost(firstEventHostIfExists)
    : null;

  // if the current host is the club, and they want to switch back to the user
  // option then it has to be the current user. it can't go back to the original
  // creator of the event
  const membershipEventHostOption =
    initialEventHost?.subjectType === 'Membership'
      ? initialEventHost
      : mockEventHostFromMembership(currentMembership);
  const clubEventHostOption =
    initialEventHost?.subjectType === 'Club'
      ? initialEventHost
      : mockEventHostFromClub(currentClub);

  const cancelPath = store.getOrThrow<string>('cancelPath');
  const isEditing = store.getOrThrow<boolean>('isEditing');
  const canManageHosts = store.getOrThrow<boolean>('canManageHosts');

  const _onSubmit = function (
    values: ClubEventFormValues,
    actions: FormikHelpers<ClubEventFormValues>,
  ) {
    sendMutationAndUpdateForm<
      ClubEventFormValues,
      ClubEventCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubEventCreateOrUpdate',
      main: () =>
        createOrUpdateMutation({
          variables: {
            input: {
              clubId: String(clubEvent.clubId),
              id: clubEvent?.id ? String(clubEvent?.id) : null,
              ..._.omit(values, 'eventHost'),
              startDate: Helpers.Utils.convertDateInLocalTimeZoneToYYYY_MM_DD(
                values.startDate,
              ),
              startTimeInMinutes:
                values.startTimeInMinutes === null
                  ? 0
                  : Helpers.Utils.convertTimeInLocalTimeZoneToMinutes(
                      values.startTimeInMinutes,
                    ),
              endDate: Helpers.Utils.convertDateInLocalTimeZoneToYYYY_MM_DD(
                values.endDate,
              ),
              endTimeInMinutes:
                values.endTimeInMinutes === null
                  ? 0
                  : Helpers.Utils.convertTimeInLocalTimeZoneToMinutes(
                      values.endTimeInMinutes,
                    ),
              eventHostSubjectId: String(values.eventHost.subjectId),
              eventHostSubjectType: values.eventHost.subjectType,
            },
          },
        }),
      successCallback: (mutationPayload) => {
        const clubEventUrl =
          mutationPayload?.clubEventCreateOrUpdate?.clubEvent?.clubEventUrl;
        assertNotNullOrUndefined(clubEventUrl);
        window.location.href = clubEventUrl;
      },
    });
  };

  const startDate = Helpers.Utils.convertYYYY_MM_DDToDateInLocalTimeZone(
    clubEvent.startDate,
  );
  const startTimeInMinutes = Helpers.Utils.convertMinutesToTimeInLocalTimeZone(
    clubEvent.startTimeInMinutes,
  );
  const endDate = Helpers.Utils.convertYYYY_MM_DDToDateInLocalTimeZone(
    clubEvent.endDate,
  );
  const endTimeInMinutes = Helpers.Utils.convertMinutesToTimeInLocalTimeZone(
    clubEvent.endTimeInMinutes,
  );
  const initialValues = {
    ..._.pick(
      clubEvent,
      'name',
      'description',
      'timeZone',
      'isAllDay',
      'isVirtual',
      'googlePlaceId',
      'location',
      'locationName',
      'longitude',
      'latitude',
      'virtualMeetingUrl',
      'areGuestsAllowed',
      'shouldCapNumberOfAttendees',
    ),
    startDate,
    startTimeInMinutes,
    endDate,
    endTimeInMinutes,
    eventHost: initialEventHost || membershipEventHostOption,
    maxNumberOfAttendees: clubEvent.maxNumberOfAttendees ?? 0,
  };

  return (
    <div className="club-event-form">
      <h1>
        {i18n.t(isEditing ? 'heading.edit' : 'heading.add', {
          scope: i18nScope,
        })}
      </h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, setFieldValue, isSubmitting, values }) => (
          <Form className="basic-form" noValidate>
            <>
              <FormField
                autoFocus={true}
                type="text"
                name="name"
                label={i18n.t('name.label', { scope: i18nScope })}
              />

              <ClubEventFormSection
                name={i18n.t('sections.when', { scope: i18nScope })}
              >
                <div className="date-time-range">
                  <div className="date-time-range-fields">
                    <FormDateField
                      withoutErrorMessage={true}
                      classes="date"
                      dateFormat="MMM d, yyyy"
                      name="startDate"
                      onChangeCallback={(newStartDate, oldStartDate) => {
                        const oldStartDateInt = oldStartDate.getTime();
                        const endDateInt = values.endDate.getTime();

                        if (oldStartDateInt === endDateInt) {
                          // trying to keep them in sync
                          setFieldValue('endDate', newStartDate);
                        } else if (oldStartDateInt < endDateInt) {
                          // try to maintain the diff if changed
                          const diff = endDateInt - oldStartDateInt;
                          const newEndDate = new Date(
                            newStartDate.getTime() + diff,
                          );
                          setFieldValue('endDate', newEndDate);
                        }
                      }}
                      minDate={new Date()}
                    />
                    {!values.isAllDay && (
                      <FormDateField
                        withoutErrorMessage={true}
                        classes="time"
                        dateFormat="h:mm aa"
                        name="startTimeInMinutes"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption=""
                      />
                    )}
                    <span className="separator">
                      {i18n.t('date_range_separator', { scope: i18nScope })}
                    </span>
                    {!values.isAllDay && (
                      <FormDateField
                        withoutErrorMessage={true}
                        classes="time"
                        dateFormat="h:mm aa"
                        name="endTimeInMinutes"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption=""
                      />
                    )}
                    <FormDateField
                      withoutErrorMessage={true}
                      classes="date"
                      dateFormat="MMM d, yyyy"
                      name="endDate"
                      minDate={new Date()}
                    />
                  </div>
                  <div className="date-time-range-errors">
                    <FormErrorMessage name="startDate" />
                    {<FormErrorMessage name="startTimeInMinutes" />}
                    {<FormErrorMessage name="endTimeInMinutes" />}
                    <FormErrorMessage name="endDate" />
                  </div>
                </div>

                {!values.isAllDay && (
                  <>
                    {shouldShowTimeZone ? (
                      <FormField as="select" name="timeZone">
                        {timeZones.map((timeZone, index) => (
                          <option key={index} value={timeZone.value}>
                            {timeZone.name}
                          </option>
                        ))}
                      </FormField>
                    ) : (
                      <a
                        className="mt-1 inline-block"
                        onClick={() => setShouldShowTimeZone(true)}
                      >
                        {i18n.t('show_time_zone', { scope: i18nScope })}
                      </a>
                    )}
                  </>
                )}

                <FormCheckboxField
                  name="isAllDay"
                  label={i18n.t('is_all_day.label', { scope: i18nScope })}
                />
              </ClubEventFormSection>

              <ClubEventFormSection
                name={i18n.t('sections.where', { scope: i18nScope })}
              >
                <FormPillsField
                  name="isVirtual"
                  pills={[
                    {
                      value: false,
                      element: i18n.t('is_virtual.in_person', {
                        scope: i18nScope,
                      }),
                    },
                    {
                      value: true,
                      element: i18n.t('is_virtual.virtual', {
                        scope: i18nScope,
                      }),
                    },
                  ]}
                />

                {values.isVirtual ? (
                  <FormUrlField
                    name="virtualMeetingUrl"
                    label={i18n.t('virtual_meeting_url.label', {
                      scope: i18nScope,
                    })}
                    placeholder={i18n.t('virtual_meeting_url.placeholder', {
                      scope: i18nScope,
                    })}
                  />
                ) : (
                  <Wrapper
                    apiKey={googleMapsApiPublicKey}
                    libraries={GOOGLE_MAPS_LIBRARIES_TO_LOAD}
                    render={() => <></>}
                  >
                    <FormAutocompleteLocationField
                      googleMapsApiPublicKey={googleMapsApiPublicKey}
                      name="location"
                      label={i18n.t('location.label', { scope: i18nScope })}
                      defaultLongitude={defaultLongitude}
                      defaultLatitude={defaultLatitude}
                      longitude={values.longitude}
                      latitude={values.latitude}
                      onSelectLocationCallback={(
                        place: google.maps.places.PlaceResult | null,
                      ) => {
                        const location = place?.geometry?.location;
                        if (
                          place == null ||
                          location == null ||
                          typeof location === 'undefined'
                        ) {
                          setFieldValue('googlePlaceId', '');
                          setFieldValue('longitude', null);
                          setFieldValue('latitude', null);
                          setFieldValue('locationName', '');
                          setFieldValue('location', '');
                          return;
                        }

                        setFieldValue('googlePlaceId', place.place_id);
                        setFieldValue('longitude', location.lng());
                        setFieldValue('latitude', location.lat());
                        setFieldValue('locationName', place.name);
                        setFieldValue('location', place.formatted_address);
                      }}
                    >
                      <div className="italic">
                        <EventLocation
                          event={values}
                          shouldShowLocationLink={false}
                        />
                      </div>
                    </FormAutocompleteLocationField>
                  </Wrapper>
                )}
              </ClubEventFormSection>

              {canManageHosts && (
                <ClubEventFormSection
                  name={i18n.t('sections.host', { scope: i18nScope })}
                >
                  <FormPillsField<MinEventHost>
                    name="eventHost"
                    isVertical={true}
                    pills={[
                      {
                        value: membershipEventHostOption,
                        element: (
                          <EventHostInfo
                            classes="inline-block"
                            picSize={picSize.SMALL}
                            eventHost={membershipEventHostOption}
                          />
                        ),
                      },
                      {
                        value: clubEventHostOption,
                        element: (
                          <EventHostInfo
                            classes="inline-block"
                            picSize={picSize.SMALL}
                            eventHost={clubEventHostOption}
                          />
                        ),
                      },
                    ]}
                  />
                </ClubEventFormSection>
              )}

              <ClubEventFormSection
                name={i18n.t('sections.what', { scope: i18nScope })}
              >
                <FormContentEditableField
                  classes="mt-3"
                  name="description"
                  value={values.description}
                  shouldShowMenuBar={false}
                  placeholder={i18n.t('description.placeholder', {
                    scope: i18nScope,
                  })}
                  remoteImageUploadConfig={
                    clubEvent?.id
                      ? {
                          imageType: RemoteImageImageType.EVENT_DESCRIPTION,
                          subjectType: RemoteImageSubjectType.EVENT,
                          subjectId: clubEvent.id,
                        }
                      : null
                  }
                />
              </ClubEventFormSection>

              <ClubEventFormSection
                name={i18n.t('sections.who', { scope: i18nScope })}
                canToggleExpandable={true}
                isExpandedInitially={false}
              >
                <FormSwitchField
                  name="areGuestsAllowed"
                  label={i18n.t('are_guests_allowed.label', {
                    scope: i18nScope,
                  })}
                />
                <FormSwitchField
                  name="shouldCapNumberOfAttendees"
                  label={i18n.t('should_cap_number_of_attendees.label', {
                    scope: i18nScope,
                  })}
                />
                {values.shouldCapNumberOfAttendees && (
                  <div style={{ maxWidth: '50%' }}>
                    <FormField
                      type="number"
                      min="0"
                      step="1"
                      name="maxNumberOfAttendees"
                      label={i18n.t('max_number_of_attendees.label', {
                        scope: i18nScope,
                      })}
                    />
                  </div>
                )}
              </ClubEventFormSection>

              <FormActions className="rtl">
                <FormActionButton
                  className="primary"
                  text={i18n.t(isEditing ? 'submit.edit' : 'submit.add', {
                    scope: i18nScope,
                  })}
                  isSubmitting={isSubmitting}
                />
                <FormActionButton
                  className="secondary"
                  text={i18n.t('cancel', { scope: i18nScope })}
                  isDisabled={isSubmitting}
                  handleClick={() => (window.location.href = cancelPath)}
                />
                <FormServerErrorMessages
                  serverErrors={status?.serverErrors?.base}
                />
              </FormActions>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
}
