import { assertNotNullOrUndefined } from 'h';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  GetCurrentUserPreferenceQuery,
  UserPreferenceUpdateMutation,
} from '__generated__/graphql';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import I18nContext from 'contexts/i18n_context';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';

import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
  FormSelectField,
} from 'components/forms/forms';

import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import {
  FormSelectFieldNumberOptionType,
  findOptionFromValue,
} from 'components/forms/form_select_field';

const i18nScope = 'components.users.my_account.preferences';

type UserPreferenceData = NonNullable<
  GetCurrentUserPreferenceQuery['currentUser']
>['userPreference'];

type UserPreferenceFormValues = {
  calendarFirstDay: FormSelectFieldNumberOptionType;
};

const USER_PREFERENCE_UPDATE = gql(`
  mutation UserPreferenceUpdate($input: UserPreferenceUpdateInput!) {
    userPreferenceUpdate(input: $input) {
      userPreference {
        id
        calendarFirstDay
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

const GET_USER_PREFERENCE = gql(`
  query GetCurrentUserPreference {
    currentUser {
      id
      userPreference {
        id
        calendarFirstDay
      }
    }
  }
`);

function UserPreferencesForm({
  userPreference,
  onSuccessCallback,
}: {
  userPreference: UserPreferenceData;
  onSuccessCallback: (updatedUserPreference: UserPreferenceData) => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [updateMutation] = useMutation(USER_PREFERENCE_UPDATE);

  const calendarFirstDayOptions = useMemo(
    () =>
      [0, 1, 2, 3, 4, 5, 6].map((index) => ({
        value: index,
        label: i18n.t(`form.calendar_first_day.values.${index}`, {
          scope: i18nScope,
        }),
      })),
    [],
  );

  const initialValues = {
    calendarFirstDay: findOptionFromValue({
      value: userPreference.calendarFirstDay,
      options: calendarFirstDayOptions,
    }),
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        calendarFirstDay: Yup.object().required(
          i18n.t('forms.errors.required'),
        ),
      }),
    [],
  );

  const onSubmit = function (
    values: UserPreferenceFormValues,
    actions: FormikHelpers<UserPreferenceFormValues>,
  ) {
    sendMutationAndUpdateForm<
      UserPreferenceFormValues,
      UserPreferenceUpdateMutation
    >({
      actions,
      mutationName: 'userPreferenceUpdate',
      resetSpinnerAfterSuccess: true,
      main: () =>
        updateMutation({
          variables: {
            input: {
              id: userPreference.id,
              calendarFirstDay: values.calendarFirstDay.value,
            },
          },
        }),
      successCallback: (mutationPayload) => {
        const updatedUserPreference =
          mutationPayload?.userPreferenceUpdate?.userPreference;
        assertNotNullOrUndefined(updatedUserPreference);
        onSuccessCallback(updatedUserPreference);
      },
    });
  };

  return (
    <div className="user-preference-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormSelectField
              classes="half-field"
              label={i18n.t('form.calendar_first_day.label', {
                scope: i18nScope,
              })}
              name="calendarFirstDay"
              options={calendarFirstDayOptions}
            />
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('form.submit', {
                  scope: i18nScope,
                })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default function UserPreferencesPage() {
  const { i18n } = React.useContext(I18nContext);

  const [userPreference, setUserPreference] =
    useState<UserPreferenceData | null>(null);

  const { loading, error } = useQuery(GET_USER_PREFERENCE, {
    onCompleted: (newData) => {
      assertNotNullOrUndefined(newData.currentUser);
      setUserPreference(newData.currentUser.userPreference);
    },
  });
  if (loading || userPreference === null) return <LoadingPage />;
  if (error) return <ErrorPage />;

  return (
    <div id="user-preferences-page">
      <div className="page-header">
        <div className="title">
          <h1>{i18n.t('title', { scope: i18nScope })}</h1>
        </div>
      </div>
      <div className="elevate-content">
        <UserPreferencesForm
          userPreference={userPreference}
          onSuccessCallback={(updatedUserPreference) => {
            setUserPreference(updatedUserPreference);
          }}
        />
      </div>
    </div>
  );
}
