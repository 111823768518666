import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import ClubProfileForm from 'components/clubs/admin/club_profile_page/club_profile_form';

const i18nScope = 'clubs.admin.club_profile.edit';

const GET_ADMIN_EDIT_CLUB_PROFILE = gql(`
  query GetAdminEditClubProfile($clubId: ID!) {
    club(id: $clubId) {
      id
      clubProfile {
        id
        description
        headerPhotoUrl
      }
    }
  }
`);

export function ClubProfileEditPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(GET_ADMIN_EDIT_CLUB_PROFILE, {
    variables: {
      clubId: String(currentClubId),
      productId: String(id),
    },
  });
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const clubProfile = data?.club?.clubProfile;
  assertNotNullOrUndefined(clubProfile);

  return (
    <div id="clubs-admin-club-profile-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page form-page"
      >
        <ClubProfileForm
          clubId={String(currentClubId)}
          clubProfile={clubProfile}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
