import _ from 'underscore';
import h, { assertNotNullOrUndefined } from 'h';
import React, { useState, useEffect } from 'react';
import { gql } from '__generated__/gql';
import {
  ClubEventCtaEnum,
  GetClubEventDetailViewQuery,
} from '__generated__/graphql';

import classNames from 'classnames';

import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';

import TippyMenu from 'components/utils/tippy_menu';
import Map from 'components/utils/map';
import AuthenticateThenRedirectLink from 'components/utils/authenticate_then_redirect_link';
import EventTime from 'components/utils/event_time';
import EventLocationOrVirtual from 'components/utils/event_location_or_virtual';
import EventHostInfo from 'components/utils/event_host_info';
import {
  MembershipProfilePic,
  MockPlaceholderProfilePic,
  picSize,
} from 'components/utils/profile_pic';
import EventRsvpButton from 'components/clubs/club_events/event_rsvp_button';
import BasicModal from 'components/utils/basic_modal';
import EventAnnouncementForm from 'components/clubs/club_events/event_announcement_form';
import EventCancelationForm from 'components/clubs/club_events/event_cancelation_form';
import { MockPlaceholderDiscussionThreads } from 'components/utils/discussions/discussion_threads';

import {
  getEventState,
  EventStates,
  getEventLatitudeAndLongitudeIfExists,
} from 'models/event';
import { DeprecatedAny } from 'types/types';
import MembershipInfoCard from 'components/utils/memberships/membership_info_card';
import TippyBasic from 'components/utils/tippy_basic';
import { MockPlaceholder, placeholderType } from 'components/utils/placeholder';
import ClubEventDiscussionThread from 'components/clubs/club_events/_support/club_event_discussion_threads';
import {
  updateListStateAfterCreateOrUpdate,
  updateListStateAfterDestroy,
} from 'helpers/utils_helper';
import { ContentEditablePresenter } from 'components/utils/content_editable_presenter';
import ShowMoreOrLess from 'components/utils/show_more_or_less';

const i18nScope = 'components.clubs.club_events.club_event_detail_view';

type CurrentUser = NonNullable<GetClubEventDetailViewQuery['currentUser']>;
type CurrentMembership = CurrentUser['membership'];
type ClubEvent = NonNullable<GetClubEventDetailViewQuery['clubEvent']>;
type ClubEventDiscussion = NonNullable<ClubEvent['discussion']>;
type EventRsvp = NonNullable<ClubEvent['eventRsvps']>[number];

// --START SERVER SIDE GRAPHQL
gql(`
  query GetClubEventDetailView($clubId: ID!, $eventId: ID!) {
    currentUser {
      id
      membership(clubId: $clubId) {
        id
        fullName
        profilePicUrl
      }
    }
    googleMapsApiPublicKey
    clubEvent(id: $eventId) {
      id
      subjectType
      subjectId
      startDate
      startTimeInMinutes
      startsAt
      endDate
      endTimeInMinutes
      endsAt
      isAllDay
      timeZone
      registrationOpensAt

      areGuestsAllowed

      name
      description
      numberOfAttendees
      hasEnded
      hasStarted
      hasRegistrationOpened
      wasCanceled
      isVirtual
      virtualMeetingUrl
      googlePlaceId
      longitude
      latitude
      locationName
      location

      clubEventUrl
      editClubEventUrl

      canUpdate { value }
      canRsvp { value }
      canListRsvps { value }
      canComment { value }
      canListComments { value }

      ctaPresenter {
        cta
      }

      eventHosts {
        id
        name
        picUrl
      }

      eventRsvps {
        id
        eventId
        state
        position
        numberOfGuests
        createdAt
        attendee {
          id
          fullName
          profilePicUrl
          firstJoinedAt
        }
      }

      discussion {
        id
        numberOfComments
      }

      club {
        id
        timeZone
      }
    }
  }
`);
// --END SERVER SIDE GRAPHQL

const MOBILE_CTA_BANNER_Z_INDEX = 999;
const MEMBERSHIP_INFO_TIPPY_Z_INDEX = 998;

// NOTE: be careful if renaming because we use these values for
// get the appropriate translation
enum PastOrUpcoming {
  PAST = 'past',
  UPCOMING = 'upcoming',
}

function HeaderMessage({ eventState }: { eventState: EventStates }) {
  const { i18n } = React.useContext(I18nContext);
  let message, messageClassName;

  if (eventState === EventStates.CANCELED) {
    message = i18n.t('message.was_canceled', { scope: i18nScope });
    messageClassName = 'message-was-canceled';
  } else if (eventState === EventStates.ENDED) {
    message = i18n.t('message.has_ended', { scope: i18nScope });
    messageClassName = 'message-has-ended';
  } else {
    return null;
  }

  return (
    <div
      className={classNames('message section-spacing mb-4', messageClassName)}
    >
      {message}
    </div>
  );
}

function TimeAndLocation({
  googleMapsApiPublicKey,
  clubEvent,
  clubTimeZone,
}: {
  googleMapsApiPublicKey: string;
  clubEvent: ClubEvent;
  clubTimeZone: string;
}) {
  const { latitude, longitude } =
    getEventLatitudeAndLongitudeIfExists(clubEvent);

  return (
    <>
      <EventTime event={clubEvent} currentTimeZone={clubTimeZone} />
      <EventLocationOrVirtual classes="mt-1" event={clubEvent} />
      {!clubEvent.isVirtual &&
        _.isNumber(longitude) &&
        _.isNumber(latitude) && (
          <Map
            classes="mt-1"
            apiKey={googleMapsApiPublicKey}
            longitude={longitude}
            latitude={latitude}
          />
        )}
    </>
  );
}

function RestrictedView({ children }: { children: React.ReactElement }) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="restricted-view">
      <div className="restricted-content">{children}</div>
      <h5 className="restricted-message vertical-center">
        {i18n.t('restricted.message', { scope: i18nScope })}
      </h5>
    </div>
  );
}

function Attendee({
  profilePic,
  attendeeInfo,
  numberOfGuests = 0,
}: {
  profilePic: React.ReactElement;
  attendeeInfo: React.ReactElement;
  numberOfGuests?: number;
}) {
  return (
    <div className="attendee">
      {profilePic}
      <span className="attendee-info">
        {attendeeInfo}
        {numberOfGuests > 0 && (
          <span className="guests small">+{numberOfGuests}</span>
        )}
      </span>
    </div>
  );
}

function AttendeesList({
  canListRsvps,
  clubEvent,
  pastOrUpcoming,
  eventRsvps,
  eventState,
}: {
  canListRsvps: boolean;
  clubEvent: ClubEvent;
  pastOrUpcoming: PastOrUpcoming;
  eventRsvps: EventRsvp[];
  eventState: EventStates;
}) {
  const { i18n } = React.useContext(I18nContext);

  // let's limit the number of placeholder profiles we show
  // so it doesn't take over the screen
  const MAX_NUM_PLACEHOLDER_PROFILES = 5;
  const attendeesI18nScope = `${i18nScope}.sections.attendees`;

  if (eventState === EventStates.CANCELED) {
    // we show nothing if it's canceled
    return null;
  }

  let totalNumberOfAttendees: number | undefined;
  let attendeesElement: React.ReactElement | undefined;
  let totalNumberOnWaitlist: number | undefined;

  if (!canListRsvps) {
    totalNumberOfAttendees = clubEvent.numberOfAttendees;

    const mockAttendeeElements: React.ReactElement[] = [];
    const cappedNumberOfAttendees = Math.min(
      totalNumberOfAttendees,
      MAX_NUM_PLACEHOLDER_PROFILES,
    );

    for (let index = 0; index < cappedNumberOfAttendees; index++) {
      mockAttendeeElements.push(
        <Attendee
          key={index}
          profilePic={
            <MockPlaceholderProfilePic
              classes="inline-block"
              size={picSize.MEDIUM}
            />
          }
          attendeeInfo={
            <MockPlaceholder
              type={placeholderType.BAR}
              widthPercent="40%"
              classes="inline-block"
            />
          }
        ></Attendee>,
      );
    }

    attendeesElement = (
      <RestrictedView>
        <div className="attendees-list mock-attendees-list">
          {mockAttendeeElements}
        </div>
      </RestrictedView>
    );
  } else {
    // we read from rsvps because the current member can rsvp and unrsvp
    // so this will be more accurate that the static value on rawClubEvent
    const confirmedEventRsvps = eventRsvps.filter(
      (er) => er.state === 'confirmed',
    );
    const waitlistedEventRsvps = eventRsvps.filter(
      (er) => er.state === 'waitlisted',
    );

    totalNumberOfAttendees =
      confirmedEventRsvps.length +
      _.reduce(
        confirmedEventRsvps,
        (memo, eventRsvp) => memo + eventRsvp.numberOfGuests,
        0,
      );

    totalNumberOnWaitlist = waitlistedEventRsvps.length;

    attendeesElement = (
      <div className="attendees-list">
        {confirmedEventRsvps.map((eventRsvp, index) => {
          assertNotNullOrUndefined(eventRsvp.attendee);

          return (
            <Attendee
              key={index}
              profilePic={
                <TippyBasic
                  zIndex={MEMBERSHIP_INFO_TIPPY_Z_INDEX}
                  content={
                    <MembershipInfoCard membership={eventRsvp.attendee} />
                  }
                >
                  <span>
                    <MembershipProfilePic
                      classes="inline-block"
                      membership={eventRsvp.attendee}
                      size={picSize.MEDIUM}
                    />
                  </span>
                </TippyBasic>
              }
              attendeeInfo={
                <span>
                  {eventRsvp.attendee.fullName}
                  {eventRsvp.numberOfGuests > 0 && (
                    <span className="guests small">
                      +{eventRsvp.numberOfGuests}
                    </span>
                  )}
                </span>
              }
            ></Attendee>
          );
        })}
      </div>
    );
  }

  if (typeof totalNumberOfAttendees === 'undefined') {
    return h.throwError('totalNumberOfAttendees should have been set');
  }

  return (
    <div className="event-attendees center-content section-spacing">
      <h3 className="mb-2">
        {i18n.t(`${pastOrUpcoming}.title`, {
          scope: attendeesI18nScope,
          count: totalNumberOfAttendees,
        })}
        {(totalNumberOnWaitlist ?? 0) > 0 && (
          <span>, {totalNumberOnWaitlist} on waitlist.</span>
        )}
      </h3>
      {totalNumberOfAttendees > 0 && attendeesElement}
    </div>
  );
}

function shouldShowCtaButtonOrMessage({
  clubEvent,
}: {
  clubEvent: ClubEvent;
}): boolean {
  return (
    clubEvent.ctaPresenter.cta !== ClubEventCtaEnum.EventCanceled &&
    clubEvent.ctaPresenter.cta !== ClubEventCtaEnum.EventEnded
  );
}

function CtaButtonOrMessage({
  isLoggedIn,
  clubEvent,
  currentMembershipEventRsvp,
  loggedOutOrNonMemberCtaRedirectPath,
  onCreateOrUpdateRsvpCallback,
  onUnrsvpCallback,
}: {
  isLoggedIn: boolean;
  clubEvent: ClubEvent;
  currentMembershipEventRsvp: EventRsvp | null;
  loggedOutOrNonMemberCtaRedirectPath: string;
  onCreateOrUpdateRsvpCallback: (eventRsvp: EventRsvp) => void;
  onUnrsvpCallback: (eventRsvp: EventRsvp) => void;
}) {
  if (!shouldShowCtaButtonOrMessage({ clubEvent })) {
    // don't show anything
    return null;
  }

  const { i18n } = React.useContext(I18nContext);

  let elementBody: React.ReactNode;

  // if they do have an rsvp already, then we should let them update/cancel their rsvp
  const cta = !h.isNullOrUndefined(currentMembershipEventRsvp?.id)
    ? ClubEventCtaEnum.Rsvp
    : clubEvent.ctaPresenter.cta;

  switch (cta) {
    case ClubEventCtaEnum.Rsvp:
      elementBody = (
        <EventRsvpButton
          clubEventId={clubEvent.id}
          areGuestsAllowed={clubEvent.areGuestsAllowed}
          currentMembershipEventRsvp={currentMembershipEventRsvp}
          currentNumberOfGuests={
            currentMembershipEventRsvp?.numberOfGuests ?? 0
          }
          onCreateOrUpdateRsvpCallback={onCreateOrUpdateRsvpCallback}
          onCancelCallback={() => {
            if (currentMembershipEventRsvp === null) {
              return h.throwError('current user event rsvp is null');
            }
            onUnrsvpCallback(currentMembershipEventRsvp);
          }}
        />
      );
      break;
    case ClubEventCtaEnum.BecomeAMember:
      elementBody = (
        <AuthenticateThenRedirectLink
          isLoggedIn={isLoggedIn}
          classes="inline"
          redirectPath={loggedOutOrNonMemberCtaRedirectPath}
        >
          <button className="btn btn-primary">
            {i18n.t('join_cta_button', { scope: i18nScope })}
          </button>
        </AuthenticateThenRedirectLink>
      );
      break;
    case ClubEventCtaEnum.RegistrationNotOpen:
      elementBody = (
        <div>
          <div className="cta-info-message">
            {i18n.t('registration_opens_at', {
              scope: i18nScope,
              values: {
                time: Helpers.Utils.formatDateTimeLong(
                  clubEvent.registrationOpensAt,
                ),
              },
            })}
          </div>
        </div>
      );
      break;
    case ClubEventCtaEnum.EventAtCapacity:
      elementBody = (
        <div>
          <div className="cta-info-message">
            {i18n.t('event_at_max_capacity', {
              scope: i18nScope,
              values: {
                time: Helpers.Utils.formatDateTimeLong(
                  clubEvent.registrationOpensAt,
                ),
              },
            })}
          </div>
        </div>
      );
      break;
    case ClubEventCtaEnum.EventCanceled:
    case ClubEventCtaEnum.EventEnded:
      // don't show anything
      return null;
    default:
      h.throwExhaustiveError('unknown cta', cta);
  }

  return <span className="cta-button-or-message">{elementBody}</span>;
}

function DiscussionSection({
  canListComments,
  clubEventId,
  canComment,
  currentMembership,
  discussion,
}: {
  canListComments: boolean;
  clubEventId: ClubEvent['id'];
  canComment: boolean;
  currentMembership: CurrentMembership | null;
  discussion: ClubEventDiscussion;
}) {
  let discussionElement;

  if (!canListComments) {
    discussionElement = (
      <RestrictedView>
        <MockPlaceholderDiscussionThreads discussion={discussion} />
      </RestrictedView>
    );
  } else {
    assertNotNullOrUndefined(currentMembership);
    discussionElement = (
      <ClubEventDiscussionThread
        clubEventId={clubEventId}
        discussion={discussion}
        currentMembership={currentMembership}
        canComment={canComment}
      />
    );
  }

  return (
    <div className="discussion-section center-content section-spacing">
      <h3 className="mb-4">Comments</h3>
      {discussionElement}
    </div>
  );
}

export default function ClubEventDetailViewWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <ClubEventDetailView {...props} />
    </RootAppWrapper>
  );
}

function ClubEventDetailView({
  graphqlResult,
  backPath,
  loggedOutOrNonMemberCtaRedirectPath,
}: {
  graphqlResult: { data: GetClubEventDetailViewQuery };
  backPath: string;
  loggedOutOrNonMemberCtaRedirectPath: string;
}) {
  assertNotNullOrUndefined(graphqlResult);
  assertNotNullOrUndefined(backPath);
  assertNotNullOrUndefined(loggedOutOrNonMemberCtaRedirectPath);

  const { i18n } = React.useContext(I18nContext);

  const graphqlData = graphqlResult.data;

  const clubEvent = graphqlData.clubEvent;
  assertNotNullOrUndefined(clubEvent);
  const club = clubEvent.club;
  assertNotNullOrUndefined(club);
  const discussion = clubEvent.discussion;
  assertNotNullOrUndefined(discussion);
  const currentUser = graphqlData.currentUser;
  const eventHosts = clubEvent.eventHosts;

  const currentMembership = graphqlData.currentUser?.membership;

  const googleMapsApiPublicKey = graphqlData.googleMapsApiPublicKey;

  const [eventRsvps, setEventRsvps] = useState(() =>
    _.sortBy(clubEvent.eventRsvps ?? [], 'createdAt'),
  );

  const [
    shouldShowEventAnnouncementModal,
    setShouldShowEventAnnouncementModal,
  ] = useState(false);

  const [shouldShowEventCancelationModal, setShouldShowEventCancelationModal] =
    useState(false);

  // handles window resizes - useful to ensure we don't
  // render 2 google maps instances one for mobile and one
  // for desktop. Since each one counts against our quota
  const isTabletOrSmallerFn = () => Helpers.ScreenSize.getIsTabletOrSmaller();
  const [isTabletOrSmaller, setIsTabletOrSmaller] =
    useState(isTabletOrSmallerFn);
  useEffect(
    Helpers.ScreenSize.useEffect(() =>
      setIsTabletOrSmaller(isTabletOrSmallerFn()),
    ),
  );

  const currentMembershipEventRsvp = currentMembership
    ? _.find(eventRsvps, (er) => er.attendee?.id === currentMembership.id)
    : null;

  const pastOrUpcoming = clubEvent.hasEnded
    ? PastOrUpcoming.PAST
    : PastOrUpcoming.UPCOMING;

  const eventState = getEventState(clubEvent);

  const canUpdate = clubEvent.canUpdate.value;

  const timeAndLocationElement = (
    <TimeAndLocation
      googleMapsApiPublicKey={googleMapsApiPublicKey}
      clubEvent={clubEvent}
      clubTimeZone={club.timeZone ?? clubEvent.timeZone}
    />
  );

  const ctaButtonOrMessageElement = shouldShowCtaButtonOrMessage({
    clubEvent,
  }) && (
    <CtaButtonOrMessage
      isLoggedIn={!h.isNullOrUndefined(currentUser)}
      clubEvent={clubEvent}
      currentMembershipEventRsvp={currentMembershipEventRsvp ?? null}
      onCreateOrUpdateRsvpCallback={(eventRsvp) =>
        updateListStateAfterCreateOrUpdate({
          setListFn: setEventRsvps,
          newItem: eventRsvp,
        })
      }
      onUnrsvpCallback={(eventRsvp) =>
        updateListStateAfterDestroy({
          setListFn: setEventRsvps,
          deletedItem: eventRsvp,
        })
      }
      loggedOutOrNonMemberCtaRedirectPath={loggedOutOrNonMemberCtaRedirectPath}
    />
  );

  return (
    <div
      className={classNames('event-detail-view', `event-state-${eventState}`)}
    >
      <HeaderMessage eventState={eventState} />
      <div className="event-wrapper">
        <div className="event-main">
          <div className="actions center-content section-spacing">
            <div className="left-actions">
              <a href={backPath} className="small">
                {i18n.t('actions.back', { scope: i18nScope })}
              </a>
            </div>
            <div className="right-actions">
              {canUpdate && eventState === EventStates.UPCOMING && (
                <TippyMenu
                  content={
                    <>
                      <div className="tippy-menu-item">
                        <a href={clubEvent.editClubEventUrl}>
                          {i18n.t('actions.edit', { scope: i18nScope })}
                        </a>
                      </div>
                      <div className="tippy-menu-item">
                        <a
                          onClick={() =>
                            setShouldShowEventAnnouncementModal(true)
                          }
                        >
                          {i18n.t('actions.email_attendees', {
                            scope: i18nScope,
                          })}
                        </a>
                      </div>
                      <div className="tippy-menu-item">
                        <a
                          onClick={() =>
                            setShouldShowEventCancelationModal(true)
                          }
                        >
                          {i18n.t('actions.cancel', { scope: i18nScope })}
                        </a>
                      </div>
                    </>
                  }
                />
              )}
            </div>
          </div>

          <div className="header center-content">
            <h1 className="event-name">{clubEvent.name}</h1>
            <div>
              <span className="hosted-by">
                {i18n.t('header.hosted_by', { scope: i18nScope })}
              </span>
              {eventHosts.map((eventHost, index) => (
                <EventHostInfo
                  classes="elevate-content"
                  key={index}
                  eventHost={eventHost}
                  picSize={picSize.SMALL}
                />
              ))}
            </div>
          </div>
          {isTabletOrSmaller && (
            <>
              <div className="mobile-when-and-where section-spacing py-3">
                <div className="center-content">{timeAndLocationElement}</div>
              </div>
              {ctaButtonOrMessageElement && (
                <div
                  className="mobile-cta-banner"
                  style={{ zIndex: MOBILE_CTA_BANNER_Z_INDEX }}
                >
                  <div className="center-content">
                    {ctaButtonOrMessageElement}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="event-description center-content section-spacing">
            {clubEvent.description && (
              <ShowMoreOrLess>
                <ContentEditablePresenter content={clubEvent.description} />
              </ShowMoreOrLess>
            )}
          </div>
          <AttendeesList
            canListRsvps={clubEvent.canListRsvps.value}
            clubEvent={clubEvent}
            pastOrUpcoming={pastOrUpcoming}
            eventRsvps={eventRsvps}
            eventState={eventState}
          />

          <DiscussionSection
            canListComments={clubEvent.canListComments.value}
            clubEventId={clubEvent.id}
            currentMembership={currentMembership}
            canComment={clubEvent.canComment.value}
            discussion={discussion}
          />
        </div>
        <div className="event-sidebar">
          {!isTabletOrSmaller && (
            <div className="event-widget section-spacing elevate-content">
              {ctaButtonOrMessageElement && (
                <div className="mb-3">{ctaButtonOrMessageElement}</div>
              )}
              {timeAndLocationElement}
            </div>
          )}
        </div>
      </div>
      {shouldShowEventAnnouncementModal && (
        <BasicModal
          className="basic-modal-content event-announcement-form-modal"
          onRequestCloseCallback={() =>
            setShouldShowEventAnnouncementModal(false)
          }
          isOpen={true}
        >
          <EventAnnouncementForm
            clubEvent={clubEvent}
            onSuccessCallback={() => {
              // just close the modal, nothing else to do
              setShouldShowEventAnnouncementModal(false);
            }}
            onCancelCallback={() => {
              setShouldShowEventAnnouncementModal(false);
            }}
          />
        </BasicModal>
      )}
      {shouldShowEventCancelationModal && (
        <BasicModal
          className="basic-modal-content event-cancelation-form-modal"
          onRequestCloseCallback={() =>
            setShouldShowEventCancelationModal(false)
          }
          isOpen={true}
        >
          <EventCancelationForm
            clubEvent={clubEvent}
            onSuccessCallback={() => {
              // refresh the page, so the user can see the cancelation messaging
              window.location.reload();
            }}
            onCancelCallback={() => {
              setShouldShowEventCancelationModal(false);
            }}
          />
        </BasicModal>
      )}
    </div>
  );
}
