import { arrayFilterNulls } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import I18nContext from 'contexts/i18n_context';
import AdminDashboard, {
  AdminDashboardAddButton,
  AdminDashboardLoadingPlaceholder,
} from 'components/utils/admin/admin_dashboard';
import AdminTable from 'components/utils/admin/admin_table';
import { ErrorPage } from 'components/utils/pages_sidebar';
import { useNavigate } from 'react-router-dom';
import CompanyLogo, { logoSize } from 'components/utils/company_logo';
import LoadMore from 'components/utils/load_more';

const i18nScope = 'components.super_admin.dashboard.companies.index';
const tableScope = `${i18nScope}.table`;

const GET_SUPER_ADMIN_COMPANIES = gql(`
  query GetSuperAdminCompanies($cursor: String) {
    companies(after: $cursor) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          logoUrl
          name
          website
        }
      }
    }
  }
`);

export default function SuperAdminCompaniesIndexPage() {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();

  const { loading, error, data, fetchMore } = useQuery(
    GET_SUPER_ADMIN_COMPANIES,
  );

  if (loading)
    return <AdminDashboardLoadingPlaceholder columnWidths={[45, 45, 10]} />;
  if (error) return <ErrorPage />;

  const companiesConnection = data?.companies;
  const pageInfo = companiesConnection?.pageInfo;
  const companies = arrayFilterNulls(
    (companiesConnection?.edges ?? []).map((e) => e?.node),
  );

  return (
    <div id="super-admin-dashboard-companies-page">
      <AdminDashboard
        title={i18n.t('title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page"
        actions={
          <AdminDashboardAddButton
            buttonText={i18n.t('add_company_button', { scope: i18nScope })}
            onClickCallback={() => navigate('new')}
          />
        }
      >
        <AdminTable
          items={companies}
          emptyTableElement={
            <p>{i18n.t('empty_table', { scope: i18nScope })}</p>
          }
          tableHeaderRow={
            <tr>
              <th>{i18n.t('heading.name', { scope: tableScope })}</th>
              <th>{i18n.t('heading.website', { scope: tableScope })}</th>
              <th>{i18n.t('heading.actions', { scope: tableScope })}</th>
            </tr>
          }
          renderItemColumns={(company) => (
            <>
              <td>
                <CompanyLogo
                  classes="inline-block"
                  company={company}
                  size={logoSize.SMALL}
                />
                <span>{company.name}</span>
              </td>
              <td>
                {company.website && (
                  <a href={company.website} target="_blank">
                    {company.website}
                  </a>
                )}
              </td>
            </>
          )}
          onClickEditCallback={(company) => navigate(`${company.id}/edit`)}
        />
        <LoadMore
          canLoadMore={pageInfo?.hasNextPage}
          onClick={() =>
            fetchMore({
              variables: {
                cursor: pageInfo?.endCursor,
              },
            })
          }
        />
      </AdminDashboard>
    </div>
  );
}
