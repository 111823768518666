import React from 'react';
import classNames from 'classnames';
import { Company } from 'types/types';

export enum logoSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large',
}

interface CompanyLogoProps {
  company: Pick<Company, 'logoUrl'>;
  size: logoSize;
  classes?: string;
}

export default function CompanyLogo({
  classes,
  size = logoSize.LARGE,
  company,
}: CompanyLogoProps) {
  return (
    <div className={classNames('company-logo', classes)}>
      <div className="company-logo-wrapper">
        <img
          className={classNames('company-logo-img', `company-logo-${size}`)}
          src={company.logoUrl}
          alt="company logo"
        />
      </div>
    </div>
  );
}
