import React from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  AdminClubMembershipTagCreateOrUpdateMutation,
  Club,
  ClubMembershipTag,
} from '__generated__/graphql';
import { Formik, Form, FormikHelpers } from 'formik';

import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
  FormField,
} from 'components/forms/forms';
import FormInlineClubMembershipsSelectField, {
  convertMembershipToMembershipOption,
  ClubMembershipOptionType,
  ClubMembershipForSelectField,
} from 'components/forms/form_inline_club_memberships_select_field';

import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'clubs.admin.memberships.tags.form';

type MembershipTagFormValues = Pick<ClubMembershipTag, 'name'> & {
  membershipOptions: ClubMembershipOptionType[];
};

type MembershipTagForForm = Pick<ClubMembershipTag, 'name'> & {
  memberships: ClubMembershipForSelectField[];
};

const MEMBERSHIP_OPTIONS_FIELD_NAME = 'membershipOptions';

const ADMIN_CLUB_MEMBERSHIP_TAG_CREATE_OR_UPDATE = gql(`
  mutation AdminClubMembershipTagCreateOrUpdate($input: ClubMembershipTagCreateOrUpdateInput!) {
    clubMembershipTagCreateOrUpdate(input: $input) {
      membershipTag {
        id
        name
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export function buildNewMembershipTag(): MembershipTagForForm {
  return {
    name: '',
    memberships: [],
  };
}

export default function MembershipTagForm({
  isEditing,
  clubId,
  membershipTag,
  onSuccessCallback,
  onCancelCallback,
}: {
  isEditing: boolean;
  clubId: Club['id'];
  membershipTag: MembershipTagForForm & {
    id?: ClubMembershipTag['id'];
  };
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [updateMutation] = useMutation(
    ADMIN_CLUB_MEMBERSHIP_TAG_CREATE_OR_UPDATE,
  );

  const _onSubmit = function (
    values: MembershipTagFormValues,
    actions: FormikHelpers<MembershipTagFormValues>,
  ) {
    sendMutationAndUpdateForm<
      MembershipTagFormValues,
      AdminClubMembershipTagCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubMembershipTagCreateOrUpdate',
      main: () =>
        updateMutation({
          variables: {
            input: {
              clubId,
              id: membershipTag.id,
              name: values.name,
              membershipIds: values[MEMBERSHIP_OPTIONS_FIELD_NAME].map(
                (membershipOption) => membershipOption.value,
              ),
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  const initialValues = {
    name: membershipTag.name,
    [MEMBERSHIP_OPTIONS_FIELD_NAME]: membershipTag.memberships.map(
      convertMembershipToMembershipOption,
    ),
  };

  return (
    <div className="membership-group-form">
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {({ status, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormField
              autoFocus={true}
              type="text"
              name="name"
              label={i18n.t('name.label', { scope: i18nScope })}
            />
            <div className="mt-5">
              <FormInlineClubMembershipsSelectField
                clubId={clubId}
                name={MEMBERSHIP_OPTIONS_FIELD_NAME}
              />
            </div>
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
