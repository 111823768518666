import h from 'h';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// Call `loadStripe` with the same connected account ID used when creating
// the PaymentIntent.
//const stripePromise = loadStripe(window.stripePublishableKey, {stripeAccount: window.connectedStripeAccountId});
const stripePublishableKey = window.stripePublishableKey;
if (
  stripePublishableKey === null ||
  typeof stripePublishableKey === 'undefined'
) {
  h.throwError('missing stripe api key');
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const stripePromise = loadStripe(stripePublishableKey!);

export default function StripeWrapper({
  children,
}: {
  children: React.ReactElement;
}) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}
