import React from 'react';
import { StripePaymentMethod } from '__generated__/graphql';

export default function PaymentMethodSummary({
  stripePaymentMethod,
}: {
  stripePaymentMethod: Pick<
    StripePaymentMethod,
    'expMonth' | 'expYear' | 'brand' | 'brandLogoUrl' | 'last4'
  >;
}) {
  // TODO we assume the paymentMethodType is a 'card'. We'll need to update
  // this when we support different payment methods but that'll be a while out.
  return (
    <span className="payment-method-summary">
      <span
        title={`expires on ${stripePaymentMethod.expMonth}/${stripePaymentMethod.expYear}`}
      >
        <img className="brand-logo" src={stripePaymentMethod.brandLogoUrl} />
        <span className="bold capitalize">
          {stripePaymentMethod.brand}
        </span>{' '}
        ending in {stripePaymentMethod.last4}
      </span>
    </span>
  );
}
