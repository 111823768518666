import _ from 'underscore';
import React, { useRef, useCallback } from 'react';
import { UserPreference } from '__generated__/graphql';
import classNames from 'classnames';
import Helpers from 'helpers/helpers';

import TippyMenu from 'components/utils/tippy_menu';
import FullCalendar, {
  EventContentArg,
  EventInput,
  EventSourceFunc,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import EventPreview from 'components/utils/events/event_preview';
import { DateTimeZone, RawClubEvent } from 'types/types';
import I18nContext from 'contexts/i18n_context';
import { AxiosResponse } from 'axios';

// const i18nScope = 'components.utils.events.calendar_view';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const EXPECTED_DATE_FORMAT = 'YYYY-MM-DD';

// type CalendarCustomEventData = CustomContentGenerator<EventContentArg>;

interface FetchEventsAPIResponse {
  clubEvents: { data: RawClubEvent }[];
}

function convertToCalendarEventData(rawClubEvent: RawClubEvent): EventInput {
  const {
    name,
    isAllDay,
    startDate,
    startsAt,
    endDate,
    endsAt,
    wasCanceled,
    hasEnded,
  } = rawClubEvent.attributes;

  const url = rawClubEvent.links.clubEventPath;

  // when the event is marked as all day then end date is
  // EXCLUSIVE not inclusive. Meaning if we had a range
  // 2022-01-14 to 2022-01-16 then on the calendar the
  // event would only have a bar on the 14th and 15th.
  // So we need to add 1 day to the end date in those cases
  // to be inclusive. There probably is a better way but
  // rolling with this for now.
  let endTime = endsAt;
  if (isAllDay && startDate !== endDate) {
    const date = Helpers.Utils.convertYYYY_MM_DDToDateInLocalTimeZone(endDate);
    endTime = new Date(date.getTime() + ONE_DAY_MS).toISOString();
  }

  return {
    title: name,
    allDay: isAllDay,
    start: startsAt,
    end: endTime,
    url,
    classNames: classNames({
      canceled: wasCanceled,
      ended: hasEnded,
    }),
    extendedProps: {
      rawData: rawClubEvent,
    },
  };
}

export default function EventsCalendarView({
  currentTimeZone,
  apiGetUrl,
  openLinksInNewTab = false,
  calendarFirstDay = 0,
}: {
  currentTimeZone: DateTimeZone;
  apiGetUrl: string;
  openLinksInNewTab?: boolean;
  calendarFirstDay?: UserPreference['calendarFirstDay'];
}) {
  const { i18n } = React.useContext(I18nContext);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggleLoadingOverlayCallback = useCallback(
    (isLoading: boolean): void => {
      if (!wrapperRef.current) {
        return;
      }
      if (isLoading) {
        wrapperRef.current.classList.add('loading');
      } else {
        wrapperRef.current.classList.remove('loading');
      }
    },
    [wrapperRef],
  );

  const fetchEventsCallback: EventSourceFunc = (
    fetchInfo,
    successCallback,
    failureCallback,
  ) => {
    const { startStr, endStr } = fetchInfo;
    const url = apiGetUrl;

    const params = {
      type: 'between',
      // TODO: figure out if this makes sense. The str format
      // is '2021-12-26T00:00:00-08:00', so we're stripping
      // out the rest and just getting the YYYY-MM-DD portion.
      // I'm unsure how that would get handled on the backed
      startDate: startStr.substring(0, EXPECTED_DATE_FORMAT.length),
      endDate: endStr.substring(0, EXPECTED_DATE_FORMAT.length),
    };

    Helpers.API.get({ url, params })
      .then((response) => {
        const responseData = (response as AxiosResponse<FetchEventsAPIResponse>)
          .data;
        const newRawClubEvents = _.pluck(responseData.clubEvents, 'data');
        const newCalendarEvents = newRawClubEvents.map(
          convertToCalendarEventData,
        );
        successCallback(newCalendarEvents);
      })
      .catch((error) => {
        const message = i18n.t('forms.errors.unknown_error');
        alert(message);
        failureCallback({ message, error });
      });
  };

  const renderEventCallback = (
    eventContentArg: EventContentArg,
  ): React.ReactElement => {
    const rawClubEvent = eventContentArg.event.extendedProps.rawData;

    const timeText = eventContentArg.timeText
      .replace('am', '')
      .replace('pm', 'p');

    return (
      <TippyMenu
        trigger="mouseenter focus"
        appendTo={document.body}
        placement="bottom-start"
        delay={[250, 0]}
        duration={0}
        content={
          <EventPreview
            currentTimeZone={currentTimeZone}
            rawClubEvent={rawClubEvent}
            openInNewTab={openLinksInNewTab}
          />
        }
      >
        <div>
          <span style={{ paddingRight: '4px' }}>{timeText}</span>
          <span>{eventContentArg.event.title}</span>
        </div>
      </TippyMenu>
    );
  };

  return (
    <div className="events-calendar-view loading" ref={wrapperRef}>
      <div className="loading-overlay"></div>
      <FullCalendar
        plugins={[dayGridPlugin]}
        timeZone={currentTimeZone}
        initialView="dayGridMonth"
        events={fetchEventsCallback}
        loading={toggleLoadingOverlayCallback}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: true,
          meridiem: 'short',
        }}
        firstDay={calendarFirstDay}
        eventContent={renderEventCallback}
        eventClick={(info) => {
          if (openLinksInNewTab) {
            info.jsEvent.preventDefault(); // don't let the browser navigate
            Helpers.Utils.openLinkInNewTab(info.event.url);
          }
        }}
      />
    </div>
  );
}
