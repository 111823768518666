import _ from 'underscore';
import React from 'react';

import TabbedView, { Tab } from 'components/utils/tabbed_view';
import EventsListView from 'components/utils/events/events_list_view';
import { DateTimeZone, RawClubEvent } from 'types/types';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'components.utils.events.tabbed_list_view';

interface EventsTabbedListViewProps {
  currentTimeZone: DateTimeZone;
  rawClubEvents: RawClubEvent[];
  openLinksInNewTab: boolean;
}

export default function EventsTabbedListView({
  currentTimeZone,
  rawClubEvents,
  openLinksInNewTab,
}: EventsTabbedListViewProps) {
  const { i18n } = React.useContext(I18nContext);

  // sort by start time, and if they start at the same time then
  // sort by number of folks who attended
  const sortedRawClubEvents = rawClubEvents.sort((a, b) => {
    const aStartsAt = new Date(a.attributes.startsAt).getTime();
    const bStartsAt = new Date(b.attributes.startsAt).getTime();
    return (
      aStartsAt - bStartsAt ||
      -1 * (a.attributes.numberOfAttendees - b.attributes.numberOfAttendees)
    );
  });

  const [unsortedPastRawClubEvents, upcomingRawClubEvents] = _.partition(
    sortedRawClubEvents,
    (rawClubEvent) => rawClubEvent.attributes.hasEnded,
  );
  const pastRawClubEvents = unsortedPastRawClubEvents.reverse(); // we want to show most recent first

  const upcomingText = i18n.t('tabs.upcoming', { scope: i18nScope });
  const pastText = i18n.t('tabs.past', { scope: i18nScope });

  return (
    <div className="events-tabbed-list-view">
      <TabbedView tabNames={[upcomingText, pastText]}>
        {(tabProps) => (
          <>
            <Tab name={upcomingText} {...tabProps}>
              <EventsListView
                openLinksInNewTab={openLinksInNewTab}
                currentTimeZone={currentTimeZone}
                rawClubEvents={upcomingRawClubEvents}
              />
            </Tab>
            <Tab name={pastText} {...tabProps}>
              <EventsListView
                openLinksInNewTab={openLinksInNewTab}
                currentTimeZone={currentTimeZone}
                rawClubEvents={pastRawClubEvents}
              />
            </Tab>
          </>
        )}
      </TabbedView>
    </div>
  );
}
