import React from 'react';
import Helpers from 'helpers/helpers';
import BaseAuthenticateButton from 'components/utils/auth/base_authenticate_button';
import classNames from 'classnames';
import StaticImagesHelper from 'helpers/static_images_helper';

interface GoogleAuthenticateButtonProps {
  buttonText: string;
  redirectPath: string;
  children?: React.ReactNode;
  classes?: string;
}

export default function GoogleAuthenticateButton({
  children,
  classes,
  buttonText,
  redirectPath,
}: GoogleAuthenticateButtonProps) {
  const url = Helpers.Routes.getAuthWithGoogle();

  return (
    <BaseAuthenticateButton
      classes={classNames('google-authenticate-button', classes)}
      redirectPath={redirectPath}
      authUrl={url}
    >
      {children}
      <button type="submit">
        <img className="auth-icon" src={StaticImagesHelper.googleIconUrl()} />
        <span className="auth-text">{buttonText}</span>
      </button>
    </BaseAuthenticateButton>
  );
}
