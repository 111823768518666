import _ from 'underscore';
import React, { useState } from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import I18nContext from 'contexts/i18n_context';
import Store, { ModelType } from 'helpers/store';

import Location from 'components/utils/location';
import Icon, { iconType } from 'components/utils/icon';
import { ClubProfileHeaderPhoto } from 'components/utils/image';
import { ClubProfilePic, picSize } from 'components/utils/profile_pic';
import { Club, DeprecatedAny, RawClub, WithRequired } from 'types/types';

const i18nScope = 'components.clubs.explore';

type ClubWithClubProfile = WithRequired<Club, 'clubProfile'>;

function ClubInfo({ rawClub }: { rawClub: RawClub }) {
  const { i18n } = React.useContext(I18nContext);
  const profilePath = rawClub.links.clubProfilePath;

  return (
    <div className="club">
      <div className="club-inner vertical-center">
        <div className="club-logo">
          <a href={profilePath}>
            <ClubProfilePic
              club={rawClub.attributes}
              size={picSize.LARGE}
              alt={i18n.t('club.logo_img.alt', {
                scope: i18nScope,
                values: {
                  clubName: rawClub.attributes.name,
                },
              })}
            />
          </a>
        </div>
        <div className="club-info">
          <a href={profilePath}>
            <div className="club-name">
              <h4 className="bold inline">{rawClub.attributes.name}</h4>
              {rawClub.attributes.isClaimed && (
                <Icon type={iconType.VERIFIED} classes="ml-1" />
              )}
            </div>
          </a>
          <Location
            text={`${rawClub.attributes.city}, ${rawClub.attributes.state}`}
          />
        </div>
      </div>
    </div>
  );
}

function ClubCard({ store, rawClub }: { store: Store; rawClub: RawClub }) {
  const { i18n } = React.useContext(I18nContext);
  const profilePath = rawClub.links.clubProfilePath;
  const club = store.getModelOrThrow<ClubWithClubProfile>(
    ModelType.CLUB,
    rawClub.id,
    ['clubProfile'],
  );

  return (
    <div className="club-card">
      <div className="club-profile-header-photo-wrapper">
        <a href={profilePath}>
          <ClubProfileHeaderPhoto
            clubProfile={club.clubProfile}
            alt={i18n.t('club.header_photo_img.alt', {
              scope: i18nScope,
              values: {
                clubName: rawClub.attributes.name,
              },
            })}
          />
        </a>
      </div>
      <div className="club-card-content">
        <ClubInfo rawClub={rawClub} />
      </div>
    </div>
  );
}

function ClaimedClubs({
  store,
  shouldShowSectionTitle,
  rawClubs,
}: {
  store: Store;
  rawClubs: RawClub[];
  shouldShowSectionTitle: boolean;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="claimed-clubs">
      {shouldShowSectionTitle && (
        <h3 className="mb-4">
          {i18n.t('featured_clubs', { scope: i18nScope })}
        </h3>
      )}
      <div className="club-list">
        {rawClubs.map((rawClub, index) => (
          <div className="club-list-item" key={index}>
            <ClubCard store={store} rawClub={rawClub} />
          </div>
        ))}
      </div>
    </div>
  );
}

function UnclaimedClubs({
  shouldShowSectionTitle,
  rawClubs,
}: {
  rawClubs: RawClub[];
  shouldShowSectionTitle: boolean;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="unclaimed-clubs mt-6">
      {shouldShowSectionTitle && (
        <h3 className="mb-4">{i18n.t('other_clubs', { scope: i18nScope })}</h3>
      )}
      <div className="club-list">
        {rawClubs.map((rawClub, index) => (
          <div className="club-list-item" key={index}>
            <ClubInfo rawClub={rawClub} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default function ExploreWrapper(props: DeprecatedAny) {
  return (
    <RootAppWrapper>
      <Explore {...props} />
    </RootAppWrapper>
  );
}

function Explore(props: DeprecatedAny) {
  const { i18n } = React.useContext(I18nContext);
  const [store] = useState(() => Store.parseInitData(props));

  const [rawClubs] = useState(() =>
    store.getAllRawModels<RawClub>(ModelType.CLUB),
  );

  const [claimedRawClubs, unclaimedRawClubs] = _.partition(
    rawClubs,
    (rawClub) => rawClub.attributes.isClaimed,
  );

  const shouldShowSectionTitle =
    claimedRawClubs.length > 0 && unclaimedRawClubs.length > 0;

  return (
    <div className="clubs-explore">
      <h2 className="center mb-4">{i18n.t('title', { scope: i18nScope })}</h2>
      <ClaimedClubs
        store={store}
        shouldShowSectionTitle={shouldShowSectionTitle}
        rawClubs={claimedRawClubs}
      />
      {unclaimedRawClubs.length > 0 && (
        <UnclaimedClubs
          shouldShowSectionTitle={shouldShowSectionTitle}
          rawClubs={unclaimedRawClubs}
        />
      )}
    </div>
  );
}
