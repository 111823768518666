import React from 'react';
import I18nContext from 'contexts/i18n_context';
import { CartItem } from 'components/clubs/storefronts/cart';

const i18nScope = 'components.clubs.storefronts.checkout_button';

export default function CheckoutButton({
  cartItems,
  goToCheckout,
}: {
  cartItems: CartItem[];
  goToCheckout: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <button
      className="btn btn-primary"
      onClick={goToCheckout}
      disabled={cartItems.length === 0}
    >
      <span>{i18n.t('checkout', { scope: i18nScope })}</span>
    </button>
  );
}
