import React from 'react';
import { Field } from 'formik';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';

type FormCurrencyFieldProps = Omit<FormFieldwrapperProps, 'children'> & {
  prefix: string;
};

export default function FormCurrencyField({
  prefix,
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
  ...otherProps // eg. autoFocus, placeholder
}: FormCurrencyFieldProps) {
  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      label={label}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-field form-currency-field">
        <div className="form-currency-field-prefix">{prefix}</div>
        <Field type="number" name={name} min="0" step="0.01" {...otherProps} />
      </div>
    </FormFieldWrapper>
  );
}
