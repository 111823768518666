import APIHelper from 'helpers/api_helper';
import ColorHelper from 'helpers/color_helper';
import CookiesHelper from 'helpers/cookies_helper';
import FormHelper from 'helpers/form_helper';
import UtilsHelper from 'helpers/utils_helper';
import RoutesHelper from 'helpers/routes_helper';
import ScreenSizeHelper from 'helpers/screen_size_helper';
import I18n from 'helpers/i18n';

export default {
  API: APIHelper,
  Color: ColorHelper,
  Cookies: CookiesHelper,
  Form: FormHelper,
  Utils: UtilsHelper,
  Routes: RoutesHelper,
  ScreenSize: ScreenSizeHelper,
  I18n: I18n,
};
