import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import _str from 'underscore.string';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetClubSponsorshipsQuery } from '__generated__/graphql';
import classNames from 'classnames';

import I18nContext from 'contexts/i18n_context';
import Linkifier from 'components/utils/linkifier';
import CompanyLogo, { logoSize } from 'components/utils/company_logo';
import { ClubId } from 'types/types';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';

const i18nScope = 'clubs.clubhouse.deals';

type ClubSponsorships = NonNullable<
  GetClubSponsorshipsQuery['club']
>['sponsorships'];
type ClubSponsorship = ClubSponsorships[number];

const GET_CLUB_SPONSORSHIPS = gql(`
  query GetClubSponsorships($clubId: ID!) {
    club(id: $clubId) {
      id
      sponsorships {
        id
        deals {
          description
          redeemInstructions
        }
        position
        company {
          id
          name
          website
          logoUrl
        }
      }
    }
  }
`);

function SponsorshipInfo({ sponsorship }: { sponsorship: ClubSponsorship }) {
  const { company, deals } = sponsorship;
  return (
    <div className="sponsorship elevate-content">
      <div className="logo-wrapper">
        <CompanyLogo company={company} size={logoSize.EXTRA_LARGE} />
      </div>
      <div className="info-wrapper">
        <div className="info-header mb-3">
          <h3>{company.name}</h3>
          <a className="ml-2" href={company.website} target="_blank">
            <i className="icon fas fa-external-link-alt"></i>
          </a>
        </div>
        {deals.map((deal, index) => (
          <div
            key={index}
            className={classNames('deal', index < deals.length - 1 && 'mb-3')}
          >
            <div className="description">
              <Linkifier>{deal.description}</Linkifier>
            </div>
            {!_str.isBlank(deal.redeemInstructions) && (
              <div className="redeem-instructions light-text-color">
                <Linkifier>{deal.redeemInstructions}</Linkifier>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default function DealsPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);

  const { loading, error, data } = useQuery(GET_CLUB_SPONSORSHIPS, {
    variables: { clubId: String(currentClubId) },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  assertNotNullOrUndefined(data?.club?.sponsorships);

  const sponsorships = _.sortBy(
    data.club.sponsorships,
    (sponsorship) => sponsorship.position,
  );

  return (
    <div id="clubhouse-deals-page">
      <div className="page-header">
        <div className="title">
          <h1>{i18n.t('title', { scope: i18nScope })}</h1>
        </div>
      </div>
      <div className="sponsorships">
        {sponsorships.map((sponsorship, index) => (
          <SponsorshipInfo key={index} sponsorship={sponsorship} />
        ))}
      </div>
    </div>
  );
}
