import _ from 'underscore';
import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormServerErrorMessages,
  FormDateField,
  FormAutoExpandField,
} from 'components/forms/forms';
import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import {
  ClubId,
  MembershipAsAdmin,
  UnparsedMembershipAsAdmin,
} from 'types/types';
import { AxiosResponse } from 'axios';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';
import { ClubMembership, ClubSubscriptionPlan } from '__generated__/graphql';

const i18nScope = 'clubs.admin.memberships.form';

type MembershipFormValues = Pick<
  MembershipAsAdmin,
  'firstName' | 'lastName' | 'startsAt' | 'expiresAt'
> & {
  startsAtDate: Date;
  expiresAtDate: Date;
  adminNote: string;
  // TODO: issue#200, using a string instead of StripePaymentMethodId
  // until we have better support for placeholder values
  subscriptionPlanId: string;
};

export type MembershipForForm = Pick<
  MembershipAsAdmin,
  'firstName' | 'lastName' | 'emailAddress' | 'startsAt' | 'expiresAt'
> & {
  adminNote: string;
  // TODO: issue#200, using a string instead of StripePaymentMethodId
  // until we have better support for placeholder values
  subscriptionPlanId: string;
};

export function buildNewMembership(): MembershipForForm {
  return {
    firstName: '',
    lastName: '',
    emailAddress: '',
    startsAt: Helpers.Utils.convertDateInLocalTimeZoneToYYYY_MM_DD(new Date()),
    expiresAt: Helpers.Utils.convertDateInLocalTimeZoneToYYYY_MM_DD(new Date()),
    subscriptionPlanId: '',
    adminNote: '',
  };
}

export function NewMembershipFormLoadingPlaceholder() {
  const twoColumnFieldsPlaceholder = (
    <div className="two-column">
      <div className="column">
        <LoadingPlaceholder type={placeholderType.BAR} classes="mb-2 py-2" />
      </div>
      <div className="column">
        <LoadingPlaceholder type={placeholderType.BAR} classes=" mb-2 py-2" />
      </div>
    </div>
  );

  const singleColumnFieldsPlaceholder = (
    <div className="two-column">
      <div className="column">
        <LoadingPlaceholder type={placeholderType.BAR} classes="mb-2 py-2" />
      </div>
      <div className="column"></div>
    </div>
  );

  const spacer = <div className="mt-3"></div>;

  return (
    <div className="mt-2">
      {twoColumnFieldsPlaceholder}
      {spacer}
      {singleColumnFieldsPlaceholder}
      {spacer}
      {singleColumnFieldsPlaceholder}
      {spacer}
      {twoColumnFieldsPlaceholder}
      {spacer}
      {twoColumnFieldsPlaceholder}
      {spacer}
    </div>
  );
}

// TODO: currently doesn't support editing a member, just creating one
export default function NewMembershipForm({
  currentClubId,
  subscriptionPlans,
  membership,
  onSuccessCallback,
  onCancelCallback,
}: {
  currentClubId: ClubId;
  subscriptionPlans: Pick<ClubSubscriptionPlan, 'id' | 'available' | 'name'>[];
  membership: MembershipForForm;
  onSuccessCallback: (membershipId: ClubMembership['id']) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const availableSubscriptionPlans = useMemo(
    () => subscriptionPlans.filter((sp) => sp.available),
    [],
  );

  const initialValues = useMemo<MembershipFormValues>(
    () => ({
      ..._.pick(
        membership,
        'firstName',
        'lastName',
        'emailAddress',
        'startsAt',
        'expiresAt',
        'subscriptionPlanId',
        'adminNote',
      ),
      startsAtDate: Helpers.Utils.convertYYYY_MM_DDToDateInLocalTimeZone(
        membership.startsAt,
      ),
      expiresAtDate: Helpers.Utils.convertYYYY_MM_DDToDateInLocalTimeZone(
        membership.expiresAt,
      ),
    }),
    [membership],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string()
          .min(2, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
        lastName: Yup.string()
          .min(2, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
        emailAddress: Yup.string()
          .email(i18n.t('forms.errors.invalid_email'))
          .required(i18n.t('forms.errors.required')),
        subscriptionPlanId: Yup.string().required(
          i18n.t('forms.errors.required'),
        ),
        startsAtDate: Yup.date()
          .required(i18n.t('forms.errors.required'))
          .typeError(i18n.t('forms.errors.invalid')),
        expiresAtDate: Yup.date()
          .required(i18n.t('forms.errors.required'))
          .typeError(i18n.t('forms.errors.invalid')),
      }),
    [],
  );

  const _onSubmit = function (
    values: MembershipFormValues,
    actions: FormikHelpers<MembershipFormValues>,
  ) {
    Helpers.Form.save({
      actions,
      main: () => {
        const params = {
          membership: {
            ...values,
            startsAt: Helpers.Utils.convertDateInLocalTimeZoneToYYYY_MM_DD(
              values.startsAtDate,
            ),
            expiresAt: Helpers.Utils.convertDateInLocalTimeZoneToYYYY_MM_DD(
              values.expiresAtDate,
            ),
          },
        };

        const url = Helpers.Routes.getClubAdminMembershipsPath(currentClubId);
        return Helpers.API.post({ url, params });
      },
      successCallback: (response) => {
        const data = (response as AxiosResponse<UnparsedMembershipAsAdmin>)
          .data;
        onSuccessCallback?.(String(data.data.id));
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_onSubmit}
    >
      {({ status, isSubmitting }) => (
        <Form className="basic-form" noValidate>
          <div className="two-column">
            <div className="column">
              <FormField
                autoFocus={true}
                classes="column"
                type="text"
                name="firstName"
                label={i18n.t('first_name.label', { scope: i18nScope })}
              />
            </div>
            <div className="column">
              <FormField
                type="text"
                name="lastName"
                label={i18n.t('last_name.label', { scope: i18nScope })}
              />
            </div>
          </div>

          <div className="two-column">
            <div className="column">
              <FormField
                type="email"
                name="emailAddress"
                label={i18n.t('email_address.label', { scope: i18nScope })}
              />
            </div>
            <div className="column"></div>
          </div>

          <div className="two-column">
            <div className="column">
              <FormField
                classes="mt-0"
                as="select"
                name="subscriptionPlanId"
                label={i18n.t('subscription_plan_id.label', {
                  scope: i18nScope,
                })}
              >
                <option value=""></option>
                {availableSubscriptionPlans.map((subscriptionPlan, index) => (
                  <option key={index} value={subscriptionPlan.id}>
                    {subscriptionPlan.name}
                  </option>
                ))}
              </FormField>
            </div>
            <div className="column"></div>
          </div>

          <div className="two-column">
            <div className="column">
              <FormDateField
                classes="date"
                dateFormat="MMM d, yyyy"
                name="startsAtDate"
                label={i18n.t('starts_at.label', { scope: i18nScope })}
              />
            </div>
            <div className="column">
              <FormDateField
                classes="date"
                dateFormat="MMM d, yyyy"
                name="expiresAtDate"
                label={i18n.t('expires_at.label', { scope: i18nScope })}
              />
            </div>
          </div>

          <FormAutoExpandField
            rows="2"
            name="adminNote"
            label={i18n.t('admin_note.label', {
              scope: i18nScope,
            })}
            placeholder={i18n.t('admin_note.placeholder', {
              scope: i18nScope,
            })}
            withoutErrorMessage={true}
          />

          <FormActions className="rtl">
            <FormActionButton
              text={i18n.t('actions.submit.add', { scope: i18nScope })}
              className="primary"
              isSubmitting={isSubmitting}
            />
            <FormActionButton
              className="secondary"
              text={i18n.t('actions.cancel', { scope: i18nScope })}
              isDisabled={isSubmitting}
              handleClick={onCancelCallback}
            />
            <FormServerErrorMessages
              serverErrors={status?.serverErrors?.base}
            />
          </FormActions>
        </Form>
      )}
    </Formik>
  );
}
