import I18nContext from 'contexts/i18n_context';
import React from 'react';

const i18nScope = 'components.utils.members_group_preview';

interface MembersGroupPreviewProps<T> {
  members: T[];
  total: number;
  renderMember: (
    member: T,
    index: number,
  ) => React.ReactElement | React.ReactNode | JSX.Element;
}

export default function MembersGroupPreview<T>({
  members,
  total,
  renderMember,
}: MembersGroupPreviewProps<T>) {
  const { i18n } = React.useContext(I18nContext);

  const diff = total - members.length;

  return (
    <div className="members-group-preview">
      <span className="profile-pics">{members.map(renderMember)}</span>
      {diff > 0 && (
        <span className="other-count ml-1 small">
          {i18n.t('others', { count: diff, scope: i18nScope })}
        </span>
      )}
    </div>
  );
}
