import _ from 'underscore';
import React, { useState } from 'react';
import { StripePaymentMethod as FullStripePaymentMethod } from '__generated__/graphql';
import classNames from 'classnames';

import StripeCreditCardFormField from 'components/payments/stripe_credit_card_form_field';
import AddPaymentMethodModal from 'components/payments/add_payment_method_modal';
import PaymentMethodSummary from 'components/payments/payment_method_summary';

import I18nContext from 'contexts/i18n_context';

const i18nScope = 'views.payments.payment_method_form_field';

type StripePaymentMethod = Pick<
  FullStripePaymentMethod,
  | 'id'
  | 'isDefault'
  | 'brand'
  | 'brandLogoUrl'
  | 'last4'
  | 'expMonth'
  | 'expYear'
>;

type OnSelectCallback = (
  stripePaymentMethodId: StripePaymentMethod['id'],
) => void;

export default function PaymentMethodFormField({
  stripePaymentMethods,
  value,
  onSelectCallback,
  onAddCallback,
}: {
  stripePaymentMethods: StripePaymentMethod[];
  value: string;
  onSelectCallback: OnSelectCallback;
  onAddCallback: (spm: StripePaymentMethod) => void;
}) {
  const selectedStripePaymentMethod =
    _.findWhere(stripePaymentMethods, {
      id: value,
    }) ?? null;

  // we'll pre-select the first payment method, # TODO store which one is the default
  const [shouldShowAllPaymentMethods, setShouldShowAllPaymentMethods] =
    useState(false);
  const [shouldShowAddPaymentMethodModal, setShouldShowAddPaymentMethodModal] =
    useState(false);

  // if the user has no existing payment methods when they load the page then
  // we'll show the credit card form inline. if they do have them then we'll
  // choose the default and the user can change it or add a new one.
  // the reason we don't update this when the user adds a payment method is
  // to keep the UX stable and to avoid lots of switching.
  const hasExistingPaymentMethods = stripePaymentMethods.length > 0;
  const [shouldShowNewCreditCardFieldInline] = useState(
    !hasExistingPaymentMethods,
  );

  if (shouldShowNewCreditCardFieldInline) {
    return <StripeCreditCardFormField />;
  }

  return (
    <div className="payment-method-form-field">
      {shouldShowAllPaymentMethods || selectedStripePaymentMethod === null ? (
        <AllPaymentMethodsOptions
          stripePaymentMethods={stripePaymentMethods}
          selectedStripePaymentMethod={selectedStripePaymentMethod}
          setShouldShowAddPaymentMethodModal={
            setShouldShowAddPaymentMethodModal
          }
          onSelectCallback={onSelectCallback}
        />
      ) : (
        <PreselectedPaymentMethod
          selectedStripePaymentMethod={selectedStripePaymentMethod}
          setShouldShowAllPaymentMethods={setShouldShowAllPaymentMethods}
        />
      )}

      {shouldShowAddPaymentMethodModal && (
        <AddPaymentMethodModal
          onCancelCallback={() => setShouldShowAddPaymentMethodModal(false)}
          onAddCallback={(unparsedStripePaymentMethod) => {
            // TODO: converting UnparsedStripePaymentMethod method to a graphql
            // stripe payment method. Remove when ready.
            const spmData = unparsedStripePaymentMethod.data.attributes;
            onAddCallback({
              id: String(spmData.id),
              isDefault: spmData.isDefault,
              brand: spmData.brand,
              brandLogoUrl: spmData.brandLogoUrl,
              last4: spmData.last4,
              expMonth: spmData.expMonth,
              expYear: spmData.expYear,
            });
            onSelectCallback(String(spmData.id));
            setShouldShowAddPaymentMethodModal(false);
          }}
        />
      )}
    </div>
  );
}

function PreselectedPaymentMethod({
  selectedStripePaymentMethod,
  setShouldShowAllPaymentMethods,
}: {
  selectedStripePaymentMethod: StripePaymentMethod;
  setShouldShowAllPaymentMethods: (val: boolean) => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="preselected-payment-method">
      <PaymentMethodSummary stripePaymentMethod={selectedStripePaymentMethod} />
      <div className="change-payment-method">
        <a
          className="link"
          onClick={() => setShouldShowAllPaymentMethods(true)}
        >
          {i18n.t('preselected.change', { scope: i18nScope })}
        </a>
      </div>
    </div>
  );
}

function AllPaymentMethodsOptions({
  stripePaymentMethods,
  selectedStripePaymentMethod,
  setShouldShowAddPaymentMethodModal,
  onSelectCallback,
}: {
  stripePaymentMethods: StripePaymentMethod[];
  selectedStripePaymentMethod: StripePaymentMethod | null;
  setShouldShowAddPaymentMethodModal: (val: boolean) => void;
  onSelectCallback: OnSelectCallback;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="select-payment-method">
      <div className="payment-methods">
        {stripePaymentMethods.map((stripePaymentMethod) => {
          const radioInputId = `stripe-payment-method-${stripePaymentMethod.id}`;
          return (
            <div
              key={stripePaymentMethod.id}
              className={classNames('payment-method mb-1', {
                selected:
                  stripePaymentMethod.id === selectedStripePaymentMethod?.id,
              })}
            >
              <input
                className="mr-1"
                id={radioInputId}
                type="radio"
                checked={
                  stripePaymentMethod.id === selectedStripePaymentMethod?.id
                }
                onChange={() => onSelectCallback(stripePaymentMethod.id)}
              />
              <label htmlFor={radioInputId}>
                <PaymentMethodSummary
                  stripePaymentMethod={stripePaymentMethod}
                />
                <span className="expires-on ml-2">
                  {stripePaymentMethod.expMonth}/{stripePaymentMethod.expYear}
                </span>
              </label>
            </div>
          );
        })}
      </div>
      <a
        className="add-new-method link"
        onClick={() => setShouldShowAddPaymentMethodModal(true)}
      >
        {i18n.t('add_new_method', { scope: i18nScope })}
      </a>
    </div>
  );
}
