import React from 'react';
import { useField } from 'formik';
import PillGroup, { Pill } from 'components/utils/pill_group';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';

type FormPillsFieldProps<T> = Omit<FormFieldwrapperProps, 'children'> & {
  onChangeCallback?: (newValue: T) => void;
  isVertical?: boolean;
  pills: Pill<T>[];
};

export default function FormPillsField<T = boolean>({
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
  onChangeCallback,
  isVertical,
  pills,
}: FormPillsFieldProps<T>) {
  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const onChange = (value: T) => {
    setValue(value);
    setTouched(true);
    onChangeCallback?.(value);
  };
  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      label={label}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-field pills-form-field">
        <PillGroup
          pills={pills}
          isVertical={isVertical}
          value={field.value}
          onClick={onChange}
        />
      </div>
    </FormFieldWrapper>
  );
}
