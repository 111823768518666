import React, { useCallback, useMemo, useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import Helpers from 'helpers/helpers';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

interface StripeCardElementChangeEventError {
  code: string;
  message: string;
}

export default function StripeCreditCardFormField() {
  // brand: 'visa'
  // complete: false
  // elementType: 'card'
  // empty: false
  // error: {code: 'incomplete_expiry', type: 'validation_error', message: 'Your card's expiration date is incomplete.'}
  // value: {postalCode: ''}

  const [error, setError] = useState<StripeCardElementChangeEventError | null>(
    null,
  );

  const CARD_ELEMENT_OPTIONS = useMemo(() => {
    const colors = Helpers.Color.getStripeCreditCardFieldColors();
    return {
      style: {
        base: {
          iconColor: colors.iconColor,
          // padding: '15px 10px',
          color: colors.textColor,
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: colors.placeholderColor,
          },
        },
        invalid: {
          color: colors.errorTextColor,
          iconColor: colors.errorIconColor,
        },
      },
    };
  }, []);

  const _onChange = useCallback((event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setError(event.error);
    }
  }, []);

  return (
    <div className="stripe-credit-card-form-field-wrapper">
      <div className="stripe-credit-card-form-field form-field">
        <div className="stripe-card-element-wrapper">
          <CardElement onChange={_onChange} options={CARD_ELEMENT_OPTIONS} />
        </div>
      </div>
      {error && <div className="form-error-message">{error.message}</div>}
    </div>
  );
}
