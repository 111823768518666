import React from 'react';
import classNames from 'classnames';

export interface Page<T> {
  id: T;
  displayTextOrElement: JSX.Element;
}

export default function ModalSidebar<T>({
  children,
  pages,
  selectedPage,
  onSelectPageCallback,
}: {
  children: React.ReactElement | React.ReactElement[];
  pages: Page<T>[];
  selectedPage: Page<T>;
  onSelectPageCallback: (page: Page<T>) => void;
}) {
  return (
    <div className="modal-sidebar">
      <div className="modal-sidebar-links">
        {pages.map((page, index) => (
          <div
            key={index}
            className={classNames('modal-sidebar-link', {
              'active-link': selectedPage?.id === page.id,
            })}
            onClick={() => onSelectPageCallback(page)}
          >
            {page.displayTextOrElement}
          </div>
        ))}
      </div>
      <div className="modal-sidebar-content">{children}</div>
    </div>
  );
}
