import { assertNotNullOrUndefined } from 'h';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  AdminClubOrdersExportMutation,
  Club,
  ClubRemoteFile,
} from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';
import I18nContext from 'contexts/i18n_context';
import Icon, { iconType } from 'components/utils/icon';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import BasicModal from 'components/utils/basic_modal';
import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
} from 'components/forms/forms';

const i18nScope = 'clubs.admin.money.orders.export_orders_button';

const ADMIN_CLUB_ORDERS_EXPORT = gql(`
  mutation AdminClubOrdersExport($input: ClubOrdersExportInput!) {
    clubOrdersExport(input: $input) {
      remoteFile {
        id
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

const GET_ADMIN_CLUB_ORDERS_EXPORT = gql(`
  query GetAdminClubOrdersExport($clubId: ID!, $remoteFileId: ID!) {
    club(id: $clubId) {
      id
      remoteFile(id: $remoteFileId) {
        id
        fileType
        isGenerating
        showUrl
      }
    }
  }
`);

export interface ExportOrdersFormValues {
  clubId: Club['id'];
}

function ExportOrderForm({
  currentClubId,
  onSuccessCallback,
  onCancelCallback,
}: {
  currentClubId: Club['id'];
  onSuccessCallback: (remoteFileId: ClubRemoteFile['id']) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [exportMutation] = useMutation(ADMIN_CLUB_ORDERS_EXPORT);

  const initialValues = useMemo(
    () => ({
      clubId: currentClubId,
    }),
    [],
  );

  const _onSubmit = function (
    values: ExportOrdersFormValues,
    actions: FormikHelpers<ExportOrdersFormValues>,
  ) {
    sendMutationAndUpdateForm<
      ExportOrdersFormValues,
      AdminClubOrdersExportMutation
    >({
      actions,
      mutationName: 'clubOrdersExport',
      main: () => exportMutation({ variables: { input: values } }),
      successCallback: (mutationPayload) => {
        const createdRemoteFile = mutationPayload?.clubOrdersExport?.remoteFile;
        assertNotNullOrUndefined(createdRemoteFile);
        onSuccessCallback(createdRemoteFile.id);
      },
    });
  };

  return (
    <div className="export-memberships-form">
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {({ status, isSubmitting }) => (
          <Form>
            <p>{i18n.t('form.description', { scope: i18nScope })}</p>
            <FormActions className="rtl">
              <FormActionButton className="primary" isSubmitting={isSubmitting}>
                <span>
                  {i18n.t('form.actions.export', { scope: i18nScope })}
                </span>
              </FormActionButton>
              <FormActionButton
                text={i18n.t('form.actions.cancel', { scope: i18nScope })}
                className="secondary"
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function PollingUntilRemoteFileReady({
  currentClubId,
  remoteFileId,
}: {
  currentClubId: Club['id'];
  remoteFileId: ClubRemoteFile['id'];
}) {
  const { i18n } = React.useContext(I18nContext);

  const { loading, error, data, stopPolling } = useQuery(
    GET_ADMIN_CLUB_ORDERS_EXPORT,
    {
      variables: {
        clubId: currentClubId,
        remoteFileId: remoteFileId,
      },
      pollInterval: 1500,
    },
  );

  useEffect(() => {
    if (data && data.club?.remoteFile?.isGenerating === false) {
      stopPolling();
    }
  }, [data, stopPolling]);

  const remoteFileUrl = data?.club?.remoteFile?.showUrl;

  if (loading || error || remoteFileUrl === null) {
    return (
      <div>
        <p>{i18n.t('polling.waiting.description', { scope: i18nScope })}</p>
        <p>
          <Icon type={iconType.LOADING} />
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>{i18n.t('polling.ready.description', { scope: i18nScope })}</p>
        <a href={remoteFileUrl} target="_blank">
          {i18n.t('polling.ready.link', { scope: i18nScope })}
        </a>
      </div>
    );
  }
}

export default function ExportOrdersButton({
  currentClubId,
}: {
  currentClubId: Club['id'];
}) {
  const { i18n } = React.useContext(I18nContext);
  const [shouldShowExportModal, setShouldShowExportModal] = useState(false);
  const [remoteFileId, setRemoteFileId] = useState<ClubRemoteFile['id'] | null>(
    null,
  );

  return (
    <span>
      <span className="export-orders-button">
        <Icon
          type={iconType.EXPORT}
          tooltipText={i18n.t('button.tooltip', { scope: i18nScope })}
          onClick={() => setShouldShowExportModal(true)}
        />
      </span>
      {shouldShowExportModal && (
        <BasicModal
          className="basic-modal-content basic-form"
          onRequestCloseCallback={() => setShouldShowExportModal(false)}
          isOpen={true}
        >
          <h3 className="mt-0">
            {i18n.t('modal.title', { scope: i18nScope })}
          </h3>
          {remoteFileId === null ? (
            <ExportOrderForm
              currentClubId={currentClubId}
              onSuccessCallback={(remoteFileId) =>
                setRemoteFileId(remoteFileId)
              }
              onCancelCallback={() => setShouldShowExportModal(false)}
            />
          ) : (
            <PollingUntilRemoteFileReady
              currentClubId={currentClubId}
              remoteFileId={remoteFileId}
            />
          )}
        </BasicModal>
      )}
    </span>
  );
}
