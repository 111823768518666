import I18nContext from 'contexts/i18n_context';
import React, { useContext } from 'react';

const i18nScope = 'components.utils.load_more';

export default function LoadMore({
  canLoadMore = false,
  onClick,
}: {
  canLoadMore?: boolean;
  onClick?: () => void;
}) {
  const { i18n } = useContext(I18nContext);

  if (!canLoadMore) {
    return null;
  }

  return (
    <div className="load-more mt-2">
      <a onClick={onClick}>{i18n.t('text', { scope: i18nScope })}</a>
    </div>
  );
}
