import _ from 'underscore';
import React, { useEffect, useMemo, useState } from 'react';

import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import { GetClubStorefrontPageQuery } from '__generated__/graphql';
import { OrderItemSubjectType } from 'types/types';
import CheckoutWrapper from 'components/utils/orders/checkout_wrapper';
import h, { assertNotNullOrUndefined } from 'h';
import BasicModal from 'components/utils/basic_modal';

import {
  CartItem,
  getTotalAmountInCentsOfCart,
} from 'components/clubs/storefronts/cart';
import CheckoutButton from 'components/clubs/storefronts/checkout_button';
import CartDetailView from 'components/clubs/storefronts/cart_detail_view';
import { ProductDetailView } from 'components/clubs/storefronts/product_detail_view';
import ProductCard from 'components/clubs/storefronts/product_card';
import CartButton from 'components/clubs/storefronts/cart_button';

const i18nScope = 'components.clubs.storefronts.storefront';

type MaybeCurrentUser = GetClubStorefrontPageQuery['currentUser'];
type Club = NonNullable<GetClubStorefrontPageQuery['club']>;
type Storefront = NonNullable<Club['storefront']>;
type Product = NonNullable<Storefront['products']>[number];
type ProductVariant = NonNullable<Product['productVariants']>[number];

enum StorefrontSteps {
  SELECT_PRODUCTS_STEP,
  CHECKOUT_STEP,
  ORDER_COMPLETED_STEP,
}

export default function StorefrontForm({
  currentUser,
  club,
  storefront,
  // used to avoid double charging the user
  orderIdempotencyKey,
  onSuccessRedirectToPath,
}: {
  currentUser: MaybeCurrentUser;
  club: Club;
  storefront: Storefront;
  orderIdempotencyKey: string;
  onSuccessRedirectToPath: string;
}) {
  const steps = [
    StorefrontSteps.SELECT_PRODUCTS_STEP,
    StorefrontSteps.CHECKOUT_STEP,
    StorefrontSteps.ORDER_COMPLETED_STEP,
  ];
  const [currentStep, setCurrentStep] = useState(steps[0]);

  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  // iterate betweens steps
  const index = steps.indexOf(currentStep);
  const goToNextStep = () => {
    if (index + 1 >= steps.length) {
      window.location.href = onSuccessRedirectToPath;
    } else {
      setCurrentStep(steps[index + 1]);
    }
  };
  const goToPreviousStep = () => {
    if (index - 1 < 0) {
      return;
    }
    setCurrentStep(steps[index - 1]);
  };

  let content;
  switch (currentStep) {
    case StorefrontSteps.SELECT_PRODUCTS_STEP:
      content = (
        <SelectProductsStep
          club={club}
          storefront={storefront}
          cartItems={cartItems}
          setCartItems={setCartItems}
          goToNextStep={goToNextStep}
          onNoProductsRedirectPath={onSuccessRedirectToPath}
        />
      );
      break;
    case StorefrontSteps.CHECKOUT_STEP:
      content = (
        <CheckoutStep
          currentUser={currentUser}
          cartItems={cartItems}
          orderIdempotencyKey={orderIdempotencyKey}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      );
      break;
    case StorefrontSteps.ORDER_COMPLETED_STEP:
      content = (
        <OrderCompletedStep onSuccessRedirectToPath={onSuccessRedirectToPath} />
      );
      break;
    default:
      h.throwExhaustiveError("don't know how to render step", currentStep);
  }

  return (
    <div id="storefront-form">
      <div className="step">{content}</div>
    </div>
  );
}

function SelectProductsStep({
  club,
  storefront,
  cartItems,
  setCartItems,
  goToNextStep,
  onNoProductsRedirectPath,
}: {
  club: Club;
  storefront: Storefront;
  cartItems: CartItem[];
  setCartItems: (cartItems: CartItem[]) => void;
  goToNextStep: () => void;
  onNoProductsRedirectPath: string;
}) {
  const { i18n } = React.useContext(I18nContext);
  const stepI18nScope = `${i18nScope}.select_products_step`;
  const [shouldShowCartModal, setShouldShowCartModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const sortedProducts = useMemo(
    () =>
      _.sortBy(
        storefront.products.filter((pr) => pr.isPubliclyVisible),
        (product) => product.position,
      ),
    [],
  );

  // handles window resizes - to move the actions bar (cart + checkout) to
  // the bottom of the screen.
  const isTabletOrSmallerFn = () => Helpers.ScreenSize.getIsTabletOrSmaller();
  const [isTabletOrSmaller, setIsTabletOrSmaller] =
    useState(isTabletOrSmallerFn);
  useEffect(
    Helpers.ScreenSize.useEffect(() =>
      setIsTabletOrSmaller(isTabletOrSmallerFn()),
    ),
  );

  // TODO: very hackish, we should persist the card
  const updateCartCallback = ({
    product,
    newQuantity,
    variant,
  }: {
    product: Product;
    newQuantity: number;
    variant: ProductVariant | null;
  }) => {
    if (newQuantity < 0) {
      h.throwError('quantity cannot be less than 0');
    }

    const index = cartItems.findIndex(
      (cartItem) =>
        cartItem.product.id === product.id &&
        cartItem.variant?.id === variant?.id,
    );
    const isAlreadyInCart = index >= 0;

    if (newQuantity === 0) {
      if (isAlreadyInCart) {
        // we need to remove it from the cart
        cartItems.splice(index, 1);
        // NOTE: splice returns the discarded items, do not combine
        // these the line above and the setCartItems
        setCartItems([...cartItems]);
      } else {
        // nothing to do, it's not in the cart and we want to add 0 units
      }
    } else {
      if (isAlreadyInCart) {
        // it's in the cart we need to update it
        cartItems[index].quantity = newQuantity;
        setCartItems([...cartItems]);
      } else {
        // just add it to the cart
        setCartItems([
          ...cartItems,
          { product, quantity: newQuantity, variant: variant },
        ]);
      }
    }
  };

  const removeCartItemCallback = ({
    product,
    variant,
  }: {
    product: Product;
    variant: ProductVariant | null;
  }) => {
    const targetCartItem = cartItems.find(
      (cartItem) =>
        cartItem.product.id === product.id &&
        cartItem.variant?.id === variant?.id,
    );
    const quantity = targetCartItem?.quantity ?? 0;
    updateCartCallback({
      product,
      newQuantity: quantity - 1,
      variant: variant,
    });
  };

  const addCartItemCallback = ({
    product,
    variant,
  }: {
    product: Product;
    variant: ProductVariant | null;
  }) => {
    const targetCartItem = cartItems.find(
      (cartItem) =>
        cartItem.product.id === product.id &&
        cartItem.variant?.id === variant?.id,
    );
    const quantity = targetCartItem?.quantity ?? 0;
    updateCartCallback({
      product,
      newQuantity: quantity + 1,
      variant: variant,
    });
  };

  const actionsElement = (
    <div>
      <CartButton
        classes="mr-4"
        cartItems={cartItems}
        onClickCallback={() => setShouldShowCartModal(true)}
      />

      <CheckoutButton cartItems={cartItems} goToCheckout={goToNextStep} />
    </div>
  );

  let stepContent: React.ReactElement;
  if (sortedProducts.length === 0) {
    stepContent = (
      <div
        className="center-content vertical-center center"
        style={{ minHeight: 400 }}
      >
        <div>
          <h2>{i18n.t('no_products.heading', { scope: stepI18nScope })}</h2>
          <button
            className="btn btn-primary mt-4"
            onClick={() => Helpers.Utils.redirectTo(onNoProductsRedirectPath)}
          >
            {i18n.t('no_products.redirect_button', { scope: stepI18nScope })}
          </button>
        </div>
      </div>
    );
  } else {
    stepContent = (
      <div className="product-cards">
        {sortedProducts.map((product, index) => {
          return (
            <ProductCard
              key={index}
              product={product}
              onClickCallback={(product: Product) =>
                setSelectedProduct(product)
              }
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="select-products-step">
      <div className="center-content">
        <div className="header-bar">
          <div className="header-bar-name">
            <h1>
              {i18n.t('heading', {
                scope: stepI18nScope,
                values: { clubName: club.name },
              })}
            </h1>
          </div>

          <div className="header-bar-actions">
            {!isTabletOrSmaller && actionsElement}
          </div>
        </div>

        {stepContent}
      </div>

      {isTabletOrSmaller && (
        <div className="mobile-fixed-actions">{actionsElement}</div>
      )}

      {selectedProduct && (
        <BasicModal
          className="basic-modal-content basic-form storefront-product-detail-view-modal"
          onRequestCloseCallback={() => setSelectedProduct(null)}
          shouldCloseOnOverlay={true}
          shouldCloseOnEsc={true}
          isOpen={true}
        >
          <ProductDetailView
            product={selectedProduct}
            addCartItemCallback={addCartItemCallback}
            onCancelCallback={() => setSelectedProduct(null)}
          />
        </BasicModal>
      )}
      {shouldShowCartModal && (
        <BasicModal
          className="basic-modal-content basic-form storefront-cart-detail-view-modal"
          onRequestCloseCallback={() => setShouldShowCartModal(false)}
          shouldCloseOnOverlay={true}
          shouldCloseOnEsc={true}
          isOpen={true}
        >
          <CartDetailView
            cartItems={cartItems}
            addCartItemCallback={addCartItemCallback}
            removeCartItemCallback={removeCartItemCallback}
            goToCheckout={goToNextStep}
            onDoneCallback={() => setShouldShowCartModal(false)}
          />
        </BasicModal>
      )}
    </div>
  );
}

// it's actually an empty hash, but typescript was complaining
// when I did interface StorefrontFormValues {}
type StorefrontFormValues = Record<string, unknown>;

function CheckoutStep({
  currentUser,
  orderIdempotencyKey,
  cartItems,
  goToNextStep,
  goToPreviousStep,
}: {
  currentUser: MaybeCurrentUser;
  orderIdempotencyKey: string;
  cartItems: CartItem[];
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) {
  // there must a user to be able to checkout
  assertNotNullOrUndefined(currentUser);

  const { i18n } = React.useContext(I18nContext);
  const stepI18nScope = `${i18nScope}.checkout_step`;

  const platformStripePaymentMethods = currentUser.platformStripePaymentMethods;

  const additionalInitialValues = {};
  const additionalValidationSchema = {};

  // TODO: awful, we should compute this on the backend and makes it easier
  // to ensure the price is as expected. Plus we could include discounts,
  // taxes, and fees.
  const totalAmountInCentsOfCart = getTotalAmountInCentsOfCart(cartItems);

  return (
    <div className="checkout-step center-content elevate-content">
      <CheckoutWrapper<StorefrontFormValues>
        orderIdempotencyKey={orderIdempotencyKey}
        additionalInitialValues={additionalInitialValues}
        additionalValidationSchema={additionalValidationSchema}
        platformStripePaymentMethods={platformStripePaymentMethods}
        overrideSubmitButtonText={i18n.t('form.actions.submit', {
          scope: stepI18nScope,
        })}
        secondaryActionButtonText={i18n.t('form.actions.back', {
          scope: stepI18nScope,
        })}
        secondaryActionButtonClickCallback={goToPreviousStep}
        createOrderItemsCallback={() =>
          cartItems.map((cartItem) =>
            cartItem.variant !== null
              ? {
                  subjectType: OrderItemSubjectType.PRODUCT_VARIANT,
                  subjectId: Number(cartItem.variant.id),
                  priceInCents: cartItem.product.priceInCents,
                  quantity: cartItem.quantity,
                }
              : {
                  subjectType: OrderItemSubjectType.PRODUCT,
                  subjectId: Number(cartItem.product.id),
                  priceInCents: cartItem.product.priceInCents,
                  quantity: cartItem.quantity,
                },
          )
        }
        onSuccessCallback={() => goToNextStep()}
      >
        {({ paymentMethodFieldFormFieldElement }) => (
          <div>
            <h1>{i18n.t('heading', { scope: stepI18nScope })}</h1>

            <div className="form-section">
              <div
                className="form-section-header"
                dangerouslySetInnerHTML={{
                  __html: i18n.t('shipping_section.heading_html', {
                    scope: stepI18nScope,
                  }),
                }}
              />
              <div className="form-section-body">
                <p>
                  {i18n.t('shipping_section.none_description', {
                    scope: stepI18nScope,
                  })}
                </p>
              </div>
            </div>

            <div className="form-section">
              <div
                className="form-section-header"
                dangerouslySetInnerHTML={{
                  __html: i18n.t('review_section.heading_html', {
                    scope: stepI18nScope,
                  }),
                }}
              />
              <div className="form-section-body">
                <table className="order-breakdown-table mt-2">
                  <tbody>
                    {cartItems.map((cartItem, index) => (
                      <tr key={index}>
                        <td>
                          {cartItem.product.name}{' '}
                          {cartItem.quantity > 1 && <>(x{cartItem.quantity})</>}
                          {cartItem.variant !== null && (
                            <>
                              <br />
                              <span className="small light-text-color">
                                {cartItem.variant.variant}
                              </span>
                            </>
                          )}
                        </td>
                        <td>
                          {i18n.c({
                            amountInCents:
                              cartItem.quantity * cartItem.product.priceInCents,
                          })}
                        </td>
                      </tr>
                    ))}
                    <tr className="total-row">
                      <td>
                        {i18n.t('review_section.total', {
                          scope: stepI18nScope,
                        })}
                      </td>
                      <td>
                        {i18n.c({ amountInCents: totalAmountInCentsOfCart })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="form-section mb-2">
              <div
                className="form-section-header"
                dangerouslySetInnerHTML={{
                  __html: i18n.t('payment_section.heading_html', {
                    scope: stepI18nScope,
                  }),
                }}
              />
            </div>
            <div className="form-section-body">
              {paymentMethodFieldFormFieldElement}
            </div>
          </div>
        )}
      </CheckoutWrapper>
    </div>
  );
}

function OrderCompletedStep({
  onSuccessRedirectToPath,
}: {
  onSuccessRedirectToPath: string;
}) {
  const { i18n } = React.useContext(I18nContext);
  const stepI18nScope = `${i18nScope}.order_completed_step`;
  return (
    <div className="order-completed-step center-content vertical-center center">
      <div>
        <h2>{i18n.t('heading', { scope: stepI18nScope })}</h2>
        <button
          className="btn btn-primary mt-4"
          onClick={() => Helpers.Utils.redirectTo(onSuccessRedirectToPath)}
        >
          {i18n.t('redirect_button', { scope: stepI18nScope })}
        </button>
      </div>
    </div>
  );
}
