import h, { assertNotNullOrUndefined } from 'h';
import React from 'react';
import RootAppWrapper from 'components/utils/root_app_wrapper';
import { gql } from '__generated__/gql';
import { GetClubStorefrontPageQuery } from '__generated__/graphql';

import StripeWrapper from 'components/utils/stripe_wrapper';
import StorefrontForm from 'components/clubs/storefronts/storefront_form';
import AuthenticationWall from 'components/utils/authentication_wall';

// TODO: should only get products that are publicly visible. right now
// it returns all products (for admins)

// --START SERVER SIDE GRAPHQL
gql(`
  query GetClubStorefrontPage($clubId: ID!) {
    currentUser {
      id
      platformStripePaymentMethods {
        id
        paymentMethodType
        brand
        brandLogoUrl
        last4
        expYear
        expMonth
        isDefault
      }
    }
    club(id: $clubId) {
      id
      name
      storefront {
        id
        products {
          id
          name
          description
          priceInCents
          isPubliclyVisible
          isSoldOut
          position
          imageUrls
          productVariants {
            id
            productId
            variant
          }
        }
      }
    }
  }
`);
// --END SERVER SIDE GRAPHQL

export default function Storefront({
  graphqlResult,
  orderIdempotencyKey,
  onSuccessRedirectToPath,
}: {
  graphqlResult: { data: GetClubStorefrontPageQuery };
  orderIdempotencyKey: string;
  onSuccessRedirectToPath: string;
}) {
  const graphqlData = graphqlResult.data;

  const currentUser = graphqlData.currentUser;

  const club = graphqlData.club;
  assertNotNullOrUndefined(club);
  const storefront = club.storefront;

  assertNotNullOrUndefined(orderIdempotencyKey);
  assertNotNullOrUndefined(onSuccessRedirectToPath);

  return (
    <RootAppWrapper>
      <StripeWrapper>
        <AuthenticationWall isLoggedOut={h.isNullOrUndefined(currentUser)}>
          <StorefrontForm
            currentUser={currentUser}
            club={club}
            storefront={storefront}
            orderIdempotencyKey={orderIdempotencyKey}
            onSuccessRedirectToPath={onSuccessRedirectToPath}
          />
        </AuthenticationWall>
      </StripeWrapper>
    </RootAppWrapper>
  );
}
