import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import MembershipTagForm from 'components/clubs/admin/memberships_page/tags/membership_tag_form';

const i18nScope = 'clubs.admin.memberships.tags.edit';

const GET_ADMIN_CLUB_EDIT_MEMBERSHIP_TAG = gql(`
  query GetAdminClubEditMembershipTag($clubId: ID!, $membershipTagId: ID!) {
    club(id: $clubId) {
      id
      membershipTag(id: $membershipTagId) {
        id
        name
        memberships {
          id
          fullName
          profilePicUrl
          emailAddress
        }
      }
    }
  }
`);

export default function MembershipTagEditPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(
    GET_ADMIN_CLUB_EDIT_MEMBERSHIP_TAG,
    {
      variables: {
        clubId: String(currentClubId),
        membershipTagId: String(id),
      },
    },
  );
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const membershipTag = club.membershipTag;
  assertNotNullOrUndefined(membershipTag);

  return (
    <AdminDashboard
      title={i18n.t('title', { scope: i18nScope })}
      contentClasses="elevate-content min-height-page membership-tag-form-page"
    >
      <MembershipTagForm
        clubId={String(currentClubId)}
        isEditing={true}
        membershipTag={membershipTag}
        onSuccessCallback={backToIndexPage}
        onCancelCallback={backToIndexPage}
      />
    </AdminDashboard>
  );
}
