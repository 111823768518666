import h from 'h';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import Modal from 'react-modal';

Modal.setAppElement('body');

interface ImageCropperModalProps {
  title?: string;
  submitText: string;
  image: File;
  imageUrl: string;
  aspectRatio: number;
  onSuccessCallback: (croppedImage: File, croppedImageUrl: string) => void;
  onCancelCallback: () => void;
}

export default function ImageCropperModal({
  title,
  submitText,
  image,
  imageUrl,
  aspectRatio,
  onSuccessCallback,
  onCancelCallback,
}: ImageCropperModalProps) {
  const [zoom, setZoom] = useState(1);
  const [rotation] = useState(0);
  const [aspect] = useState(aspectRatio);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isApplying, setIsApplying] = useState(false);

  const onCropComplete = (
    _croppedAreaPercentage: Area,
    croppedAreaPixels: Area,
  ) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onApply = async () => {
    setIsApplying(true);
    try {
      const { blob: croppedImageBlob, blobUrl: croppedImageUrl } =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await _getCroppedImageUrlAsync(imageUrl, croppedAreaPixels!, rotation);
      const croppedImage = new File([croppedImageBlob], image.name, {
        type: image.type,
        lastModified: new Date().getTime(),
      });
      onSuccessCallback(croppedImage, croppedImageUrl);
    } catch (error) {
      console?.error(error);
      setIsApplying(false);
    }
  };

  return (
    <Modal
      overlayClassName="basic-modal-overlay"
      className="basic-modal-content cropper-modal basic-form"
      isOpen={true}
      onRequestClose={onCancelCallback}
      closeTimeoutMS={300}
      shouldCloseOnOverlayClick={false}
      contentLabel={title}
    >
      <i
        className="close-modal-btn fas fa-times"
        onClick={onCancelCallback}
      ></i>
      {title && <h1>{title}</h1>}
      <div className="cropper-wrapper">
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onZoomChange={setZoom}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="form-actions">
        <button
          className="form-action-button primary"
          onClick={onApply}
          disabled={isApplying}
        >
          {isApplying ? (
            <i className="fas fa-circle-notch fa-spin"></i>
          ) : (
            submitText
          )}
        </button>
      </div>
    </Modal>
  );
}

// the client side cropping code was adapted based on
// https://ricardo-ch.github.io/react-easy-crop/

function _createImageAsync(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
}

function _getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
async function _getCroppedImageUrlAsync(
  imageUrl: string,
  pixelCrop: Area,
  rotation = 0,
): Promise<{ blob: Blob; blobUrl: string }> {
  const image = await _createImageAsync(imageUrl);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (ctx === null) {
    return h.throwError('canvas context should not be null');
  }

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(_getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      if (file === null) {
        return h.throwError('unexpected file when cropping is null');
      }
      resolve({ blob: file, blobUrl: URL.createObjectURL(file) });
    }, 'image/jpeg');
  });
}
