import React from 'react';
import classNames from 'classnames';
import { Club, ClubMembership, User } from '__generated__/graphql';

import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';

export enum picSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large',
}

interface ProfilePicProps {
  classes?: string;
  size?: picSize;
  alt?: string;
  // either the picUrl or children is required
  picUrl?: string;
  children?: React.ReactElement;
}

// we accept just the profilePicUrl because in some cases
// like events, we just serialize the first 3 profile pic
// urls without the full user object
export function UserProfilePic({
  classes,
  size,
  profilePicUrl,
  user,
}: Pick<ProfilePicProps, 'classes' | 'size'> & {
  profilePicUrl?: string;
  user?: Pick<User, 'profilePicUrl'>;
}) {
  return (
    <ProfilePic
      classes={classNames('user-profile-pic', classes)}
      size={size}
      picUrl={user?.profilePicUrl ?? profilePicUrl}
    />
  );
}

export function MembershipProfilePic({
  classes,
  size,
  profilePicUrl,
  membership,
}: Pick<ProfilePicProps, 'classes' | 'size'> & {
  profilePicUrl?: string;
  membership?: Pick<ClubMembership, 'profilePicUrl'>;
}) {
  return (
    <ProfilePic
      classes={classNames('membership-profile-pic', classes)}
      size={size}
      picUrl={membership?.profilePicUrl ?? profilePicUrl}
    />
  );
}

export function ClubProfilePic({
  classes,
  size,
  club,
  alt,
}: Pick<ProfilePicProps, 'classes' | 'size'> & {
  club: Pick<Club, 'logoUrl'>;
  alt?: string;
}) {
  return (
    <ProfilePic
      classes={classNames('club-profile-pic', classes)}
      size={size}
      picUrl={club.logoUrl}
      alt={alt}
    />
  );
}

export function LoadingPlaceholderProfilePic({
  classes,
  size,
}: Pick<ProfilePicProps, 'classes' | 'size'>) {
  return (
    <ProfilePic
      classes={classNames('loading-placeholder-profile-pic', classes)}
      size={size}
    >
      <LoadingPlaceholder type={placeholderType.FULL} />
    </ProfilePic>
  );
}

export function MockPlaceholderProfilePic({
  classes,
  size,
}: Pick<ProfilePicProps, 'classes' | 'size'>) {
  return (
    <ProfilePic
      classes={classNames('mock-placeholder-profile-pic', classes)}
      size={size}
    >
      <div className="full faux-img"></div>
    </ProfilePic>
  );
}

export function ProfilePic({
  children,
  classes,
  size = picSize.LARGE,
  picUrl,
  alt = 'profile pic',
}: ProfilePicProps) {
  return (
    <div className={classNames('profile-pic', classes)}>
      <div className={classNames('profile-pic-wrapper', `profile-pic-${size}`)}>
        {children ?? <img src={picUrl} alt={alt} className="profile-pic-img" />}
      </div>
    </div>
  );
}
