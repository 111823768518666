import _str from 'underscore.string';
import React from 'react';
import Helpers from 'helpers/helpers';
import classNames from 'classnames';

interface BaseAuthenticateButtonProps {
  authUrl: string;
  redirectPath: string;
  children?: React.ReactNode;
  classes?: string;
}

export default function BaseAuthenticateButton({
  children,
  classes,
  authUrl,
  redirectPath,
}: BaseAuthenticateButtonProps) {
  const csrfToken = Helpers.Utils.getCSRFToken();

  return (
    <div className={classNames('base-authenticate-button', classes)}>
      <form action={authUrl} method="post">
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        {!_str.isBlank(redirectPath) && (
          <input
            type="hidden"
            name="omniauth_redirect_path"
            value={redirectPath}
          />
        )}
        {children}
      </form>
    </div>
  );
}
