import React from 'react';
import _str from 'underscore.string';
import I18nContext from 'contexts/i18n_context';

import { FormSwitchField } from 'components/forms/forms';

export default function NotificationSettingRow({
  i18nRowScope,
  emailSettingName,
  label = null,
  description = null,
}: {
  i18nRowScope: string;
  emailSettingName: string;
  label?: string | null;
  description?: string | null;
}) {
  const { i18n } = React.useContext(I18nContext);

  const snakeCaseSettingName = _str.underscored(emailSettingName);
  const labelText =
    label ??
    i18n.t(`fields.${snakeCaseSettingName}.label`, { scope: i18nRowScope });
  const descriptionText =
    description ??
    i18n.t(`fields.${snakeCaseSettingName}.description`, {
      scope: i18nRowScope,
    });

  return (
    <div className="setting-grid-row">
      <FormSwitchField
        label={labelText}
        description={descriptionText}
        name={emailSettingName}
      />
    </div>
  );
}
