import { assertNotNullOrUndefined } from 'h';
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import Helpers from 'helpers/helpers';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import classNames from 'classnames';
import { Area, ReferenceLine } from 'recharts';
import {
  cardinal10TensionFn,
  TimeSeriesAreaChart,
  getStrokeAndFillForLinearGradient,
  ChartLinearGradient,
  LINEAR_GRADIENT_COLOR_SCHEMES,
} from 'components/utils/charts_wrapper';
import MembershipsList from 'components/clubs/admin/overview_page/memberships_list';

import { ClubId } from 'types/types';
import { ClubMembershipSearchFiltersEnum } from '__generated__/graphql';

const i18nScope = 'clubs.admin.overview';

const GET_ADMIN_CLUB_OVERVIEW_SUMMARY = gql(`
  query GetAdminClubOverviewMetricsSummary($clubId: ID!) {
    club(id: $clubId) {
      id
      overviewMetricsSummary {
        numberOfActiveMemberships
        numberOfNewMembershipsLast30Days
        numberOfChurnedMembershipsLast30Days
        membersTimeSeries
        membersTimeSeriesReferenceLineLabel
      }
    }
  }
`);

export default function OverviewPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);

  const { loading, error, data } = useQuery(GET_ADMIN_CLUB_OVERVIEW_SUMMARY, {
    variables: {
      clubId: String(currentClubId),
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const overviewMetricsSummary = club.overviewMetricsSummary;
  assertNotNullOrUndefined(overviewMetricsSummary);

  const {
    numberOfActiveMemberships,
    numberOfNewMembershipsLast30Days,
    numberOfChurnedMembershipsLast30Days,
    membersTimeSeries,
    membersTimeSeriesReferenceLineLabel,
  } = overviewMetricsSummary;

  const gradientColorScheme = LINEAR_GRADIENT_COLOR_SCHEMES[0];
  const chart = {
    dataKey: 'members',
    ...getStrokeAndFillForLinearGradient(gradientColorScheme),
  };

  return (
    <div id="clubs-admin-overview-page" className="elevate-content">
      <div className="mb-4">
        <h1>
          {i18n.t('heading', {
            count: numberOfActiveMemberships,
            scope: i18nScope,
          })}
        </h1>
        <p
          className={classNames('sub-heading mt-0', {
            'color-joined': numberOfNewMembershipsLast30Days > 0,
            'color-churned': numberOfChurnedMembershipsLast30Days > 0,
          })}
          dangerouslySetInnerHTML={{
            __html: i18n.t('summary_of_last_30_days_html', {
              scope: i18nScope,
              values: {
                joinedCount: numberOfNewMembershipsLast30Days,
                churnedCount: numberOfChurnedMembershipsLast30Days,
              },
            }),
          }}
        />
      </div>

      <div className="members-time-series-wrapper">
        <TimeSeriesAreaChart
          data={membersTimeSeries}
          xDataKey="label"
          tooltipContentCallback={({ payload, label }) => {
            return (
              <div className="data-point-tooltip">
                {label} <br />
                {payload.map((chart, index) => {
                  return (
                    <div
                      key={index}
                      className="bold"
                      style={{ color: chart.color }}
                    >
                      {chart.value} {chart.name}
                    </div>
                  );
                })}
              </div>
            );
          }}
        >
          <>
            <defs>
              <ChartLinearGradient scheme={gradientColorScheme} />
            </defs>
            <Area
              type={cardinal10TensionFn}
              strokeWidth={3}
              dataKey={chart.dataKey}
              stroke={chart.stroke}
              fill={chart.fill}
            />
            <ReferenceLine
              x={membersTimeSeriesReferenceLineLabel}
              stroke="grey"
              strokeWidth={2}
            />
          </>
        </TimeSeriesAreaChart>
      </div>

      <div className="memberships-lists mt-6">
        <MembershipsList
          clubId={currentClubId}
          heading={i18n.t('memberships_list.joined_recently.heading', {
            scope: i18nScope,
          })}
          getInfo={(membership) =>
            `Joined on ${Helpers.Utils.formatDate(membership.startsAt)}`
          }
          filter={ClubMembershipSearchFiltersEnum.JoinedRecently}
        />
        <MembershipsList
          clubId={currentClubId}
          heading={i18n.t('memberships_list.overdue.heading', {
            scope: i18nScope,
          })}
          getInfo={(membership) =>
            `Expired on ${Helpers.Utils.formatDate(membership.expiresAt)}`
          }
          filter={ClubMembershipSearchFiltersEnum.Overdue}
        />
        <MembershipsList
          clubId={currentClubId}
          heading={i18n.t('memberships_list.expired_recently.heading', {
            scope: i18nScope,
          })}
          getInfo={(membership) =>
            `Expired on ${Helpers.Utils.formatDate(membership.expiresAt)}`
          }
          filter={ClubMembershipSearchFiltersEnum.ExpiredRecently}
        />
        <MembershipsList
          clubId={currentClubId}
          heading={i18n.t('memberships_list.renewing_soon.heading', {
            scope: i18nScope,
          })}
          getInfo={(membership) =>
            `Expires on ${Helpers.Utils.formatDate(membership.expiresAt)}`
          }
          filter={ClubMembershipSearchFiltersEnum.RenewingSoon}
        />
      </div>
    </div>
  );
}
