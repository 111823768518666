import _ from 'underscore';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import {
  FormActions,
  FormActionButton,
  FormSwitchField,
  FormField,
  FormFieldWrapper,
} from 'components/forms/forms';
import ImageUploader from 'components/utils/image_uploader';
import Helpers from 'helpers/helpers';
import ModalSidebar from 'components/utils/modal_sidebar';

const i18nScope = 'clubs.admin.profile.meta_form';

const ProfilePageMetaSchema = Yup.object().shape({
  path: Yup.string()
    .required(window.translations.forms.errors.required)
    .matches(/^\w*$/, window.translations.forms.errors.alphanumeric),
});

export default function ProfilePageMetaForm({
  i18n,
  profilePage,
  onSaveCallback,
}) {
  const _onSubmit = function (values, actions) {
    Helpers.Form.save({
      values,
      actions,
      main: () => {
        const url = Helpers.Routes.getClubAdminProfilePagePath(
          profilePage.clubId,
          profilePage.id,
        );
        const params = { clubProfilePage: values };
        const options = { shouldUseFormData: true };
        return Helpers.API.put({ url, params, options });
      },
      successCallback: onSaveCallback,
    });
  };

  const pages = [
    {
      id: 'general',
      displayTextOrElement: i18n.t('pages.general', { scope: i18nScope }),
      fn: _renderGeneralSection,
    },
    {
      id: 'seo',
      displayTextOrElement: i18n.t('pages.seo', { scope: i18nScope }),
      fn: _renderSEOSection,
    },
  ];

  const [selectedPage, setSelectedPage] = useState(_.first(pages));

  return (
    <div id="profile-page-meta-form">
      <ModalSidebar
        pages={pages}
        selectedPage={selectedPage}
        onSelectPageCallback={(page) => setSelectedPage(page)}
      >
        <Formik
          initialValues={_.pick(
            profilePage,
            'ogTitle',
            'ogDescription',
            'ogImage',
            'path',
            'title',
            'enabled',
          )}
          validationSchema={ProfilePageMetaSchema}
          onSubmit={_onSubmit}
        >
          {({ setFieldTouched, setFieldValue, isSubmitting }) => (
            <Form className="basic-form double-spaced-fields" noValidate>
              {pages.map((page) => (
                <div key={page.id}>
                  {page.fn({
                    selectedPage,
                    i18n,
                    profilePage,
                    setFieldTouched,
                    setFieldValue,
                  })}
                </div>
              ))}
              <div className="actions">
                <FormActions>
                  <FormActionButton
                    text={i18n.t('submit', { scope: i18nScope })}
                    className="primary"
                    isSubmitting={isSubmitting}
                  />
                </FormActions>
              </div>
            </Form>
          )}
        </Formik>
      </ModalSidebar>
    </div>
  );
}

function _renderGeneralSection({ selectedPage, i18n }) {
  return (
    <div
      className={classNames('meta-form-page', {
        hide: selectedPage.id !== 'general',
      })}
    >
      <h2 className="meta-form-page-title">
        {i18n.t('pages.general', { scope: i18nScope })}
      </h2>
      <div className="meta-form-page-fields">
        <FormSwitchField
          name="enabled"
          label={i18n.t('general.enabled.label', { scope: i18nScope })}
          description={i18n.t('general.enabled.description', {
            scope: i18nScope,
          })}
        />
        <FormField
          type="text"
          name="path"
          label={i18n.t('general.path.label', { scope: i18nScope })}
          placeholder={i18n.t('general.path.placeholder', { scope: i18nScope })}
        />
        <FormField
          type="text"
          name="title"
          label={i18n.t('general.title.label', { scope: i18nScope })}
          placeholder={i18n.t('general.title.placeholder', {
            scope: i18nScope,
          })}
          description={i18n.t('general.title.description', {
            scope: i18nScope,
          })}
        />
      </div>
    </div>
  );
}

function _renderSEOSection({
  selectedPage,
  i18n,
  profilePage,
  setFieldTouched,
  setFieldValue,
}) {
  const placeholderOgImageUrl = 'https://via.placeholder.com/400x300';

  return (
    <div
      className={classNames('meta-form-page', {
        hide: selectedPage.id !== 'seo',
      })}
    >
      <h2 className="meta-form-page-title">
        {i18n.t('pages.seo', { scope: i18nScope })}
      </h2>
      <div className="meta-form-page-fields">
        <FormFieldWrapper name="ogImage">
          <ImageUploader
            name="ogImage"
            classes="og-image-uploader"
            existingImageUrl={profilePage.ogImageUrl ?? placeholderOgImageUrl}
            aspectRatio={4 / 3}
            onChangeCallback={(name, croppedImage) => {
              setFieldValue(name, croppedImage);
              setFieldTouched(name, true);
            }}
            cropModalTitle={i18n.t('seo.og_image.crop_modal_title', {
              scope: i18nScope,
            })}
            cropModalSubmit={i18n.t('seo.og_image.crop_modal_submit', {
              scope: i18nScope,
            })}
          />
        </FormFieldWrapper>
        <FormField
          type="text"
          name="ogTitle"
          placeholder={i18n.t('seo.og_title', { scope: i18nScope })}
        />
        <FormField
          type="text"
          name="ogDescription"
          placeholder={i18n.t('seo.og_description', { scope: i18nScope })}
        />
      </div>
    </div>
  );
}
