import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  GetCurrentUserNotificationsSettingsQuery,
  UserNotificationsSettingUpdateMutation,
} from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
} from 'components/forms/forms';

import { ClubProfilePic, picSize } from 'components/utils/profile_pic';
import NotificationSettingRow from 'components/users/my_account/notifications/notification_setting_row';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';

const i18nScope = 'components.users.my_account.notifications';
const unsFormI18nScope = `${i18nScope}.user_notifications_setting.form`;

type UserNotificationsSettingData = NonNullable<
  GetCurrentUserNotificationsSettingsQuery['currentUser']
>['userNotificationsSetting'];

type MembershipNotificationsSettingPreviews = NonNullable<
  GetCurrentUserNotificationsSettingsQuery['currentUser']
>['memberships'];

type UserNotificationsSettingFormValues = Pick<
  UserNotificationsSettingData,
  'recommendedClubsEmail' | 'platformUpdatesEmail' | 'platformSurveysEmail'
>;

const USER_NOTIFICATIONS_SETTING_UPDATE = gql(`
  mutation UserNotificationsSettingUpdate($input: UserNotificationsSettingUpdateInput!) {
    userNotificationsSettingUpdate(input: $input) {
      userNotificationsSetting {
        id
        recommendedClubsEmail
        platformUpdatesEmail
        platformSurveysEmail
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

const GET_NOTIFICATIONS_SETTING = gql(`
  query GetCurrentUserNotificationsSettings {
    currentUser {
      id
      userNotificationsSetting {
        id
        recommendedClubsEmail
        platformUpdatesEmail
        platformSurveysEmail
      }
      memberships {
        id
        club {
          id
          name
          logoUrl
          slug {
            name
          }
        }
        membershipNotificationsSetting {
          id
        }
      }
    }
  }
`);

function UserNotificationsSettingForm({
  userNotificationsSetting,
  onSuccessCallback,
}: {
  userNotificationsSetting: UserNotificationsSettingData;
  onSuccessCallback: (updatedUNS: UserNotificationsSettingData) => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const [updateMutation] = useMutation(USER_NOTIFICATIONS_SETTING_UPDATE);

  const onSubmit = function (
    values: UserNotificationsSettingFormValues,
    actions: FormikHelpers<UserNotificationsSettingFormValues>,
  ) {
    sendMutationAndUpdateForm<
      UserNotificationsSettingFormValues,
      UserNotificationsSettingUpdateMutation
    >({
      actions,
      mutationName: 'userNotificationsSettingUpdate',
      resetSpinnerAfterSuccess: true,
      main: () =>
        updateMutation({
          variables: {
            input: {
              id: userNotificationsSetting.id,
              ...values,
            },
          },
        }),
      successCallback: (mutationPayload) => {
        const updatedUNS =
          mutationPayload?.userNotificationsSettingUpdate
            ?.userNotificationsSetting;
        assertNotNullOrUndefined(updatedUNS);
        onSuccessCallback(updatedUNS);
      },
    });
  };

  const generalRowProps = { i18nRowScope: unsFormI18nScope };
  const rows: {
    emailSettingName: keyof UserNotificationsSettingFormValues;
  }[] = [
    { emailSettingName: 'recommendedClubsEmail' },
    { emailSettingName: 'platformUpdatesEmail' },
    { emailSettingName: 'platformSurveysEmail' },
  ];

  const allAttributeNames: (keyof UserNotificationsSettingFormValues)[] = [];
  rows.map((row) => {
    if (row.emailSettingName) {
      allAttributeNames.push(row.emailSettingName);
    }
  });

  return (
    <div className="notifications-setting-grid-form">
      <Formik
        initialValues={_.pick(userNotificationsSetting, allAttributeNames)}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            {rows.map((row, index) => (
              <NotificationSettingRow
                key={index}
                {...generalRowProps}
                {...row}
              />
            ))}
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('actions.submit', { scope: unsFormI18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function ClubsSection({
  membershipNotificationsSettingPreviews,
}: {
  membershipNotificationsSettingPreviews: MembershipNotificationsSettingPreviews;
}) {
  const { i18n } = React.useContext(I18nContext);

  // TODO sort by membership state (active then expired), then maybe alphabetically
  // for now it's okay folks won't have that many listed

  if (
    membershipNotificationsSettingPreviews === null ||
    typeof membershipNotificationsSettingPreviews === 'undefined' ||
    membershipNotificationsSettingPreviews.length === 0
  ) {
    return null;
  }

  return (
    <div className="mt-3">
      <hr className="hr" />
      <h4>{i18n.t('clubs_section.heading', { scope: i18nScope })}</h4>
      {membershipNotificationsSettingPreviews.map(
        (membershipNotificationsSettingPreview) => {
          const club = membershipNotificationsSettingPreview.club;
          assertNotNullOrUndefined(club);
          const membershipNotificationsSetting =
            membershipNotificationsSettingPreview.membershipNotificationsSetting;
          assertNotNullOrUndefined(membershipNotificationsSetting);

          return (
            <div key={membershipNotificationsSetting.id} className="mt-3">
              <ClubProfilePic
                classes="inline-block mr-1"
                club={club}
                size={picSize.MEDIUM}
              />
              {membershipNotificationsSettingPreview.club.name}
              <Link className="ml-3" to={`./${club.slug.name}`}>
                {i18n.t('clubs_section.edit', { scope: i18nScope })}
              </Link>
            </div>
          );
        },
      )}
    </div>
  );
}

export default function UserNotificationsPage() {
  const { i18n } = React.useContext(I18nContext);
  const [userNotificationsSetting, setUserNotificationsSetting] =
    useState<UserNotificationsSettingData | null>(null);

  const { loading, error, data } = useQuery(GET_NOTIFICATIONS_SETTING, {
    onCompleted: (newData) => {
      assertNotNullOrUndefined(newData.currentUser);
      setUserNotificationsSetting(newData.currentUser.userNotificationsSetting);
    },
  });
  if (loading || userNotificationsSetting === null) return <LoadingPage />;
  if (error) return <ErrorPage />;
  assertNotNullOrUndefined(data?.currentUser);

  const membershipNotificationsSettingPreviews = data.currentUser.memberships;
  return (
    <div id="notifications-page">
      <div className="page-header">
        <div className="title">
          <h1>{i18n.t('title', { scope: i18nScope })}</h1>
        </div>
      </div>
      <div className="elevate-content">
        <UserNotificationsSettingForm
          userNotificationsSetting={userNotificationsSetting}
          onSuccessCallback={(updatedUNS) => {
            setUserNotificationsSetting(updatedUNS);
          }}
        />
        <ClubsSection
          membershipNotificationsSettingPreviews={
            membershipNotificationsSettingPreviews
          }
        />
      </div>
    </div>
  );
}
