import React from 'react';
import { useField } from 'formik';
import FormFieldWrapper, {
  FormFieldwrapperProps,
} from 'components/forms/form_field_wrapper';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

type FormDateFieldProps = Omit<FormFieldwrapperProps, 'children'> & {
  showTimeSelect?: true;
  showTimeSelectOnly?: true;
  timeIntervals?: number;
  timeCaption?: '';
  dateFormat?: string;
  minDate?: Date;
  onChangeCallback?: (newValue: Date, oldValue: Date) => void;
};

export default function FormDateField({
  onChangeCallback,
  classes,
  name,
  label,
  description,
  withoutErrorMessage,
  ...otherProps
}: FormDateFieldProps) {
  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;
  const onChange = (newValue: Date) => {
    const oldValue = field.value;
    setValue(newValue);
    setTouched(true);
    onChangeCallback?.(newValue, oldValue);
  };

  return (
    <FormFieldWrapper
      name={name}
      classes={classes}
      label={label}
      description={description}
      withoutErrorMessage={withoutErrorMessage}
    >
      <div className="form-field date-form-field">
        <DatePicker
          showPopperArrow={false}
          highlightDates={[new Date()]}
          selected={field.value}
          onSelect={onChange}
          onChange={onChange}
          {...otherProps}
        />
      </div>
    </FormFieldWrapper>
  );
}
