import React from 'react';

import Helpers from 'helpers/helpers';

import ImageUploader, {
  ImageUploaderProps,
} from 'components/utils/image_uploader';

import {
  ModelId,
  RemoteImageImageType,
  RemoteImageSubjectType,
  UnparsedRemoteImage,
} from 'types/types';
import { AxiosResponse } from 'axios';

export interface RemoteImageUploadConfig {
  imageType: RemoteImageImageType;
  subjectType: RemoteImageSubjectType;
  subjectId: ModelId;
}

type RemoteImageUploaderProps = Pick<
  ImageUploaderProps,
  | 'children'
  | 'classes'
  | 'name'
  | 'aspectRatio'
  | 'cropModalTitle'
  | 'cropModalSubmit'
  | 'existingImageUrl'
> & {
  remoteImageUploadConfig: RemoteImageUploadConfig;
  onChangeCallback: (
    unparsedRemoteImage: UnparsedRemoteImage,
    fieldName: string,
  ) => void;
  errorMessage: string;
};

// this is used in form content editable component where we extend
// the image extension to enable drag images into the editor. When
// a user drags an image we then upload it and insert it. I placed
// the code for it here since the logic to upload a remote image
// is basically copied from the RemoteImageUploader. Eventually we
// can consolidate them but wasn't a priority for now.
export function getRemoteImageUploadFn(
  remoteImageUploadConfig: RemoteImageUploadConfig,
  errorMessage: string,
) {
  return async function (image: File): Promise<string> {
    const url = Helpers.Routes.getRemoteImagesPath();
    const { imageType, subjectType, subjectId } = remoteImageUploadConfig;
    const values = { imageType, subjectType, subjectId, image };
    const params = { remoteImage: values };
    const options = { shouldUseFormData: true };
    try {
      const response = await Helpers.API.post({ url, params, options });
      const unparsedRemoteImage = (
        response as AxiosResponse<UnparsedRemoteImage>
      ).data;
      return unparsedRemoteImage.data.attributes.imageUrl;
    } catch (error) {
      alert(errorMessage);
      throw error;
    }
  };
}

export default function RemoteImageUploader({
  children,
  remoteImageUploadConfig,
  onChangeCallback,
  errorMessage,
  ...otherProps
}: RemoteImageUploaderProps) {
  const _setUploadImageAndSetId = (fieldName: string, image: File) => {
    const { imageType, subjectType, subjectId } = remoteImageUploadConfig;
    const values = { imageType, subjectType, subjectId, image };
    const params = { remoteImage: values };
    const options = { shouldUseFormData: true };
    const url = Helpers.Routes.getRemoteImagesPath();
    return Helpers.API.post({ url, params, options })
      .then((response) => {
        const unparsedRemoteImage = (
          response as AxiosResponse<UnparsedRemoteImage>
        ).data;
        onChangeCallback(unparsedRemoteImage, fieldName);
      })
      .catch(() => {
        alert(errorMessage);
      });
  };

  return (
    <div className="remote-image-uploader">
      <ImageUploader {...otherProps} onChangeCallback={_setUploadImageAndSetId}>
        {children}
      </ImageUploader>
    </div>
  );
}
