import { assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  EmergencyContact,
  EmergencyContactCreateOrUpdateMutation,
} from '__generated__/graphql';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  FormActions,
  FormActionButton,
  FormField,
  FormServerErrorMessages,
} from 'components/forms/forms';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';

const i18nScope = 'components.apps.emergency_contacts.form';

export function buildNewEmergencyContact(): EmergencyContactFormValues {
  return {
    name: '',
    phoneNumber: '',
    relationship: '',
  };
}

export type EmergencyContactFormValues = Pick<
  EmergencyContact,
  'name' | 'phoneNumber' | 'relationship'
>;

const EMERGENCY_CONTACT_CREATE_OR_UPDATE = gql(`
  mutation EmergencyContactCreateOrUpdate($input: EmergencyContactCreateOrUpdateInput!) {
    emergencyContactCreateOrUpdate(input: $input) {
      emergencyContact {
        id
        name
        phoneNumber
        relationship
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export default function EmergencyContactForm({
  isEditing,
  emergencyContact,
  onSuccessCallback,
  onCancelCallback,
  hideTitle,
  overrideSubmitActionText,
  overrideCancelActionText,
}: {
  isEditing: boolean;
  emergencyContact: EmergencyContactFormValues & {
    id?: EmergencyContact['id'];
  };
  onSuccessCallback: (
    savedEmergencyContact: EmergencyContactFormValues & {
      id: EmergencyContact['id'];
    },
  ) => void;
  onCancelCallback: () => void;
  hideTitle?: boolean;
  overrideSubmitActionText?: string;
  overrideCancelActionText?: string;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [emergencyContactCreateOrUpdateMutation] = useMutation(
    EMERGENCY_CONTACT_CREATE_OR_UPDATE,
  );

  const initialValues = _.pick(
    emergencyContact,
    'name',
    'phoneNumber',
    'relationship',
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .min(2, i18n.t('forms.errors.min_length'))
          .required(i18n.t('forms.errors.required')),
        phoneNumber: Yup.string()
          .min(10, i18n.t('forms.errors.min_length'))
          .max(25, i18n.t('forms.errors.max_length'))
          .required(i18n.t('forms.errors.required')),
        relationship: Yup.string().max(255, i18n.t('forms.errors.max_length')),
      }),
    [],
  );

  const _onSubmit = function (
    values: EmergencyContactFormValues,
    actions: FormikHelpers<EmergencyContactFormValues>,
  ) {
    sendMutationAndUpdateForm<
      EmergencyContactFormValues,
      EmergencyContactCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'emergencyContactCreateOrUpdate',
      main: () =>
        emergencyContactCreateOrUpdateMutation({
          variables: {
            input: {
              id: emergencyContact?.id,
              ...values,
            },
          },
        }),
      successCallback: (mutationPayload) => {
        const savedEmergencyContact =
          mutationPayload?.emergencyContactCreateOrUpdate?.emergencyContact;
        assertNotNullOrUndefined(savedEmergencyContact);
        onSuccessCallback(savedEmergencyContact);
      },
    });
  };

  const _getTitle = () => {
    if (hideTitle) {
      return null;
    }

    const title = isEditing
      ? i18n.t('title.edit', { scope: i18nScope })
      : i18n.t('title.add', { scope: i18nScope });

    return <h1>{title}</h1>;
  };

  return (
    <div>
      {_getTitle()}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormField
              autoFocus={true}
              type="text"
              name="name"
              label={i18n.t('name.label', { scope: i18nScope })}
            />
            <FormField
              type="text"
              name="phoneNumber"
              label={i18n.t('phone_number.label', { scope: i18nScope })}
              placeholder={i18n.t('phone_number.placeholder', {
                scope: i18nScope,
              })}
            />
            <FormField
              type="text"
              name="relationship"
              label={i18n.t('relationship.label', { scope: i18nScope })}
            />
            <FormActions className="rtl">
              <FormActionButton
                text={
                  overrideSubmitActionText ??
                  i18n.t(
                    isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                    { scope: i18nScope },
                  )
                }
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={
                  overrideCancelActionText ??
                  i18n.t('actions.cancel', { scope: i18nScope })
                }
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
