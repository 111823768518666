import React, { useEffect, useRef, useState } from 'react';
import I18nContext from 'contexts/i18n_context';
import classNames from 'classnames';

const i18nScope = 'components.utils.show_more_or_less';

export default function ShowMoreOrLess({
  children,
}: {
  children: React.ReactNode;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [shouldShowCta, setShouldShowCta] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      if (contentRef.current.scrollHeight > 300) {
        setShouldShowCta(true);
        setIsExpanded(false);
      }
    }
  }, [contentRef]);

  return (
    <div className="show-more-or-less">
      <div className="show-more-or-less-content-wrapper">
        <div
          ref={contentRef}
          className={classNames(
            'show-more-or-less-content',
            isExpanded ? 'expanded' : 'not-expanded',
          )}
        >
          {children}
        </div>
        {!isExpanded && <div className="show-more-or-less-gradient"></div>}
      </div>

      {shouldShowCta && (
        <div className="show-more-or-less-link">
          <a onClick={() => setIsExpanded((prev) => !prev)}>
            {i18n.t(isExpanded ? 'show_less' : 'show_more', {
              scope: i18nScope,
            })}
          </a>
        </div>
      )}
    </div>
  );
}
