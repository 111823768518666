// helpers
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};
interface OptionForSelectField {
  name: string;
  value: string;
}
export type OptionsForSelectField = OptionForSelectField[];

// when adding typescript to the codebase I had to take some
// shortcuts and used `any` in a few places. Goiing forward
// we don't allow `any` to be used. I wanted to make that clear
// in the places that do currently use it so I created this alias

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeprecatedAny = any;

export type DateYYYYMMDDString = string;
export type DateTimeISOString = string;
export type DateTimeMinutes = number;
export type DateTimeZone = string;

export type Latitude = number;
export type Longitude = number;

export type EmailAddress = string;
export type Currency = string;

export type ModelId = number;
// ids
export type ClubId = number;
export type ClubAuditLogId = number;
export type ClubMetricsSummaryId = number;
export type ClubTypeId = number;
export type ClubEventId = number;
export type ClubProfileId = number;
export type ClubProfilePageId = number;
export type CommentId = number;
export type CompanyId = number;
export type CustomEmailId = number;
export type DiscussionId = number;
export type EmailId = number;
export type EmailBlastId = number;
export type EmergencyContactId = number;
export type EventHostId = number;
export type EventRsvpId = number;
export type MembershipId = number;
export type MembershipAuditLogId = number;
export type MembershipRenewalCancelationId = number;
export type MembershipNotificationsSettingId = number;
export type OrderId = number;
export type OrderItemId = number;
export type PermissionGroupId = number;
export type ProductId = number;
export type ProductVariantId = number;
export type RemoteImageId = number;
export type SignedWaiverId = number;
export type StorefrontId = number;
export type SubscriptionId = number;
export type SubscriptionPlanId = number;
export type SponsorshipId = number;
export type StripePaymentMethodId = number;
export type UserId = number;
export type UserNotificationsSettingId = number;
export type UserPreferenceId = number;
export type WaiverId = number;

export type AddOnName = string;

export interface UnparsedJSONModel<T> {
  data: T;
  included: DeprecatedAny[];
}

export interface RawModel<I, T, A> {
  id: I;
  type: T;
  attributes: A;
  relationships: { [key: string]: DeprecatedAny | DeprecatedAny[] };
}

export enum AuditLogType {
  MEMBERSHIP_AUDIT_LOG = 'MembershipAuditLog',
  CLUB_AUDIT_LOG = 'ClubAuditLog',
}
export interface BaseAuditLog {
  loggedAt: DateTimeISOString;
  isSystemGenerated: boolean;
  // createdBy?: Membership | User;
  createdByFullName: string | null;
}

// NOTE: if you change these then you MUST update the backend too
// and don't forget about cross version compatibility
export enum ClubAuditLogType {
  ADMIN_NOTE = 'admin_note',
  EXPORTED_MEMBERSHIPS = 'exported_memberships',
}

export interface ClubAuditLog extends BaseAuditLog {
  type: AuditLogType.CLUB_AUDIT_LOG;
  id: ClubAuditLogId;
  clubId: ClubId;
  logType: ClubAuditLogType;
  data: {
    message?: string;
  };
}

// NOTE: if you change these then you MUST update the backend too
// and don't forget about cross version compatibility
export enum MembershipAuditLogType {
  ADMIN_NOTE = 'admin_note',
  MEMBER_IMPORTED = 'member_imported',
  MANUAL_MEMBER_IMPORT = 'manual_member_import',
  MEMBER_JOINED = 'member_joined',
  MEMBER_RENEWED = 'member_renewed',
  // membership states
  STATE_SUBSCRIBED = 'state_subscribed',
  STATE_RENEWING_SOON = 'state_renewing_soon',
  STATE_OVERDUE = 'state_overdue',
  STATE_EXPIRED = 'state_expired',
  // membership renewals
  RENEWAL_CANCELED = 'renewal_canceled',
  RENEWAL_RESUMED = 'renewal_resumed',
}

export interface MembershipAuditLog extends BaseAuditLog {
  type: AuditLogType.MEMBERSHIP_AUDIT_LOG;
  id: MembershipAuditLogId;
  membershipId: MembershipId;
  logType: MembershipAuditLogType;
  data: {
    // HACK: it should be really set by the schema for each log type
    message?: string;
    feedback?: string;
  };
}

export type AuditLog = ClubAuditLog | MembershipAuditLog;

// NOTE: if you change these then you MUST update the backend too
// and don't forget about cross version compatibility
export enum ClubMetricsSummaryPeriodType {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}
export interface ClubMetricsSummary {
  id: ClubMetricsSummaryId;
  clubId: ClubId;
  startDate: DateYYYYMMDDString;
  endDate: DateYYYYMMDDString;
  periodType: ClubMetricsSummaryPeriodType;
  membershipsActiveCount: number;
  membershipsJoinedCount: number;
  membershipsOverdueCount: number | null;
  membershipsExpiredCount: number;
  wasMembershipsDataBackfilled: boolean;
  ordersTotalAmountInCents: number;
  ordersCount: number;
  ordersMembershipsTotalAmountInCents: number;
  ordersMembershipsCount: number;
  ordersStorefrontTotalAmountInCents: number;
  ordersStorefrontCount: number;
  eventsCount: number;
  eventsActiveMembersCount: number;
  eventsNumMembersByEventsAttendedCountSnapshot: {
    numEvents: number;
    numMembers: number;
  }[];
  eventsTopAttendeesPartialSnapshot: {
    attendeeId: MembershipId;
    count: number;
  };
}

export interface ClubProfile {
  id: ClubProfileId;
  headerPhotoUrl: string;
  description: string;
}
export type RawClubProfile = RawModel<
  ClubProfileId,
  'clubProfile',
  ClubProfile
>;
export type UnparsedClubProfile = UnparsedJSONModel<RawClubProfile>;

export interface Club {
  id: ClubId;
  clubTypeId: ClubTypeId;
  name: string;
  shortName: string;
  addOnNames: AddOnName[];
  logoUrl: string;
  website: string;
  city: string;
  state: string;
  countryAlpha3: string;
  timeZone: DateTimeZone;
  isClaimed: boolean;

  replyTo: EmailAddress;
  currency: Currency;

  latitude: null | Latitude;
  longitude: null | Longitude;
  // associations
  clubProfile?: ClubProfile;
  sponsors?: Company[];
}

export type RawClub = RawModel<ClubId, 'club', Club> & {
  links: {
    clubProfilePath: string;
    clubJoinPath: string;
    clubRenewPath: string;
    clubCancelPath: string;
  };
};
export type UnparsedClub = UnparsedJSONModel<RawClub>;

export interface ClubAsSuperAdmin extends Club {
  slugName: string;
  isListed: boolean;
}

export interface ClubType {
  name: string;
}

export type CommentAuthor = User | Membership;

export interface Comment {
  id: CommentId;
  body: string;
  author: CommentAuthor;
  createdAt: DateTimeISOString;
}
export type RawComment = RawModel<CommentId, 'comment', Comment>;
export type UnparsedComment = UnparsedJSONModel<RawComment>;

export interface Company {
  id: CompanyId;
  logoUrl: string;
  name: string;
  website: string;
}
export type RawCompany = RawModel<CompanyId, 'company', Company>;
export type UnparsedCompany = UnparsedJSONModel<RawCompany>;
export interface Discussion {
  id: DiscussionId;
  numberOfComments: number;
}

export interface EmailClick {
  timestamp: EpochTimeStamp;
  url: string;
}
export interface EmergencyContact {
  id: EmergencyContactId;
  name: string;
  phoneNumber: string;
  relationship: string;
}
export type RawEmergencyContact = RawModel<
  EmergencyContactId,
  'emergencyContact',
  EmergencyContact
>;
export type UnparsedEmergencyContact = UnparsedJSONModel<RawEmergencyContact>;

interface BaseEventHost {
  id: EventHostId;
}
export interface ClubEventHost extends BaseEventHost {
  subjectType: 'Club';
  subjectId: ClubId;
  subject?: Club;
}

export interface UserEventHost extends BaseEventHost {
  subjectType: 'User';
  subjectId: UserId;
  subject?: User;
}

export interface MembershipEventHost extends BaseEventHost {
  subjectType: 'Membership';
  subjectId: MembershipId;
  subject?: Membership;
}

export type EventHost = ClubEventHost | UserEventHost | MembershipEventHost;

// Event interface is already used to represent DOM Events, so I cannot use
// that word. So going with BaseEvent for now but not happy with this either
export interface BaseEvent {
  name: string;
  description: string;
  wasCanceled: boolean;
  hasEnded: boolean;
  startDate: DateYYYYMMDDString;
  startTimeInMinutes: DateTimeMinutes;
  startsAt: DateTimeISOString;
  endDate: DateYYYYMMDDString;
  endTimeInMinutes: DateTimeMinutes;
  endsAt: DateTimeISOString;
  isAllDay: boolean;
  timeZone: DateTimeZone;
  isVirtual: boolean;
  virtualMeetingUrl: string;
  googlePlaceId: string;
  locationName: string;
  location: string;
  longitude: Longitude | null;
  latitude: Latitude | null;
  attendeeProfilePicUrls: string[];
  areGuestsAllowed: boolean;
  shouldCapNumberOfAttendees: boolean;
  maxNumberOfAttendees: number | null;
  numberOfAttendees: number;
}

export interface ClubEvent extends BaseEvent {
  id: ClubEventId;
  clubId: ClubId;
  // associations
  eventHosts?: EventHost[];
}

export type RawClubEvent = RawModel<ClubEventId, 'clubEvent', ClubEvent> & {
  links: {
    clubEventPath: string;
    editClubEventPath: string;
  };
};

export type UnparsedClubEvent = UnparsedJSONModel<RawClubEvent>;

interface BaseEventRsvp {
  id: EventRsvpId;
  numberOfGuests: number;
}
export interface UserEventRsvp extends BaseEventRsvp {
  attendeeType: 'User';
  attendeeId: UserId;
  // association
  attendee?: User;
}

export interface MembershipEventRsvp extends BaseEventRsvp {
  attendeeType: 'Membership';
  attendeeId: MembershipId;
  // association
  attendee?: Membership;
}

export type EventRsvp = UserEventRsvp | MembershipEventRsvp;

export type RawEventRsvp = RawModel<string, 'eventRsvp', EventRsvp>;
export type UnparsedEventRsvp = UnparsedJSONModel<RawEventRsvp>;

// NOTE: if you change these then you MUST update the backend too
// and don't forget about cross version compatibility
export enum MembershipStates {
  DRAFT = 'draft',
  SUBSCRIBED = 'subscribed',
  RENEWING_SOON = 'renewing_soon',
  OVERDUE = 'overdue',
  EXPIRED = 'expired',
}

export interface Membership {
  id: MembershipId;
  state: MembershipStates;
  profilePicUrl: string;
  firstName: string;
  lastName: string;
  fullName: string;
  startsAt: DateYYYYMMDDString;
  firstJoinedAt: DateYYYYMMDDString;
  // associations
  club?: Club;
}
export interface MembershipAsOwner extends Membership {
  emailAddress: string;
  expiresAt: DateYYYYMMDDString;
  renewsAt: DateYYYYMMDDString;
  isRenewalCanceled: boolean;
}

export interface MembershipAsAdmin extends MembershipAsOwner {
  emailAddress: string;
  // associations
  subscriptions?: Subscription[];
  emergencyContacts?: EmergencyContact[];
  signedWaivers?: SignedWaiver[];
}

export type RawMembershipAsAdmin = RawModel<
  MembershipId,
  'membership',
  MembershipAsAdmin
>;

export type UnparsedMembershipAsAdmin = UnparsedJSONModel<RawMembershipAsAdmin>;

export interface MembershipRenewalCancelation {
  id: MembershipRenewalCancelationId;
  feedback: string;
}

export interface Order {
  id: OrderId;
  orderItems: OrderItem[];
}

export enum OrderItemSubjectType {
  SUBSCRIPTION_PLAN = 'SubscriptionPlan',
  PRODUCT = 'Product',
  PRODUCT_VARIANT = 'ProductVariant',
}
export interface OrderItem {
  id: OrderItemId;
  subjectType: OrderItemSubjectType;
  subjectId: ModelId;
  priceInCents: number;
  quantity: number;
  totalAmountInCents: number;
}
export interface Product {
  id: ProductId;
  position: number;
  name: string;
  description: string;
  priceInCents: number;
  isUnlimitedStock: boolean;
  isPubliclyVisible: boolean;
  publiclyVisibleAt: DateTimeISOString;
  imageUrls: string[];
  productVariants?: ProductVariant[];
}

export type RawProduct = RawModel<ProductId, 'product', Product>;

export type UnparsedProduct = UnparsedJSONModel<RawProduct>;

export interface ProductVariant {
  id: ProductVariantId;
  variant: string;
}

export enum RemoteImageImageType {
  CLUB_PROFILE_PAGE_SECTION = 'club_profile_page_section',
  CUSTOM_EMAIL = 'custom_email',
  CUSTOM_PAGE = 'custom_page',
  EVENT_DESCRIPTION = 'event_description',
}

export enum RemoteImageSubjectType {
  CUSTOM_EMAIL = 'CustomEmail',
  CUSTOM_PAGE = 'CustomPage',
  EVENT = 'Event',
}

export interface RemoteImage {
  id: RemoteImageId;
  imageUrl: string;
  imageType: RemoteImageImageType;
}

export type RawRemoteImage = RawModel<
  RemoteImageId,
  'remoteImage',
  RemoteImage
>;

export type UnparsedRemoteImage = UnparsedJSONModel<RawRemoteImage>;

export interface SignedWaiver {
  id: SignedWaiverId;
  waiverId: WaiverId;
  signedAt: DateTimeISOString;
  wasSignedOffPlatform: boolean;
  signedOffPlatformMessage: string;
}

export interface Sponsorship {
  id: SponsorshipId;
  position: number;
  companyId: CompanyId;
  deals: {
    description: string;
    redeemInstructions: string;
  }[];
  // associations
  company?: Company;
}

export type RawSponsorship = RawModel<
  SponsorshipId,
  'sponsorship',
  Sponsorship
> & {
  links: Record<string, never>; // never for now, but once we have a link then we can convert it to {}
  relationships: Record<string, unknown>;
};

export type UnparsedSponsorship = UnparsedJSONModel<RawSponsorship>;

export interface Storefront {
  id: StorefrontId;
  publiclyVisibleProducts: Product[];
}

export interface Subscription {
  id: SubscriptionId;
  subscriptionPlanId: SubscriptionPlanId;
}

export interface SubscriptionPlan {
  id: SubscriptionPlanId;
  name: string;
  position: number;
  frequency: 'annually' | 'biennially';
  priceInCents: number;
  available: boolean;
  autoRenew: boolean;
}

export type RawSubscriptionPlan = RawModel<
  SubscriptionPlanId,
  'subscriptionPlan',
  SubscriptionPlan
>;
export type UnparsedSubscriptionPlan = UnparsedJSONModel<RawSubscriptionPlan>;

export enum StripePaymentMethodPaymentMethodType {
  CARD = 'card',
}
export interface StripePaymentMethod {
  id: StripePaymentMethodId;
  brand: string;
  brandLogoUrl: string;
  paymentMethodType: StripePaymentMethodPaymentMethodType;
  expMonth: string;
  expYear: string;
  last4: string;
  isDefault: boolean;
}
export type RawStripePaymentMethod = RawModel<
  StripePaymentMethodId,
  'stripePaymentMethod',
  StripePaymentMethod
>;
export type UnparsedStripePaymentMethod =
  UnparsedJSONModel<RawStripePaymentMethod>;

export interface User {
  id: UserId;
  profilePicUrl: string;
  firstName: string;
  lastName: string;
  fullName: string;
}

export interface UserAsOwner extends User {
  emailAddress: EmailAddress;
  isPlaceholderEmailAddress: boolean;
}

export type RawUserAsOwner = RawModel<UserId, 'user', UserAsOwner>;
export type UnparsedUserAsOwner = UnparsedJSONModel<RawUserAsOwner>;
export interface Waiver {
  id: WaiverId;
  name: string;
  content: string;
  isRequired: boolean;
  signedCount: number;
  position: number;
}

export type RawWaiver = RawModel<WaiverId, 'waiver', Waiver> & {
  links: {
    showPath: string;
  };
  relationships: Record<string, unknown>;
};

export type UnparsedWaiver = UnparsedJSONModel<RawWaiver>;
