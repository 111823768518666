import _ from 'underscore';
import React from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  AdminClubCustomPageCreateOrUpdateMutation,
  Club,
  ClubCustomPage,
} from '__generated__/graphql';
import { Formik, Form, FormikHelpers } from 'formik';

import {
  FormActions,
  FormActionButton,
  FormServerErrorMessages,
  FormField,
  FormSwitchField,
} from 'components/forms/forms';
import { RemoteImageImageType, RemoteImageSubjectType } from 'types/types';

import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import I18nContext from 'contexts/i18n_context';
import FormContentEditableField from 'components/forms/form_content_editable_field';

const i18nScope = 'clubs.admin.pages.form';

export type CustomPageFormValues = Pick<
  ClubCustomPage,
  'isPublished' | 'icon' | 'title' | 'body'
>;

type CustomPageForForm = CustomPageFormValues;

const ADMIN_CLUB_CUSTOM_PAGE_CREATE_OR_UPDATE = gql(`
  mutation AdminClubCustomPageCreateOrUpdate($input: ClubCustomPageCreateOrUpdateInput!) {
    clubCustomPageCreateOrUpdate(input: $input) {
      customPage {
        id
        isPublished
        icon
        title
        body
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export function buildNewCustomPage(): CustomPageForForm {
  return {
    isPublished: false,
    icon: '📃',
    title: '',
    body: '',
  };
}

export default function CustomPageForm({
  isEditing,
  clubId,
  customPage,
  onSuccessCallback,
  onCancelCallback,
}: {
  isEditing: boolean;
  clubId: Club['id'];
  customPage: CustomPageForForm & {
    id: ClubCustomPage['id'];
  };
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  const [updateMutation] = useMutation(ADMIN_CLUB_CUSTOM_PAGE_CREATE_OR_UPDATE);

  const _onSubmit = function (
    values: CustomPageFormValues,
    actions: FormikHelpers<CustomPageFormValues>,
  ) {
    sendMutationAndUpdateForm<
      CustomPageFormValues,
      AdminClubCustomPageCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubCustomPageCreateOrUpdate',
      main: () =>
        updateMutation({
          variables: {
            input: {
              clubId,
              id: customPage.id,
              ...values,
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  const initialValues = _.pick(
    customPage,
    'isPublished',
    'icon',
    'title',
    'body',
  );

  return (
    <div className="membership-group-form">
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {({ values, status, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormSwitchField
              name="isPublished"
              style={{ maxWidth: 200 }}
              label={i18n.t('is_published.label', { scope: i18nScope })}
              description={i18n.t('is_published.description', {
                scope: i18nScope,
              })}
            />

            <FormField
              classes="blend title-form-field mt-4"
              autoFocus={true}
              type="text"
              name="title"
              placeholder={i18n.t('title.placeholder', { scope: i18nScope })}
            />

            <FormContentEditableField
              name="body"
              value={values.body}
              placeholder={i18n.t('body.placeholder', {
                scope: i18nScope,
              })}
              remoteImageUploadConfig={{
                imageType: RemoteImageImageType.CUSTOM_PAGE,
                subjectType: RemoteImageSubjectType.CUSTOM_PAGE,
                subjectId: Number(customPage.id),
              }}
            />

            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
