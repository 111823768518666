// centralizing all breakpoint helpers
import _ from 'underscore';

const SMALL_BREAK_POINT = 428;
const LARGE_BREAK_POINT = 1024;

export default class ScreenSizeHelper {
  static getIsWidthSmallerThanOrEqual(px: number) {
    // defaults to yes
    return (window?.innerWidth ?? 0) <= px;
  }

  static getIsMobile() {
    // defaults to yes
    return this.getIsWidthSmallerThanOrEqual(SMALL_BREAK_POINT);
  }

  static getIsTabletOrSmaller() {
    // defaults to yes
    return this.getIsWidthSmallerThanOrEqual(LARGE_BREAK_POINT - 1);
  }

  static useEffect(callback: () => void) {
    return () => {
      const debouncedHandleResize = _.debounce(callback, 100);
      window.addEventListener('resize', debouncedHandleResize);
      return () => window.removeEventListener('resize', debouncedHandleResize);
    };
  }
}
