import React from 'react';

import { Company } from 'types/types';
import SponsorLogosGrid from 'components/utils/sponsorships/sponsor_logos_grid';

interface ClubSponsorsSectionProps {
  sponsors: Company[];
  titleHtml: React.ReactElement;
}

export default function ClubSponsorsSection({
  sponsors,
  titleHtml,
}: ClubSponsorsSectionProps) {
  if (sponsors.length === 0) {
    return null;
  }

  return (
    <div className="section sponsors-section">
      <div className="center-content">
        <h2 className="section-heading">{titleHtml}</h2>
        <div className="sponsors">
          <SponsorLogosGrid sponsors={sponsors} />
        </div>
      </div>
    </div>
  );
}
