import React from 'react';

import ImageUploader from 'components/utils/image_uploader';
import I18nContext from 'contexts/i18n_context';
import { Company } from 'types/types';

const i18nScope = 'components.utils.images.company_logo_uploader';

interface CompanyLogoUploaderProps {
  name: string;
  company: Pick<Company, 'logoUrl'>;
  setFieldTouched: (name: string, wasTouched: boolean) => void;
  setFieldValue: (name: string, value: File) => void;
}

export default function CompanyLogoUploader({
  name,
  company,
  setFieldValue,
  setFieldTouched,
}: CompanyLogoUploaderProps) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <span className="company-logo-uploader">
      <ImageUploader
        name={name}
        classes="logo-uploader"
        existingImageUrl={company.logoUrl}
        aspectRatio={16 / 9}
        onChangeCallback={(name, croppedImage) => {
          setFieldValue(name, croppedImage);
          setFieldTouched(name, true);
        }}
        cropModalTitle={i18n.t('crop_modal.title', { scope: i18nScope })}
        cropModalSubmit={i18n.t('crop_modal.submit', { scope: i18nScope })}
      />
    </span>
  );
}
