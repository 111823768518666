import _ from 'underscore';
import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';

interface TippyBasicProps extends TippyProps {
  classes?: string;
  children?: React.ReactElement;
}

export default function TippyBasic({
  children,
  classes,
  ...props
}: TippyBasicProps) {
  const config = Object.assign({}, props);
  _.defaults(config, {
    arrow: false,
    delay: [500, 0],
    placement: 'bottom',
    animation: 'fade',
    trigger: 'mouseenter focus',
    hideOnClick: false,
    zIndex: 999, // modals begin in the 1000s
  });

  // if manually controlled then no trigger
  if (Object.prototype.hasOwnProperty.call(config, 'visible')) {
    delete config.trigger;
  }

  return (
    <Tippy className={classNames('tippy-basic', classes)} {...config}>
      {children}
    </Tippy>
  );
}
