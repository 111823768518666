import _ from 'underscore';
import React, { useContext, useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormFieldWrapper,
  FormServerErrorMessages,
} from 'components/forms/forms';
import ImageUploader from 'components/utils/image_uploader';
import Helpers from 'helpers/helpers';
import I18nContext from 'contexts/i18n_context';
import { PartialRecord, UnparsedUserAsOwner, UserAsOwner } from 'types/types';
import { AxiosResponse } from 'axios';
import I18n from 'helpers/i18n';

const i18nScope = 'components.clubs.onboarding.steps.profile.form';

type ProfileStepFormValues = {
  firstName: string;
  lastName: string;
  emailAddress?: string;
};

function getValidationSchema({
  shouldShowEmailAddressField,
  i18n,
}: {
  shouldShowEmailAddressField: boolean;
  i18n: I18n;
}) {
  const shape: PartialRecord<keyof ProfileStepFormValues, Yup.StringSchema> = {
    firstName: Yup.string()
      .required(i18n.t('forms.errors.required'))
      .min(2, i18n.t('forms.errors.min_length')),
    lastName: Yup.string()
      .required(i18n.t('forms.errors.required'))
      .min(2, i18n.t('forms.errors.min_length')),
  };

  if (shouldShowEmailAddressField) {
    shape.emailAddress = Yup.string()
      .required(i18n.t('forms.errors.required'))
      .email(i18n.t('forms.errors.invalid_email'));
  }

  return Yup.object().shape(shape);
}

function getInitialValues({
  currentUser,
  shouldShowEmailAddressField,
}: {
  currentUser: UserAsOwner;
  shouldShowEmailAddressField: boolean;
}) {
  const initialValues: ProfileStepFormValues = _.pick(
    currentUser,
    'profilePic',
    'firstName',
    'lastName',
  );
  if (shouldShowEmailAddressField) {
    // don't show the placeholder value
    initialValues.emailAddress = '';
  }
  return initialValues;
}

export default function ProfileStepForm({
  currentUser,
  onSuccessCallback,
  onCancelCallback,
}: {
  currentUser: UserAsOwner;
  onSuccessCallback: (unparsedUser: UnparsedUserAsOwner) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = useContext(I18nContext);
  // if they're using a placeholder email then we want to include it
  const shouldShowEmailAddressField = currentUser.isPlaceholderEmailAddress;
  // if we don't have their email
  const canSkipStep = !shouldShowEmailAddressField;

  const initialValues = useMemo(
    () => getInitialValues({ currentUser, shouldShowEmailAddressField }),
    [currentUser],
  );
  const validationSchema = useMemo(
    () => getValidationSchema({ shouldShowEmailAddressField, i18n }),
    [],
  );

  const _onSubmit = function (
    values: ProfileStepFormValues,
    actions: FormikHelpers<ProfileStepFormValues>,
  ) {
    Helpers.Form.save<ProfileStepFormValues>({
      actions,
      main: () => {
        const url = Helpers.Routes.getUserPath(currentUser.id);
        // don't include email if it's hidden
        const user_params = shouldShowEmailAddressField
          ? values
          : _.omit(values, 'emailAddress');
        const params = { user: user_params };
        return Helpers.API.put({
          url,
          params,
          options: { shouldUseFormData: true },
        });
      },
      successCallback: (response) => {
        onSuccessCallback?.(
          (response as AxiosResponse<UnparsedUserAsOwner>).data,
        );
      },
    });
  };

  return (
    <div className="profile-step-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ status, setFieldTouched, setFieldValue, isSubmitting }) => (
          <Form className="basic-form" noValidate>
            <FormFieldWrapper name="profilePic" classes="profile-pic-field">
              <div className="center mt-4 mb-1">
                {i18n.t('profile_pic.label', { scope: i18nScope })}
              </div>
              <ImageUploader
                name="profilePic"
                classes="profile-pic-uploader"
                existingImageUrl={currentUser.profilePicUrl}
                aspectRatio={1}
                onChangeCallback={(name, croppedImage) => {
                  setFieldValue(name, croppedImage);
                  setFieldTouched(name, true);
                }}
                cropModalTitle={i18n.t('profile_pic.crop_modal_title', {
                  scope: i18nScope,
                })}
                cropModalSubmit={i18n.t('profile_pic.crop_modal_submit', {
                  scope: i18nScope,
                })}
              />
            </FormFieldWrapper>
            <FormField
              type="text"
              name="firstName"
              label={i18n.t('first_name.label', { scope: i18nScope })}
            />
            <FormField
              type="text"
              name="lastName"
              label={i18n.t('last_name.label', { scope: i18nScope })}
            />
            {shouldShowEmailAddressField && (
              <FormField
                type="email"
                name="emailAddress"
                label={i18n.t('email_address.label', { scope: i18nScope })}
              />
            )}
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t('actions.submit', { scope: i18nScope })}
                className="primary"
                isSubmitting={isSubmitting}
              />
              {canSkipStep && (
                <FormActionButton
                  className="secondary"
                  text={i18n.t('actions.cancel', { scope: i18nScope })}
                  isDisabled={isSubmitting}
                  handleClick={onCancelCallback}
                />
              )}
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
